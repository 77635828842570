import { useEffect } from 'react'
import { NavLink, Outlet, useLoaderData } from 'react-router-dom'
import RequirementCard from './RequirementCard'
import styles from './RequirementDetailPage.module.css'
import * as requirementApi from '../../../api/v2/requirements'
import * as specApi from '../../../api/v2/specifications'
import { useBreadcrumbsContext } from '../../../context/BreadcrumbsContext'

interface RequirementDetailsLoaderData {
  specification: specApi.Specification | null
  requirement: requirementApi.Requirement | null
}

const RequirementDetailPage = () => {
  const { setBreadcrumbs } = useBreadcrumbsContext()
  const { specification, requirement } =
    useLoaderData() as RequirementDetailsLoaderData

  useEffect(() => {
    if (specification && requirement) {
      setBreadcrumbs([
        { text: 'Specifications', linkTo: '/specifications' },
        {
          text: specification.name,
          linkTo: `/specifications/${specification.id}/document`,
        },
        {
          text: requirement.title || '(No Title)',
          linkTo: `/specifications/${specification.id}/requirements/${requirement.id}`,
        },
      ])
    }
  }, [specification, requirement, setBreadcrumbs])

  return (
    <div className={styles.container}>
      {requirement && <RequirementCard requirement={requirement} />}
      <div className={styles.outletMenu}>
        <NavLink
          to="./history"
          className={({ isActive }) =>
            isActive
              ? `${styles.outletItem} ${styles.active}`
              : styles.outletItem
          }
        >
          History
        </NavLink>
        <NavLink
          to="./exceptions"
          className={({ isActive }) =>
            isActive
              ? `${styles.outletItem} ${styles.active}`
              : styles.outletItem
          }
        >
          Exceptions
        </NavLink>
        <NavLink
          to="./evidence"
          className={({ isActive }) =>
            isActive
              ? `${styles.outletItem} ${styles.active}`
              : styles.outletItem
          }
        >
          Evidence
        </NavLink>
      </div>
      <Outlet />
    </div>
  )
}

export default RequirementDetailPage
