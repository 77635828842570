import LoadingIndicator, {
  LoaderSize,
} from '../../../components/loading-indicator/LoadingIndicator'

export const pdfImportInProgress = (
  <span style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
    Importing PDF
    <LoadingIndicator size={LoaderSize.SMALL} />
  </span>
)

export const wordImportInProgress = (
  <span style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
    Importing Word Document
    <LoadingIndicator size={LoaderSize.SMALL} />
  </span>
)
