import Tag, { TAG_COLORS } from '.'
import { useAttributesContext } from '../../context/AttributesContext.tsx'

interface PhaseTagProps {
  phaseId: string
  onCancel?: () => void
}
const PhaseTag = (props: PhaseTagProps) => {
  const { phaseId, onCancel } = props
  const { getPhaseById } = useAttributesContext()
  const phase = getPhaseById(phaseId)
  const fontColor = phase?.metadata?.STYLES?.COLOR_FONT
  const backgroundColor = phase?.metadata?.STYLES?.COLOR_BG

  return (
    <Tag
      text={phase?.name}
      color={
        fontColor && backgroundColor
          ? {
              fontColor,
              backgroundColor,
            }
          : TAG_COLORS.gray3
      }
      onCancel={onCancel}
    />
  )
}

export default PhaseTag
