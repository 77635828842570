import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { getSpecificationAsset } from '../../../api/v2/assets.ts'
import * as blockApi from '../../../api/v2/blocks'
import { BlockType, ImageBlockData } from '../../../api/v2/blocks'
import * as requirementApi from '../../../api/v2/requirements'
import { useSpecificationContext } from '../../../context/SpecificationContext'

export interface VisibleBlock {
  block?: blockApi.Block<blockApi.BlockData>
  requirement?: requirementApi.Requirement
  imgSrc?: string
}

export interface VisibleBlockData {
  visibleBlocks: Record<string, VisibleBlock>
  setVisibleBlocks: Dispatch<SetStateAction<Record<string, VisibleBlock>>>
  fetchVisibleBlocks: (index?: number, showCount?: number) => Promise<void>
}

export const DEFAULT_SHOW_COUNT = 40

const getPrefetchIndex = (index: number, showCount: number) => {
  return Math.max(index - showCount - 1, 0)
}

const getPostFetchIndex = (
  index: number,
  showCount: number,
  maxIndex: number,
) => {
  return Math.min(index + showCount - 1, maxIndex)
}

const useVisibleBlocks: (
  blockIds: string[],
  specificationId: string,
  revisionId: string,
  documentId?: string,
) => VisibleBlockData = (blockIds, specificationId, revisionId, documentId) => {
  const { publicTenant } = useSpecificationContext()

  const [visibleBlocks, setVisibleBlocks] = useState<
    Record<string, VisibleBlock>
  >({})

  const lastUsedShowCount = useRef<number>(DEFAULT_SHOW_COUNT)
  const lastUsedParamIndex = useRef<number>()

  const fetchVisibleBlocks = useCallback(
    async (paramIndex?: number, showCountParam?: number, force?: boolean) => {
      if (!documentId || blockIds.length < 1) {
        return
      }

      const { getFilteredBlocks, getRequirements } = publicTenant
        ? {
            ...blockApi.publicTenantMethods,
            ...requirementApi.publicTenantMethods,
          }
        : { ...blockApi, ...requirementApi }

      const showCount = showCountParam ? showCountParam : DEFAULT_SHOW_COUNT
      const indexToFetch = paramIndex || 0
      const lastPrefetchIndex = getPrefetchIndex(
        lastUsedParamIndex.current || 0,
        lastUsedShowCount.current,
      )
      const lastPostFetchIndex = getPostFetchIndex(
        lastUsedParamIndex.current || 0,
        lastUsedShowCount.current,
        blockIds.length - 1,
      )

      if (
        // Are we fetching that index already?
        // Will the index being asked for already be in the last request once it resolves?
        !force &&
        lastUsedParamIndex.current !== undefined &&
        indexToFetch >= lastPrefetchIndex &&
        indexToFetch <= lastPostFetchIndex
      ) {
        // If so, wait for last request to resolve
        return
      }

      lastUsedShowCount.current = showCount
      lastUsedParamIndex.current = indexToFetch

      const { blocks } = await getFilteredBlocks(specificationId, documentId, {
        id: blockIds.filter(
          (_id, i) =>
            i >= getPrefetchIndex(indexToFetch, showCount) &&
            i <=
              getPostFetchIndex(indexToFetch, showCount, blockIds.length - 1),
        ),
      })
      const requirementIds = blocks
        .filter((b) => b.type === blockApi.BlockType.Requirement)
        .map((b) => b.id)

      const requirements = await getRequirements(specificationId, {
        ids: requirementIds,
        revisionIds: [revisionId],
      })

      const imageBlocks = blocks.filter((b) => b.type === BlockType.Image)
      let allAssets = {}

      for (const imageBlock of imageBlocks) {
        const blob = await getSpecificationAsset(
          specificationId,
          (imageBlock.data as ImageBlockData).assetId,
        )
        const src = URL.createObjectURL(blob || '')

        allAssets = { ...allAssets, [imageBlock.id]: src }
      }

      const data = blocks.reduce(
        (record, block) => ({
          ...record,
          [block.id]: {
            block,
            requirement: requirements.find((r) => r.id === block.id),
            imgSrc: allAssets[block.id],
          },
        }),
        {},
      )

      setVisibleBlocks((prev) => {
        return { ...prev, ...data }
      })
    },
    [blockIds, documentId, publicTenant, revisionId, specificationId],
  )

  // Responds to block actions that modify the block id and ordering
  useEffect(() => {
    fetchVisibleBlocks(
      lastUsedParamIndex.current,
      lastUsedShowCount.current,
      true,
    )
  }, [blockIds, fetchVisibleBlocks])

  return {
    visibleBlocks,
    setVisibleBlocks,
    fetchVisibleBlocks,
  }
}

export default useVisibleBlocks
