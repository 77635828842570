import { ResetAlt } from '@carbon/icons-react'
import React, {
  ComponentType,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styles from './Table.module.css'
import { ColumnResizer } from './useResizableColumns'
import useClickOutside from '../../hooks/useClickOutside'
import IconButton from '../icon-button/IconButton'

export interface TableData {
  id: string
}

type MultiSelect = {
  select?: (selected: any[]) => void
  remove?: (removed: any[]) => void
  selected?: boolean
}
export interface TableItem {
  data: any
  isExpanded?: boolean
  onExpand?: () => void
  multiSelect?: MultiSelect
}

export type HeaderAction = {
  multiSelected: string[]
  setMultiSelected?: Dispatch<SetStateAction<string[]>>
  rows?: string[]
}

export interface TableColumn {
  label: string
  prettyLabel?: string
  width?: string
  minWidth?: number
  transparent?: boolean
  Component: ComponentType<TableItem>
  HeaderAction?: ComponentType<HeaderAction>
}

type TableProps<T extends TableData> = {
  rowItems: Array<T>
  columns?: Array<TableColumn>
  columnResizer?: ColumnResizer
  onRowClick?: (rowData: T, ignoredRowClickColumns?: string[]) => void
  ignoredRowClickColumns?: string[]
  scrollToIndex?: number
  expandAll?: boolean
}

const Table = <T extends TableData>(props: TableProps<T>) => {
  const {
    rowItems,
    columns: columnsProp,
    columnResizer,
    onRowClick: onRowClickProp,
    ignoredRowClickColumns,
    scrollToIndex,
    expandAll,
  } = props

  const [expandedRow, setExpandedRow] = useState(-1)
  const expandedRef = useClickOutside(() => {
    setExpandedRow(-1)
  }, expandedRow >= 0)

  const columns = useMemo(
    () => (columnResizer ? columnResizer.columns : columnsProp ?? []),
    [columnResizer, columnsProp],
  )

  const columnTemplate = useMemo(
    () => columns.map((col) => col.width || 'max-content').join(' '),
    [columns],
  )

  const allColumnsHidden = columns.length === 0

  const scrollRef = useRef<HTMLTableCellElement>(null)

  useEffect(() => {
    if (scrollToIndex && scrollToIndex >= 0) {
      scrollRef.current?.scrollIntoView?.({ block: 'center' })
    }
  }, [scrollToIndex])

  const onRowClick = onRowClickProp
    ? (e: React.MouseEvent<HTMLTableRowElement>, rowData: T) => {
        if (ignoredRowClickColumns) {
          const columnLabel = (e?.target as HTMLElement)
            ?.closest('td')
            ?.getAttribute('data-column-label')

          if (
            // permit columns with empty string labels, we use empty string value for columns like actions
            (!!columnLabel || columnLabel === '') &&
            ignoredRowClickColumns.includes(columnLabel)
          ) {
            return
          }
        }

        return onRowClickProp(rowData, ignoredRowClickColumns)
      }
    : undefined

  return (
    <table
      className={styles.tableRoot}
      style={{ gridTemplateColumns: columnTemplate }}
      cellPadding={0}
      cellSpacing={0}
    >
      <thead>
        <tr>
          {columns.map(({ label, transparent }, index) => {
            const { onResizeColumn, resizeRefs, wasResized, clearSizing } =
              columnResizer ?? {}

            return (
              <th
                key={label}
                ref={(ref) => {
                  if (resizeRefs && ref) {
                    resizeRefs.current![index] = ref
                  }
                }}
                className={`${styles.headerCell} ${
                  transparent ? styles.headerTransparent : ''
                }`}
              >
                {label && <span>{label}</span>}
                {onResizeColumn && resizeRefs?.current && !transparent && (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    className={styles.resize}
                    onMouseDown={() => onResizeColumn(index)}
                  />
                )}
                {transparent && wasResized && (
                  <IconButton title="Reset column size" onClick={clearSizing}>
                    <ResetAlt />
                  </IconButton>
                )}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {allColumnsHidden && (
          <tr>
            <td className={styles.columnsHidden}>
              All columns are hidden. Select Display to enable columns.
            </td>
          </tr>
        )}
        {!allColumnsHidden &&
          rowItems.map((rowItem, index) => (
            <Fragment key={rowItem.id}>
              {index === scrollToIndex && (
                <tr>
                  <td
                    ref={scrollRef}
                    style={{
                      gridColumnStart: `span ${columns.length}`,
                      height: 0,
                      padding: 0,
                    }}
                  />
                </tr>
              )}
              <tr
                ref={(el) => {
                  if (expandedRow === index) {
                    expandedRef.current = el
                  }
                }}
                className={`${
                  index === scrollToIndex ? styles.highlight : ''
                } ${onRowClick ? styles.clickable : ''}`}
                onClick={onRowClick ? (e) => onRowClick(e, rowItem) : undefined}
              >
                {columns.map(({ Component, label }, colIndex) => {
                  const isTransparentCol = columns[colIndex]?.transparent
                  return (
                    <td
                      key={label}
                      className={`${styles.tableCell} ${
                        ignoredRowClickColumns?.includes(label)
                          ? styles.nonClickable
                          : ''
                      } ${isTransparentCol ? styles.cellTransparent : ''}`}
                      data-column-label={label}
                    >
                      <Component
                        data={{ ...rowItem, expandAll }}
                        isExpanded={expandAll || expandedRow === index}
                        onExpand={() => setExpandedRow(index)}
                      />
                    </td>
                  )
                })}
              </tr>
            </Fragment>
          ))}
      </tbody>
    </table>
  )
}

export default Table
