import Tag from '.'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import { getCssVar } from '../../lib/string.ts'

const TAG_COLORS = {
  [RevisionStatus.DRAFT]: {
    backgroundColor: getCssVar('--color-draft-bg'),
    fontColor: getCssVar('--color-draft-text'),
  },
  [RevisionStatus.REVIEW]: {
    backgroundColor: getCssVar('--color-review-bg'),
    fontColor: getCssVar('--color-review-text'),
  },
  [RevisionStatus.ACTIVE]: {
    backgroundColor: getCssVar('--color-active-bg'),
    fontColor: getCssVar('--color-active-text'),
  },
  [RevisionStatus.CLONING]: {
    backgroundColor: getCssVar('--color-archived-bg'),
    fontColor: getCssVar('--color-archived-text'),
  },
  [RevisionStatus.SUPERSEDED]: {
    backgroundColor: getCssVar('--color-archived-bg'),
    fontColor: getCssVar('--color-archived-text'),
  },
}

const RevisionStatusTag = (props: {
  status: RevisionStatus
  onCancel?: () => void
}) => {
  const { status, onCancel } = props
  return <Tag text={status} color={TAG_COLORS[status]} onCancel={onCancel} />
}

export default RevisionStatusTag
