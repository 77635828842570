import { Link as LinkIcon, WarningHexFilled } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import styles from './AddLinkedRequirementModal.module.css'
import Modal from './index.tsx'
import { Requirement, getRequirements } from '../../api/v2/requirements.ts'
import { getRevisions } from '../../api/v2/revisions.ts'
import {
  getAllSpecifications,
  getRequirementsToLink,
  GetRequirementToLinkResponse,
} from '../../api/v2/specifications.ts'
import { RequirementLink } from '../../context/MatrixContext.tsx'
import useSpecTree from '../../hooks/useSpecTree.ts'
import { LinkedRequirementType } from '../../types/enums.ts'
import Button, { BUTTON_COLORS } from '../button'
import InputDropdownSelection, {
  OptionType,
} from '../dropdown/InputDropdown.tsx'
import { toastError, toastSuccess } from '../toast'

const LinkLevelWarnings = ({
  shouldDisplayWarning,
}: {
  shouldDisplayWarning: { selectedLevel: number; currentLevel: number }
}) => {
  const { selectedLevel, currentLevel } = shouldDisplayWarning
  return (
    <div className={styles.warning}>
      <div>
        <WarningHexFilled size={24} />
      </div>
      <div>
        Warning: the requirement you are linking to is in a
        <strong> Level {selectedLevel}</strong> specification, where the current
        specification is <strong>Level {currentLevel}</strong>. Ensure you are
        linking in the right direction.
      </div>
    </div>
  )
}

export interface AddLinkedRequirementProps {
  linkType: LinkedRequirementType
  requirement: Requirement
  specificationId: string
  existingLinks: RequirementLink[]
  linkRequirements: (linkTarget: {
    documentId: string
    blockId: string
    specificationId: string
  }) => void
}

const AddLinkedRequirementModal = (
  props: AddLinkedRequirementProps & { closeModal: () => void },
) => {
  const {
    linkType,
    requirement: { title, id },
    specificationId,
    linkRequirements,
    existingLinks,
    closeModal,
  } = props

  const [specTreeMetadata, setspecTreeMetadata] = useState({})
  const { getSpecTreeInfo } = useSpecTree()

  const [selectedSpecOption, setSelectedSpecOption] = useState<OptionType>()
  const [selectedReqOption, setSelectedReqOption] = useState<OptionType>()

  const [specOptions, setSpecOptions] = useState<OptionType[]>([])
  const [reqOptions, setReqOptions] = useState<OptionType[]>([])
  const [filteredReqOptions, setFilteredReqOptions] = useState<OptionType[]>([])

  useEffect(() => {
    const fetchSpecsAndRequirements = async () => {
      const allSpecs = await getAllSpecifications()

      const specTreeInfo = await getSpecTreeInfo(allSpecs)

      setspecTreeMetadata(specTreeInfo)

      const fetchedSpecOptions = allSpecs.map((spec) => ({
        id: spec.id,
        label: spec.name,
        sublabel: spec.specificationIdentifier,
        data: {
          specificationId: spec.id,
          specificationName: spec.name,
        },
      }))
      const specMap = Object.fromEntries(
        fetchedSpecOptions.map((spec) => [spec.id, spec.sublabel]),
      )
      setSpecOptions(fetchedSpecOptions)

      const requirements: GetRequirementToLinkResponse[] =
        await getRequirementsToLink({ limit: 5000 })

      const fetchedReqOptions = requirements.map((req) => ({
        id: req.id,
        label: req.title || '(No Title)',
        sublabel: `${specMap[req.specificationId] || 'Document Number'}-${
          req.requirementIdentifier
        }`,
        data: {
          documentId: req.documentId,
          specificationId: req.specificationId,
        },
      }))

      setReqOptions(fetchedReqOptions)
      setFilteredReqOptions(fetchedReqOptions)
    }

    fetchSpecsAndRequirements()
  }, [getSpecTreeInfo])

  useEffect(() => {
    const filterRequirements = async () => {
      if (!selectedSpecOption) {
        setFilteredReqOptions(
          reqOptions
            .filter((req) => req.id !== id)
            .filter(
              (req) =>
                !existingLinks.some((link) => {
                  const optionIsAlreadyParent =
                    link.parent.id === req.id && link.child.id === id
                  const optionIsAlreadyChild =
                    link.child.id === req.id && link.parent.id === id
                  return optionIsAlreadyParent || optionIsAlreadyChild
                }),
            ),
        )
        return
      }

      const [latestRevision] = await getRevisions(selectedSpecOption.id)

      const reqs: Requirement[] = await getRequirements(selectedSpecOption.id, {
        revisionIds: [latestRevision.id],
      })

      setFilteredReqOptions(
        reqs
          .map((req) => ({
            id: req.id,
            label: req.title || '(No Title)',
            sublabel: `${selectedSpecOption.sublabel || 'Document Number'}-${
              req.context.requirementIdentifier
            }`,
            data: {
              documentId: latestRevision.documentId,
              specificationId: selectedSpecOption.id,
            },
          }))
          .filter((req) => req.data.specificationId === selectedSpecOption.id)
          .filter((req) => req.id !== id)
          .filter(
            (req) =>
              !existingLinks.some((link) => {
                const optionIsAlreadyParent =
                  link.parent.id === req.id && link.child.id === id
                const optionIsAlreadyChild =
                  link.child.id === req.id && link.parent.id === id
                return optionIsAlreadyParent || optionIsAlreadyChild
              }),
          ),
      )
    }
    filterRequirements()
  }, [selectedSpecOption, reqOptions, existingLinks, id])

  const shouldDisplayWarning: () => null | {
    selectedLevel: number
    currentLevel: number
  } = () => {
    const selectedReqSpecId = selectedReqOption?.data.specificationId || ''
    const selectedSpecId = selectedSpecOption?.id || ''
    const selectedId = selectedReqSpecId || selectedSpecId

    // check that both specifications belong to a program
    // not displaying any warning/message right now
    if (!specTreeMetadata[selectedId] || !specTreeMetadata[specificationId]) {
      return null
    }
    const { level: selectedLevel, program: selectedProgram } =
      specTreeMetadata[selectedId]

    const { level: currentLevel, program: currentProgram } =
      specTreeMetadata[specificationId]

    // make sure both specs are in same program
    // shouldn't need this in the future, don't want to show warning for now
    if (selectedProgram.id !== currentProgram.id) {
      return null
    }

    if (
      (linkType === LinkedRequirementType.Parent &&
        selectedLevel > currentLevel) ||
      (linkType === LinkedRequirementType.Child && selectedLevel < currentLevel)
    ) {
      return { selectedLevel, currentLevel }
    }

    return null
  }

  const displayWarning = shouldDisplayWarning()

  return (
    <Modal
      title={
        linkType === LinkedRequirementType.Parent
          ? 'Add Parent Requirement'
          : 'Add Child Requirement'
      }
      icon={<LinkIcon />}
      onClose={closeModal}
    >
      <div className={styles.content}>
        <div className={styles.subheader}>{title || 'Untitled'}</div>
        <InputDropdownSelection
          placeholder="Search by specification name"
          selected={selectedSpecOption}
          options={specOptions}
          onSelect={(option) => {
            if (!option) {
              setSelectedSpecOption(undefined)
            } else {
              setSelectedSpecOption(option)
            }
          }}
          onQueryOptions={(query, options) => {
            if (!query) {
              return options
            }

            return options.filter(
              (opt) =>
                opt.label &&
                opt.label.toLowerCase().includes(query.toLowerCase()),
            )
          }}
        />
        <div className={styles.info}>
          Most recent results listed. Type to filter or select a specification.
        </div>
        <div>
          <InputDropdownSelection
            placeholder="Search by requirement name or ID"
            selected={selectedReqOption}
            options={filteredReqOptions}
            onSelect={(option) => {
              setSelectedReqOption(option)
            }}
            onQueryOptions={(query, options) => {
              if (!query) {
                return options
              }

              const queryLabelMatch = (opt, query) =>
                opt?.label &&
                opt.label.toLowerCase().includes(query.toLowerCase())

              const querySublabelMatch = (opt, query) =>
                opt?.sublabel &&
                opt.sublabel.toLowerCase().includes(query.toLowerCase())

              const exactMatchSortToFront = (a: OptionType) =>
                a.sublabel.toLowerCase() === query.toLowerCase() ? -1 : 0

              const matchingOptions = options
                .filter(
                  (opt) =>
                    queryLabelMatch(opt, query) ||
                    querySublabelMatch(opt, query),
                )
                .sort(exactMatchSortToFront)

              return matchingOptions
            }}
          />
        </div>
        {displayWarning && (
          <LinkLevelWarnings shouldDisplayWarning={displayWarning} />
        )}
        <Button
          className={styles.addLink}
          disabled={!selectedReqOption}
          color={BUTTON_COLORS.PRIMARY}
          text="Create Link"
          onClick={() => {
            try {
              linkRequirements({
                specificationId: selectedReqOption!.data.specificationId,
                documentId: selectedReqOption!.data.documentId,
                blockId: selectedReqOption!.id,
              })
              toastSuccess('Created link successfully.')
              closeModal()
            } catch (e) {
              captureException(e)
              toastError('Failed to add link', 'Please refresh and try again.')
            }
          }}
          endIcon={<LinkIcon size={16} />}
        />
      </div>
    </Modal>
  )
}

export default AddLinkedRequirementModal
