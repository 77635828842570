import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { TableColumn } from './Table'

const getScrollContainer = (el) => {
  while (el) {
    const { overflowX } = getComputedStyle(el)
    if (overflowX !== 'visible') {
      return el
    }
    el = el.parentElement
  }

  return null
}

const initialState = (
  columns: TableColumn[],
  tableKey?: string,
): [TableColumn[], boolean] => {
  // If we've saved a resize, load it
  if (tableKey) {
    const data = localStorage.getItem(tableKey)
    if (data) {
      const savedWidths = JSON.parse(data)
      return [
        columns.map((col, i) => ({
          ...col,
          width: savedWidths[i] ?? 'max-content',
        })),
        true,
      ]
    }
  }
  // If not, use the column config, defaulting to max-content
  return [
    columns.map((col) => (col.width ? col : { ...col, width: 'max-content' })),
    false,
  ]
}

export type ColumnResizer = {
  columns: TableColumn[]
  wasResized: boolean
  onResizeColumn: (index: number) => void
  resizeRefs: RefObject<HTMLTableCellElement[]>
  clearSizing: () => void
}

const useResizableColumns = (
  columns: TableColumn[],
  tableKey: string,
): ColumnResizer => {
  const [[initialColumns, initialWasResized]] = useState(() =>
    initialState(columns, tableKey),
  )

  const [sizedColumns, setSizedColumns] =
    useState<TableColumn[]>(initialColumns)
  const [wasResized, setWasResized] = useState(initialWasResized)

  /* Refs should be placed on <th> elements*/
  const resizeRefs = useRef<HTMLTableCellElement[]>([])
  const activeIndex = useRef<number | null>(null)

  const disableSelect = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (activeIndex?.current !== null) {
        const el = resizeRefs.current?.[activeIndex.current]
        const column = columns[activeIndex.current]
        const scrollEl = getScrollContainer(el)
        if (el) {
          const width =
            e.clientX + (scrollEl ? scrollEl.scrollLeft : 0) - el.offsetLeft
          const labelWidth = (el.children[0] as HTMLSpanElement).offsetWidth

          const minWidth =
            column.minWidth !== undefined ? column.minWidth : labelWidth
          // 40px is th padding default
          if (width && width >= minWidth + 40) {
            setWasResized(true)
            setSizedColumns((cols) =>
              cols.map((col, i) =>
                i === activeIndex.current
                  ? { ...col, width: `${width}px` }
                  : col,
              ),
            )
          }
        }
      }
    },
    [columns],
  )

  const onMouseUp = useCallback(() => {
    activeIndex.current = null
    document.body.style.cursor = 'default'

    window.removeEventListener('selectstart', disableSelect)
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)

    setSizedColumns((cols) => {
      const widths = cols.map((col) => col.width)
      localStorage.setItem(tableKey, JSON.stringify(widths))
      return cols
    })
  }, [onMouseMove, tableKey, disableSelect])

  const onMouseDown = useCallback(
    (index: number) => {
      // Cursor flashed during drag when relying on :hover css
      document.body.style.cursor = 'ew-resize'
      window.addEventListener('selectstart', disableSelect)
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)

      activeIndex.current = index
    },
    [onMouseMove, onMouseUp, disableSelect],
  )

  const clearSizing = useCallback(() => {
    localStorage.removeItem(tableKey)
    const [cols] = initialState(columns)
    setSizedColumns(cols)
    setWasResized(false)
  }, [columns, tableKey])

  useEffect(() => {
    // if columns are added or removed, return to config default
    if (columns.length !== sizedColumns.length) {
      clearSizing()
    }
  }, [clearSizing, columns.length, sizedColumns.length])

  return {
    onResizeColumn: onMouseDown,
    columns: sizedColumns,
    wasResized,
    resizeRefs,
    clearSizing,
  }
}

export default useResizableColumns
