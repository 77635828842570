import Tag, { TAG_COLORS } from './index.tsx'
import { useAttributesContext } from '../../context/AttributesContext.tsx'

const ProgramTag = ({ programId }) => {
  const { getProgramById } = useAttributesContext()
  const program = getProgramById(programId)
  const fontColor = program?.metadata?.STYLES?.COLOR_FONT
  const backgroundColor = program?.metadata?.STYLES?.COLOR_BG

  return (
    <Tag
      text={program?.name}
      color={
        fontColor && backgroundColor
          ? {
              fontColor,
              backgroundColor,
            }
          : TAG_COLORS.gray3
      }
    />
  )
}

export default ProgramTag
