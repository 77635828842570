import { Image } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useRef, useCallback } from 'react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import {
  ImageFileExtensions,
  useSectionContext,
} from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'
import { toastError } from '../../toast'

const AddImageBlockAction = (props: BlockActionItemProps) => {
  const { block, closeMenu } = props
  const { contentIsEditable } = useSpecificationContext()
  const { createImageBlock } = useSectionContext()
  const fileInput = useRef<HTMLInputElement>(null)

  const importImage = useCallback(async () => {
    try {
      const imageFile = fileInput.current?.files![0]
      if (!imageFile) {
        return
      }

      await createImageBlock(block.id, imageFile)
      closeMenu()
    } catch (e) {
      captureException(e)
      toastError(
        'Unable to import image',
        'Please refresh the page and try again.',
      )
    }
  }, [createImageBlock, block, closeMenu])

  return (
    contentIsEditable && (
      <button
        className={styles.actionItem}
        onClick={() => fileInput.current?.click()}
      >
        <Image />
        Image
        <input
          ref={fileInput}
          style={{ display: 'none' }}
          type="file"
          accept={ImageFileExtensions.join(', ')}
          onChange={importImage}
        />
      </button>
    )
  )
}

export default AddImageBlockAction
