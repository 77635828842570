import { Keyboard } from '@carbon/icons-react'
import Modal from './index.tsx'
import styles from './KeyboardShortcutsModal.module.css'
import useClickOutside from '../../hooks/useClickOutside.ts'

const KeyboardShortcutsModal = (props: { closeModal: () => void }) => {
  const { closeModal } = props

  const modalRef = useClickOutside(() => closeModal())

  return (
    <Modal
      innerRef={modalRef}
      title="Keyboard Shortcuts"
      icon={<Keyboard size={20} />}
      onClose={closeModal}
    >
      <div className={styles.content}>
        <div className={styles.heading}>Instructions</div>
        <div className={styles.headingInfo}>
          The following keyboard shortcuts can help you navigate the document
          <br />
          editor.
        </div>
        <div className={styles.shortcut}>
          <span className={styles.command}>\ (backslash)</span> Add a reference
          to another requirement, image, or table
        </div>
        <div className={styles.shortcut}>
          <span className={styles.command}>ctrl + enter</span> From a
          requirement, create a new requirement below
        </div>
        <div className={styles.shortcut}>
          <span className={styles.command}>tab</span> Move to next editable
          field
        </div>
        <div className={styles.shortcut}>
          <span className={styles.command}>shift + tab</span> Move back to
          previous editable field
        </div>
        <div className={styles.heading}>Note</div>
        <div className={styles.headingInfo}>
          To select multiple blocks at a time, click and drag your mouse across
          the intended selection.
        </div>
      </div>
    </Modal>
  )
}

export default KeyboardShortcutsModal
