import Tag, { TAG_COLORS } from './index.tsx'
import { EvidenceType } from '../../types/enums.ts'

interface EvidenceTypeTagProps {
  type: EvidenceType
}

const EvidenceTypeTag = (props: EvidenceTypeTagProps) => {
  const { type } = props

  return (
    <Tag
      text={type.toUpperCase()}
      color={
        type === EvidenceType.Validation
          ? TAG_COLORS.blue3NoHover
          : type === EvidenceType.Verification
            ? TAG_COLORS.red2NoHover
            : TAG_COLORS.gray3NoHover
      }
    />
  )
}

export default EvidenceTypeTag
