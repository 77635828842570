import { EMPTY_DELTA, plaintextToDelta } from './string.ts'
import type * as apiv2 from '../api/v2/requirements.ts'

export const extractShallStatementDelta = (
  requirement: apiv2.Requirement | Partial<apiv2.Requirement> | undefined,
) => {
  let delta = EMPTY_DELTA

  if (requirement?.data?.delta?.shallStatement) {
    delta = requirement.data.delta.shallStatement
  } else if (requirement?.shallStatement) {
    delta = plaintextToDelta(requirement.shallStatement)
  }

  return delta
}

export const extractRationaleDelta = (
  requirement: apiv2.Requirement | Partial<apiv2.Requirement> | undefined,
) => {
  let delta = EMPTY_DELTA

  if (requirement?.data?.delta?.rationale) {
    delta = requirement.data.delta.rationale
  } else if (requirement?.rationale) {
    delta = plaintextToDelta(requirement.rationale)
  }

  return delta
}
