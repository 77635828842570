export enum AvatarSize {
  Large,
  MediumLarge,
  Medium,
  MediumSmall,
  Small,
}

export enum AvatarColor {
  Blue,
  Blue2,
  Green,
  Green2,
  Green3,
  Purple,
  Red,
  White,
}

export const AVATAR_SIZES = {
  [AvatarSize.Large]: 'large',
  [AvatarSize.MediumLarge]: 'mediumLarge',
  [AvatarSize.Medium]: 'medium',
  [AvatarSize.MediumSmall]: 'mediumSmall',
  [AvatarSize.Small]: 'small',
}

export const AVATAR_COLORS = {
  [AvatarColor.Blue]: 'var(--color-blue4)',
  [AvatarColor.Blue2]: 'var(--color-blue5)',
  [AvatarColor.Green]: 'var(--color-green7)',
  [AvatarColor.Green2]: 'var(--color-green8)',
  [AvatarColor.Green3]: 'var(--color-green9)',
  [AvatarColor.Purple]: 'var(--color-purple4)',
  [AvatarColor.Red]: 'var(--color-red4)',
  [AvatarColor.White]: 'var(--color-white)',
}
