import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import styles from './SpecificationHistoryPage.module.css'
import * as revisionApi from '../../api/v2/revisions.ts'
import { Revision, RevisionStatus } from '../../api/v2/revisions.ts'
import * as specificationApi from '../../api/v2/specifications.ts'
import { Specification } from '../../api/v2/specifications.ts'
import * as userApi from '../../api/v2/users.ts'
import { User } from '../../api/v2/users.ts'
import Avatar from '../../components/avatar'
import { AvatarSize } from '../../components/avatar/constants.ts'
import Chip from '../../components/chip'
import { formatTime, monthLongDayYear } from '../../lib/date.ts'
import { getFullName } from '../../lib/user.ts'

const SpecificationHistoryPage = () => {
  const [specification] = useOutletContext() as [
    specificationApi.Specification,
    Dispatch<SetStateAction<specificationApi.Specification>>,
  ]
  const [revisions, setRevisions] = useState<Revision[]>()
  const [latestRevision, setLatestRevision] = useState<Revision>()
  const [userIdToUsers, setUserIdToUsers] = useState<
    Record<string, userApi.User>
  >({})
  const [lastModifiedUser, setLastModifiedUser] = useState<userApi.User>()

  useEffect(() => {
    const loadRevisions = async () => {
      const specRevisions = await revisionApi.getRevisions(specification.id)

      setRevisions(
        specRevisions.sort(
          (a, b) => a.createdOn.getTime() - b.createdOn.getTime(),
        ),
      )
    }

    loadRevisions()
  }, [specification.id])

  useEffect(() => {
    const loadUsers = async () => {
      const userIds = Array.from(
        new Set([
          specification.lastModifiedBy,
          ...(revisions?.flatMap((revision) => [
            revision.createdBy,
            revision.lastModifiedBy,
          ]) ?? []),
        ]),
      )

      const users = await Promise.all(
        userIds.map((userId) => userApi.getUser(userId)),
      )

      const userIdsToUsers = users.reduce((acc, user) => {
        return {
          ...acc,
          [user.id]: user,
        }
      }, {})

      setUserIdToUsers(userIdsToUsers)
    }

    loadUsers()
  }, [revisions, specification.lastModifiedBy])

  useEffect(() => {
    const user = latestRevision && userIdToUsers[latestRevision.lastModifiedBy]
    setLastModifiedUser(user)
  }, [latestRevision, latestRevision?.lastModifiedBy, userIdToUsers])

  useEffect(() => {
    const latestRevision =
      revisions &&
      revisions.reduce((latest, current) =>
        latest.lastModifiedOn.getTime() > current.lastModifiedOn.getTime()
          ? latest
          : current,
      )

    setLatestRevision(latestRevision)
  }, [revisions])

  return (
    <div className={styles.specHistoryPage}>
      <div className={styles.header}>
        <div className={styles.title}>Specification history</div>
        <div className={styles.metadata}>
          <div className={styles.lastUpdated}>
            {latestRevision && (
              <>
                {`Last updated ${monthLongDayYear(
                  latestRevision.lastModifiedOn,
                )} at ${formatTime(latestRevision.lastModifiedOn)}`}
                {lastModifiedUser &&
                  ` by ${getFullName(
                    lastModifiedUser.firstName,
                    lastModifiedUser.lastName,
                  )}`}
              </>
            )}
          </div>
          <Link
            to={`/specifications/${specification.id}/document`}
            className={styles.linkSpecification}
          >
            View specification
          </Link>
        </div>
      </div>

      {revisions &&
        revisions.map((revision) => (
          <div className={styles.version} key={revision.id}>
            <div className={styles.versionNumber}>
              Version {revision.version}
            </div>
            {/* Initial draft */}
            <RevisionHistoryEntry
              specification={specification}
              revisionStatus={RevisionStatus.DRAFT}
              createdOn={revision.createdOn}
              createdBy={userIdToUsers[revision.createdBy]}
              description={(specificationName) => (
                <>
                  {revision.version === 1 ? '' : 'New version of '}
                  {specificationName} created
                </>
              )}
            />
            {/* Release */}
            {revision.status === RevisionStatus.ACTIVE && (
              <RevisionHistoryEntry
                specification={specification}
                revisionStatus={revision.status}
                createdOn={revision.lastModifiedOn}
                createdBy={userIdToUsers[revision.lastModifiedBy]}
                description={(specificationName) => (
                  <>{specificationName} changed to</>
                )}
              />
            )}
            <div></div>
          </div>
        ))}
    </div>
  )
}

const RevisionHistoryEntry = (props: {
  specification: Specification
  revisionStatus: RevisionStatus
  createdOn: Date
  createdBy?: User
  description: (specificationName: ReactNode) => ReactNode
}) => {
  const { specification, revisionStatus, createdOn, createdBy, description } =
    props

  return (
    <div className={styles.historyItem}>
      <div className={styles.dateTime}>
        <div>{monthLongDayYear(createdOn)}</div>
        <div className={styles.time}>{formatTime(createdOn)}</div>
      </div>
      <div className={styles.description}>
        <div>
          {description(
            <span className={styles.specName}>{specification.name}</span>,
          )}
        </div>
        <div>
          <Chip
            variant="status"
            value={RevisionStatus[revisionStatus].toUpperCase()}
          />
        </div>
      </div>
      <div className={styles.user}>
        <Avatar
          size={AvatarSize.Medium}
          firstName={createdBy?.firstName}
          lastName={createdBy?.lastName}
          showName
        />
      </div>
    </div>
  )
}

export default SpecificationHistoryPage
