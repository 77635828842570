import Tag from './index.tsx'
import { getCssVar } from '../../lib/string.ts'
import { ExceptionStatus, EXCEPTION_STATUS_TO_NAME } from '../../types/enums'

const TAG_COLORS = {
  [ExceptionStatus.Requested]: {
    backgroundColor: getCssVar('--color-orange'),
    fontColor: getCssVar('--color-orange3'),
    hoverBackgroundColor: '#ffcdb8',
  },
  [ExceptionStatus.InReview]: {
    backgroundColor: '#DCD0FF',
    fontColor: '#4E00CE',
    hoverBackgroundColor: '#a878e8',
  },
  [ExceptionStatus.Accepted]: {
    backgroundColor: '#D0FFE8',
    fontColor: '#0BAA8D',
    hoverBackgroundColor: '#C2F9D7',
  },
}

const ExceptionStatusTag = (props: {
  status: ExceptionStatus
  onCancel?: () => void
  hoverable?: boolean
}) => {
  const { status, onCancel, hoverable } = props
  return (
    <Tag
      text={EXCEPTION_STATUS_TO_NAME[status]}
      color={
        hoverable
          ? TAG_COLORS[status]
          : { ...TAG_COLORS[status], hoverBackgroundColor: undefined }
      }
      onCancel={onCancel}
    />
  )
}

export default ExceptionStatusTag
