import { ListChecked, Checkmark, Edit } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLoaderData } from 'react-router-dom'
import ExceptionForm from './ExceptionForm'
import styles from './ExceptionPage.module.css'
import {
  createException,
  updateException,
  CreateExceptionRequest,
  Exception,
  ExceptionType,
  ExtendedRequirementExceptionLink,
} from '../../api/v2/exceptions'
import {
  createExceptionLink,
  CreateExceptionLinkRequest,
  deleteEntityLink,
} from '../../api/v2/links'
import Button, { BUTTON_COLORS } from '../../components/button'
import { toastError, toastSuccess } from '../../components/toast'
import { ExceptionStatus } from '../../types/enums'

const defaultExceptionState = {
  title: '',
  type: ExceptionType.Waiver,
  status: ExceptionStatus.Requested,
  variance: '',
  rationale: '',
  program: '',
}

const ExceptionPage = () => {
  const { loadedException, linkedRequirements: loadedLinkedRequirements } =
    useLoaderData() as {
      loadedException: Exception | null
      linkedRequirements: ExtendedRequirementExceptionLink[]
    }

  const navigate = useNavigate()
  const [exceptionRecord, setExceptionRecord] =
    useState<CreateExceptionRequest>(loadedException || defaultExceptionState)
  const [editMode, setEditMode] = useState(loadedException ? false : true)

  const [linkedRequirements, setLinkedRequirements] = useState<
    ExtendedRequirementExceptionLink[]
  >(loadedLinkedRequirements || [])

  useEffect(() => {
    window.document.title = 'Exceptions'
  }, [])

  const isExceptionValid = () => {
    const requiredFields = ['title', 'type']
    for (const field of requiredFields) {
      if (!exceptionRecord[field]) {
        toastError(
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
          '',
        )
        return false
      }
    }
    return true
  }

  const onExceptionCreate = async () => {
    if (!isExceptionValid()) {
      return
    }
    try {
      const createdException = await createException(exceptionRecord)
      const exceptionId = createdException.id

      for (const link of linkedRequirements) {
        const linkRequest: CreateExceptionLinkRequest = {
          ...link,
          exceptionId,
        }
        try {
          await createExceptionLink(linkRequest)
        } catch (error) {
          console.error(
            'Failed to create requirement link for exception:',
            link,
            error,
          )
          toastError('Failed to create one or more linked requirements', '')
          return
        }
      }

      toastSuccess('Exception created successfully', '')
      navigate('/exceptions')
    } catch (error) {
      toastError('Error creating exception record', '')
      console.error('Exception creation error:', error)
      captureException(error)
    }
  }

  const onExceptionUpdate = async () => {
    if (!isExceptionValid()) {
      return
    }
    try {
      const { title, type, status, variance, rationale, program } =
        exceptionRecord
      const updatePayload = {
        title,
        type,
        status,
        variance,
        rationale,
        program,
      }

      if (loadedException) {
        await updateException(loadedException.id, updatePayload)

        const linksToDelete = loadedLinkedRequirements.filter(
          (loadedLink) =>
            !linkedRequirements.some(
              (currentLink) =>
                currentLink.requirementId === loadedLink.requirementId &&
                currentLink.exceptionId === loadedLink.exceptionId,
            ),
        )

        const linksToCreate = linkedRequirements.filter(
          (currentLink) =>
            !loadedLinkedRequirements.some(
              (loadedLink) =>
                loadedLink.requirementId === currentLink.requirementId &&
                loadedLink.exceptionId === currentLink.exceptionId,
            ),
        )

        for (const link of linksToDelete) {
          try {
            await deleteEntityLink(link.linkId)
          } catch (error) {
            console.error('Failed to delete requirement link:', link, error)
            toastError('Failed to delete one or more linked requirements', '')
            captureException(error)
          }
        }

        const createdLinks: ExtendedRequirementExceptionLink[] = []
        for (const link of linksToCreate) {
          const linkRequest: CreateExceptionLinkRequest = {
            exceptionId: loadedException.id,
            specificationId: link.specificationId,
            documentId: link.documentId,
            requirementId: link.requirementId,
          }
          try {
            const createdLink = await createExceptionLink(linkRequest)
            createdLinks.push({
              ...link,
              linkId: createdLink.id,
            })
          } catch (error) {
            console.error(
              'Failed to create requirement link for exception:',
              link,
              error,
            )
            toastError('Failed to create one or more linked requirements', '')
            captureException(error)
          }
        }

        setLinkedRequirements((prevLinks) =>
          prevLinks.map(
            (link) =>
              createdLinks.find(
                (createdLink) => createdLink.linkId === link.linkId,
              ) || link,
          ),
        )
      }

      toastSuccess('Exception updated successfully', '')
      setEditMode(false)
      navigate(`/exceptions/${loadedException?.id}`)
    } catch (error) {
      toastError('Error updating exception record', '')
      captureException(error)
    }
  }

  return (
    <>
      <div className={styles.header}>
        <Link className={styles.returnLink} to={`/exceptions`}>
          <ListChecked />
          Return to Exceptions
        </Link>
        {loadedException ? (
          editMode ? (
            <Button
              color={BUTTON_COLORS.PRIMARY}
              onClick={() => onExceptionUpdate()}
              text="Save"
              endIcon={<Checkmark size={20} />}
            />
          ) : (
            <Button
              color={BUTTON_COLORS.PRIMARY}
              onClick={() => setEditMode(true)}
              text="Edit"
              endIcon={<Edit size={20} />}
            />
          )
        ) : (
          <Button
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => onExceptionCreate()}
            text="Create Exception"
            endIcon={<Checkmark size={20} />}
          />
        )}
      </div>
      <div
        className={`${styles.container} ${
          editMode ? styles.editModeBackground : ''
        }`}
      >
        <ExceptionForm
          editMode={editMode}
          setEditMode={setEditMode}
          exceptionRecord={exceptionRecord}
          setExceptionRecord={setExceptionRecord}
          linkedRequirements={linkedRequirements}
          setLinkedRequirements={setLinkedRequirements}
        />
      </div>
    </>
  )
}

export default ExceptionPage
