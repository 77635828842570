import api from './api'
import { UserRole } from '../../types/enums.ts'

const createUrl = (teamId?: string) =>
  `/api/v2/teams${teamId ? `/${teamId}` : ''}`

export interface Team {
  id: string
  name: UserRole
}

export interface UserTeams {
  userId: string
  teams: Team[]
}

export const getTeams: () => Promise<{
  teams: Team[]
}> = () => api.get(createUrl())

export const getCurrentUserTeams: () => Promise<{
  teams: Team[]
}> = async () => {
  return api.get(`${createUrl()}/users`)
}

export const addUserToTeam: (
  teamId: string,
  userId: string,
) => Promise<Team> = async (teamId, userId) => {
  return api.put(`${createUrl(teamId)}/users/${userId}`, {
    body: {},
  })
}

export const removeUserFromTeam: (
  teamId: string,
  userId: string,
) => Promise<void> = async (teamId, userId) => {
  return api.delete(`${createUrl(teamId)}/users/${userId}`)
}

export const getUserTeams: (userId: string) => Promise<UserTeams> = async (
  userId,
) => {
  return api.get(`${createUrl()}/users/${userId}`)
}

export const getMultipleUserTeams: (
  userIds: string[],
) => Promise<UserTeams[]> = async (userIds) => {
  return Promise.all(userIds.map((userId) => getUserTeams(userId)))
}
