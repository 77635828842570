import { ChevronDown, Link } from '@carbon/icons-react'
import { useCallback, useEffect, useState } from 'react'
import Modal from '.'
import styles from './LinkEvidenceModal.module.css'
import { Evidence, getEvidenceRecords } from '../../api/v2/evidence'
import * as linkApi from '../../api/v2/links.ts'
import { LinkEntityType } from '../../api/v2/links.ts'
import { useAttributesContext } from '../../context/AttributesContext'
import useClickOutside from '../../hooks/useClickOutside'
import Button, { BUTTON_COLORS } from '../button'
import Tag, { TAG_COLORS } from '../tag'

const EvidenceOption = (props) => {
  const { evidence, onSelect, selectable } = props
  const { getEvidenceMethodById } = useAttributesContext()
  const method = getEvidenceMethodById(evidence.method)

  const tagColor = method
    ? {
        fontColor: method.metadata.STYLES.COLOR_FONT,
        backgroundColor: method.metadata.STYLES.COLOR_BG,
      }
    : TAG_COLORS.primary

  return (
    <button
      className={`${styles.option} ${selectable ? styles.selectable : ''}`}
      onClick={onSelect}
    >
      <div className={styles.text}>
        <span className={styles.recordTitle}>{evidence.title}</span>
      </div>
      <Tag text={method?.name || ''} color={tagColor} />
    </button>
  )
}

const LinkEvidenceModal = (props) => {
  const { closeModal, evidenceType, requirement, onLink: onLinkProp } = props
  const [linkableEvidence, setLinkableEvidence] = useState<Evidence[]>([])

  useEffect(() => {
    const fetchEvidence = async () => {
      const evidences = await getEvidenceRecords()
      const evidenceLinks = await linkApi.getEntityLinks({
        documentBlockId: [requirement.id],
        link: [linkApi.LinkType.Evidence],
        entityType: [LinkEntityType.Evidence],
      })

      const requirementEvidenceIds = evidenceLinks.map((l) => l.entityId)
      setLinkableEvidence(
        evidences
          .filter((e) => e.type === evidenceType)
          .filter((e) => !requirementEvidenceIds.includes(e.id)),
      )
    }
    fetchEvidence()
  }, [evidenceType, requirement])

  const [selectedEvidence, setSelectedEvidence] = useState<Evidence>()
  const [selectOpen, setSelectOpen] = useState(false)
  const ref = useClickOutside(() => setSelectOpen(false), selectOpen)

  const onLink = useCallback(() => {
    onLinkProp(selectedEvidence?.id)
    closeModal()
  }, [closeModal, onLinkProp, selectedEvidence?.id])

  const evidenceTypeDisplayValue =
    evidenceType.charAt(0).toUpperCase() + evidenceType.slice(1).toLowerCase()

  return (
    <Modal
      title={`Link ${evidenceTypeDisplayValue} artifact`}
      icon={<Link size={20} />}
      onClose={closeModal}
    >
      <div className={styles.root}>
        <h3>{requirement.title}</h3>
        <div className={styles.body}>
          <div className={styles.selectLabel}>
            {evidenceTypeDisplayValue} artifact:
          </div>
          <div ref={ref} className={styles.selectContainer}>
            <button
              className={styles.select}
              onClick={() => setSelectOpen(!selectOpen)}
            >
              {selectedEvidence ? (
                <EvidenceOption evidence={selectedEvidence} />
              ) : (
                `Select ${evidenceTypeDisplayValue} artifact`
              )}
              <ChevronDown size={16} />
            </button>
            <div
              className={`${styles.dropdown} ${styles.basicElevation} ${
                selectOpen ? styles.open : ''
              }`}
            >
              {linkableEvidence.map((ev) => (
                <div key={ev.id}>
                  <EvidenceOption
                    evidence={ev}
                    onSelect={() => {
                      setSelectedEvidence(ev)
                      setSelectOpen(false)
                    }}
                    selectable
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            disabled={selectedEvidence === null}
            frontIcon={<Link size={20} />}
            text="Create link"
            color={BUTTON_COLORS.PRIMARY}
            onClick={onLink}
          />
        </div>
      </div>
    </Modal>
  )
}

export default LinkEvidenceModal
