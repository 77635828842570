import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { displayDevTools } from '../lib/dev-tools.ts'
import {
  getDevToolsDisableMFA,
  getDevToolsUseDevApi,
  KEY_DEV_TOOLS_DISABLE_MFA,
  KEY_DEV_TOOLS_USE_DEV_API,
} from '../lib/localstorage.ts'

interface DevToolsContext {
  disableMFA: boolean
  setDisableMFA?: Dispatch<SetStateAction<boolean>>
  useDevApi: boolean
  setUseDevApi: Dispatch<SetStateAction<boolean>>
}

const DevToolsCtx = createContext<DevToolsContext>({
  disableMFA: false,
  setDisableMFA: () => {},
  useDevApi: false,
  setUseDevApi: () => {},
})

const setLsDisableMFA = (disable: boolean) => {
  localStorage.setItem(KEY_DEV_TOOLS_DISABLE_MFA, disable + '')
}

const setLsUseDevApi = (disable: boolean) => {
  localStorage.setItem(KEY_DEV_TOOLS_USE_DEV_API, disable + '')
}

export const DevToolsProvider = (props) => {
  const [disableMFA, setDisableMFA] = useState<boolean>(getDevToolsDisableMFA())
  const [useDevApi, setUseDevApi] = useState<boolean>(getDevToolsUseDevApi())

  useEffect(() => {
    setLsDisableMFA(disableMFA)
  }, [disableMFA])

  useEffect(() => {
    setLsUseDevApi(useDevApi)
  }, [useDevApi])

  return (
    <DevToolsCtx.Provider
      value={{
        disableMFA: displayDevTools() ? disableMFA : false,
        setDisableMFA: displayDevTools() ? setDisableMFA : () => {},
        useDevApi: displayDevTools() ? useDevApi : false,
        setUseDevApi: displayDevTools() ? setUseDevApi : () => {},
      }}
    >
      {props.children}
    </DevToolsCtx.Provider>
  )
}

export const useDevTools = () => {
  return useContext(DevToolsCtx)
}

export default useDevTools
