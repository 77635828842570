import api from './api'

const createUrl: (
  evidenceId: string,
  activityId: string,
  assetId?: string,
) => string = (evidenceId, activityId, assetId) =>
  `/api/v2/evidences/${evidenceId}/activities/${activityId}/assets${
    assetId ? `/${assetId}` : ''
  }`

export const createEvidenceActivityAsset: (
  evidenceId: string,
  activityId: string,
  file: File,
) => Promise<{ id: string; state: string }> = (
  evidenceId,
  activityId,
  file,
) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('content-type', file.type)
  formData.append('filename', file.name)

  return api.postRaw(createUrl(evidenceId, activityId), {
    body: formData,
  })
}

export const getEvidenceActivityAsset = async (
  evidenceId: string,
  activityId: string,
  assetId: string,
) => {
  const { data, headers } = await api.getBlobWithHeaders(
    createUrl(evidenceId, activityId, assetId),
  )

  let filename = ''

  if (headers) {
    const disposition = headers.get('Content-Disposition')

    if (disposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)

      if (matches !== null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }
  }

  return { data, filename }
}
