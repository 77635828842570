export class ApiError extends Error {
  public fetchResponse: Response

  constructor(message: string, fetchResponse: Response) {
    super(message)

    Object.setPrototypeOf(this, ApiError.prototype)

    this.fetchResponse = fetchResponse
  }
}
