import { Add } from '@carbon/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import styles from './Matrix.module.css'
import MatrixColumns, {
  HistoricalRevisionColumns,
  MatrixColumnLabels,
  MatrixColumnPrettyLabels,
  PublicSpecMatrixColumns,
} from './MatrixColumns'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import * as api from '../../api/v2/search.ts'
import { SearchRequirementContent } from '../../api/v2/search.ts'
import { MatrixColumnId } from '../../api/v2/specifications.ts'
import Button from '../../components/button'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types'
import useResizableColumns from '../../components/table/useResizableColumns.ts'
import VirtualTable from '../../components/table/VirtualTable'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import { useMatrixContext } from '../../context/MatrixContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext'
import { RequirementStatus } from '../../types/enums.ts'
import DisplayFilter, { FilterGroup } from '../search/display-filter'

const BASE_FILTER_KEYS = [
  FilterKey.RequirementType,
  FilterKey.RequirementStatus,
  FilterKey.Compliance,
  FilterKey.RequirementComment,
]

const getFilterKeys = (isHistoricalRevision: boolean) =>
  isHistoricalRevision
    ? BASE_FILTER_KEYS
    : [
        ...BASE_FILTER_KEYS,
        FilterKey.ValidationMethod,
        FilterKey.VerificationMethod,
      ]

const FILTER_GROUP_0 = {
  filters: [
    {
      label: MatrixColumnId.Comments,
      prettyLabel: MatrixColumnLabels.Comments,
    },
    { label: MatrixColumnId.ID, prettyLabel: MatrixColumnLabels.ID },
    {
      label: MatrixColumnId.SectionNumber,
      prettyLabel: MatrixColumnLabels.SectionNumber,
    },
    {
      label: MatrixColumnId.RequirementName,
      prettyLabel: MatrixColumnLabels.RequirementName,
    },
    {
      label: MatrixColumnId.ShallStatement,
      prettyLabel: MatrixColumnLabels.ShallStatement,
    },
    {
      label: MatrixColumnId.Compliance,
      prettyLabel: MatrixColumnLabels.Compliance,
    },
    {
      label: MatrixColumnId.ComplianceNotes,
      prettyLabel: MatrixColumnLabels.ComplianceNotes,
    },
    {
      label: MatrixColumnId.Rationale,
      prettyLabel: MatrixColumnLabels.Rationale,
    },
    {
      label: MatrixColumnId.ExternalNumber,
      prettyLabel: MatrixColumnLabels.ExternalNumber,
    },
    { label: MatrixColumnId.Status, prettyLabel: MatrixColumnLabels.Status },
    { label: MatrixColumnId.Type, prettyLabel: MatrixColumnLabels.Type },
  ],
}

const FILTER_GROUP_1 = {
  spaceBetween: true,
  filters: [
    {
      label: MatrixColumnId.ParentRequirements,
      prettyLabel: MatrixColumnLabels.ParentRequirements,
    },
    {
      label: MatrixColumnId.ChildRequirements,
      prettyLabel: MatrixColumnLabels.ChildRequirements,
    },
    {
      label: MatrixColumnId.ParentRequirementName,
      prettyLabel: MatrixColumnLabels.ParentRequirementName,
    },
    {
      label: MatrixColumnId.ChildRequirementName,
      prettyLabel: MatrixColumnLabels.ChildRequirementName,
    },
    {
      label: MatrixColumnId.ParentShallStatement,
      prettyLabel: MatrixColumnLabels.ParentShallStatement,
    },
    {
      label: MatrixColumnId.ChildShallStatement,
      prettyLabel: MatrixColumnLabels.ChildShallStatement,
    },
  ],
}

const FILTER_GROUP_2 = {
  spaceBetween: true,
  filters: [
    {
      label: MatrixColumnId.Validation,
      prettyLabel: MatrixColumnLabels.Validation,
    },
    {
      label: MatrixColumnId.Verification,
      prettyLabel: MatrixColumnLabels.Verification,
    },
    {
      label: MatrixColumnId.ValidationRecordTitle,
      prettyLabel: MatrixColumnPrettyLabels.RecordTitle,
    },
    {
      label: MatrixColumnId.VerificationRecordTitle,
      prettyLabel: MatrixColumnPrettyLabels.RecordTitle,
    },
    {
      label: MatrixColumnId.ValidationDescriptionOfActivity,
      prettyLabel: MatrixColumnPrettyLabels.ValidationDescriptionOfActivity,
    },
    {
      label: MatrixColumnId.VerificationDescriptionOfActivity,
      prettyLabel: MatrixColumnPrettyLabels.VerificationDescriptionOfActivity,
    },
  ],
}

const Matrix = () => {
  const { requirementId: urlRequirementId } = useParams()
  const [queryParameters] = useSearchParams()
  const scrollToRequirementId =
    queryParameters.get('requirementId') || urlRequirementId

  const {
    specification,
    matrixViewUIConfig,
    revision,
    updateMatrixViewUIConfig,
    createRequirement,
    submittingNewVersion,
    isHistoricalRevision,
    publicTenant,
  } = useSpecificationContext()
  const [filters, toggleFilter] = useFilterState(
    getFilterKeys(isHistoricalRevision),
  )
  const { requirements, blockOrder, bodySectionId, requirementIdToComments } =
    useMatrixContext()
  const { userIsEditor } = useAuth()

  const userCanEdit = userIsEditor(specification.id)
  const { complianceStates } = useAttributesContext()
  const [expandAll, setExpandAll] = useState(false)
  const [searchResults, setSearchResults] = useState<
    SearchRequirementContent[]
  >([])

  const isFiltersEmpty = Object.keys(filters).reduce((prev, filterKey) => {
    const isEmpty = !filters[filterKey] || filters[filterKey].length === 0
    return prev && isEmpty
  }, true)

  const isFilteringEvidence =
    (filters[FilterKey.ValidationMethod] &&
      filters[FilterKey.ValidationMethod].length > 0) ||
    (filters[FilterKey.VerificationMethod] &&
      filters[FilterKey.VerificationMethod].length > 0)

  // Let the backend handle filtering evidences since we dynamically load them and can't filter all requirements without fetching all evidences upfront
  useEffect(() => {
    const loadData = async () => {
      if (!isFilteringEvidence) {
        setSearchResults([])
        return
      }

      const formattedFilters = {
        ...filters,
        compliance: filters.compliance.map(
          (compliance) =>
            complianceStates.find((state) => state.name === compliance)?.id,
        ),
      }

      const { contents } = await api.getSearchRequirements({
        query: '',
        specificationIds: [specification.id],
        // Since we're looking at one spec, we don't want to paginate. Set page size very high to get all results at once
        limit: 10_000,
        token: null,
        filters: formattedFilters,
      })
      setSearchResults(contents)
    }

    loadData()
  }, [
    complianceStates,
    filters,
    isFilteringEvidence,
    isFiltersEmpty,
    specification.id,
  ])

  const filteredRequirements = useMemo(() => {
    return requirements
      .filter((r) =>
        revision.status === RevisionStatus.ACTIVE
          ? r.status === RequirementStatus.Active
          : true,
      )
      .filter(
        (r) =>
          filters[FilterKey.RequirementStatus].length === 0 ||
          filters[FilterKey.RequirementStatus].includes(r.status),
      )
      .filter(
        (r) =>
          filters[FilterKey.RequirementType].length === 0 ||
          r.types.some((type) =>
            filters[FilterKey.RequirementType].includes(type),
          ),
      )
      .filter(
        (r) =>
          filters[FilterKey.Compliance].length === 0 ||
          filters[FilterKey.Compliance].includes(r.compliance),
      )
      .filter((r) => {
        if (
          !filters[FilterKey.ValidationMethod] ||
          filters[FilterKey.ValidationMethod].length === 0
        ) {
          return true
        }
        const isPresent =
          searchResults.findIndex((sr) => sr.requirement.id === r.id) > -1
        return isPresent
      })

      .filter((r) => {
        if (
          !filters[FilterKey.VerificationMethod] ||
          filters[FilterKey.VerificationMethod].length === 0
        ) {
          return true
        }
        const isPresent =
          searchResults.findIndex((sr) => sr.requirement.id === r.id) > -1

        return isPresent
      })
      .filter(
        (r) =>
          filters[FilterKey.RequirementComment].length === 0 ||
          requirementIdToComments[r.id]?.some((comment) =>
            filters[FilterKey.RequirementComment].includes(comment.resolved),
          ),
      )
      .map((r) => ({
        ...r,
        publicTenant,
      }))
  }, [
    requirements,
    revision.status,
    filters,
    searchResults,
    requirementIdToComments,
    publicTenant,
  ])

  const filteredColumns = useMemo(
    () =>
      (publicTenant
        ? PublicSpecMatrixColumns
        : isHistoricalRevision
          ? HistoricalRevisionColumns
          : MatrixColumns
      )
        .filter(
          (col) =>
            !matrixViewUIConfig.some(
              (item) => item.columnId === col.label && item.hidden,
            ),
        )
        .filter(
          (col) =>
            !(specification.external && col.label === MatrixColumnId.Status),
        ),
    [
      isHistoricalRevision,
      matrixViewUIConfig,
      publicTenant,
      specification.external,
    ],
  )

  const columnResizer = useResizableColumns(filteredColumns, 'matrixTable')

  const filteredColumnIds = matrixViewUIConfig
    .filter((item) => item.hidden)
    .map((item) => item.columnId)

  const scrollToIndex = filteredRequirements.findIndex(
    (requirement) => requirement.id === scrollToRequirementId,
  )

  const filterGroups = [
    FILTER_GROUP_0,
    ...(isHistoricalRevision ? [] : [FILTER_GROUP_1, FILTER_GROUP_2]),
  ].map((group) => {
    return {
      ...group,
      filters: group.filters.filter((filter) => {
        if (specification.external && filter.label === MatrixColumnId.Status) {
          return false
        }
        return matrixViewUIConfig.some((configItem) => {
          return configItem.columnId === filter.label
        })
      }),
    }
  }) as FilterGroup[]

  return (
    <div className={styles.root}>
      <div className={styles.actions}>
        <Button
          text={expandAll ? 'Collapse' : 'Expand'}
          onClick={() => setExpandAll(!expandAll)}
        />
        {!publicTenant && (
          <DisplayFilter
            filterGroups={filterGroups}
            selectedFilters={filteredColumnIds}
            onFilterClick={(filterName) =>
              updateMatrixViewUIConfig(filterName as MatrixColumnId)
            }
          />
        )}
        <FilterDropdown
          menus={getFilterKeys(isHistoricalRevision)}
          activeFilters={filters}
          onSelectFilter={toggleFilter}
        />
        <AppliedFilters
          filterKeys={getFilterKeys(isHistoricalRevision)}
          filters={filters}
          onRemove={toggleFilter}
        />
      </div>
      {!submittingNewVersion && (
        <div className={styles.table}>
          <div className={styles.inner}>
            <VirtualTable
              rowItems={filteredRequirements}
              columnResizer={columnResizer}
              scrollToIndex={scrollToIndex}
              expandAll={expandAll}
            />
            {revision.status === RevisionStatus.DRAFT &&
              userCanEdit &&
              !publicTenant &&
              !isHistoricalRevision && (
                <button
                  className={styles.add}
                  onClick={() =>
                    createRequirement(
                      bodySectionId,
                      blockOrder.slice(-1)[0] || null,
                      {},
                    )
                  }
                >
                  <Add />
                </button>
              )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Matrix
