import { Team } from '../api/v2/teams.ts'
import { UserRole } from '../types/enums.ts'

export const getUserRole: (teams: Team[] | undefined) => UserRole = (
  teams = [],
) => {
  const teamRoleNames = teams.map((role) => role.name)

  if (teamRoleNames.includes(UserRole.ROOT)) {
    return UserRole.ROOT
  }

  if (teamRoleNames.includes(UserRole.ADMIN)) {
    return UserRole.ADMIN
  }

  return UserRole.MEMBER
}

interface IsAdminCheckOptions {
  teams?: Team[] | undefined
  userRole?: UserRole | undefined
}

export const isAdminCheck: (options: IsAdminCheckOptions) => boolean = ({
  teams,
  userRole,
}) => {
  if (teams) {
    userRole = getUserRole(teams)
    return userRole.includes(UserRole.ADMIN) || userRole.includes(UserRole.ROOT)
  }

  if (userRole) {
    return userRole.includes(UserRole.ADMIN) || userRole.includes(UserRole.ROOT)
  }

  return false
}
