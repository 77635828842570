import { useCallback, useEffect, useMemo, useState } from 'react'
import { getAllUsers, User } from '../api/v2/users.ts'

interface UseUsers {
  users: User[]
  getUserById: (userId: string) => User | null
}

const useUsers = (): UseUsers => {
  const [users, setUsers] = useState<Record<string, User>>({})

  const fetchUsers = async () => {
    const users = (await getAllUsers())?.users || []
    const userIdToUser = users.reduce((acc, user) => {
      return {
        ...acc,
        [user.id]: user,
      }
    }, {})
    setUsers(userIdToUser)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const getUserById = useCallback((userId: string) => users[userId], [users])

  return {
    users: useMemo(() => Object.values(users), [users]),
    getUserById,
  }
}

export default useUsers
