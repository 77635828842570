import { useCallback } from 'react'
import Modal from '.'
import useClickOutside from '../../hooks/useClickOutside'
import SpecificationPdfViewer from '../../pages/specification/pdf-viewer/SpecificationPdfViewer'

export interface SpecificationPdfViewerModalProps {
  specificationId: string
  assetId: string
  uploadedOn: Date
}

const SpecificationPdfViewerModal = (
  props: SpecificationPdfViewerModalProps & { closeModal: () => void },
) => {
  const { closeModal, specificationId, assetId, uploadedOn } = props

  const onClickOutside = useCallback(() => {
    closeModal()
  }, [closeModal])

  const containerRef = useClickOutside(onClickOutside)

  return (
    <Modal
      innerRef={containerRef}
      title="Original document"
      subtitle={`Uploaded ${uploadedOn}`}
      onClose={closeModal}
    >
      <div
        style={{
          minHeight: '80vh',
          maxHeight: '80vh',
          minWidth: '70vw',
          overflow: 'auto',
        }}
      >
        <SpecificationPdfViewer
          assetId={assetId}
          specificationId={specificationId}
        />
      </div>
    </Modal>
  )
}

export default SpecificationPdfViewerModal
