import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AttributeValueResponse } from '../api/v2/attributes.ts'
import { FilterKey, FilterSet } from '../components/filter-menu/types.ts'

export interface LoadSearchParamConfig {
  key: string
  hasLoaded: (val: string | null) => boolean
}

/**
 * useLoadSearchParams is designed for query params that need time to process after initial render (often
 * due to waiting for other app state to resolve before processing the query param).
 * The hook will continue to check the loading state of the configs passed in as the configs and their dependencies change.
 * When the caller determines the loading state is successful, they are free to do additional tasks within `hasLoaded`, like set additional state.
 *
 * Note: This hook will only call hasLoaded until it returns true for the first time and no longer after wards.
 */
const useLoadSearchParams = (configs: LoadSearchParamConfig[]) => {
  const [urlParams] = useSearchParams()

  const loadedQueryParams = useRef(
    configs.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.key]: false,
      }
    }, {}) as Record<string, boolean>,
  )

  useEffect(() => {
    if (Object.values(loadedQueryParams.current).every((val) => val)) {
      return
    }

    configs.forEach((config) => {
      if (!loadedQueryParams.current[config.key]) {
        loadedQueryParams.current[config.key] = config.hasLoaded(
          urlParams.get(config.key),
        )
      }
    })
  }, [urlParams, configs])
}

const hasLoadedProgramFilter = (
  val: string | null,
  activePrograms: AttributeValueResponse[],
  activeFilters: FilterSet,
  toggleFilter: (filterKey: FilterKey, value: any) => void,
  filterKey: FilterKey,
) => {
  if (val && activePrograms && activeFilters && activePrograms.length > 0) {
    const matchingProgram = activePrograms.find(
      (program) => program.id && program.id === val,
    )

    if (matchingProgram?.id && activeFilters[filterKey].length < 1) {
      toggleFilter(filterKey, matchingProgram.id)
    } else {
      console.warn(`No active program found for query param ${val}`)
    }

    return true
  }

  return false
}

export const hasLoadedSpecProgramFilter = (
  val: string | null,
  activePrograms: AttributeValueResponse[],
  activeFilters: FilterSet,
  toggleFilter: (filterKey: FilterKey, value: any) => void,
) => {
  return hasLoadedProgramFilter(
    val,
    activePrograms,
    activeFilters,
    toggleFilter,
    FilterKey.SpecificationProgram,
  )
}

export const hasLoadedEvidenceProgramFilter = (
  val: string | null,
  activePrograms: AttributeValueResponse[],
  activeFilters: FilterSet,
  toggleFilter: (filterKey: FilterKey, value: any) => void,
) => {
  return hasLoadedProgramFilter(
    val,
    activePrograms,
    activeFilters,
    toggleFilter,
    FilterKey.EvidenceProgram,
  )
}

export default useLoadSearchParams
