import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import * as api from '../api/v2/changeRequests'

type ChangeRequestList = {
  changeRequests: api.ChangeRequest[]
  updateChangeRequest: (
    specificationId: string,
    requirementId: string,
    changeRequestId: string,
    update: Partial<api.ChangeRequest>,
  ) => void
}

const ChangeRequestListContext = createContext<ChangeRequestList>({
  changeRequests: [],
  updateChangeRequest: () => {},
})

const ChangeRequestListContextProvider = (props) => {
  const [changeRequests, setChangeRequests] = useState<api.ChangeRequest[]>([])

  useEffect(() => {
    const fetchChangeRequests = async () => {
      const requests = await api.getAllChangeRequests()
      setChangeRequests(requests)
    }
    fetchChangeRequests()
  }, [])

  const updateChangeRequest = useCallback(
    async (
      specificationId: string,
      requirementId: string,
      changeRequestId: string,
      update: Partial<api.ChangeRequest>,
    ) => {
      await api.updateChangeRequest(
        specificationId,
        requirementId,
        changeRequestId,
        update,
      )
      setChangeRequests((requests) =>
        requests.map((r) =>
          r.id === changeRequestId ? { ...r, ...update } : r,
        ),
      )
    },
    [],
  )

  return (
    <ChangeRequestListContext.Provider
      value={{ changeRequests, updateChangeRequest }}
    >
      {props.children}
    </ChangeRequestListContext.Provider>
  )
}

const useChangeRequestListContext = () => {
  const ctx = useContext(ChangeRequestListContext)
  if (!ctx) {
    console.error('ChangeRequestListContext has no provider')
  }
  return ctx
}

export { ChangeRequestListContextProvider, useChangeRequestListContext }
