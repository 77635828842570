import { Close, Document } from '@carbon/icons-react'
import { Link } from 'react-router-dom'
import styles from './ExceptionLinkedRequirementBox.module.css'
import globalStyles from '../../global.module.css'

const ExceptionLinkedRequirementBox = ({ requirement, onDelete, editable }) => {
  const { title, specificationTitle, text, specificationId, requirementId } =
    requirement

  return (
    <div
      className={`${styles.requirementBox} ${globalStyles.basicElevation} ${
        !editable ? styles.consumption : ''
      }`}
    >
      {editable && (
        <button
          className={styles.closeButton}
          onClick={onDelete}
          aria-label="Delete"
        >
          <Close size={16} />
        </button>
      )}
      <div className={styles.requirementTitle}>{title}</div>
      <div className={styles.specificationTitle}>
        <span>
          <Document size={16} />
        </span>
        {specificationTitle}
      </div>
      <div className={styles.requirementText}>{text}</div>
      {!editable && (
        <Link
          className={styles.link}
          to={`/specifications/${specificationId}/matrix/${requirementId}`}
          target="_blank"
        >
          View in matrix
        </Link>
      )}
    </div>
  )
}

export default ExceptionLinkedRequirementBox
