import styles from './UnauthorizedPage.module.css'

export const UnauthorizedPage = () => {
  return (
    <div className={styles.errorPage}>
      <div className={styles.header}>Sorry, something went wrong.</div>
      <div className={styles.message}>
        You don't have permission to access this page.
        <br />
        If you need assistance, contact our support team at{' '}
        <a href={`mailto:help@stell-engineering.com`} className={styles.link}>
          help@stell-engineering.com
        </a>
      </div>
    </div>
  )
}
