import { captureException } from '@sentry/react'
import { useCallback, useEffect, useState } from 'react'
import ChangeRequestCard from './change-request-card/ChangeRequestCard.tsx'
import CommentCard from './comment-card/CommentCard.tsx'
import CommentForm, { CommentMode } from './comment-card/CommentForm.tsx'
import styles from './RequirementComments.module.css'
import { UUID } from '../../../api/utilityTypes.ts'
import {
  deleteChangeRequest,
  updateChangeRequest,
} from '../../../api/v2/changeRequests.ts'
import * as contextApi from '../../../api/v2/context.ts'
import * as reqApi from '../../../api/v2/requirements.ts'
import Accordion from '../../../components/accordion'
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator.tsx'
import { toastError } from '../../../components/toast'
import { useAuth } from '../../../context/AuthContext.tsx'
import {
  RequirementChangeRequest,
  RequirementCommentType,
} from '../../../types/api/v2/requirements.ts'
import { ChangeRequestStatus } from '../../../types/enums.ts'

const sortByTime = (
  a: reqApi.RequirementCommentWrapper,
  b: reqApi.RequirementCommentWrapper,
) => b.createdOn.getTime() - a.createdOn.getTime()

const RequirementComments = (props: {
  specificationId: string
  revisionId: string
  requirement: reqApi.Requirement
  onAddComment: () => void
  onDeleteComment: () => void
  onToggleResolveComment?: (requirementId: string, commentId: string) => void
}) => {
  const {
    requirement,
    specificationId,
    revisionId,
    onAddComment,
    onDeleteComment,
    onToggleResolveComment,
  } = props
  const { userIsCommenter } = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)
  const [commentMode, setCommentMode] = useState(CommentMode.Comment)
  const [comments, setComments] = useState<reqApi.RequirementCommentWrapper[]>(
    [],
  )
  const [previousComments, setPreviousComments] =
    useState<reqApi.RequirementCommentWrapper[]>()

  const fetchComments = useCallback(async () => {
    setLoading(true)
    try {
      const cs = await reqApi.getRequirementCommentsWithUsers(
        specificationId,
        requirement.id,
      )
      setComments(cs.sort(sortByTime))
    } catch (error) {
      console.error('Unable to get comments to display.', error)
      setError(error as Error)
      captureException(error)
    } finally {
      setLoading(false)
    }
  }, [requirement.id, specificationId])

  const onCreate = async () => {
    setComments(
      (
        await reqApi.getRequirementCommentsWithUsers(
          specificationId,
          requirement.id,
        )
      ).sort(sortByTime),
    )
    if (onAddComment) {
      onAddComment()
    }
  }

  const onStatusUpdate = async (id: string, status: ChangeRequestStatus) => {
    try {
      await updateChangeRequest(specificationId, requirement.id, id, { status })
      setComments((cs) =>
        cs.map((c) =>
          c.content.id === id ? { ...c, content: { ...c.content, status } } : c,
        ),
      )
    } catch (e) {
      toastError('Error updating status', 'Please refresh and try again')
      captureException(e)
    }
  }

  useEffect(() => {
    fetchComments()
  }, [fetchComments])

  const onDelete = async (comment: reqApi.RequirementCommentWrapper) => {
    if (comment.type === RequirementCommentType.ChangeRequest) {
      try {
        await deleteChangeRequest(
          specificationId,
          requirement.id,
          comment.content.id,
        )
        setComments((cs) =>
          cs.filter((c) => c.content.id !== comment.content.id),
        )

        if (onDeleteComment) {
          onDeleteComment()
        }
      } catch (e) {
        captureException(e)
        toastError(
          'Error deleting change request',
          'Please refresh and try again',
        )
      }
    } else {
      try {
        await reqApi.deleteComment(
          specificationId,
          requirement.id,
          comment.content.id,
        )
        setComments((cs) =>
          cs.filter((c) => c.content.id !== comment.content.id),
        )

        if (onDeleteComment) {
          onDeleteComment()
        }
      } catch (e) {
        captureException(e)
        toastError('Error deleting comment', 'Please refresh and try again')
      }
    }
  }

  const onToggleResolve = useCallback(
    async (commentId: UUID, resolveComment: boolean) => {
      try {
        await reqApi.updateRequirementComment(
          specificationId,
          requirement.id,
          commentId,
          { resolve: resolveComment },
        )
        setComments((prev) =>
          prev.map((comment) =>
            comment.content.id === commentId
              ? {
                  ...comment,
                  content: { ...comment.content, resolved: resolveComment },
                }
              : comment,
          ),
        )
        if (onToggleResolveComment) {
          onToggleResolveComment(requirement.id, commentId)
        }
      } catch (error) {
        console.error(
          `Error ${resolveComment ? 'resolving' : 'un-resolving'} comment`,
          error,
        )
        captureException(error)
        toastError(
          `Unable to ${resolveComment ? 'resolve' : 're-open'} comment`,
          'Try again later',
        )
      }
    },
    [onToggleResolveComment, requirement.id, specificationId],
  )

  useEffect(() => {
    const loadPreviousComments = async () => {
      try {
        const requirementVersions = [
          ...(
            await contextApi.getRequirementVersions(
              specificationId,
              requirement.context.id,
            )
          ).sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime()),
        ]

        const previousCommentRequests = requirementVersions
          .filter((reqVersion) => requirement.id !== reqVersion.requirementId)
          .map(async (reqVersion) => {
            const response = await reqApi.getRequirementCommentsWithUsers(
              specificationId,
              reqVersion.requirementId,
            )

            return response.map((wrapper) => ({
              ...wrapper,
              version: reqVersion.version,
            }))
          })

        setPreviousComments((await Promise.all(previousCommentRequests)).flat())
      } catch (error) {
        captureException(error)
        console.error('Unable to load previous comments', error)
      }
    }

    loadPreviousComments()
  }, [specificationId, requirement, revisionId])

  return (
    <div className={styles.requirementComments}>
      <div>
        {/* TODO STE-1418: Disable for historical revisions */}
        {userIsCommenter(specificationId) && (
          <CommentForm
            requirementId={requirement.id}
            specificationId={specificationId}
            commentMode={commentMode}
            toggleCommentMode={() => {
              setCommentMode(
                commentMode === CommentMode.Comment
                  ? CommentMode.ChangeRequest
                  : CommentMode.Comment,
              )
            }}
            onCreate={onCreate}
          />
        )}
      </div>
      <div>
        {loading && (
          <div className={styles.loadingContainer}>
            <LoadingIndicator />
          </div>
        )}
        {error && (
          <div className={styles.errorContainer}>
            Unable to load comments at this time.
          </div>
        )}
        {comments.length === 0 && (
          <div className={styles.errorContainer}>
            No comments on current version
          </div>
        )}
        {comments.length > 0 &&
          comments.map((comment: reqApi.RequirementCommentWrapper) =>
            comment.type === RequirementCommentType.ChangeRequest ? (
              <ChangeRequestCard
                key={comment.content.id}
                changeRequest={comment.content as RequirementChangeRequest}
                onStatusChange={(status) =>
                  onStatusUpdate(comment.content.id, status)
                }
                onDelete={() => onDelete(comment)}
              />
            ) : (
              <CommentCard
                key={comment.content.id}
                comment={comment.content as reqApi.RequirementComment}
                onDelete={() => onDelete(comment)}
                onToggleResolve={onToggleResolve}
              />
            ),
          )}
      </div>
      {previousComments && previousComments.length > 0 && (
        <Accordion
          sections={[
            {
              title: 'Comments on previous versions',
              children: (
                <div style={{ width: '100%' }}>
                  {previousComments.map(
                    (comment: reqApi.RequirementCommentWrapper) =>
                      comment.type === RequirementCommentType.ChangeRequest ? (
                        <ChangeRequestCard
                          key={comment.content.id}
                          changeRequest={
                            comment.content as RequirementChangeRequest
                          }
                          requirementVersion={comment.version}
                        />
                      ) : (
                        <CommentCard
                          key={comment.content.id}
                          comment={comment.content as reqApi.RequirementComment}
                          requirementVersion={comment.version}
                        />
                      ),
                  )}
                </div>
              ),
            },
          ]}
        />
      )}
    </div>
  )
}

export default RequirementComments
