import { createContext, useContext, useState, useEffect } from 'react'
import { useMatches } from 'react-router-dom'

export interface Breadcrumb {
  text: string
  linkTo: string
}

interface BreadcrumbsCtx {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: (crumbs: Breadcrumb[]) => void
}

const BreadcrumbsContext = createContext<BreadcrumbsCtx>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
})

const BreadcrumbsContextProvider = (props) => {
  const matches = useMatches() as any

  const match = matches.findLast((match) => match.handle?.crumbs)

  const initialBreadcrumbs =
    typeof match?.handle?.crumbs === 'function'
      ? match.handle.crumbs(match.data, match.params)
      : []

  const [breadcrumbs, setBreadcrumbs] =
    useState<Breadcrumb[]>(initialBreadcrumbs)

  useEffect(() => {
    // Use dynamicCrumb to enable page management of breadcrumbs
    if (match?.handle?.isDynamicCrumb) return

    setBreadcrumbs(match?.handle?.crumbs?.(match.data, match.params) || [])
  }, [match])

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {props.children}
    </BreadcrumbsContext.Provider>
  )
}

const useBreadcrumbsContext = () => {
  const ctx = useContext(BreadcrumbsContext)
  if (!ctx) {
    console.error('BreadcrumbsContext has no provider')
  }
  return ctx
}

export { BreadcrumbsContextProvider, useBreadcrumbsContext }
