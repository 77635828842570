import { OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './EvidenceActivityTableColumns.module.css'
import Dropdown from '../../components/dropdown'
import { TableColumn } from '../../components/table/Table'
import Tag, { TAG_COLORS } from '../../components/tag'
import EvidenceActivityStatusTag from '../../components/tag/EvidenceActivityStatusTag'
import EvidenceActivityTypeTag from '../../components/tag/EvidenceActivityTypeTag'
import ProgramTag from '../../components/tag/ProgramTag.tsx'
import { useAttributesContext } from '../../context/AttributesContext'
import { useEvidenceActivitiesContext } from '../../context/EvidenceActivitiesContext'
import useClickOutside from '../../hooks/useClickOutside'
import { useModals } from '../../hooks/useModals'
import { monthDayYear } from '../../lib/date'

const Actions = ({ data }) => {
  const [open, setOpen] = useState(false)
  const { openConfirmationModal } = useModals()
  const { deleteEvidenceActivity } = useEvidenceActivitiesContext()

  const closeMenu = () => {
    if (open) {
      setOpen(false)
    }
  }

  const ref = useClickOutside(closeMenu)

  const onDelete = () => {
    closeMenu()
    openConfirmationModal({
      title: 'Delete Activity',
      titleIcon: <TrashCan size={20} />,
      promptText: `Are you sure you want to delete evidence activity '${data.title}'?`,
      onConfirm: () => {
        deleteEvidenceActivity(data.recordId, data.id)
      },
    })
  }
  return (
    <div ref={ref} className={styles.actions}>
      <button onClick={() => setOpen(!open)}>
        <OverflowMenuVertical size={24} />
      </button>
      <Dropdown className={styles.dropdown} isOpen={open}>
        <button
          className={`${styles.actionItem} ${styles.deleteButton}`}
          onClick={onDelete}
        >
          Delete
        </button>
      </Dropdown>
    </div>
  )
}

const ActivityTitle = ({ data }) => {
  return (
    <div className={styles.activityTitle}>
      <span>{data.title}</span>
    </div>
  )
}

const DateCreated = ({ data }) => {
  return (
    <div className={styles.dateCreated}>{monthDayYear(data.createdOn)}</div>
  )
}

const ParentRecord = ({ data }) => {
  const { getEvidenceRecordById } = useEvidenceActivitiesContext()
  const { getEvidenceMethodById } = useAttributesContext()
  let color = ''
  const evidence = getEvidenceRecordById(data.recordId)
  if (evidence) {
    const method = getEvidenceMethodById(evidence.method)
    color = method?.metadata.STYLES.COLOR_BG || ''
  }

  return (
    <div className={styles.parentRecord}>
      <span className={styles.pip} style={{ backgroundColor: color }}></span>
      <span className={styles.title}>{evidence?.title}</span>
    </div>
  )
}

const InheritedProgram = ({ data }) => {
  const { getEvidenceRecordById } = useEvidenceActivitiesContext()
  const evidence = getEvidenceRecordById(data.recordId)

  return (
    <div>
      {evidence?.program && <ProgramTag programId={evidence.program} />}
    </div>
  )
}

const InheritedMethod = ({ data }) => {
  const { getEvidenceRecordById } = useEvidenceActivitiesContext()
  const { getEvidenceMethodById } = useAttributesContext()

  const evidence = getEvidenceRecordById(data.recordId)
  const method = evidence?.method
    ? getEvidenceMethodById(evidence.method)
    : undefined

  const tagColor = method
    ? {
        fontColor: method.metadata.STYLES.COLOR_FONT,
        backgroundColor: method.metadata.STYLES.COLOR_BG,
      }
    : TAG_COLORS.gray3NoHover

  return (
    <div>
      <Tag text={method?.name || ''} color={tagColor} />
    </div>
  )
}

const Owners = ({ data }) => {
  return (
    <div className={styles.owners}>
      {data.owners.map((owner: string, index: number) => {
        const user = data.ownerIdsToName[owner]
        const comma = data.owners.length - 1 === index ? '' : `, `
        return (
          user && (
            <span key={user.id} className={styles.owner}>
              {user.firstName} {user.lastName}
              {comma}
            </span>
          )
        )
      })}
    </div>
  )
}

const Type = ({ data }) => {
  return (
    <div className={styles.type}>
      <EvidenceActivityTypeTag type={data.type} />
    </div>
  )
}

const DueDate = ({ data }) => {
  return <div className={styles.dueDate}>{monthDayYear(data.dueDate)}</div>
}

const Status = ({ data }) => {
  return (
    <div className={styles.status}>
      <EvidenceActivityStatusTag status={data.status} />
    </div>
  )
}

export enum EvidenceActivityColumnLabels {
  ACTIONS = '',
  TITLE = 'Activity Title',
  TYPE = 'Type',
  DATE_CREATED = 'Date Created',
  PARENT_RECORD = 'Parent Record',
  INHERITED_PROGRAM = 'Program',
  INHERITED_METHOD = 'Method',
  OWNER = 'Owner',
  DUE_DATE = 'Due Date',
  STATUS = 'Status',
}

const EvidenceActivityTableColumns: TableColumn[] = [
  {
    label: EvidenceActivityColumnLabels.ACTIONS,
    Component: Actions,
    transparent: true,
  },
  {
    label: EvidenceActivityColumnLabels.TITLE,
    Component: ActivityTitle,
  },
  {
    label: EvidenceActivityColumnLabels.TYPE,
    Component: Type,
  },
  {
    label: EvidenceActivityColumnLabels.DATE_CREATED,
    Component: DateCreated,
  },
  {
    label: EvidenceActivityColumnLabels.PARENT_RECORD,
    Component: ParentRecord,
  },
  {
    label: EvidenceActivityColumnLabels.INHERITED_PROGRAM,
    Component: InheritedProgram,
  },
  {
    label: EvidenceActivityColumnLabels.INHERITED_METHOD,
    Component: InheritedMethod,
  },
  {
    label: EvidenceActivityColumnLabels.OWNER,
    Component: Owners,
  },
  {
    label: EvidenceActivityColumnLabels.DUE_DATE,
    Component: DueDate,
  },
  {
    label: EvidenceActivityColumnLabels.STATUS,
    Component: Status,
  },
]

export default EvidenceActivityTableColumns
