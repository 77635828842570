import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { RequirementOverviewGraphProps } from './RequirementOverviewGraph.tsx'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import { Evidence, EvidenceActivity } from '../../api/v2/evidence.ts'
import {
  ChangeRequestAddedData,
  CommentAddedData,
  EvidenceRecordLinkedData,
  Notification,
  NotificationType,
  ParentChildLinkAddedData,
  RequirementTextChangedData,
  ReviewRequestedData,
} from '../../api/v2/notifications.ts'
import {
  getSearchRequirements,
  SearchRequirementContent,
} from '../../api/v2/search.ts'
import * as api from '../../api/v2/specifications.ts'
import * as tenantsApi from '../../api/v2/tenants.ts'
import { TenantOverview } from '../../api/v2/tenants.ts'
import { FilterKey, FilterSet } from '../../components/filter-menu/types.ts'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import {
  EvidenceActivityData,
  useEvidenceActivitiesContext,
} from '../../context/EvidenceActivitiesContext.tsx'
import { useNotifications } from '../../context/NotificationsContext.tsx'
import { useSpecificationListContext } from '../../context/SpecificationListContext.tsx'
import * as ls from '../../lib/localstorage.ts'
import {
  getRecentlyUsedDashboardFilter,
  setRecentlyUsedDashboardFilter,
} from '../../lib/localstorage.ts'
import { EvidenceActivityStatus, LoadingState } from '../../types/enums.ts'

const isIncompleteActivity = (activity: EvidenceActivity) => {
  return (
    activity.status === EvidenceActivityStatus.Todo ||
    activity.status === EvidenceActivityStatus.InProgress
  )
}

interface DashboardDataLoaded {
  filteredSpecifications: Array<api.Specification>
  getIncompleteActivitiesFromEvidenceId: (id: string) => EvidenceActivityData[]
  isLoading: boolean
  incompleteRecords: Evidence[]
  recentlyCreatedReqs: SearchRequirementContent[]
  requirementOverviewGraphData: RequirementOverviewGraphProps
  selectedProgram: AttributeValueResponse | null
  setSelectedProgram: Dispatch<SetStateAction<AttributeValueResponse | null>>
  selectedSpecificationsCount: number
  selectedRecordsCount: number
  selectedRequirementsCount: number
  selectedActivitiesCount: number
  evidenceActivityTags: Record<EvidenceActivityStatus, number>
  taskNotifications: Notification<
    ReviewRequestedData | CommentAddedData | ChangeRequestAddedData
  >[]
  updatedReqNotifications: Notification<
    | RequirementTextChangedData
    | EvidenceRecordLinkedData
    | ParentChildLinkAddedData
  >[]
}
const useDashboardDataLoader = (): DashboardDataLoaded => {
  const { allNotifications } = useNotifications()
  const { specifications } = useSpecificationListContext()
  const { evidenceRecords, evidenceActivities, evidenceActivitiesLoading } =
    useEvidenceActivitiesContext()
  const [selectedProgram, setSelectedProgram] =
    useState<AttributeValueResponse | null>(ls.getRecentlyUsedDashboardFilter())
  const { activePrograms } = useAttributesContext()
  const [recentlyCreatedReqs, setRecentlyCreatedReqs] = useState<
    SearchRequirementContent[]
  >([])
  const [requirementOverviewGraphData, setRequirementOverviewGraphData] =
    useState<RequirementOverviewGraphProps>({
      completeRequirements: 0,
      incompleteRequirements: 0,
      partiallyCompleteRequirements: 0,
    })

  const [
    isFetchingInitialRecentlyCreatedReqs,
    setIsFetchingInitialRecentlyCreatedReqs,
  ] = useState(true)
  const [
    isFetchingInitialReqOverviewData,
    setIsFetchingInitialReqOverviewData,
  ] = useState(true)

  const isLoading =
    isFetchingInitialRecentlyCreatedReqs ||
    isFetchingInitialReqOverviewData ||
    evidenceActivitiesLoading === LoadingState.Loading

  const filteredSpecifications = useMemo(
    () =>
      selectedProgram
        ? specifications.filter((spec) => spec.program === selectedProgram?.id)
        : specifications,
    [selectedProgram, specifications],
  )

  const filteredEvidences = useMemo(
    () =>
      selectedProgram
        ? evidenceRecords.filter(
            (evidence) => evidence.program === selectedProgram?.id,
          )
        : evidenceRecords,
    [evidenceRecords, selectedProgram],
  )

  const filteredEvidenceActivites = useMemo(
    () =>
      selectedProgram
        ? evidenceActivities.filter((evidenceActivity) =>
            evidenceRecords.some((evidence) => {
              const preposition =
                evidence.id === evidenceActivity.recordId &&
                evidence.program === selectedProgram?.id
              return preposition
            }),
          )
        : evidenceActivities,
    [evidenceActivities, evidenceRecords, selectedProgram],
  )

  const evidenceActivityTags = useMemo(() => {
    return filteredEvidenceActivites.reduce(
      (acc, cur) => {
        acc[cur.status] = acc[cur.status] ? acc[cur.status] + 1 : 1
        return acc
      },
      {} as Record<EvidenceActivityStatus, number>,
    )
  }, [filteredEvidenceActivites])

  const filteredNotifications = useMemo(
    () =>
      (selectedProgram
        ? (allNotifications || []).filter(
            (notification) =>
              filteredSpecifications.find(
                (spec) => spec.id === notification.data.specificationId,
              )?.program === selectedProgram.id,
          )
        : allNotifications || []
      ).filter((notification) => !notification.read),
    [allNotifications, filteredSpecifications, selectedProgram],
  )

  const taskNotifications = useMemo(
    () =>
      filteredNotifications
        .filter(
          (notification) =>
            notification.type === NotificationType.REVIEW_REQUESTED ||
            notification.type === NotificationType.COMMENT_ADDED ||
            notification.type === NotificationType.CHANGE_REQUEST_ADDED,
        )
        .slice(0, 5),
    [filteredNotifications],
  ) as Notification<
    ReviewRequestedData | CommentAddedData | ChangeRequestAddedData
  >[]

  const updatedReqNotifications = useMemo(() => {
    const specIds = filteredSpecifications.map((spec) => spec.id)
    return (allNotifications || [])
      .filter(
        (notification) =>
          specIds.includes(notification.data.specificationId) &&
          (notification.type === NotificationType.REQUIREMENT_TEXT_CHANGED ||
            notification.type === NotificationType.EVIDENCE_RECORD_LINKED ||
            notification.type === NotificationType.PARENT_CHILD_LINK_ADDED ||
            notification.type ===
              NotificationType.REQUIREMENT_COMPLIANCE_CHANGED),
      )
      .slice(0, 4)
  }, [allNotifications, filteredSpecifications]) as Notification<
    | RequirementTextChangedData
    | EvidenceRecordLinkedData
    | ParentChildLinkAddedData
  >[]

  const incompleteRecords = useMemo(
    () =>
      filteredEvidences
        .filter(
          (record) =>
            evidenceActivities.some(
              (activity) =>
                activity.recordId === record.id &&
                isIncompleteActivity(activity),
            ) ||
            evidenceActivities.findIndex(
              (activity) =>
                activity.recordId === record.id &&
                activity.status === EvidenceActivityStatus.Complete,
            ) === -1,
        )
        .slice(0, 5),
    [evidenceActivities, filteredEvidences],
  )

  const selectedSpecificationsCount = useMemo(
    () => filteredSpecifications.length,
    [filteredSpecifications.length],
  )
  const selectedRequirementsCount = useMemo(
    () =>
      filteredSpecifications
        .map((spec) => spec.requirementCount)
        .reduce((sum, count) => sum + count, 0),
    [filteredSpecifications],
  )

  const selectedRecordsCount = useMemo(
    () => filteredEvidences.length,
    [filteredEvidences.length],
  )
  const selectedActivitiesCount = useMemo(
    () => filteredEvidenceActivites.length,
    [filteredEvidenceActivites.length],
  )
  const getIncompleteActivitiesFromEvidenceId = useCallback(
    (evidenceId: string) => {
      return evidenceActivities.filter(
        (activity) =>
          activity.recordId === evidenceId && isIncompleteActivity(activity),
      )
    },
    [evidenceActivities],
  )

  useEffect(() => {
    const lsRecentlyUsedDashboardFilter = getRecentlyUsedDashboardFilter()
    // Check if saved program filter in local storage is still valid (the program may have been deleted since being saved)
    if (
      activePrograms &&
      activePrograms.length > 0 &&
      selectedProgram &&
      !activePrograms.some((pro) => pro.id === selectedProgram.id)
    ) {
      setRecentlyUsedDashboardFilter(null)
      setSelectedProgram(null)
    }

    if (selectedProgram !== lsRecentlyUsedDashboardFilter) {
      setRecentlyUsedDashboardFilter(selectedProgram)
    }
  }, [activePrograms, selectedProgram])

  useEffect(() => {
    const fetchTenantOverview = async () => {
      let tenantOverview = {} as TenantOverview
      try {
        tenantOverview = await tenantsApi.getTenantOverview(selectedProgram?.id)
      } catch {
        console.error('Failed to fetch tenant overview data.')
      }

      setRequirementOverviewGraphData({
        completeRequirements:
          tenantOverview?.requirements?.counts?.complete || 0,
        partiallyCompleteRequirements:
          tenantOverview?.requirements?.counts?.partial || 0,
        incompleteRequirements:
          tenantOverview?.requirements?.counts?.incomplete || 0,
      })
      setIsFetchingInitialReqOverviewData(false)
    }

    const fetchRecentlyCreatedReqs = async () => {
      const firstRecentlyCreatedReqs = (
        await getSearchRequirements({
          query: '',
          limit: 5,
          filters: (selectedProgram
            ? { [FilterKey.SpecificationProgram]: [selectedProgram.id] }
            : {}) as unknown as FilterSet,
        })
      ).contents
      setRecentlyCreatedReqs(firstRecentlyCreatedReqs)
      setIsFetchingInitialRecentlyCreatedReqs((val) => (!val ? val : false))
    }

    fetchTenantOverview()
    fetchRecentlyCreatedReqs()
  }, [selectedProgram])

  return {
    filteredSpecifications,
    getIncompleteActivitiesFromEvidenceId,
    isLoading,
    incompleteRecords,
    recentlyCreatedReqs,
    requirementOverviewGraphData,
    selectedProgram,
    setSelectedProgram,
    selectedSpecificationsCount,
    selectedRecordsCount,
    selectedRequirementsCount,
    taskNotifications,
    updatedReqNotifications,
    selectedActivitiesCount,
    evidenceActivityTags,
  }
}

export default useDashboardDataLoader
