import { animated, useSpring } from '@react-spring/web'
import { MouseEvent } from 'react'
import styles from './AnimatedNode.module.css'
import Node from './Node'
import { ExpandedNode, TreeNode } from './types'
import { getCssVar } from '../../../lib/string.ts'

interface AnimatedNodeProps {
  index: number
  node: TreeNode
  position: string
  verticalOffset: number
  expandedNode: ExpandedNode
  expansionVerticalOffset: number
  rootHeight: number
  nodeHeight: number
  expandedNodeHeight: number
  nodeWidth: number
  setExpandedNode: (expandedNode: ExpandedNode) => void
  setExpandedNodeHeight: (expandedNodeHeight: number) => void
  setRootHeight: (rootHeight: number) => void
  closeModal: () => void
}

const AnimatedNode = (props: AnimatedNodeProps) => {
  const {
    index,
    node,
    position,
    verticalOffset,
    expandedNode,
    expansionVerticalOffset,
    rootHeight,
    nodeHeight,
    expandedNodeHeight,
    nodeWidth,
    setExpandedNode,
    setExpandedNodeHeight,
    setRootHeight,
    closeModal,
  } = props
  const isRootNode = node.depth === 0
  const isBelowExpandedNode =
    expandedNode.position === position && expandedNode.index < index
  const isExpandedNode =
    expandedNode.position === position && expandedNode.index === index
  const additionalExpandedWidth = 50
  const shiftParent =
    expandedNode.position === position &&
    position === 'left' &&
    !isBelowExpandedNode
  const shiftChild =
    expandedNode.position === position &&
    position === 'right' &&
    isBelowExpandedNode
  const offsetSign = node.data.position === 'left' ? -1 : 1

  let top = node.x
  let left = node.y

  if (node.data.position !== 'root') {
    top += verticalOffset * offsetSign
    left = (node.y + 250) * offsetSign

    // Shifts grandrelation nodes further left/right
    // Adds 50 when attached to expanded node to account for expanded node width
    left += node.data.level > 1 ? 300 * offsetSign : 0
    left += node.data.level > 1 && position === 'right' ? 50 : 0
  }

  // Centers nodes on expanded node
  if (node.data.level > 1 && node.parent.data.nodeId === expandedNode.nodeId) {
    top -= expansionVerticalOffset / 2
  }

  let finalTop = top
  finalTop += shiftChild ? expansionVerticalOffset : 0
  finalTop -= shiftParent ? expansionVerticalOffset : 0

  const animatedProps = useSpring({
    from: {
      transform: `translate(${left}px, ${top}px)`,
      opacity: 0,
    },
    to: { transform: `translate(${left}px, ${finalTop}px)`, opacity: 1 },
  })

  const width = isExpandedNode ? nodeWidth + additionalExpandedWidth : nodeWidth
  const y = isRootNode ? -rootHeight / 2 : -nodeHeight / 2
  const x = -nodeWidth / 2
  const height = isRootNode
    ? rootHeight
    : isExpandedNode
      ? expandedNodeHeight
      : nodeHeight

  const onSelectNode = (e: MouseEvent<SVGForeignObjectElement>) => {
    if (node.data.position === 'root') return
    const nodeInfo = e.currentTarget.getAttribute('data-node-info')
    const [position, indexStr, requirementId, levelStr, nodeId] = nodeInfo
      ? nodeInfo.split('_')
      : []
    const index = Number(indexStr)
    const level = Number(levelStr)

    setExpandedNode({ position, index, requirementId, level, nodeId })
  }

  return (
    <animated.g style={animatedProps}>
      <rect
        className={`${styles.dropShadow} ${styles.node}`}
        height={height}
        width={width}
        y={y}
        x={x}
        fill={getCssVar('--color-white2')}
        stroke={isRootNode ? `${getCssVar('--color-green10')}` : '#F1EFEF'}
        strokeWidth={1}
        rx={5}
      />
      <foreignObject
        className={`${styles.node} ${
          !isExpandedNode && !isRootNode ? styles.collapsed : ''
        }`}
        x={x}
        y={y}
        width={width}
        height={height}
        onClick={onSelectNode}
        data-node-info={`${position}_${index}_${node.data.requirementId}_${node.data.level}_${node.data.nodeId}`}
      >
        <Node
          nodeData={node.data}
          setRootHeight={setRootHeight}
          isExpanded={isExpandedNode}
          closeModal={closeModal}
        />
      </foreignObject>
      {/* Dummy node used to measure expanded node height */}
      {isExpandedNode && (
        <foreignObject
          width={width}
          height={height}
          style={{
            visibility: 'hidden',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '-1',
          }}
        >
          <Node
            nodeData={node.data}
            setExpandedNodeHeight={setExpandedNodeHeight}
            isExpanded={isExpandedNode}
            closeModal={closeModal}
          />
        </foreignObject>
      )}
    </animated.g>
  )
}

export default AnimatedNode
