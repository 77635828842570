import { CaretLeft, CaretRight } from '@carbon/icons-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import DisplayFilter, { FilterGroup } from './display-filter'
import SearchInput from './input/SearchInput'
import styles from './SearchPage.module.css'
import SearchTableColumns, { COLUMN_ID_TO_NAME } from './SearchTableColumns'
import * as api from '../../api/v2/search.ts'
import { SearchRequirementContent } from '../../api/v2/search.ts'
import Button from '../../components/button'
import FilterDropdown, {
  useFilterState,
  AppliedFilters,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types.ts'
import Table from '../../components/table/Table'
import { toastError } from '../../components/toast'
import Tooltip from '../../components/tooltip/Tooltip.tsx'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import { useReports } from '../../hooks/useReports.ts'
import { ReportColumnId } from '../../types/enums.ts'

const PAGE_SIZE = 100
const REPORT_SIZE_MAX = 1000

const FILTER_KEYS = [
  FilterKey.RequirementStatus,
  FilterKey.RequirementType,
  FilterKey.SpecificationProgram,
  FilterKey.Compliance,
  FilterKey.ValidationMethod,
  FilterKey.VerificationMethod,
  FilterKey.SpecificationName,
]

const FILTER_GROUP_0: FilterGroup = {
  filters: [
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementIdentifier],
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementName],
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementShallStatement],
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementCompliance],
      hidden: true,
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementRationale],
      hidden: true,
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementType],
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationName],
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.RequirementStatus],
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.SpecificationProgramName],
    },
  ],
}

const FILTER_GROUP_1: FilterGroup = {
  filters: [
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.EvidenceTypeValidation],
    },
    {
      label: COLUMN_ID_TO_NAME[ReportColumnId.EvidenceTypeVerification],
    },
  ],
}

const hiddenFilters = [FILTER_GROUP_0, FILTER_GROUP_1]
  .flatMap((group) => group.filters)
  .filter((filter) => filter.hidden)
  .map((filter) => filter.label)

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState<
    Array<SearchRequirementContent>
  >([])
  const { isSignedIn } = useAuth()

  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)

  const previousQuery = useRef({ query: '', filters })
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const [filteredColumns, setFilteredColumns] =
    useState<string[]>(hiddenFilters)
  const [totalHits, setTotalHits] = useState(0)
  const tokens = useRef<Array<string | null>>([])
  const { generateSearchReport } = useReports()
  const { complianceStates } = useAttributesContext()

  useEffect(() => {
    window.document.title = 'Search'
  }, [])

  useEffect(() => {
    if (complianceStates.length === 0 || !isSignedIn) {
      return
    }
    const loadData = async () => {
      if (
        previousQuery.current.query !== query ||
        previousQuery.current.filters !== filters
      ) {
        tokens.current = []
        previousQuery.current.query = query
        previousQuery.current.filters = filters
        if (page !== 0) {
          setPage(0)
          return
        }
      }

      const formattedFilters = {
        ...filters,
        compliance: filters.compliance.map(
          (compliance) =>
            complianceStates.find((state) => state.name === compliance)?.id,
        ),
      }
      const specificationIds = filters.specificationName.map(
        (specification) => specification.id,
      )

      const token = tokens.current[page] || null
      const {
        contents,
        requestToken,
        nextToken = null,
      } = await api.getSearchRequirements({
        query,
        limit: PAGE_SIZE,
        token,
        filters: formattedFilters,
        specificationIds,
      })
      tokens.current[page] = requestToken
      tokens.current[page + 1] = nextToken
      setSearchResults(contents)
    }

    loadData()
  }, [query, page, filters, complianceStates, isSignedIn])

  // Used only for hit count
  useEffect(() => {
    if (!isSignedIn) {
      return
    }

    const fetchHits = async () => {
      const formattedFilters = {
        ...filters,
        compliance: filters.compliance.map(
          (compliance) =>
            complianceStates.find((state) => state.name === compliance)?.id,
        ),
      }
      const specificationIds = filters.specificationName.map(
        (specification) => specification.id,
      )

      const { contents } = await api.getSearchRequirements({
        query,
        limit: Math.max(201, REPORT_SIZE_MAX),
        filters: formattedFilters,
        specificationIds,
      })
      setTotalHits(contents.length)
    }
    fetchHits()
  }, [query, filters, isSignedIn, complianceStates])

  const onGenerateSearchReport = useCallback(() => {
    if ((tokens || page) && tokens.current[page] === null) {
      console.error('Failed to create report, no token available.')
      toastError(
        'Failed to create report.',
        'Please try again or refresh the page.',
      )
      return
    }

    const types = filters[FilterKey.RequirementType]
    const specificationPrograms = filters[FilterKey.SpecificationProgram]
    const statuses = filters[FilterKey.RequirementStatus]
    const compliances = filters[FilterKey.Compliance]
    const evidenceValidationMethods = filters[FilterKey.ValidationMethod]
    const evidenceVerificationMethods = filters[FilterKey.VerificationMethod]
    const specificationIds = filters[FilterKey.SpecificationName].map(
      (specification) => specification.id,
    )
    const q = {
      query,
      ...(types && types.length > 0 ? { types } : {}),
      ...(specificationPrograms && specificationPrograms.length > 0
        ? { specificationPrograms }
        : {}),
      ...(statuses && statuses.length > 0 ? { statuses } : {}),
      ...(compliances && compliances.length > 0
        ? {
            compliances: compliances.map(
              (compliance) =>
                complianceStates.find((state) => state.name === compliance)?.id,
            ),
          }
        : {}),
      ...(evidenceValidationMethods && evidenceValidationMethods.length > 0
        ? { evidenceValidationMethods }
        : {}),
      ...(evidenceVerificationMethods && evidenceVerificationMethods.length > 0
        ? { evidenceVerificationMethods }
        : {}),
      ...(specificationIds && specificationIds.length > 0
        ? { specificationIds }
        : {}),
    }

    return generateSearchReport(q)
  }, [complianceStates, filters, generateSearchReport, page, query])

  const resultCount = `${totalHits}${totalHits > 200 ? '+' : ''}`

  const onPrevious = () => {
    if (page > 0) {
      setPage(page - 1)
    }
  }

  const onNext = () => {
    if (tokens.current[page + 1]) {
      setPage(page + 1)
    }
  }

  const onDisplayFilterClick = (filterName: string) => {
    if (filteredColumns.includes(filterName)) {
      const newFilteredCols = filteredColumns.filter((f) => f !== filterName)
      setFilteredColumns(newFilteredCols)
    } else {
      setFilteredColumns([...filteredColumns, filterName])
    }
  }

  const filteredSearchTableColumns = SearchTableColumns.filter(
    (col) => !filteredColumns.includes(col.label),
  )

  const filterGroups = [FILTER_GROUP_0, FILTER_GROUP_1].map((group) => {
    return {
      ...group,
      filters: group.filters.filter((filter) =>
        SearchTableColumns.some((col) => {
          return col.label === filter.label
        }),
      ),
    }
  }) as FilterGroup[]

  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.headerText}>
          <h1>Search</h1>
        </div>
        <div className={styles.searchBar}>
          <div className={styles.text}>
            Search for requirements or specifications
          </div>
          <SearchInput query={query} setQuery={setQuery} />
          <span className={styles.text}>
            {resultCount} result{resultCount === '1' ? '' : 's'}
          </span>
        </div>
        <div className={styles.searchActions}>
          <DisplayFilter
            filterGroups={filterGroups}
            selectedFilters={filteredColumns}
            onFilterClick={onDisplayFilterClick}
          />
          <FilterDropdown
            menus={FILTER_KEYS}
            activeFilters={filters}
            onSelectFilter={toggleFilter}
          />
          <AppliedFilters
            filterKeys={FILTER_KEYS}
            filters={filters}
            onRemove={toggleFilter}
          />
          <div style={{ flexShrink: '0' }}>
            <Tooltip
              content={
                totalHits >= REPORT_SIZE_MAX
                  ? `Use filters and query searches to create reports of up to ${REPORT_SIZE_MAX.toLocaleString()} requirements across specifications`
                  : undefined
              }
              placement="left"
            >
              <Button
                disabled={
                  searchResults.length === 0 || totalHits >= REPORT_SIZE_MAX
                }
                text="Generate report"
                onClick={onGenerateSearchReport}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={styles.results}>
        {searchResults.length > 0 ? (
          <Table
            rowItems={searchResults.map((content) => ({
              id: content.requirement.id,
              ...content,
            }))}
            columns={filteredSearchTableColumns}
          />
        ) : (
          <div className={styles.noResults}>
            No results matching current query
          </div>
        )}
      </div>

      {searchResults.length > 0 && (
        <div className={styles.pager}>
          <button
            onClick={onPrevious}
            className={page === 0 ? styles.hidden : ''}
          >
            <CaretLeft size={24} />
          </button>
          {page * PAGE_SIZE + 1} - {page * PAGE_SIZE + searchResults.length}
          <button
            onClick={onNext}
            className={tokens.current[page + 1] ? '' : styles.hidden}
          >
            <CaretRight size={24} />
          </button>
        </div>
      )}
    </>
  )
}

export default SearchPage
