import { Add } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import styles from './SharedProjectPage.module.css'
import SharedSpecificationsTableColumns from './SharedSpecificationsTableColumns'
import {
  getProjectUsers,
  getSpecificationSnapshots,
  Project,
  SpecificationSnapshotData,
} from '../../api/v2/projects.ts'
import { User } from '../../api/v2/users.ts'
import Button, { BUTTON_COLORS } from '../../components/button/index.tsx'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator'
import Table from '../../components/table/Table'
import Tag from '../../components/tag'
import { useAuth } from '../../context/AuthContext.tsx'
import { useModals } from '../../hooks/useModals.ts'
import { getFullName } from '../../lib/user.ts'
import { LoadingState } from '../../types/enums'
import {
  getOrgProfiles,
  OrgProfile,
} from '../shared-projects/SharedProjectsPage.tsx'

const SharedProjectPage = () => {
  const { isAdmin, userTenant, userTenantId } = useAuth()
  const navigate = useNavigate()
  const project = useLoaderData() as Project
  const { openShareProjectModal } = useModals()
  const [sharedSpecSnapshots, setSharedSpecSnapshots] =
    useState<SpecificationSnapshotData[]>()
  const [specsLoading, setSpecsLoading] = useState<LoadingState>()
  const { projectId } = useParams()
  const [projectUsers, setProjectUsers] = useState<User[]>([])
  const [profiles, setProfiles] = useState<OrgProfile>({
    createdBy: undefined,
    sharedWith: undefined,
  })

  useEffect(() => {
    setSpecsLoading(LoadingState.Loading)
    const getSharedSpecSnapshots = async () => {
      try {
        const snapshots = await getSpecificationSnapshots(projectId!)
        const sortedSnapshots = snapshots.sort(
          (a, b) => b.createdOn.getTime() - a.createdOn.getTime(),
        )
        setSharedSpecSnapshots(sortedSnapshots)
        setSpecsLoading(LoadingState.Loaded)
      } catch (error) {
        console.error('Unable to load shared specification snapshots', error)
        captureException(error)
        setSpecsLoading(LoadingState.Failed)
      }
    }

    const loadProfiles = async () => {
      const profiles = await getOrgProfiles(project)

      setProfiles(profiles)
    }

    getSharedSpecSnapshots()
    loadProfiles()
  }, [project, projectId])

  useEffect(() => {
    const fetchProjectUsers = async () => {
      try {
        const response = await getProjectUsers(projectId!)
        setProjectUsers(response.users)
      } catch (error) {
        console.error('Unable to load project users', error)
        captureException(error)
      }
    }
    fetchProjectUsers()
  }, [projectId])

  const handleAddProjectUser = (newUser) => {
    setProjectUsers((prevUsers) => [...prevUsers, newUser])
  }

  return (
    <div className={styles.root}>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <Tag
            text={project.name}
            color={
              project.metadata?.STYLES
                ? {
                    fontColor: project.metadata.STYLES.COLOR_FONT,
                    backgroundColor: project.metadata.STYLES.COLOR_BG,
                  }
                : undefined
            }
          />
          <h1>
            {userTenant?.name === project.createdByTenant?.name
              ? `${profiles?.createdBy?.displayName} · ${profiles?.sharedWith?.displayName}`
              : `${profiles?.sharedWith?.displayName} · ${profiles?.createdBy?.displayName}`}
          </h1>
        </div>
        <div className={styles.sharedSpecs}>
          <h2>Shared Specifications</h2>
          <div className={styles.table}>
            {specsLoading === LoadingState.Loading && <LoadingIndicator />}
            {specsLoading === LoadingState.Failed && (
              <div>Unable to load shared specifications</div>
            )}
            {specsLoading === LoadingState.Loaded &&
              sharedSpecSnapshots &&
              (sharedSpecSnapshots.length > 0 ? (
                <Table
                  rowItems={sharedSpecSnapshots}
                  columns={SharedSpecificationsTableColumns}
                  onRowClick={(rowData) => {
                    navigate(
                      `/projects/${projectId}/snapshots/${rowData.id}/matrix`,
                    )
                  }}
                />
              ) : (
                <div>No shared specifications</div>
              ))}
          </div>
        </div>
      </div>
      <div className={styles.sideContent}>
        <div className={styles.sharedWith}>
          <div className={styles.sharedWithHeader}>Project shared with</div>
          {project.sharedWithTenant ? (
            projectUsers && projectUsers.length > 0 ? (
              projectUsers.map((user) => (
                <div key={user.id}>
                  {getFullName(user.firstName, user.lastName)}
                </div>
              ))
            ) : (
              <div />
            )
          ) : (
            <div>Project has not been shared with other tenant.</div>
          )}
          {userTenantId === project.createdByTenant?.id && isAdmin && (
            <Button
              text="Invite user"
              onClick={() =>
                openShareProjectModal({
                  projectId: projectId!,
                  contactId: project.sharedWithTenant!.id,
                  onAddProjectUser: handleAddProjectUser,
                  projectUsers: projectUsers,
                })
              }
              endIcon={<Add size={16} />}
              color={BUTTON_COLORS.PRIMARY}
              disabled={!project.sharedWithTenant}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SharedProjectPage
