import { createContext, useCallback, useState } from 'react'

export const MODAL_ID_IMPORT_EXCEL = 'IMPORT EXCEL MODAL'
export const MODAL_ID_IMPORT_WORD = 'IMPORT WORD MODAL'
export const MODAL_ID_REQUIREMENT_DRAWER = 'REQUIREMENT DRAWER'
export const MODAL_ID_REMOVE_STELL_USER = 'REMOVE STELL USER MODAL'
export const MODAL_ID_SEARCH_REQUIREMENT = 'SEARCH REQUIREMENT DRAWER'
export const MODAL_ID_SPECIFICATION_REVIEWERS = 'SPECIFICATION REVIEWERS'
export const MODAL_ID_GENERATE_REPORT = 'GENERATE REPORT MODAL'
export const MODAL_ID_DUPLICATE_SPECIFICATION = 'DUPLICATE SPECIFICATION MODAL'
export const MODAL_ID_CONFIRMATION = 'CONFIRMATION MODAL'
export const MODAL_ID_SPECIFICATION_HISTORY = 'SPECIFICATION HISTORY MODAL'
export const MODAL_ID_LINK_EVIDENCE = 'LINK EVIDENCE MODAL'
export const MODAL_ID_CREATE_EVIDENCE = 'CREATE EVIDENCE MODAL'
export const MODAL_ID_ADD_LINKED_REQUIREMENT = 'ADD LINKED REQUIREMENT MODAL'
export const MODAL_ID_ADD_LINKED_REQUIREMENT_EXCEPTION =
  'ADD LINKED REQUIREMENT EXCEPTION MODAL'
export const MODAL_ID_LINKED_REQUIREMENT = 'LINKED REQUIREMENT VIEW MODAL'
export const MODAL_ID_LINKED_EVIDENCE = 'LINKED EVIDENCE VIEW MODAL'
export const MODAL_ID_CHANGE_REQUEST_DRAWER = 'CHANGE REQUEST DRAWER'
export const MODEL_ID_EVIDENCE_RECORD = 'EVIDENCE DRAWER'
export const MODEL_ID_SNAPSHOT_REVIEW = 'SNAPSHOT REVIEW DRAWER'
export const MODEL_ID_EVIDENCE_ACTIVITY_DRAWER = 'EVIDENCE ACTIVITY DRAWER'
export const MODAL_ID_SHARE_SPECIFICATION = 'SHARE SPECIFICATION MODAL'
export const MODAL_ID_SPECIFICATION_PDF_VIEWER = 'SPECIFICATION PDF VIEWER'
export const MODAL_ID_IMPORT_PDF = 'IMPORT PDF MODAL'
export const MODAL_ID_SHARE_REVIEW = 'SHARE REVIEW MODAL'
export const MODAL_ID_NEW_SPECIFICATION = 'NEW SPECIFICATION'
export const MODAL_ID_SPECIFICATION_TREE_BUILDER =
  'SPECIFICATION TREE BUILDER MODAL'
export const MODAL_ID_KEYBOARD_SHORTCUTS = 'KEYBOARD SHORTCUTS'
export const MODAL_ID_CREATE_PROJECT = 'CREATE PROJECT MODAL'
export const MODAL_ID_SHARE_PROJECT = 'SHARE PROJECT MODAL'
export const MODAL_ID_PROJECT_SPECIFICATION_REVIEWERS =
  'MANAGE SPECIFICATION REVIEWERS MODAL'

interface ModalContextType {
  isOpen: boolean
  currentModalId: string | undefined
  currentModalProps: object | undefined
  openModal: (modalId: string, modalProps?: object) => void
  closeModal: () => void
}

const INITIAL_CONTEXT = {
  currentModalId: undefined,
  currentModalProps: undefined,
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
}

export const GlobalModalContext =
  createContext<ModalContextType>(INITIAL_CONTEXT)

export const ModalContext = (props) => {
  const { children } = props
  const [isOpen, setIsOpen] = useState(false)
  const [currentModalId, setCurrentModalId] = useState()
  const [currentModalProps, setCurrentModalProps] = useState()

  const openModal = useCallback((modalId, modalProps) => {
    setCurrentModalId(modalId)
    setCurrentModalProps(modalProps)
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setCurrentModalId(undefined)
    setCurrentModalProps(undefined)
  }, [])

  return (
    <GlobalModalContext.Provider
      value={{
        isOpen,
        currentModalId,
        currentModalProps,
        openModal,
        closeModal,
      }}
    >
      {children}
    </GlobalModalContext.Provider>
  )
}
