import { ReactElement } from 'react'
import styles from './ReviewStatusItem.module.css'

interface ReviewStatusItemProps {
  icon: ReactElement
  text: string
  percentage?: number
  subText: string
}

const ReviewStatusItem = (props: ReviewStatusItemProps) => {
  const { icon, text, percentage, subText } = props
  return (
    <div className={styles.reviewStatusItem}>
      <div className={styles.iconWrapper}>{icon}</div>
      <div className={styles.textWrapper}>
        <span className={styles.text}>
          {text}
          {percentage !== undefined && (
            <>
              {' · '}
              <span className={styles.percentageText}>
                {percentage.toFixed(0)}%
              </span>
            </>
          )}
        </span>
        <p className={styles.subText}>{subText}</p>
      </div>
    </div>
  )
}

export default ReviewStatusItem
