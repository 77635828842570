import api from './api.ts'
import {
  EntityReviewReviewerStatus,
  EntityType,
} from '../../types/api/v2/entity.ts'

const createUrl = (userId?: string) =>
  `/api/v2/users${userId ? `/${userId}` : ''}`

/**
 * Users
 */

export interface User {
  id: string
  userName: string
  firstName: string
  lastName: string
  email: string
  jobTitle: string
  dateJoined: Date
  teamIds: string[]
}

export const getUser = async (userId: string): Promise<User> => {
  const response = await api.get(createUrl(userId))
  return {
    ...response,
    dateJoined: new Date(response.dateJoined),
  }
}

export const getPendingReviewSpecIds = async (
  userId: string,
): Promise<string[]> => {
  const response: {
    reviews: Record<
      string,
      {
        entityType: EntityType
        data: {
          specificationId: string
        }
        reviewer: {
          status: EntityReviewReviewerStatus
        }
      }
    >
  } = await api.get(`${createUrl(userId)}/reviews`)

  return Object.values(response.reviews)
    .filter((val) => val.reviewer.status === EntityReviewReviewerStatus.PENDING)
    .map((val) => val.data.specificationId)
}

export interface UsersResponse {
  users: User[]
}

export const getAllUsers: () => Promise<UsersResponse> = () =>
  api.get(`${createUrl()}`)

/**
 * USER PERMISSIONS
 */
export enum SpecificationPermission {
  Owner = 'OWNER',
  Editor = 'EDITOR',
  Commenter = 'COMMENTER',
}

export enum SpecificationSnapshotPermission {
  Owner = 'OWNER',
  Reviewer = 'REVIEWER',
}

export enum ProjectPermission {
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type UserPermissions = {
  specifications: {
    [specificationId: string]: {
      role: SpecificationPermission
    }
  }
  specificationSnapshots: {
    [snapshotId: string]: {
      role: SpecificationSnapshotPermission
    }
  }
  projects: {
    [projectId: string]: {
      role: ProjectPermission
    }
  }
}

export const getUserPermissions: () => Promise<UserPermissions> = () =>
  api.get(`${createUrl()}/permissions`)

export interface UpdateUserRequest {
  firstName?: string
  lastName?: string
  email?: string
  jobTitle?: string
}

export const updateUser: (request: UpdateUserRequest) => Promise<User> = (
  request,
) => api.patch(`${createUrl()}`, { body: request })

interface DeleteUserResponse {
  id: string
}

export const deleteUser: (userId: string) => Promise<DeleteUserResponse> = (
  userId,
) => {
  return api.delete(createUrl(userId))
}

interface CreateUserRequest {
  tenantId: string
  firstName: string
  lastName: string
  email: string
  password: string
  jobTitle: string
}

export const createUser: (req: CreateUserRequest) => Promise<User> = (req) => {
  return api.post(createUrl(), {
    body: req,
  })
}

export const updatePassword: (newPassword: string) => Promise<void> = (
  newPassword,
) =>
  api.put(`${createUrl()}/update_password`, {
    body: {
      password: newPassword,
    },
  })

/**
 * PASSWORD RESET
 */

export const requestPasswordReset: (email: string) => Promise<void> = (email) =>
  api.postUnauthenticated(`${createUrl()}/reset_password`, {
    body: { email },
  })

export const isValidResetPasswordToken: (token: string) => Promise<any> = (
  token,
) =>
  api.getUnauthenticated(
    `${createUrl()}/reset_password?token=${encodeURIComponent(token)}`,
  )

export const resetPasswordWithToken: (
  token: string,
  password: string,
) => Promise<any> = (token, password) =>
  api.postUnauthenticated(`${createUrl()}/reset_password/token`, {
    body: {
      token,
      password,
    },
  })
