import { TextAlignJustify } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'

const AddTextBlockAction = (props: BlockActionItemProps) => {
  const { block, closeMenu } = props
  const { contentIsEditable } = useSpecificationContext()
  const { createTextBlock } = useSectionContext()

  return (
    contentIsEditable && (
      <button
        className={styles.actionItem}
        onClick={async () => {
          await createTextBlock(block?.id)
          closeMenu()
        }}
      >
        <TextAlignJustify />
        Text
      </button>
    )
  )
}

export default AddTextBlockAction
