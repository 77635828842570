import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import BlockActions from './block-actions/BlockActions.tsx'
import styles from './BlockEditor.module.css'
import HeadingBlock from './blocks/HeadingBlock.tsx'
import ImageBlock from './blocks/ImageBlock.tsx'
import RequirementBlock from './blocks/RequirementBlock.tsx'
import TableBlock from './blocks/table/TableBlock.tsx'
import TextBlock from './blocks/TextBlock.tsx'
import type { VisibleBlock } from './hooks/useVisibleBlocks.tsx'
import {
  BLOCK_IS_SELECTED_CLASSNAME,
  DATA_VALUE_BLOCK_ID,
  DATA_VALUE_REQUIREMENT_VERSION,
  IGNORE_FIELD_CLASSNAME,
} from './SelectableBlock.tsx'
import { Block, BlockData, BlockType } from '../../api/v2/blocks.ts'
import { Requirement } from '../../api/v2/requirements.ts'
import { Revision, RevisionStatus } from '../../api/v2/revisions.ts'
import { useDocumentContext } from '../../context/DocumentContext.tsx'
import { useSectionContext } from '../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext.tsx'
import globalStyles from '../../global.module.css'
import { RequirementStatus } from '../../types/enums.ts'

export const ALL_BLOCKS = {
  [BlockType.Text]: TextBlock,
  [BlockType.Image]: ImageBlock,
  [BlockType.Requirement]: RequirementBlock,
  [BlockType.Table]: TableBlock,
  [BlockType.Heading]: HeadingBlock,
}

interface BlockProps {
  // RequirementBlock props
  isCommentsHidden?: boolean
  requirement?: Requirement
  updateRequirement?: (update: Partial<Requirement>) => void

  // ImageBlock props
  imgSrc?: string
}

export const BlockToElement = (props: {
  block: Block<BlockData>
  blockProps: BlockProps
}) => {
  const { block, blockProps } = props

  if (!block) {
    return null
  }

  const BlockComponent = ALL_BLOCKS[block.type]
  // @ts-expect-error - Typescript is being smart and is correctly unhappy that we can't formally define the props as one of the block props. Ignoring, but can also be resolved by making BlockProps more declarative.
  return <BlockComponent block={block} {...blockProps} />
}

const BlockWithActions = (props: {
  block: Block<BlockData>
  blockProps: BlockProps
  autoScroll: boolean
}) => {
  const { block, blockProps, autoScroll } = props
  const { publicTenant } = useSpecificationContext()
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const isActionsVisible = !publicTenant && (isFocused || isHovered)

  const scrollRef = useRef<HTMLDivElement>(null)

  const expandedBlockProps = useMemo(() => {
    return {
      ...blockProps,
      isActionsVisible,
      isHovered,
    }
  }, [blockProps, isActionsVisible, isHovered])

  return (
    <div
      ref={scrollRef}
      className={`${styles.blockWrapper} ${
        autoScroll ? globalStyles.scrollHighlight : ''
      }`}
      onFocus={() =>
        setIsFocused(!!scrollRef.current?.contains(document.activeElement))
      }
      onBlur={() => {
        setIsFocused(!!scrollRef.current?.contains(document.activeElement))
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {block && (
        <>
          <BlockActions
            block={block}
            isHidden={!isActionsVisible}
            {...expandedBlockProps}
          >
            <BlockToElement block={block} blockProps={expandedBlockProps} />
          </BlockActions>
        </>
      )}
    </div>
  )
}

interface VirtualBlockProps {
  // Required for virtualization
  index: number
  style: React.CSSProperties

  onRefresh: (index: number, height?: number) => void
  blockIds: string[]
  visibleBlocks: Record<string, VisibleBlock>
  revision: Revision | null
  scrollToBlockId: string | null
  addBottomPadding?: boolean
}

const VirtualBlock = (props: VirtualBlockProps) => {
  const {
    index,
    style,
    onRefresh,
    blockIds,
    visibleBlocks,
    revision,
    scrollToBlockId,
    addBottomPadding,
  } = props

  const blockRef = useRef<HTMLDivElement>(null)
  const blockId = blockIds?.[index]
  const visibleBlock = visibleBlocks?.[blockId]
  const { requirement, imgSrc, block } = visibleBlock ?? {}

  const hidden =
    revision?.status === RevisionStatus.ACTIVE &&
    requirement &&
    requirement.status === RequirementStatus.Archived

  useEffect(() => {
    // Ideally we would only create one resize observer at the editor level and listen to all block resizes, this will work for the time being.

    const observer = new ResizeObserver(() => {
      if (onRefresh) {
        onRefresh(index, blockRef.current?.clientHeight)
      }
    })

    if (blockRef.current) {
      observer.observe(blockRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [index, onRefresh])

  return (
    <div style={style}>
      <div ref={blockRef}>
        {!hidden && !block && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '40px',
              marginLeft: '300px',
            }}
          />
        )}
        {!hidden && block && (
          <BlockWithActions
            key={blockId}
            block={block}
            blockProps={{ requirement, imgSrc }}
            autoScroll={scrollToBlockId === blockId}
          />
        )}
        {/*Add padding on last block so on hover block actions have space to expand in scroll container*/}
        {addBottomPadding &&
          blockIds.length > 0 &&
          blockIds.length - 1 === index && (
            <div style={{ paddingBottom: '360px' }} />
          )}
      </div>
    </div>
  )
}

const MARGIN = 15

const VirtualBlockRenderer = ({ index, style, data }) => {
  return <VirtualBlock index={index} style={style} {...data} addBottomPadding />
}

const InfiniteBlockLoader = (props) => {
  const { containerHeight } = props
  const { blockIds, visibleBlocks, fetchVisibleBlocks } = useSectionContext()
  const { scrollToBlockId } = useDocumentContext()
  const { revision } = useSpecificationContext()

  const rowHeights = useRef({})
  const infiniteLoaderRef = useRef<any>(null)
  const listRef = useRef<any>(null)
  const hasAutoScrolled = useRef<boolean>(false)

  useEffect(() => {
    const autoScroll = async () => {
      if (!scrollToBlockId || hasAutoScrolled.current || !listRef.current) {
        return
      }

      const blockIdx = blockIds.indexOf(scrollToBlockId)

      await fetchVisibleBlocks(blockIdx)

      const scrollToBlock = () => {
        listRef.current.scrollToItem(blockIdx, 'start')
      }

      // Trigger initial blocks to fetch
      scrollToBlock()

      // Recenter matching block once the data and height have been determined
      setTimeout(() => {
        scrollToBlock()
      }, 1500)

      hasAutoScrolled.current = true
    }

    autoScroll()
  }, [blockIds, fetchVisibleBlocks, scrollToBlockId])

  const onRefresh = useCallback((index: number, height: number) => {
    if (!listRef.current || !infiniteLoaderRef.current) {
      return
    }

    const newHeight = height + MARGIN
    const existingHeight = rowHeights.current[index]

    if (newHeight === existingHeight) {
      return
    }

    // console.log(
    //   `index: ${index} newHeight: ${newHeight} existingHeight: ${existingHeight}`,
    // )

    rowHeights.current = {
      ...rowHeights.current,
      [index]: newHeight,
    }

    listRef.current.resetAfterIndex(index)
    infiniteLoaderRef.current.resetloadMoreItemsCache()
  }, [])

  return (
    <div
      style={{
        height: containerHeight - 60,
        width: '100%',
      }}
    >
      <AutoSizer
        style={{
          height: '100%',
          width: '100%',
        }}
        disableWidth
      >
        {({ height }) => (
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isItemLoaded={(index) => {
              const blockId = blockIds[index]
              return visibleBlocks[blockId] !== undefined
            }}
            itemCount={blockIds.length}
            loadMoreItems={(index) => {
              fetchVisibleBlocks(index)
            }}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                className={`${styles.virtualList} ${IGNORE_FIELD_CLASSNAME}`}
                ref={(el) => {
                  ref(el)
                  listRef.current = el
                }}
                height={height}
                width="100%"
                itemCount={blockIds.length}
                itemSize={(index) => rowHeights.current[index] || 40}
                onItemsRendered={onItemsRendered}
                itemData={{
                  onRefresh,
                  blockIds,
                  visibleBlocks,
                  revision,
                  scrollToBlockId,
                }}
                itemKey={(index) => blockIds[index]}
              >
                {/*It's crucial only one instance of the renderer exists, or else it*/}
                {/*is rerendered on every change and causes focus loss*/}
                {VirtualBlockRenderer}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  )
}

const BlockEditor = (props: {
  className?: string
  containerHeight: number
}) => {
  const { className, containerHeight } = props
  const { blockIds, deleteBlocks } = useSectionContext()

  const onDelete: (event: KeyboardEvent) => Promise<void> = useCallback(
    async (event) => {
      if (event.key === 'Backspace') {
        const selectedBlocks = Array.from(
          document.getElementsByClassName(BLOCK_IS_SELECTED_CLASSNAME),
        ).map((blockEle) => ({
          id: blockEle.getAttribute(DATA_VALUE_BLOCK_ID),
          requirementVersion: blockEle.getAttribute(
            DATA_VALUE_REQUIREMENT_VERSION,
          ),
        }))

        if (selectedBlocks.every(({ id }) => blockIds.includes(id!))) {
          await deleteBlocks(selectedBlocks)
        }
      }
    },
    [blockIds, deleteBlocks],
  )

  useEffect(() => {
    document.addEventListener('keydown', onDelete, false)

    return () => {
      document.removeEventListener('keydown', onDelete, false)
    }
  }, [onDelete])

  return (
    <div className={`quillBounds ${styles.blockEditor} ${className ?? ''}`}>
      <div className={styles.section}>
        <InfiniteBlockLoader containerHeight={containerHeight} />
      </div>
    </div>
  )
}

const FetchAllBlockEditor = (props: { className?: string }) => {
  const { className } = props
  const { revision } = useSpecificationContext()
  const { blockIds, visibleBlocks, fetchVisibleBlocks } = useSectionContext()

  useEffect(() => {
    fetchVisibleBlocks(0, 10_000)
  }, [blockIds.length, fetchVisibleBlocks])

  return (
    <div className={`${styles.blockEditor} ${className ?? ''}`}>
      <div className={styles.section}>
        {blockIds.map((id, index) => (
          <VirtualBlock
            index={index}
            style={{}}
            key={id}
            blockIds={blockIds}
            visibleBlocks={visibleBlocks}
            revision={revision}
            onRefresh={() => {}}
            scrollToBlockId={null}
          />
        ))}
      </div>
    </div>
  )
}

export { BlockEditor, FetchAllBlockEditor }
