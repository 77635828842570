export const EMPTY_DELTA = JSON.stringify({ ops: [] })

export const truncateString = (str: string, maxLength: number): string => {
  if (str === null || str === undefined) return ''
  return str.length <= maxLength
    ? str
    : `${str.substring(0, maxLength).trim()}...`
}

export const deltaObjectToPlainText = (delta) => {
  return delta.ops
    .reduce(function (text, op) {
      if (!op.insert) return text
      if (op.insert?.reference) return text + (op.insert.reference?.name || ' ')
      if (typeof op.insert !== 'string') return text + ' '
      return text + op.insert
    }, '')
    .trim()
}
export const deltaToPlaintext = (delta: string) =>
  deltaObjectToPlainText(JSON.parse(delta))

export const plaintextToDelta = (text: string) =>
  JSON.stringify({ ops: [{ insert: text }] })

export const isEmptyDelta = (delta: string | undefined | null) => {
  return (
    delta === EMPTY_DELTA ||
    delta === JSON.stringify({ ops: [{ insert: '\n' }] }) ||
    !delta
  )
}

export const getCssVar = (varName) =>
  window.getComputedStyle(document.body).getPropertyValue(varName)
