import { UUID } from '../../../api/utilityTypes.ts'
import { ChangeRequest } from '../../../api/v2/changeRequests'
import { User } from '../../../api/v2/users.ts'

export type RequirementGraphViewResponse = {
  focusRequirementId: UUID
  requirements: {
    [key: string]: {
      id: UUID
      displayId: string
      title?: string
      shallStatement?: string
      specificationId: UUID
      relations: Array<{
        type: 'CHILD' | 'PARENT'
        entityId: UUID
      }>
    }
  }
  specifications: {
    [key: string]: {
      id: UUID
      title: string
    }
  }
}

export interface RequirementChangeRequest
  extends Omit<ChangeRequest, 'createdBy'> {
  createdBy: User
}

export enum RequirementCommentType {
  Comment,
  ChangeRequest,
}
