import { ReactNode, useEffect, useState } from 'react'
import styles from './Popover.module.css'
import useClickOutside from '../../hooks/useClickOutside.ts'

interface PopoverProps {
  className?: string
  classNamePopoverContainer?: string
  children: ReactNode
  content?: ReactNode
  placement?: 'left' | 'right' | 'top' | 'bottom' | 'offsetBottomRight'
  onVisibilityChange?: (isVisible: boolean) => void
}

const placementToStyle = {
  top: styles.placeTop,
  bottom: styles.placeBottom,
  left: styles.placeLeft,
  right: styles.placeRight,
  offsetBottomRight: styles.placeOffsetBottomRight,
}

const Popover = (props: PopoverProps) => {
  const {
    className,
    classNamePopoverContainer,
    children,
    content,
    placement = 'bottom',
    onVisibilityChange,
  } = props
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
  const placementClass = placementToStyle[placement] || styles.placeBottom
  const ref = useClickOutside(
    () => setIsPopoverVisible(false),
    isPopoverVisible,
  )

  const togglePopover = () => {
    if (!content) {
      return
    }

    setIsPopoverVisible(!isPopoverVisible)
  }

  useEffect(() => {
    if (onVisibilityChange) {
      onVisibilityChange(isPopoverVisible)
    }
  }, [isPopoverVisible, onVisibilityChange])

  return (
    <div
      ref={ref}
      className={`${styles.popoverContainer} ${
        classNamePopoverContainer || ''
      }`}
      onClick={togglePopover}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          togglePopover()
        }
      }}
    >
      <div className={styles.clickable}>{children}</div>
      {content && isPopoverVisible && (
        <div
          className={`${styles.popover} ${placementClass} ${className || ''}`}
        >
          {typeof content === 'string' ? (
            <div className={styles.textContent}>{content}</div>
          ) : (
            content
          )}
        </div>
      )}
    </div>
  )
}

export default Popover
