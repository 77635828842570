import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './index.module.css'
import Button from '../../../components/button'
import Dropdown from '../../../components/dropdown'
import Tag, { TAG_COLORS } from '../../../components/tag'
import useClickOutside from '../../../hooks/useClickOutside.ts'

export interface Filter {
  label: string
  prettyLabel?: string
  hidden?: boolean
}
export interface FilterGroup {
  spaceBetween?: boolean
  filters: Filter[]
}

interface DisplayFilterType {
  filterGroups: FilterGroup[]
  selectedFilters?: string[]
  onFilterClick: (filterLabel: string) => void
}

const DisplayFilter = (props: DisplayFilterType) => {
  const { filterGroups, selectedFilters = [], onFilterClick } = props
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useClickOutside(() => {
    if (isOpen) {
      setIsOpen(!isOpen)
    }
  }, isOpen)

  return (
    <div ref={containerRef} className={styles.displayFilter}>
      <Button
        text="Display"
        endIcon={<CaretDown />}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        {filterGroups
          .filter((group) => group?.filters?.length > 0)
          .map((group, groupIdx) => (
            <div key={group.filters.map((f) => f.label).join('-')}>
              <div
                className={`${styles.filterGroup} ${
                  group.spaceBetween ? styles.spaceBetween : ''
                }`}
              >
                {group.filters.map(({ label, prettyLabel }) => (
                  <button key={label} onClick={() => onFilterClick(label)}>
                    <Tag
                      className={styles.displayTag}
                      text={prettyLabel || label}
                      color={
                        selectedFilters.includes(label)
                          ? TAG_COLORS.secondary
                          : TAG_COLORS.primary
                      }
                      uppercase={false}
                    />
                  </button>
                ))}
              </div>
              {groupIdx !== filterGroups.length - 1 && (
                <hr className={styles.divide} />
              )}
            </div>
          ))}
      </Dropdown>
    </div>
  )
}

export default DisplayFilter
