import { Link } from 'react-router-dom'
import styles from './NotificationRow.module.css'
import {
  ChangeRequestAddedData,
  CommentAddedData,
  NotificationType,
  ReviewRequestedData,
  SpecificationImportedData,
} from '../../api/v2/notifications.ts'
import { formatTime, monthLongDayYear } from '../../lib/date.ts'

export const NOTIFICATIONS = {
  [NotificationType.CHANGE_REQUEST_ADDED]: ({ notification, setIsOpen }) => {
    const changeRequestAddedData = notification.data as ChangeRequestAddedData
    return (
      <NotificationRow notification={notification}>
        {changeRequestAddedData.userFullName} added a change request to{' '}
        <Link
          to={`/specifications/${changeRequestAddedData.specificationId}/document/${changeRequestAddedData.requirementId}`}
          className={styles.linkText}
          onClick={() => setIsOpen(false)}
        >
          {changeRequestAddedData.requirementTitle}
        </Link>
      </NotificationRow>
    )
  },
  [NotificationType.COMMENT_ADDED]: ({ notification, setIsOpen }) => {
    const commentAddedData = notification.data as CommentAddedData
    return (
      <NotificationRow notification={notification}>
        {commentAddedData.userFullName} commented on{' '}
        <Link
          to={`/specifications/${commentAddedData.specificationId}/document/${commentAddedData.requirementId}`}
          className={styles.linkText}
          onClick={() => setIsOpen(false)}
        >
          {commentAddedData.requirementTitle || 'Untitled'}
        </Link>
      </NotificationRow>
    )
  },
  [NotificationType.REVIEW_REQUESTED]: ({ notification, setIsOpen }) => {
    const reviewRequestedData = notification.data as ReviewRequestedData
    return (
      <NotificationRow notification={notification}>
        {reviewRequestedData.userFullName} requested your review on{' '}
        <Link
          to={`/specifications/${reviewRequestedData.specificationId}`}
          className={styles.linkText}
          onClick={() => setIsOpen(false)}
        >
          {reviewRequestedData.specificationName}
        </Link>
      </NotificationRow>
    )
  },
  [NotificationType.SPECIFICATION_IMPORTED]: ({ notification, setIsOpen }) => {
    const specificationImportedData =
      notification.data as SpecificationImportedData

    return (
      <NotificationRow notification={notification}>
        <span>Your PDF has imported successfully, </span>
        <Link
          to={`/specifications/${specificationImportedData.specificationId}`}
          className={styles.linkText}
          onClick={() => setIsOpen(false)}
        >
          view specification
        </Link>
      </NotificationRow>
    )
  },
}

const NotificationRow = ({ notification, children }) => (
  <div className={styles.notificationRow}>
    <div className={styles.content}>
      <div className={notification.read ? styles.read : styles.unread} />
      <div className={styles.text}>{children}</div>
    </div>
    <div className={styles.metadata}>
      <span>{monthLongDayYear(notification.createdOn)}</span>
      {' · '}
      <span>{formatTime(notification.createdOn)}</span>
    </div>
  </div>
)

export default NotificationRow
