import {
  Add,
  ChevronDown,
  Document,
  DocumentWordProcessor,
  TableSplit,
} from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SpecificationsPage.module.css'
import SpecificationsTableColumns, {
  PublicSpecificationsTableColumns,
} from './SpecificationsTableColumns.tsx'
import Button, { BUTTON_COLORS } from '../../components/button'
import Dropdown from '../../components/dropdown/index.tsx'
import { AppliedFilters, useFilterState } from '../../components/filter-menu'
import FilterDropdown from '../../components/filter-menu/FilterDropdown'
import { FilterKey } from '../../components/filter-menu/types'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator'
import Pager from '../../components/pager/Pager'
import Table from '../../components/table/Table'
import useResizableColumns from '../../components/table/useResizableColumns.ts'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useSpecificationListContext } from '../../context/SpecificationListContext'
import useLoadSearchParams, {
  hasLoadedSpecProgramFilter,
  LoadSearchParamConfig,
} from '../../hooks/useLoadSearchParams.ts'
import { useModals } from '../../hooks/useModals'
import * as ls from '../../lib/localstorage.ts'

const ROWS_PER_PAGE = 15
const FILTER_KEYS = [
  FilterKey.SpecificationCategory,
  FilterKey.SpecificationProgram,
  FilterKey.SpecificationPhase,
]

const SpecificationsPage = () => {
  const { publicTenant } = useSpecificationListContext()
  const basePath = publicTenant ? '/public-specifications' : '/specifications'

  const { createSpecification, specifications, loading } =
    useSpecificationListContext()

  const navigate = useNavigate()
  const { openImportExcelModal, openImportPDFModal, openImportWordModal } =
    useModals()
  const { activePrograms } = useAttributesContext()

  const [isImportDropdownOpen, setIsImportDropdownOpen] = useState(false)
  const toggleImportDropdown = () =>
    setIsImportDropdownOpen(!isImportDropdownOpen)

  const [page, setPage] = useState(0)

  const [filters, toggleFilter] = useFilterState(
    FILTER_KEYS,
    ls.getRecentlyUsedSpecificationsFilters(),
  )

  const loadSearchParamsConfig = useCallback(
    () =>
      [
        {
          key: 'programId',
          hasLoaded: (val) =>
            hasLoadedSpecProgramFilter(
              val,
              activePrograms,
              filters,
              toggleFilter,
            ),
        },
      ] as LoadSearchParamConfig[],
    [activePrograms, filters, toggleFilter],
  )
  useLoadSearchParams(loadSearchParamsConfig())

  useEffect(() => {
    setPage(0)
    window.document.title = 'Specifications'
  }, [])

  useEffect(() => {
    ls.setRecentlyUsedSpecificationsFilter(filters)
  }, [filters])

  const columnResizer = useResizableColumns(
    publicTenant
      ? PublicSpecificationsTableColumns
      : SpecificationsTableColumns,
    publicTenant ? 'publicSpecificationsColumns' : 'specificationsColumns',
  )

  const filteredSpecs = useMemo(
    () =>
      specifications
        .filter(
          (s) =>
            filters[FilterKey.SpecificationProgram].length === 0 ||
            filters[FilterKey.SpecificationProgram].includes(s.program),
        )
        .filter(
          (s) =>
            filters[FilterKey.SpecificationCategory].length === 0 ||
            filters[FilterKey.SpecificationCategory].includes(s.category),
        )
        .filter(
          (s) =>
            filters[FilterKey.SpecificationPhase].length === 0 ||
            filters[FilterKey.SpecificationPhase].includes(s.phase),
        ),
    [filters, specifications],
  )

  const currentSpecs = filteredSpecs
    .slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE)
    .map((spec) => ({
      ...spec,
      publicTenant,
    }))

  // Error standards page if feature flag is disabled
  const enableStandardsPortal = useFeatureFlagEnabled('standards-portal')
  if (publicTenant && enableStandardsPortal === false) {
    throw new Error()
  }

  return (
    <>
      <div className={styles.header}>
        <h1>{publicTenant ? 'Stell Standards Library' : 'Specifications'}</h1>
        {!publicTenant && (
          <div className={styles.actions}>
            <Button
              color={BUTTON_COLORS.PRIMARY}
              onClick={async () => {
                const { id } = (await createSpecification?.()) || { id: '' }
                navigate(`${basePath}/${id}`)
              }}
              text="Create"
              endIcon={<Add size={20} />}
            />
            <div className={styles.dropdownContainer}>
              <Button
                onClick={toggleImportDropdown}
                text="Import"
                endIcon={<ChevronDown />}
              />
              <Dropdown
                isOpen={isImportDropdownOpen}
                className={styles.importDropdown}
              >
                <button
                  className={styles.importButton}
                  onClick={() => {
                    openImportPDFModal()
                    toggleImportDropdown()
                  }}
                >
                  <Document /> Import PDF
                </button>
                <button
                  className={styles.importButton}
                  onClick={() => {
                    openImportExcelModal()
                    toggleImportDropdown()
                  }}
                >
                  <TableSplit /> Import Excel
                </button>
                <button
                  className={styles.importButton}
                  onClick={() => {
                    openImportWordModal()
                    toggleImportDropdown()
                  }}
                >
                  <DocumentWordProcessor /> Import Word
                </button>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <FilterDropdown
          menus={FILTER_KEYS}
          activeFilters={filters}
          onSelectFilter={(key, val) => toggleFilter(key, val)}
        />
        <AppliedFilters
          filters={filters}
          onRemove={(key, val) => toggleFilter(key, val)}
          filterKeys={FILTER_KEYS}
        />
      </div>
      <div className={styles.specifications}>
        {loading && (
          <div className={styles.center}>
            <LoadingIndicator />
          </div>
        )}
        {!loading && (
          <>
            <div className={styles.table}>
              <Table rowItems={currentSpecs} columnResizer={columnResizer} />
            </div>
            {filteredSpecs.length !== 0 &&
              filteredSpecs.length > ROWS_PER_PAGE && (
                <div>
                  <Pager
                    currentPage={page}
                    onPageSelect={setPage}
                    pageCount={Math.ceil(filteredSpecs.length / ROWS_PER_PAGE)}
                  />
                </div>
              )}
          </>
        )}
      </div>
    </>
  )
}

export default SpecificationsPage
