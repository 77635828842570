import { CaretLeft, CaretRight } from '@carbon/icons-react'
import styles from './Pager.module.css'

type PagerProps = {
  currentPage: number
  onPageSelect: (page: number) => void
  pageCount: number
}

const Pager = (props: PagerProps) => {
  const { currentPage, onPageSelect, pageCount } = props

  const onNext = () => {
    if (currentPage + 1 < pageCount) {
      onPageSelect(currentPage + 1)
    }
  }

  const onPrevious = () => {
    if (currentPage - 1 >= 0) {
      onPageSelect(currentPage - 1)
    }
  }

  return (
    <div className={styles.root}>
      <button
        onClick={onPrevious}
        className={`${styles.pageButton} ${
          currentPage === 0 ? styles.hidden : ''
        }`}
      >
        <CaretLeft size={24} />
      </button>
      <div className={styles.pages}>
        {[...Array(pageCount).keys()].map((pageNumber) => (
          <button
            key={pageNumber}
            className={`${styles.pageNumber} ${
              pageNumber === currentPage ? styles.selected : ''
            }`}
            onClick={() => onPageSelect(pageNumber)}
          >
            {pageNumber + 1}
          </button>
        ))}
      </div>
      <button
        onClick={onNext}
        className={`${styles.pageButton} ${
          currentPage === pageCount - 1 ? styles.hidden : ''
        }`}
      >
        <CaretRight size={24} />
      </button>
    </div>
  )
}

export default Pager
