import { AddComment, CaretLeft, CaretRight, Close } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useCallback, useState } from 'react'
import Drawer from '.'
import { DRAWER_TAB } from './enums.ts'
import styles from './SnapshotReviewDrawer.module.css'
import { UUID } from '../../api/utilityTypes'
import { BlockType } from '../../api/v2/blocks.ts'
import { ReviewStatus } from '../../api/v2/projects'
import * as projectApi from '../../api/v2/projects.ts'
import {
  SnapshotRequirementComment,
  SpecificationSnapshot,
} from '../../api/v2/projects.ts'
import { SnapshotReviewWithReviewer } from '../../context/SharedSpecificationContext.tsx'
import useClickOutside from '../../hooks/useClickOutside'
import { entries } from '../../lib/utils'
import { reviewStates } from '../../pages/shared-specification/SharedRequirementColumns'
import SnapshotCommentForm from '../../pages/shared-specification/snapshot-comments/SnapshotCommentForm.tsx'
import SnapshotComments from '../../pages/shared-specification/snapshot-comments/SnapshotComments.tsx'
import Button, { BUTTON_COLORS } from '../button'
import SnapshotRequirementHistoryEvents from '../requirement/SnapshotRequirementHistoryEvents.tsx'
import { toastError } from '../toast'

const HistoryTab = (props: {
  requirement: {
    id: UUID
    sectionNumber: string
    title: string
    shallStatement: string
  }
  specificationSnapshot: SpecificationSnapshot
}) => {
  const { requirement, specificationSnapshot } = props

  return (
    <SnapshotRequirementHistoryEvents
      projectId={specificationSnapshot.project.id}
      snapshotId={specificationSnapshot.id}
      requirementId={requirement.id}
    />
  )
}

const CommentsTab = (props: {
  reviews: SnapshotReviewWithReviewer[]
  updateRequirementReview: (
    reviewId: UUID,
    requirementId: UUID,
    status: ReviewStatus,
  ) => Promise<void>
  onUpdateReview: (state: ReviewStatus) => void
  requirement: {
    id: UUID
    sectionNumber: string
    title: string
    shallStatement: string
  }
  comments: SnapshotRequirementComment[]
  addRequirementComment: (
    requirementId: UUID,
    comment: string,
  ) => Promise<SnapshotRequirementComment | null>
  onAddRequirementComment: (comment: SnapshotRequirementComment) => void
  deleteRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => Promise<{ id: UUID } | null>
  onDeleteRequirementComment: (commentId: UUID) => void
  onToggleResolveRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => void
  userId: UUID
  specificationSnapshot: SpecificationSnapshot
}) => {
  const {
    reviews,
    updateRequirementReview,
    onUpdateReview,
    requirement,
    comments,
    addRequirementComment,
    onAddRequirementComment,
    deleteRequirementComment,
    onDeleteRequirementComment,
    onToggleResolveRequirementComment,
    userId,
    specificationSnapshot,
  } = props

  const [open, setOpen] = useState<boolean>(false)
  const ref = useClickOutside(() => setOpen(false))

  const userReview =
    reviews.filter((r) => r.reviewer.userId === userId)?.[0] ?? null

  const reviewState = userReview?.status ?? null

  const addComment = useCallback(
    async (requirementId: UUID, newComment: string) => {
      try {
        const createdComment = await addRequirementComment(
          requirementId,
          newComment,
        )
        if (!createdComment) {
          toastError('Unable to add comment', 'Try again later')
          return false
        }
        onAddRequirementComment(createdComment)
        return true
      } catch (error) {
        console.error('Error adding comment', error)
        captureException(error)
        toastError('Unable to add comment', 'Try again later')
        return false
      }
    },
    [addRequirementComment, onAddRequirementComment],
  )

  const deleteComment = useCallback(
    async (requirementId: UUID, commentId: UUID) => {
      try {
        const deletedComment = await deleteRequirementComment(
          requirementId,
          commentId,
        )
        if (!deletedComment) {
          toastError('Unable to delete comment', 'Try again later')
          return
        }
        onDeleteRequirementComment(deletedComment.id)
      } catch (error) {
        console.error('Error deleting comment', error)
        captureException(error)
        toastError('Unable to delete comment', 'Try again later')
      }
    },
    [deleteRequirementComment, onDeleteRequirementComment],
  )

  const toggleResolveComment = useCallback(
    async (requirementId: UUID, commentId: UUID, resolveComment: boolean) => {
      try {
        await projectApi.updateSnapshotRequirementComment(
          specificationSnapshot.project.id,
          specificationSnapshot.id,
          requirementId,
          commentId,
          { resolve: resolveComment },
        )
        onToggleResolveRequirementComment(requirementId, commentId)
      } catch (error) {
        console.error(
          `Error ${resolveComment ? 'resolving' : 'un-resolving'} comment`,
          error,
        )
        toastError(
          `Unable to ${resolveComment ? 'resolve' : 're-open'} comment`,
          'Try again later',
        )
        captureException(error)
      }
    },
    [
      onToggleResolveRequirementComment,
      specificationSnapshot.id,
      specificationSnapshot.project.id,
    ],
  )

  const commentProps = {
    requirementId: requirement.id,
    onDeleteComment: deleteComment,
    onToggleResolveComment: toggleResolveComment,
    userId,
  }

  const options = entries(reviewStates).map(([state, stuff]) => (
    <button
      key={state}
      className={`${styles.item} ${
        reviewState === state ? styles.selected : ''
      }`}
      onClick={async () => {
        try {
          await updateRequirementReview(userReview?.id, requirement.id, state)
          onUpdateReview(state)
        } catch (error) {
          console.error('Unable to update review', error)
          captureException(error)
        }
      }}
    >
      {stuff.component}
      {stuff.label}
    </button>
  ))

  return (
    <div className={styles.commentsTab}>
      {reviewState !== null && (
        <div className={`${styles.addReview}`}>
          <span className={styles.sectionTitle}>My Review</span>
          <button
            className={`${styles.dropdownElevation} ${styles.currentReview}`}
            onClick={() => setOpen(!open)}
          >
            {reviewStates[reviewState].component}
            {reviewStates[reviewState].label}
          </button>
          {open && (
            <div className={styles.body} ref={ref}>
              <div className={styles.close}>
                <div>Add your review</div>
                <button onClick={() => setOpen(!open)}>
                  <Close />
                </button>
              </div>
              {options}
            </div>
          )}
        </div>
      )}
      <div className={`${styles.currentReviews} ${styles.dropdownElevation}`}>
        <span className={styles.sectionTitle}>Current Review Statuses</span>
        {reviews.map((rev) => (
          <div key={rev.id} className={styles.currentReviews}>
            <span>
              {rev.reviewer.firstName} {rev.reviewer.lastName}
            </span>
            {reviewStates[rev.status].component}{' '}
            {reviewStates[rev.status].label}
          </div>
        ))}
      </div>
      <div className={styles.allComments}>
        <div className={styles.commentsTitle}>
          <span className={styles.sectionTitle}>Comments</span>
          <span className={styles.commentCount}>
            <AddComment size={16} />
            {comments.length}
          </span>
        </div>
        {reviewState !== null && (
          <SnapshotCommentForm
            requirementId={requirement.id}
            onAddComment={addComment}
          />
        )}
        <SnapshotComments comments={comments} {...commentProps} />
      </div>
      {reviewState == null && (
        <div className={styles.commentForm}>
          <SnapshotCommentForm
            requirementId={requirement.id}
            onAddComment={addComment}
          />
        </div>
      )}
    </div>
  )
}

const TABS = {
  [DRAWER_TAB.COMMENTS]: CommentsTab,
  [DRAWER_TAB.HISTORY]: HistoryTab,
}

const DrawerTabs = (props: {
  reviews: SnapshotReviewWithReviewer[]
  updateRequirementReview: (
    reviewId: UUID,
    requirementId: UUID,
    status: ReviewStatus,
  ) => Promise<void>
  onUpdateReview: (state: ReviewStatus) => void
  requirement: {
    id: UUID
    sectionNumber: string
    title: string
    shallStatement: string
  }
  comments: SnapshotRequirementComment[]
  addRequirementComment: (
    requirementId: UUID,
    comment: string,
  ) => Promise<SnapshotRequirementComment | null>
  onAddRequirementComment: (comment: SnapshotRequirementComment) => void
  deleteRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => Promise<{ id: UUID } | null>
  onDeleteRequirementComment: (commentId: UUID) => void
  onToggleResolveRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => void
  initialTab: DRAWER_TAB
  userId: UUID
  specificationSnapshot: SpecificationSnapshot
}) => {
  const {
    reviews,
    updateRequirementReview,
    onUpdateReview,
    requirement,
    comments,
    addRequirementComment,
    onAddRequirementComment,
    deleteRequirementComment,
    onDeleteRequirementComment,
    onToggleResolveRequirementComment,
    initialTab = DRAWER_TAB.COMMENTS,
    userId,
    specificationSnapshot,
  } = props

  const [selectedTab, setSelectedTab] = useState(initialTab)

  const Tab = TABS[selectedTab]

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsHeader}>
        <button
          className={
            selectedTab === DRAWER_TAB.COMMENTS
              ? styles.activeTab
              : styles.inactiveTab
          }
          onClick={() => setSelectedTab(DRAWER_TAB.COMMENTS)}
        >
          Comments/Reviews
        </button>
        <button
          className={
            selectedTab === DRAWER_TAB.HISTORY
              ? styles.activeTab
              : styles.inactiveTab
          }
          onClick={() => setSelectedTab(DRAWER_TAB.HISTORY)}
        >
          History
        </button>
      </div>
      <Tab
        requirement={requirement}
        updateRequirementReview={updateRequirementReview}
        onUpdateReview={onUpdateReview}
        reviews={reviews}
        comments={comments}
        addRequirementComment={addRequirementComment}
        onAddRequirementComment={onAddRequirementComment}
        deleteRequirementComment={deleteRequirementComment}
        onDeleteRequirementComment={onDeleteRequirementComment}
        onToggleResolveRequirementComment={onToggleResolveRequirementComment}
        userId={userId}
        specificationSnapshot={specificationSnapshot}
      />
    </div>
  )
}

export type SnapshotReviewDrawerProps = {
  updateRequirementReview: (
    reviewId: UUID,
    requirementId: UUID,
    status: ReviewStatus,
  ) => Promise<void>
  requirement: {
    id: UUID
    sectionNumber: string
    title: string
    shallStatement: string
  }
  requirementIdtoReviews: Record<UUID, SnapshotReviewWithReviewer[]>
  commentsByRequirementId: Record<UUID, SnapshotRequirementComment[]>
  addRequirementComment: (
    requirementId: UUID,
    comment: string,
  ) => Promise<SnapshotRequirementComment | null>
  deleteRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => Promise<{ id: UUID } | null>
  toggleResolveRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => void
  closeModal: () => void
  initialTab: DRAWER_TAB
  userId: UUID
  specificationSnapshot: SpecificationSnapshot
}

enum ReviewNavButton {
  BACK = 'Back',
  NEXT = 'Next',
}

const SnapshotReviewDrawer = (props: SnapshotReviewDrawerProps) => {
  const {
    requirementIdtoReviews,
    updateRequirementReview,
    requirement: initialRequirement,
    commentsByRequirementId,
    addRequirementComment,
    deleteRequirementComment,
    toggleResolveRequirementComment,
    closeModal,
    initialTab = DRAWER_TAB.COMMENTS,
    userId,
    specificationSnapshot,
  } = props
  const [requirement, setRequirement] = useState<{
    id: UUID
    sectionNumber: string
    title: string
    shallStatement: string
  }>(initialRequirement)
  const [snapshotReviews, setSnapshotReviews] = useState<
    Record<UUID, SnapshotReviewWithReviewer[]>
  >(requirementIdtoReviews)

  const [snapshotComments, setSnapshotComments] = useState<
    Record<string, SnapshotRequirementComment[]>
  >(commentsByRequirementId)

  const requirementOrder = specificationSnapshot.contents.documentBlocks
    .filter((block) => block.type === BlockType.Requirement)
    .map((block) => block.id)
  const [requirementIndex, setRequirementIndex] = useState(
    requirementOrder.indexOf(requirement.id),
  )

  const handleNavClick = (navButton: ReviewNavButton) => {
    const newIndex =
      navButton === ReviewNavButton.BACK
        ? requirementIndex - 1
        : requirementIndex + 1
    const newRequirement =
      specificationSnapshot.contents.requirements.find(
        (req) => req.id === requirementOrder[newIndex],
      ) || null

    if (newRequirement) {
      setRequirementIndex((requirementIndex) =>
        navButton === ReviewNavButton.BACK
          ? requirementIndex - 1
          : requirementIndex + 1,
      )
      setRequirement({
        id: newRequirement.id,
        sectionNumber: newRequirement.sectionNumber,
        title: newRequirement.title,
        shallStatement: newRequirement.shallStatement,
      })
    }
  }

  return (
    <Drawer onClose={closeModal}>
      <div className={styles.drawer}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <div className={styles.requirementTitle}>
              <span>{requirement.sectionNumber}</span>
              <h2>{requirement.title}</h2>
            </div>
            <div className={styles.shallStatement}>
              {requirement.shallStatement}
            </div>
          </div>
          <DrawerTabs
            reviews={snapshotReviews[requirement.id] || []}
            updateRequirementReview={updateRequirementReview}
            onUpdateReview={(state: ReviewStatus) => {
              setSnapshotReviews((prev) => ({
                ...prev,
                [requirement.id]: prev[requirement.id].map((r) =>
                  r.reviewer.userId === userId ? { ...r, status: state } : r,
                ),
              }))
            }}
            requirement={requirement}
            comments={snapshotComments[requirement.id] || []}
            addRequirementComment={addRequirementComment}
            onAddRequirementComment={(
              addedComment: SnapshotRequirementComment,
            ) => {
              setSnapshotComments((prev) => ({
                ...prev,
                [requirement.id]: [
                  ...(prev[requirement.id] || []),
                  addedComment,
                ],
              }))
            }}
            deleteRequirementComment={deleteRequirementComment}
            onDeleteRequirementComment={(deletedCommentId: UUID) =>
              setSnapshotComments((prev) => ({
                ...prev,
                [requirement.id]: (prev[requirement.id] || []).filter(
                  (c) => c.id !== deletedCommentId,
                ),
              }))
            }
            onToggleResolveRequirementComment={(
              requirementId: UUID,
              commentId: UUID,
            ) => {
              toggleResolveRequirementComment(requirementId, commentId)
              setSnapshotComments((prev) => ({
                ...prev,
                [requirement.id]: prev[requirement.id].map((comment) =>
                  comment.id === commentId
                    ? { ...comment, resolved: !comment.resolved }
                    : comment,
                ),
              }))
            }}
            initialTab={initialTab}
            userId={userId}
            specificationSnapshot={specificationSnapshot}
          />
        </div>
        <div className={styles.footer}>
          <Button
            text={ReviewNavButton.BACK}
            color={BUTTON_COLORS.SECONDARY}
            frontIcon={<CaretLeft size={16} />}
            onClick={() => handleNavClick(ReviewNavButton.BACK)}
            disabled={requirementIndex === 0}
            className={styles.navButton}
          />
          <span>
            Requirement {requirementIndex + 1} of {requirementOrder.length}
          </span>
          <Button
            text={ReviewNavButton.NEXT}
            color={BUTTON_COLORS.SECONDARY}
            endIcon={<CaretRight size={16} />}
            onClick={() => handleNavClick(ReviewNavButton.NEXT)}
            disabled={requirementIndex === requirementOrder.length - 1}
            className={styles.navButton}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default SnapshotReviewDrawer
