import {
  CheckmarkFilled,
  ErrorFilled,
  InformationFilled,
} from '@carbon/icons-react'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Id, ToastOptions, UpdateOptions, toast } from 'react-toastify'
import styles from './index.module.css'

export enum StellToastType {
  SUCCESS,
  ERROR,
  INFO,
}

interface SuccessToastProps {
  msg: string | ReactElement
  cta?: string | ReactElement
  ctaUrl?: string
}
const SuccessToast = (props: SuccessToastProps) => {
  const { msg, cta, ctaUrl } = props

  return (
    <div className={styles.container}>
      <CheckmarkFilled className={styles.successIcon} size="24" />
      <div className={styles.msgContainer}>
        <div className={styles.msg}>{msg}</div>
        {cta && (
          <Link className={styles.cta} to={ctaUrl ?? '/'}>
            {cta}
          </Link>
        )}
      </div>
    </div>
  )
}

interface ErrorToastProps {
  msg: string | ReactElement
  suggestion?: string | ReactElement
}

const ErrorToast = (props: ErrorToastProps) => {
  const { msg, suggestion } = props

  return (
    <div className={styles.container}>
      <ErrorFilled className={styles.failureIcon} size="24" />
      <div className={styles.msgContainer}>
        <div className={styles.failureMsg}>{msg}</div>
        <div className={styles.suggestion}>{suggestion}</div>
      </div>
    </div>
  )
}

interface InfoToastProps {
  msg: string | ReactElement
}

const InfoToast = (props: InfoToastProps) => {
  const { msg } = props
  return (
    <div className={styles.container}>
      <InformationFilled className={styles.infoIcon} size="24" />
      <div className={styles.msgContainer}>
        <div className={styles.msg}>{msg}</div>
      </div>
    </div>
  )
}

const toastSuccess = (
  msg: string | ReactElement,
  cta?: string,
  ctaUrl?: string,
  toastOptions?: ToastOptions,
): Id => {
  return toast(
    <SuccessToast msg={msg} cta={cta} ctaUrl={ctaUrl} />,
    toastOptions || {},
  )
}

const toastError = (
  msg: string | ReactElement,
  suggestion: string | ReactElement,
  toastOptions?: ToastOptions,
): Id => {
  return toast(
    <ErrorToast msg={msg} suggestion={suggestion} />,
    toastOptions || {},
  )
}

const toastInfo = (msg: ReactElement, toastOptions?: ToastOptions): Id => {
  return toast(<InfoToast msg={msg} />, toastOptions || {})
}

const COMPONENT_BY_TYPE = {
  [StellToastType.SUCCESS]: SuccessToast,
  [StellToastType.ERROR]: ErrorToast,
  [StellToastType.INFO]: InfoToast,
}

const updateToast = (
  toastId: Id,
  update: {
    toastType: StellToastType
    toastProps: SuccessToastProps | ErrorToastProps | InfoToastProps
  },
  toastOptions?: UpdateOptions,
) => {
  const { toastType, toastProps } = update
  const ToastComponent = COMPONENT_BY_TYPE[toastType]

  toast.update(toastId, {
    render: <ToastComponent {...toastProps} />,
    ...(toastOptions || {}),
  })
}

export { toastSuccess, toastError, toastInfo, updateToast }
