import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './ChangeRequestStatusSelect.module.css'
import useClickOutside from '../../hooks/useClickOutside'
import { ChangeRequestStatus } from '../../types/enums'
import Dropdown from '../dropdown'
import ChangeRequestStatusTag from '../tag/ChangeRequestStatusTag'

const ChangeRequestStatusSelect = (props: {
  value: ChangeRequestStatus
  onSelect?: (selectedStatus: ChangeRequestStatus) => void
}) => {
  const { value, onSelect } = props

  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(() => setIsOpen(false), isOpen)

  return (
    <div ref={ref} className={styles.status}>
      <ChangeRequestStatusTag status={value} />
      {onSelect && (
        <>
          <button onClick={() => setIsOpen(!isOpen)}>
            <CaretDown size={20} />
          </button>
          <Dropdown className={styles.dropdown} isOpen={isOpen}>
            {Object.values(ChangeRequestStatus).map((statusOption) => (
              <button
                key={statusOption}
                className={styles.menuItem}
                onClick={() => {
                  onSelect(statusOption)
                  setIsOpen(false)
                }}
                data-selected={statusOption === value}
              >
                <ChangeRequestStatusTag status={statusOption} />
              </button>
            ))}
          </Dropdown>
        </>
      )}
    </div>
  )
}

export default ChangeRequestStatusSelect
