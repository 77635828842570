import { SkillLevel } from '@carbon/icons-react'
import { useEffect, useState } from 'react'
import ReviewStatusItem from './ReviewStatusItem'
import styles from './StatsDropdown.module.css'
import { UUID } from '../../../api/utilityTypes'
import { ReviewStatus, SnapshotReview } from '../../../api/v2/projects'
import Avatar from '../../../components/avatar'
import { AvatarSize } from '../../../components/avatar/constants'
import Dropdown from '../../../components/dropdown'
import { reviewStates } from '../SharedRequirementColumns'

interface StatsDropdownProps {
  isOpen: boolean
  reviews: SnapshotReview[]
}

type ReviewerCount = {
  total: number
  reviewed: number
  firstName: string
  lastName: string
}

const StatsDropdown = (props: StatsDropdownProps) => {
  const { isOpen, reviews } = props
  const [statusCounts, setStatusCounts] = useState({})
  const [reviewerCounts, setReviewerCounts] = useState<
    Record<UUID, ReviewerCount>
  >({})
  const [totalUniqueRequirements, setTotalUniqueRequirements] = useState(0)

  useEffect(() => {
    const requirementStatusMap: Map<string, Set<string>> = new Map()

    reviews.forEach((review) => {
      Object.values(review.requirements).forEach((requirementReview) => {
        const { requirementId, status } = requirementReview
        if (!requirementStatusMap.has(requirementId)) {
          requirementStatusMap.set(requirementId, new Set())
        }
        requirementStatusMap.get(requirementId)!.add(status)
      })
    })

    const statusMap: Record<string, number> = {}
    requirementStatusMap.forEach((statusSet) => {
      statusSet.forEach((status) => {
        if (!statusMap[status]) {
          statusMap[status] = 0
        }
        statusMap[status]++
      })
    })

    setStatusCounts(statusMap)
    setTotalUniqueRequirements(requirementStatusMap.size)

    const reviewerMap = reviews.reduce(
      (acc, review) => {
        const { userId, firstName, lastName } = review.reviewer
        const totalRequirements = Object.keys(review.requirements).length
        const reviewedRequirements = Object.values(review.requirements).filter(
          (requirementReview) => requirementReview.status !== 'EMPTY',
        ).length

        if (!acc[userId]) {
          acc[userId] = {
            total: 0,
            reviewed: 0,
            firstName: firstName,
            lastName: lastName,
          }
        }
        acc[userId].total += totalRequirements
        acc[userId].reviewed += reviewedRequirements

        return acc
      },
      {} as Record<UUID, ReviewerCount>,
    )
    setReviewerCounts(reviewerMap)
  }, [reviews])

  return (
    <Dropdown isOpen={isOpen} className={styles.dropdown}>
      <div className={styles.header}>
        <h2>Review statistics</h2>
        <SkillLevel size={16} />
      </div>
      <div className={styles.overall}>
        <h3>Overall · {totalUniqueRequirements} requirements</h3>
        {Object.entries(reviewStates).map(([status, { component, label }]) => {
          const count = statusCounts[status as ReviewStatus] || 0
          return (
            <ReviewStatusItem
              key={status}
              icon={component}
              text={label}
              subText={`${count} ${
                count === 1 ? 'requirement' : 'requirements'
              }`}
            />
          )
        })}
      </div>
      <div className={styles.reviewers}>
        <h3>Reviewers</h3>
        {Object.entries(reviewerCounts).map(
          ([reviewerId, { total, reviewed, firstName, lastName }]) => {
            const percentage = total > 0 ? (reviewed / total) * 100 : 0
            const userAvatar = (
              <Avatar
                firstName={firstName}
                lastName={lastName}
                size={AvatarSize.Small}
              />
            )
            return (
              <ReviewStatusItem
                key={reviewerId}
                icon={userAvatar}
                text={`${firstName} ${lastName}`}
                percentage={percentage}
                subText={`${reviewed}/${total} requirements reviewed`}
              />
            )
          },
        )}
      </div>
    </Dropdown>
  )
}

export default StatsDropdown
