import { useCallback, useContext } from 'react'
import { Evidence } from '../api/v2/evidence.ts'
import { Requirement } from '../api/v2/requirements.ts'
import { User } from '../api/v2/users.ts'
import { ChangeRequestDrawerProps } from '../components/drawer/ChangeRequestDrawer.tsx'
import { EvidenceActivityDrawerProps } from '../components/drawer/EvidenceActivityDrawer.tsx'
import { EvidenceRecordDrawerProps } from '../components/drawer/EvidenceRecordDrawer.tsx'
import { RequirementDrawerProps } from '../components/drawer/RequirementDrawer.tsx'
import { SnapshotReviewDrawerProps } from '../components/drawer/SnapshotReviewDrawer.tsx'
import { AddLinkedRequirementExceptionProps } from '../components/modal/AddLinkedRequirementExceptionModal.tsx'
import { AddLinkedRequirementProps } from '../components/modal/AddLinkedRequirementModal.tsx'
import { ConfirmationModalProps } from '../components/modal/ConfirmationModal'
import { CreateProjectModalProps } from '../components/modal/CreateProjectModal.tsx'
import {
  CreateEvidenceComplianceReportAction,
  CreateSearchReportAction,
  CreateSpecificationReportAction,
} from '../components/modal/GenerateReportModal.tsx'
import { LinkedEvidenceViewModalProps } from '../components/modal/LinkedEvidenceViewModal.tsx'
import { LinkedRequirementViewModalProps } from '../components/modal/LinkedRequirementViewModal.tsx'
import { ManageSharedSpecReviewersModalProps } from '../components/modal/ManageSharedSpecReviewersModal.tsx'
import { NewSpecificationModalProps } from '../components/modal/NewSpecificationModal.tsx'
import { ShareProjectModalProps } from '../components/modal/ShareProjectModal.tsx'
import { ShareSpecificationModalProps } from '../components/modal/ShareSpecificationModal.tsx'
import { SpecificationPdfViewerModalProps } from '../components/modal/SpecificationPdfViewerModal.tsx'
import { SpecificationTreeBuilderModalProps } from '../components/modal/SpecificationTreeBuilderModal.tsx'
import {
  GlobalModalContext,
  MODAL_ID_ADD_LINKED_REQUIREMENT,
  MODAL_ID_ADD_LINKED_REQUIREMENT_EXCEPTION,
  MODAL_ID_CHANGE_REQUEST_DRAWER,
  MODAL_ID_CONFIRMATION,
  MODAL_ID_CREATE_EVIDENCE,
  MODAL_ID_CREATE_PROJECT,
  MODAL_ID_DUPLICATE_SPECIFICATION,
  MODAL_ID_GENERATE_REPORT,
  MODAL_ID_IMPORT_EXCEL,
  MODAL_ID_IMPORT_PDF,
  MODAL_ID_IMPORT_WORD,
  MODAL_ID_KEYBOARD_SHORTCUTS,
  MODAL_ID_LINK_EVIDENCE,
  MODAL_ID_LINKED_EVIDENCE,
  MODAL_ID_LINKED_REQUIREMENT,
  MODAL_ID_NEW_SPECIFICATION,
  MODAL_ID_PROJECT_SPECIFICATION_REVIEWERS,
  MODAL_ID_REMOVE_STELL_USER,
  MODAL_ID_REQUIREMENT_DRAWER,
  MODAL_ID_SEARCH_REQUIREMENT,
  MODAL_ID_SHARE_PROJECT,
  MODAL_ID_SHARE_SPECIFICATION,
  MODAL_ID_SPECIFICATION_HISTORY,
  MODAL_ID_SPECIFICATION_PDF_VIEWER,
  MODAL_ID_SPECIFICATION_REVIEWERS,
  MODAL_ID_SPECIFICATION_TREE_BUILDER,
  MODEL_ID_EVIDENCE_ACTIVITY_DRAWER,
  MODEL_ID_EVIDENCE_RECORD,
  MODEL_ID_SNAPSHOT_REVIEW,
} from '../context/GlobalModalContext'
import { EvidenceType } from '../types/enums.ts'

interface UseModalsType {
  currentModalId: string | undefined
  currentModalProps: object | undefined
  isOpen: boolean
  closeModal: () => void
  openImportPDFModal: () => void
  openImportExcelModal: () => void
  openImportWordModal: () => void
  openSearchRequirementDrawer: (props: any) => void
  openRequirementDrawer: (props: RequirementDrawerProps) => void
  openChangeRequestDrawer: (props: ChangeRequestDrawerProps) => void
  openGenerateReportModal: (
    searchReport?: CreateSearchReportAction,
    specReport?: CreateSpecificationReportAction,
    evidenceReport?: CreateEvidenceComplianceReportAction,
  ) => void
  openDuplicateSpecificationModal: (props: { specification: any }) => void
  openSpecificationReviewersModal: (props: any) => void
  openConfirmationModal: (props: ConfirmationModalProps) => void
  openRemoveStellUserModal: (
    user: any,
    onRemoveUser: (userId: string) => void,
  ) => void
  openSpecificationHistoryModal: (specificationId: string) => void
  openLinkEvidenceModal: (props: {
    requirement: Requirement
    evidenceType: string
    onLink:
      | ((evidenceId: string, evidenceType: EvidenceType) => void)
      | ((evidenceId: string, evidenceType: EvidenceType) => Promise<void>)
  }) => void
  openCreateEvidenceModal: (props: {
    evidenceType?: string
    onCreate?: (evidence: Evidence) => void
    errorOnCreate?: { msg: string; suggestion: string }
  }) => void
  openAddLinkedRequirementExceptionModal: (
    props: AddLinkedRequirementExceptionProps,
  ) => void
  openAddLinkedRequirementModal: (props: AddLinkedRequirementProps) => void
  openLinkedRequirementViewModal: (
    props: LinkedRequirementViewModalProps,
  ) => void
  openLinkedEvidenceViewModal: (props: LinkedEvidenceViewModalProps) => void
  openEvidenceDrawer: (props: EvidenceRecordDrawerProps) => void
  openEvidenceActivityDrawer: (props: EvidenceActivityDrawerProps) => void
  openShareSpecificationModal: (props: ShareSpecificationModalProps) => void
  openSpecificationPdfViewerModal: (
    props: SpecificationPdfViewerModalProps,
  ) => void
  openSnapshotReviewDrawer: (props: SnapshotReviewDrawerProps) => void
  openNewSpecificationModal: (props: NewSpecificationModalProps) => void
  openSpecificationTreeBuilderModal: (
    props: SpecificationTreeBuilderModalProps,
  ) => void
  openKeyboardShortcutsModal: () => void
  openCreateProjectModal: (props: CreateProjectModalProps) => void
  openShareProjectModal: (props: ShareProjectModalProps) => void
  openSharedProjectSpecificationReviewersModal: (props: any) => void
}

export const useModals = (): UseModalsType => {
  const {
    isOpen,
    openModal,
    closeModal: contextCloseModal,
    currentModalId,
    currentModalProps,
  } = useContext(GlobalModalContext)

  const openImportPDFModal = useCallback(() => {
    openModal(MODAL_ID_IMPORT_PDF)
  }, [openModal])

  const openImportExcelModal = useCallback(() => {
    openModal(MODAL_ID_IMPORT_EXCEL)
  }, [openModal])

  const openImportWordModal = useCallback(() => {
    openModal(MODAL_ID_IMPORT_WORD)
  }, [openModal])

  const openSearchRequirementDrawer = useCallback(
    (props: object) => {
      openModal(MODAL_ID_SEARCH_REQUIREMENT, props)
    },
    [openModal],
  )

  const openRequirementDrawer = useCallback(
    (props: RequirementDrawerProps) => {
      openModal(MODAL_ID_REQUIREMENT_DRAWER, props)
    },
    [openModal],
  )

  const openChangeRequestDrawer = useCallback(
    (props: ChangeRequestDrawerProps) => {
      openModal(MODAL_ID_CHANGE_REQUEST_DRAWER, props)
    },
    [openModal],
  )

  const openGenerateReportModal = useCallback(
    (
      searchReport?: CreateSearchReportAction,
      specReport?: CreateSpecificationReportAction,
      evidenceReport?: CreateEvidenceComplianceReportAction,
    ) => {
      openModal(MODAL_ID_GENERATE_REPORT, {
        searchReport,
        specReport,
        evidenceReport,
      })
    },
    [openModal],
  )

  const openDuplicateSpecificationModal = useCallback(
    (props) => {
      openModal(MODAL_ID_DUPLICATE_SPECIFICATION, props)
    },
    [openModal],
  )

  const openConfirmationModal = useCallback(
    (props) => {
      openModal(MODAL_ID_CONFIRMATION, props)
    },
    [openModal],
  )

  const openRemoveStellUserModal = useCallback(
    (user: User, onRemoveUser) => {
      openModal(MODAL_ID_REMOVE_STELL_USER, { user, onRemoveUser })
    },
    [openModal],
  )

  const openSpecificationHistoryModal = useCallback(
    (specificationId: string) => {
      openModal(MODAL_ID_SPECIFICATION_HISTORY, { specificationId })
    },
    [openModal],
  )

  const openSpecificationReviewersModal = useCallback(
    (props) => openModal(MODAL_ID_SPECIFICATION_REVIEWERS, props),
    [openModal],
  )

  const openLinkEvidenceModal = useCallback(
    (props) => openModal(MODAL_ID_LINK_EVIDENCE, props),
    [openModal],
  )

  const openCreateEvidenceModal = useCallback(
    (props) => openModal(MODAL_ID_CREATE_EVIDENCE, props),
    [openModal],
  )

  const openAddLinkedRequirementExceptionModal = useCallback(
    (props: AddLinkedRequirementExceptionProps) =>
      openModal(MODAL_ID_ADD_LINKED_REQUIREMENT_EXCEPTION, props),
    [openModal],
  )

  const openAddLinkedRequirementModal = useCallback(
    (props: AddLinkedRequirementProps) =>
      openModal(MODAL_ID_ADD_LINKED_REQUIREMENT, props),
    [openModal],
  )

  const openLinkedRequirementViewModal = useCallback(
    (props: LinkedRequirementViewModalProps) =>
      openModal(MODAL_ID_LINKED_REQUIREMENT, props),
    [openModal],
  )

  const openLinkedEvidenceViewModal = useCallback(
    (props) => openModal(MODAL_ID_LINKED_EVIDENCE, props),
    [openModal],
  )

  const openEvidenceDrawer = useCallback(
    (props: EvidenceRecordDrawerProps) =>
      openModal(MODEL_ID_EVIDENCE_RECORD, props),
    [openModal],
  )

  const openEvidenceActivityDrawer = useCallback(
    (props: EvidenceActivityDrawerProps) => {
      openModal(MODEL_ID_EVIDENCE_ACTIVITY_DRAWER, props)
    },
    [openModal],
  )

  const openShareSpecificationModal = useCallback(
    (props: ShareSpecificationModalProps) => {
      openModal(MODAL_ID_SHARE_SPECIFICATION, props)
    },
    [openModal],
  )

  const openSnapshotReviewDrawer = useCallback(
    (props: SnapshotReviewDrawerProps) => {
      openModal(MODEL_ID_SNAPSHOT_REVIEW, props)
    },
    [openModal],
  )

  const openSpecificationPdfViewerModal = useCallback(
    (props: SpecificationPdfViewerModalProps) => {
      openModal(MODAL_ID_SPECIFICATION_PDF_VIEWER, props)
    },
    [openModal],
  )

  const openNewSpecificationModal = useCallback(
    (props: NewSpecificationModalProps) => {
      openModal(MODAL_ID_NEW_SPECIFICATION, props)
    },
    [openModal],
  )

  const openSpecificationTreeBuilderModal = useCallback(
    (props: SpecificationTreeBuilderModalProps) => {
      openModal(MODAL_ID_SPECIFICATION_TREE_BUILDER, props)
    },
    [openModal],
  )

  const openKeyboardShortcutsModal = useCallback(() => {
    openModal(MODAL_ID_KEYBOARD_SHORTCUTS)
  }, [openModal])

  const openCreateProjectModal = useCallback(
    (props: CreateProjectModalProps) => {
      openModal(MODAL_ID_CREATE_PROJECT, props)
    },
    [openModal],
  )

  const openShareProjectModal = useCallback(
    (props: ShareProjectModalProps) => {
      openModal(MODAL_ID_SHARE_PROJECT, props)
    },
    [openModal],
  )

  const openSharedProjectSpecificationReviewersModal = useCallback(
    (props: ManageSharedSpecReviewersModalProps) => {
      openModal(MODAL_ID_PROJECT_SPECIFICATION_REVIEWERS, props)
    },
    [openModal],
  )

  const closeModal = useCallback(() => {
    // Do any clean up for your modal here
    contextCloseModal()
  }, [contextCloseModal])

  return {
    currentModalId,
    currentModalProps,
    isOpen,
    closeModal,
    openImportExcelModal,
    openImportWordModal,
    openImportPDFModal,
    openSearchRequirementDrawer,
    openRequirementDrawer,
    openChangeRequestDrawer,
    openGenerateReportModal,
    openDuplicateSpecificationModal,
    openConfirmationModal,
    openRemoveStellUserModal,
    openSpecificationHistoryModal,
    openSpecificationReviewersModal,
    openLinkEvidenceModal,
    openCreateEvidenceModal,
    openAddLinkedRequirementExceptionModal,
    openAddLinkedRequirementModal,
    openLinkedRequirementViewModal,
    openLinkedEvidenceViewModal,
    openEvidenceDrawer,
    openEvidenceActivityDrawer,
    openShareSpecificationModal,
    openSpecificationPdfViewerModal,
    openSnapshotReviewDrawer,
    openNewSpecificationModal,
    openSpecificationTreeBuilderModal,
    openKeyboardShortcutsModal,
    openCreateProjectModal,
    openShareProjectModal,
    openSharedProjectSpecificationReviewersModal,
  }
}
