import { ScatterMatrix } from '@carbon/icons-react'
import { useNavigate } from 'react-router-dom'
import styles from './BlockActions.module.css'
import { RequirementBlockActionItemProps } from './types'
import { useSpecificationContext } from '../../../context/SpecificationContext'

const ViewInMatrixAction = (props: RequirementBlockActionItemProps) => {
  const { block } = props
  const navigate = useNavigate()
  const { specification, contentIsEditable } = useSpecificationContext()

  return (
    <>
      {contentIsEditable && <div className={styles.sectionDivider} />}
      <button
        className={styles.actionItem}
        onClick={() => {
          navigate(`/specifications/${specification?.id}/matrix/${block?.id}`)
        }}
      >
        <ScatterMatrix />
        View in matrix
      </button>
    </>
  )
}

export default ViewInMatrixAction
