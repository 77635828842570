const StellTextLogo = (props) => {
  const { height = 84, textColor = '#1B1D1E' } = props
  return (
    <svg
      height={height}
      viewBox="0 0 326 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0047758 56.3195H17.0539C17.2836 64.7737 23.1175 69.3412 30.6717 69.3412C37.6517 69.3412 41.7698 65.8009 41.7698 60.887C41.7698 46.3795 2.86425 49.3489 2.86425 22.847C2.86425 9.36668 13.6202 0 29.4107 0C46.0028 0 56.8736 10.3963 57.2158 25.7016L40.5088 26.1579C40.1666 18.3894 35.9312 14.0488 29.638 14.0488C24.0315 14.0488 19.911 17.1328 19.911 22.159C19.911 38.4939 59.2736 32.8969 59.2736 59.8574C59.2736 74.5943 46.802 83.39 30.5521 83.39C10.6411 83.39 0.229715 70.139 0 56.3172L0.0047758 56.3195Z"
        fill={textColor}
      />
      <path
        d="M89.4882 82.2505V16.108H68.2061V1.1442H127.365V16.108H106.083V82.2505H89.4906H89.4882Z"
        fill={textColor}
      />
      <path
        d="M194.533 16.108H158.831V34.0435H188.239V48.6656H158.831V67.2867H194.533V82.2505H142.241V1.1442H194.533V16.108Z"
        fill={textColor}
      />
      <path
        d="M209.754 82.2505V1.1442H226.346V67.2843H260.329V82.2481H209.754V82.2505Z"
        fill={textColor}
      />
      <path
        d="M275.206 82.2505V1.1442H291.798V67.2843H325.781V82.2481H275.206V82.2505Z"
        fill={textColor}
      />
    </svg>
  )
}

export default StellTextLogo
