import { CSSProperties, ReactNode } from 'react'
import styles from './SelectableBlock.module.css'

export const SELECTABLE_BLOCK_CLASSNAME = styles.selectableBlock

export const IGNORE_FIELD_CLASSNAME = styles.ignoreField

export const BLOCK_IS_SELECTED_CLASSNAME = styles.blockIsSelected

export const DATA_VALUE_BLOCK_ID = 'data-block-id'

export const DATA_VALUE_REQUIREMENT_VERSION = 'data-requirement-version'

interface SelectableBlockProps {
  className?: string
  style?: CSSProperties
  blockId: string
  requirementVersionCount?: number
  children?: ReactNode
}

export const getSelectableBlockElement = (blockId: string) => {
  return document.querySelector(`[data-block-id="${blockId}"]`)
}

const SelectableBlock = (props: SelectableBlockProps) => {
  const { className, style, blockId, requirementVersionCount, children } = props
  return (
    <div
      className={`${SELECTABLE_BLOCK_CLASSNAME} ${className || ''}`}
      data-block-id={blockId}
      data-requirement-version={requirementVersionCount}
      style={style}
    >
      {children}
    </div>
  )
}

export default SelectableBlock
