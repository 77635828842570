import { Add, CheckmarkOutline } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import styles from './OrganizationSection.module.css'
import * as api from '../../api/v2/attributes.ts'
import { getTenantProfile, patchTenantProfile } from '../../api/v2/tenants.ts'
import Accordion from '../../components/accordion'
import Button, { BUTTON_COLORS } from '../../components/button'
import AttributeInput, {
  getTempAttributeId,
  NEW_ATTRIBUTE_NAME,
} from '../../components/input/AttributeInput.tsx'
import { TextInput } from '../../components/input/TextInput.tsx'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'
import { getRandomTagColor } from '../../components/tag'
import { toastError, toastSuccess } from '../../components/toast'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import {
  getRecentlyUsedDashboardFilter,
  setRecentlyUsedDashboardFilter,
} from '../../lib/localstorage.ts'
import { AttributeName, AttributeValueStatus } from '../../types/enums.ts'

const AdminDisplayProfileSettings = () => {
  const [displayName, setDisplayName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true)
      const { displayName: serverDisplayName } = await getTenantProfile()

      setDisplayName(serverDisplayName)
      setLoading(false)
    }
    asyncFunc()
  }, [])

  const handlePatchTenantProfile = async () => {
    try {
      const newTenantProfile = await patchTenantProfile({
        displayName,
      })
      setDisplayName(newTenantProfile.displayName)
      toastSuccess('Display Name Updated')
    } catch (e) {
      toastError('Unable to save display name', 'Try Again Later')
      console.error(e)
      captureException(e)
    }
  }

  return (
    <div className={styles.displayProfile}>
      <p>
        Your organization’s display name and image will be visible when
        connecting with other contacts to share specifications and reviews.
        Images must be less than 10mb.
      </p>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className={styles.displayName}>
          <TextInput
            id="display-name"
            label="Display Name"
            value={displayName}
            setValue={setDisplayName}
          />
          <div className={styles.saveButtonWrapper}>
            <Button
              text="Save"
              onClick={() => handlePatchTenantProfile()}
              endIcon={<CheckmarkOutline size={16} />}
              color={BUTTON_COLORS.PRIMARY}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const TagSection = () => {
  const { isAdmin } = useAuth()
  const {
    getAttributeByName,
    activeCategories,
    activeComplianceStates,
    activePrograms,
    activeRequirementTypes,
    activeEvidenceMethods,
    reload,
  } = useAttributesContext()

  const TagsEditor = (props: {
    activeTags: api.AttributeValueResponse[]
    attributeName: AttributeName
    description: string
    entityCountLabel: (count: number) => string
    onCreate: (tag: api.AttributeValueResponse) => void
    onUpdate: (tag: api.UpdateAttributeResponse) => void
    onArchive: (tag: api.AttributeValueResponse) => void
  }) => {
    const {
      activeTags,
      attributeName,
      description,
      entityCountLabel,
      onCreate,
      onUpdate,
      onArchive,
    } = props
    const [tags, setTags] = useState(activeTags)
    const attribute = getAttributeByName(attributeName)

    if (!attribute) {
      return (
        <div className={styles.section}>
          <span className={styles.description}>Unable to load tag editor.</span>
        </div>
      )
    }

    return (
      <div className={styles.section}>
        <span className={styles.description}>{description}</span>
        {isAdmin && (
          <Button
            className={styles.addBtn}
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => {
              const newTagColor = getRandomTagColor()
              const newTag = {
                id: getTempAttributeId(),
                name: NEW_ATTRIBUTE_NAME,
                status: AttributeValueStatus.None,
                entityCount: 0,
                metadata: {
                  STYLES: {
                    COLOR_FONT: newTagColor.fontColor,
                    COLOR_BG: newTagColor.backgroundColor,
                    COLOR_BG_HOVER: newTagColor.hoverBackgroundColor || '',
                  },
                },
              }
              setTags([newTag, ...tags])
            }}
            endIcon={<Add />}
          />
        )}
        {tags.map((tag, idx) => (
          <AttributeInput
            key={`${tag.id}-${idx}`}
            attribute={tag}
            attributeId={attribute.id || ''}
            entityCountLabel={entityCountLabel(tag.entityCount)}
            isAdmin={isAdmin}
            onAttributeArchive={(archivedTag) => {
              setTags(
                tags.filter(
                  (tag: api.AttributeValueResponse) =>
                    tag.id !== archivedTag.id,
                ),
              )
              onArchive(archivedTag)
            }}
            onAttributeCreate={(prevId, newTag) => {
              setTags([newTag, ...tags.filter((tag) => tag.id !== prevId)])
              onCreate(newTag)
            }}
            onAttributeUpdate={(updatedTag) => {
              onUpdate(updatedTag)
            }}
          />
        ))}
      </div>
    )
  }

  return (
    <Accordion
      expandedByDefault
      sections={[
        {
          title: 'Types',
          children: (
            <TagsEditor
              attributeName={AttributeName.RequirementType}
              activeTags={activeRequirementTypes}
              description={
                isAdmin
                  ? 'Create and edit a list of type tags organization members can add to requirements.'
                  : 'Only your organization’s administrator can add or edit types. Reach out to them for assistance.'
              }
              entityCountLabel={(count: number) =>
                count > 1 ? 'requirements' : 'requirement'
              }
              onCreate={reload}
              onUpdate={reload}
              onArchive={reload}
            />
          ),
        },
        {
          title: 'Categories',
          children: (
            <TagsEditor
              attributeName={AttributeName.SpecificationCategory}
              activeTags={activeCategories}
              description={
                'Create and edit a list of category tags. Members can mark their specifications as part of a category.'
              }
              entityCountLabel={(count: number) =>
                count > 1 ? 'specifications' : 'specification'
              }
              onCreate={reload}
              onUpdate={reload}
              onArchive={reload}
            />
          ),
        },
        {
          title: 'Programs',
          children: (
            <TagsEditor
              attributeName={AttributeName.SpecificationProgram}
              activeTags={activePrograms}
              description={
                'Create and edit a list of program tags. Members can mark their specifications as part of a program.'
              }
              entityCountLabel={(count: number) =>
                count > 1 ? 'specifications' : 'specification'
              }
              onCreate={reload}
              onUpdate={reload}
              onArchive={(archivedTag) => {
                const lsFilter = getRecentlyUsedDashboardFilter()
                if (lsFilter?.id === archivedTag.id) {
                  setRecentlyUsedDashboardFilter(null)
                }

                reload()
              }}
            />
          ),
        },
        {
          title: 'Methods',
          children: (
            <TagsEditor
              attributeName={AttributeName.EvidenceMethod}
              activeTags={activeEvidenceMethods}
              description={
                isAdmin
                  ? 'Create and edit a list of method tags organization members can add to evidences.'
                  : 'Only your organization’s administrator can add or edit methods. Reach out to them for assistance.'
              }
              entityCountLabel={(count: number) =>
                count > 1 ? 'evidences' : 'evidence'
              }
              onCreate={reload}
              onUpdate={reload}
              onArchive={reload}
            />
          ),
        },
        {
          title: 'Compliances',
          children: (
            <TagsEditor
              attributeName={AttributeName.RequirementCompliance}
              activeTags={activeComplianceStates}
              description={
                isAdmin
                  ? 'Create and edit a list of compliance tags organization members can add to requirements.'
                  : 'Only your organization’s administrator can add or edit compliance types. Reach out to them for assistance.'
              }
              entityCountLabel={(count: number) =>
                count > 1 ? 'requirements' : 'requirement'
              }
              onCreate={reload}
              onUpdate={reload}
              onArchive={reload}
            />
          ),
        },
      ]}
    ></Accordion>
  )
}

const OrganizationSection = ({ showTags = false }) => {
  const { isAdmin } = useAuth()
  return (
    <div>
      {isAdmin ? <AdminDisplayProfileSettings /> : undefined}
      {showTags ? <TagSection /> : undefined}
    </div>
  )
}

export default OrganizationSection
