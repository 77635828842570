import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './DropdownSelection.module.css'
import Dropdown from './index.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'

export interface OptionType {
  id: string
  label: string
  disabled?: boolean
}

export interface DropdownSelectionType {
  initialSelection?: OptionType
  options: OptionType[]
  disabled?: boolean
  onSelect: (option: OptionType) => void
}

const DropdownSelection = (props: DropdownSelectionType) => {
  const { initialSelection, options, disabled, onSelect } = props

  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useClickOutside(() => {
    setIsOpen(false)
  })

  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${disabled ? styles.disabled : ''}`}
    >
      <button
        className={styles.dropdownBtn}
        onClick={() => {
          if (options.length > 0) {
            setIsOpen(!isOpen)
          }
        }}
      >
        {initialSelection?.label}
        <CaretDown
          className={options.length > 0 && !disabled ? '' : styles.hide}
        />
      </button>
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        {options.map((option) => {
          const isDisabled = option.disabled

          return (
            <div key={option.id} className={styles.option}>
              <button
                key={option.id}
                className={`${styles.notSelected} ${
                  isDisabled ? styles.disabled : ''
                }`}
                disabled={isDisabled}
                onClick={() => {
                  onSelect(option)
                  setIsOpen(false)
                }}
              >
                {option.label}
              </button>
            </div>
          )
        })}
      </Dropdown>
    </div>
  )
}

export default DropdownSelection
