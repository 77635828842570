import { Document } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import styles from './ChangeRequestDrawer.module.css'
import Drawer from './index.tsx'
import { ChangeRequest } from '../../api/v2/changeRequests.ts'
import { getRequirement, Requirement } from '../../api/v2/requirements.ts'
import { formatTime, monthLongDayYear } from '../../lib/date.ts'
import {
  extractRationaleDelta,
  extractShallStatementDelta,
} from '../../lib/requirement.ts'
import { isEmptyDelta } from '../../lib/string.ts'
import LoadingIndicator from '../loading-indicator/LoadingIndicator.tsx'
import QuillContent from '../quill-content/QuillContent.tsx'
import ChangeRequestStatusTag from '../tag/ChangeRequestStatusTag.tsx'

export enum DRAWER_TAB {
  DETAILS = 'details',
  HISTORY = 'history',
}

const DetailsTab = ({ changeRequest }) => {
  const {
    createdByName,
    createdOn,
    proposed,
    current,
    memo,
    requirementTitle,
    specificationName,
    specificationId,
    requirementId,
    revisionId,
  } = changeRequest

  const [loading, setLoading] = useState(true)
  const [requirement, setRequirement] = useState<Requirement>()
  const [error, setError] = useState('')

  useEffect(() => {
    const loadRequirement = async () => {
      setLoading(true)
      try {
        const req = await getRequirement(specificationId, requirementId)
        setRequirement(req)
      } catch (e) {
        console.error('Could not get requirement information', e)
        captureException(e)
        setError('Unable to get requirement information')
      } finally {
        setLoading(false)
      }
    }
    loadRequirement()
  }, [specificationId, revisionId, requirementId])

  return (
    <div className={styles.tabContent}>
      <div className={styles.changeRequestContainer}>
        <span className={styles.headerText}>Change Request</span>
        <div className={`${styles.changeRequestCard} ${styles.basicElevation}`}>
          <div className={styles.requestorDetails}>
            <div>
              <span>Requested by </span>
              <span>{createdByName}</span>
            </div>
            <span>
              {monthLongDayYear(createdOn)} {' · '}
              {formatTime(createdOn)}
            </span>
          </div>
          <div className={styles.changeRequestDetails}>
            <div className={styles.textRow}>
              <div className={styles.rowLabel}>WAS</div>
              <div className={styles.wasText}>{current}</div>
            </div>
            <div className={styles.textRow}>
              <div className={styles.rowLabel}>IS</div>
              <div className={styles.isText}>{proposed}</div>
            </div>
          </div>
          <div className={styles.memo}>{memo}</div>
        </div>
      </div>
      <div className={styles.requirementContainer}>
        <span className={styles.headerText}>Requirement</span>
        <div className={`${styles.requirementCard} ${styles.basicElevation}`}>
          {loading && (
            <div className={styles.loading}>
              <LoadingIndicator />
            </div>
          )}

          {error && <div className={styles.error}>{error}</div>}

          {!loading && !error && (
            <>
              <div className={styles.requirementHeader}>
                <span className={styles.requirementTitle}>
                  {requirementTitle}
                </span>
                <span className={styles.specName}>
                  <Document size={16} />
                  {specificationName}
                </span>
              </div>
              <div className={styles.requirementShallStatement}>
                <QuillContent
                  readOnly
                  placeholder=""
                  delta={extractShallStatementDelta(requirement)}
                />
              </div>
              {(!isEmptyDelta(requirement?.data?.delta?.rationale) ||
                requirement?.rationale) && (
                <div className={styles.requirementRationale}>
                  <div className={styles.rationaleHeader}>Rationale:</div>
                  <QuillContent
                    readOnly
                    placeholder=""
                    delta={extractRationaleDelta(requirement)}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const TABS = {
  [DRAWER_TAB.DETAILS]: DetailsTab,
}

const DrawerTabs = (props) => {
  const { initialTab, changeRequest } = props
  const [selectedTab, setSelectedTab] = useState(initialTab)

  const Tab = TABS[selectedTab]

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsHeader}>
        <button
          className={
            selectedTab === DRAWER_TAB.DETAILS
              ? styles.activeTab
              : styles.inactiveTab
          }
          onClick={() => setSelectedTab(DRAWER_TAB.DETAILS)}
        >
          Details
        </button>
      </div>
      <Tab changeRequest={changeRequest} />
    </div>
  )
}

export interface ChangeRequestDrawerProps {
  initialTab: DRAWER_TAB
  changeRequest: ChangeRequest
}

const ChangeRequestDrawer = (
  props: ChangeRequestDrawerProps & { closeModal: () => void },
) => {
  const { closeModal, changeRequest, initialTab = DRAWER_TAB.DETAILS } = props
  const { displayId, status } = changeRequest

  return (
    <Drawer onClose={closeModal}>
      <div className={styles.container}>
        <span className={styles.title}>{displayId}</span>
        <span>
          <ChangeRequestStatusTag status={status} />
        </span>
        <DrawerTabs initialTab={initialTab} changeRequest={changeRequest} />
      </div>
    </Drawer>
  )
}

export default ChangeRequestDrawer
