import { ListChecked } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import styles from './CreateEvidencePage.module.css'
import EvidenceActivities from './EvidenceActivities'
import EvidenceRecordDetails from './EvidenceRecordDetails'
import {
  CreateEvidenceRequest,
  Evidence,
  createEvidenceRecord,
  updateEvidenceRecord,
  getEvidenceRecord,
} from '../../api/v2/evidence'
import { toastError } from '../../components/toast'
import { EvidenceType } from '../../types/enums'

interface RouteParams extends Record<string, string | undefined> {
  evidenceId?: string
}

const CreateEvidencePage = () => {
  const { evidenceId } = useParams<RouteParams>()
  const [urlParams] = useSearchParams()
  const [isRecordLocked, setIsRecordLocked] = useState(false)
  const [isEditingRecord, setIsEditingRecord] = useState(false)
  const [evidenceRecord, setEvidenceRecord] = useState<CreateEvidenceRequest>({
    type: EvidenceType.Validation,
    method: '',
    program: '',
    title: '',
    cadences: [],
    description: '',
    complianceStatement: '',
  })
  const [savedEvidenceRecord, setSavedEvidenceRecord] = useState<Evidence>()

  useEffect(() => {
    const loadEvidence = async () => {
      if (!evidenceId) {
        return
      }
      const evidence = await getEvidenceRecord(evidenceId)

      const isNavEdit = urlParams.get('edit') === 'true'
      if (isNavEdit) {
        setIsEditingRecord(true)
        setIsRecordLocked(false)
      } else {
        setIsRecordLocked(true)
      }

      setEvidenceRecord({
        type: evidence.type,
        method: evidence.method,
        program: evidence.program,
        title: evidence.title,
        cadences: evidence.cadences,
        description: evidence.description,
        complianceStatement: evidence.complianceStatement,
      })
      setSavedEvidenceRecord(evidence)
    }

    loadEvidence()
  }, [evidenceId, urlParams])

  const isRecordValid = () => {
    const requiredFields = ['type', 'method', 'title']
    for (const field of requiredFields) {
      if (!evidenceRecord[field]) {
        toastError(
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
          '',
        )
        return false
      }
    }
    return true
  }

  const onRecordCreate = async () => {
    if (!isRecordValid()) {
      return
    }
    try {
      const newEvidenceRecord = await createEvidenceRecord(evidenceRecord)
      setSavedEvidenceRecord(newEvidenceRecord)
      setIsRecordLocked(true)
    } catch (error) {
      toastError('Error creating evidence record', '')
      console.error('Could not create evidence record', error)
      captureException(error)
    }
  }

  const onRecordUpdate = async () => {
    if (!isRecordValid()) {
      return
    }
    try {
      const updatedEvidenceRecord = await updateEvidenceRecord(
        savedEvidenceRecord?.id ?? '',
        evidenceRecord,
      )
      setSavedEvidenceRecord(updatedEvidenceRecord)
      setIsRecordLocked(true)
      setIsEditingRecord(false)
    } catch (error) {
      toastError('Error saving evidence record', '')
      console.error('Could not update evidence record', error)
      captureException(error)
    }
  }

  return (
    <>
      <Link
        className={styles.returnLink}
        to={`/evidence/records/${savedEvidenceRecord?.id}`}
      >
        <ListChecked />
        Return to evidence
      </Link>
      <div className={styles.container}>
        <EvidenceRecordDetails
          isLocked={isRecordLocked}
          setIsLocked={setIsRecordLocked}
          isEditing={isEditingRecord}
          setIsEditing={setIsEditingRecord}
          onUpdate={onRecordUpdate}
          onCreate={onRecordCreate}
          evidenceRecord={evidenceRecord}
          setEvidenceRecord={setEvidenceRecord}
          savedEvidenceRecord={savedEvidenceRecord}
        />
        <EvidenceActivities evidenceId={savedEvidenceRecord?.id} />
      </div>
    </>
  )
}

export default CreateEvidencePage
