import api from '../api.ts'

type V2ApiMethod = (path: string, options?: any) => any

type V2ApiMethods = {
  get: V2ApiMethod
  getBlob: V2ApiMethod
  getBlobWithHeaders: V2ApiMethod
  post: V2ApiMethod
  postRaw: V2ApiMethod
  put: V2ApiMethod
  putRaw: V2ApiMethod
  patch: V2ApiMethod
  delete: V2ApiMethod
  getUnauthenticated: V2ApiMethod
  postUnauthenticated: V2ApiMethod
}

const withV2 = (method) => (path, options) => method(path, options, true)

export const usingPublicTenant =
  (method: V2ApiMethod): V2ApiMethod =>
  (path, options) =>
    method(path, {
      ...options,
      headers: {
        ...options?.headers,
        'X-Stell-Tenant-Id': '00000000-0000-0000-0000-000000000000',
      },
    })

export default Object.keys(api).reduce(
  (accumulator, key) => ({ ...accumulator, [key]: withV2(api[key]) }),
  {},
) as V2ApiMethods
