import { Compass, Settings } from '@carbon/icons-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AVATAR_COLORS,
  AVATAR_SIZES,
  AvatarColor,
  AvatarSize,
} from './constants.ts'
import styles from './index.module.css'
import { useAuth } from '../../context/AuthContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { getInitials, getInitialsFromFullName } from '../../lib/user.ts'
import Button from '../button'
import Dropdown from '../dropdown'

const UserDropdown = (props) => {
  const { isOpen, onSignout, setIsOpen } = props

  return (
    <Dropdown className={styles.dropdown} isOpen={isOpen}>
      <Link
        className={styles.link}
        to="/settings"
        onClick={() => setIsOpen(false)}
      >
        <Settings /> Settings
      </Link>
      <Link
        className={styles.link}
        to="/guide"
        onClick={() => setIsOpen(false)}
      >
        <Compass /> User Guide
      </Link>
      <Button
        className={styles.signOut}
        text="Sign Out"
        onClick={() => {
          onSignout()
          setIsOpen(false)
        }}
      />
    </Dropdown>
  )
}

interface AvatarProps {
  size?: AvatarSize
  fullName?: string
  firstName?: string
  lastName?: string
  showName?: boolean
  color?: AvatarColor
  img?: any
  fontColor?: string
  border?: 'gradient' | 'solid'
  dropdown?: boolean
}

const Avatar = (props: AvatarProps) => {
  const {
    size: sizeProp,
    fullName,
    firstName,
    lastName,
    showName,
    color,
    img,
    fontColor,
    border = 'gradient',
    dropdown: displayDropdownProp,
  } = props

  const size = AVATAR_SIZES[sizeProp || AvatarSize.Large]
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const containerRef = useClickOutside(() => {
    if (isDropdownOpen) {
      setIsDropdownOpen(!isDropdownOpen)
    }
  }, isDropdownOpen)
  const { signOut } = useAuth()

  let initials: string | undefined

  if (firstName && lastName) {
    initials = getInitials(firstName, lastName)
  } else if (fullName) {
    initials = getInitialsFromFullName(fullName)
  }

  const displayName =
    firstName && lastName ? `${firstName} ${lastName}` : fullName

  return (
    <div ref={containerRef} className={`${styles.container} ${styles[size]}`}>
      <button
        className={`${styles.avatar} ${
          img || color !== undefined
            ? ''
            : border === 'solid'
              ? styles.avatarBorder
              : styles.gradientAvatarBorder
        }`}
        style={{
          backgroundColor:
            img || color === undefined ? undefined : AVATAR_COLORS[color],
          pointerEvents: displayDropdownProp ? undefined : 'none',
          color: fontColor ? fontColor : '#838080',
        }}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen)
        }}
      >
        {initials}
      </button>
      {showName && displayName && (
        <span className={styles.name}>{displayName}</span>
      )}
      {displayDropdownProp && (
        <UserDropdown
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          onSignout={signOut}
        />
      )}
    </div>
  )
}

export default Avatar
