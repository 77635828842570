import api from './api.ts'

export interface SpecificationNodeData {
  id: string
  name: string
  identifier: string
}

export interface SpecificationTreeNode {
  id: string
  specificationTreeId: string
  specification: SpecificationNodeData
  children: SpecificationTreeNode[]
}

export const getSpecificationTreeByProgramId = async (
  programId: string,
): Promise<SpecificationTreeNode> => {
  const { specificationTrees } = await api.get(
    `/api/v2/programs/${programId}/specifications_trees`,
  )
  return {
    // Placeholder node is hidden; used to combine trees within program
    id: 'placeholder',
    specificationTreeId: '',
    specification: {
      id: '',
      name: '',
      identifier: '',
    },
    children: specificationTrees,
  }
}

export const createSpecificationNode = (
  programId: string,
  specificationId: string,
  parentNodeId?: string,
  specificationTreeId?: string,
): Promise<SpecificationTreeNode> =>
  api.post(`/api/v2/programs/${programId}/specifications_trees/nodes`, {
    body: {
      parentNodeId,
      specificationId,
      specificationTreeId,
    },
  })

export const deleteSpecificationNode = (
  programId: string,
  nodeId: string,
  specificationTreeId: string,
): Promise<{ id: string }> =>
  api.delete(
    `/api/v2/programs/${programId}/specifications_trees/${specificationTreeId}/nodes/${nodeId}`,
  )
