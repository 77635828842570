import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import styles from './SpecificationPermissions.module.css'
import SpecificationPermissionsUserRow, {
  SpecificationPermissionsUser,
} from './SpecificationPermissionsUserRow.tsx'
import * as api from '../../api/v2/specifications.ts'
import { Specification } from '../../api/v2/specifications.ts'
import * as teamsApi from '../../api/v2/teams.ts'
import * as usersApi from '../../api/v2/users.ts'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import { getInitials, sortByFirstThenLastName } from '../../lib/user.ts'
import { EntityRole, UserRole } from '../../types/enums.ts'

const SpecificationPermissions = () => {
  const [specification] = useOutletContext() as [Specification]
  const [users, setUsers] = useState<SpecificationPermissionsUser[]>([])
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const { userIsOwner } = useAuth()
  const canChangeRoles = userIsOwner(specification.id)

  useEffect(() => {
    const loadUsers = async () => {
      setLoading(true)
      setError(undefined)
      try {
        const [userResponses, userIdToPermissions, teamsResponse] =
          await Promise.all([
            usersApi.getAllUsers(),
            api.getUserRolesForSpecification(specification.id),
            teamsApi.getTeams(),
          ])

        const orgRoleToId = teamsResponse.teams.reduce(
          (acc, team) => {
            acc[team.name] = team.id
            return acc
          },
          {} as Record<UserRole, string>,
        )

        const users = (userResponses?.users || []).map((userResponse) => ({
          userId: userResponse.id,
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          fullName: `${userResponse.firstName} ${userResponse.lastName}`,
          initials: getInitials(userResponse.firstName, userResponse.lastName),
          email: userResponse.email,
          specRole: userIdToPermissions[userResponse.id]?.role,
          orgRole: userResponse.teamIds.includes(orgRoleToId[UserRole.ROOT])
            ? UserRole.ROOT
            : userResponse.teamIds.includes(orgRoleToId[UserRole.ADMIN])
              ? UserRole.ADMIN
              : undefined,
        }))

        users.sort(sortByFirstThenLastName)
        setUsers(users)
      } catch (error) {
        setError(true)
        console.error('Failed to load user permissions', error)
        captureException(error)
      } finally {
        setLoading(false)
      }
    }

    loadUsers()
  }, [specification.id])

  const updateUserRole = (
    user: SpecificationPermissionsUser,
    role?: EntityRole,
  ) => {
    if (!users) {
      console.warn('No users to update')
      return
    }

    setUsers((previousUsers) =>
      previousUsers.map((u) =>
        u.userId === user.userId ? { ...u, specRole: role } : u,
      ),
    )
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <span>Unable to load specification permissions.</span>
      </div>
    )
  }

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    )
  }

  return (
    <div className={styles.specificationPermissions}>
      <div className={styles.userPermissions}>
        <table className={styles.permissionsTable}>
          <tbody>
            {users.map((user) => (
              <SpecificationPermissionsUserRow
                key={user.userId}
                specificationId={specification.id}
                user={user}
                onSpecRoleChange={(role) => updateUserRole(user, role)}
                readOnly={!canChangeRoles}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SpecificationPermissions
