import { useState } from 'react'
import {
  Outlet,
  useLoaderData,
  useNavigation,
  useParams,
} from 'react-router-dom'
import styles from './SpecificationControlsPage.module.css'
import { Specification } from '../../api/v2/specifications.ts'
import LinkTabs from '../../components/link-tabs/LinkTabs.tsx'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'

const SpecificationControlsPage = () => {
  const { id } = useParams()
  const [specification, setSpecification] = useState<Specification>(
    useLoaderData() as Specification,
  )
  const navigation = useNavigation()
  const loading = navigation.state === 'loading'

  return (
    <div className={styles.specControlsPage}>
      <h1>Specification controls</h1>
      <div>
        <LinkTabs
          tabs={[
            {
              name: 'Settings',
              path: `/specifications/${id}/settings/settings`,
            },
            {
              name: 'History',
              path: `/specifications/${id}/settings/history`,
            },
          ]}
        />
        <div>
          {loading ? (
            <div className={styles.loadingContainer}>
              <LoadingIndicator />
            </div>
          ) : (
            <Outlet context={[specification, setSpecification]} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SpecificationControlsPage
