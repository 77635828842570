import {
  CSSProperties,
  KeyboardEventHandler,
  useCallback,
  ReactNode,
  useState,
} from 'react'

interface HoverableSpanProps {
  text: string
  readOnly?: boolean
  hoverContent?: ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick?: () => void
  onKeyPress?: KeyboardEventHandler<HTMLSpanElement>
  style?: CSSProperties
  className?: string
  role?: string
  tabIndex?: number
}

const HoverableSpan = (props: HoverableSpanProps) => {
  const {
    text,
    hoverContent,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    onClick = () => {},
    onKeyPress,
    readOnly = true,
    style = {},
    className = '',
  } = props

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = useCallback(() => {
    if (!readOnly) {
      setIsHovered(true)
      onMouseEnter()
    }
  }, [readOnly, onMouseEnter])

  const handleMouseLeave = useCallback(() => {
    if (!readOnly) {
      setIsHovered(false)
      onMouseLeave()
    }
  }, [readOnly, onMouseLeave])

  const handleClick = useCallback(() => {
    onClick()
  }, [onClick])

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLSpanElement>) => {
      if (!readOnly) {
        if (onKeyPress) {
          onKeyPress(event)
        } else if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          handleClick()
        }
      }
    },
    [handleClick, onKeyPress, readOnly],
  )

  return (
    <span
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={readOnly ? undefined : handleClick}
      onKeyDown={readOnly ? undefined : handleKeyPress}
      style={{ ...style, cursor: readOnly ? 'default' : 'pointer' }}
      className={className}
      role={readOnly ? 'text' : 'button'}
      tabIndex={0}
    >
      {text}
      {hoverContent && !readOnly && (
        <span
          style={{
            marginLeft: '5px',
            opacity: isHovered && !readOnly ? 1 : 0,
            transition: 'opacity 0.1s ease-in-out',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          {hoverContent}
        </span>
      )}
    </span>
  )
}

export default HoverableSpan
