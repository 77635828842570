import { Add, SendAlt } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useCallback, useEffect, useState } from 'react'
import globalStyles from './index.module.css'
import Modal from './index.tsx'
import styles from './ManageSharedSpecReviewersModal.module.css'
import * as projectsApi from '../../api/v2/projects.ts'
import * as usersApi from '../../api/v2/users.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import { getFullName, sortByFirstThenLastName } from '../../lib/user.ts'
import { getOrgProfiles } from '../../pages/shared-projects/SharedProjectsPage.tsx'
import { AvatarSize } from '../avatar/constants.ts'
import Avatar from '../avatar/index.tsx'
import Checkbox from '../input/Checkbox.tsx'
import { toastError, toastSuccess } from '../toast/index.tsx'

export interface ManageSharedSpecReviewersModalProps {
  specificationSnapshot: projectsApi.SpecificationSnapshot
  reloadReviews: () => void
}

const ManageSharedSpecReviewersModal = (
  props: ManageSharedSpecReviewersModalProps & { closeModal: () => void },
) => {
  const { specificationSnapshot, reloadReviews, closeModal } = props

  const [availableUsers, setAvailableUsers] = useState<usersApi.User[]>([])
  const [filteredReviewers, setFilteredReviewers] = useState<usersApi.User[]>(
    [],
  )
  const [externalUsers, setExternalUsers] = useState<usersApi.User[]>([])
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [initialUserIds, setInitialUserIds] = useState<string[]>([])
  const [noInitialUsers, setNoInitialUsers] = useState<boolean>(false)
  const [sharedWithTenant, setSharedWithTenant] = useState<string>()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    const loadUsers = async () => {
      const project = await projectsApi.getProject(
        specificationSnapshot.project.id,
      )

      const profiles = await getOrgProfiles(project)

      setSharedWithTenant(
        profiles?.sharedWith?.displayName ||
          project?.sharedWithTenant?.name ||
          '',
      )

      const { users } = await projectsApi.getProjectUsers(
        specificationSnapshot.project.id,
      )
      setExternalUsers(users)
      const tenantUsers = (await usersApi.getAllUsers())?.users || []

      const currentReviewers = await projectsApi.getRequestedReviewers(
        specificationSnapshot.project.id,
        specificationSnapshot.id,
      )

      // Stell admin is a special root user that should not display in the set
      // of options to add as a reviewer.
      const availableReviewers = users
        .concat(tenantUsers)
        .filter((user) => user.userName != 'admin@root.com')
        .sort(sortByFirstThenLastName)

      const initialSelectedUserIds = currentReviewers.users.map(
        (reviewer: usersApi.User) => reviewer.id,
      )
      setInitialUserIds(initialSelectedUserIds)
      setSelectedUserIds(initialSelectedUserIds)
      setNoInitialUsers(initialSelectedUserIds.length === 0)
      setAvailableUsers(availableReviewers)
      setFilteredReviewers(availableReviewers)
    }

    loadUsers()
  }, [closeModal, specificationSnapshot.id, specificationSnapshot.project.id])

  const onSelectChange = (reviewer: usersApi.User, selected: boolean) =>
    setSelectedUserIds(
      selected
        ? [...selectedUserIds, reviewer.id]
        : selectedUserIds.filter((id) => id !== reviewer.id),
    )

  const hasChanges = useCallback(() => {
    return (
      selectedUserIds.length !== initialUserIds.length ||
      !selectedUserIds.every((id) => initialUserIds.includes(id))
    )
  }, [initialUserIds, selectedUserIds])

  const handleSearch = (query: string) => {
    if (!availableUsers) {
      setFilteredReviewers([])
      return
    }
    const filtered = availableUsers.filter((user) =>
      getFullName(user.firstName, user.lastName)
        .toLowerCase()
        .includes(query.toLowerCase()),
    )
    setFilteredReviewers(filtered)
  }

  const assignReviewers = useCallback(async () => {
    setSubmitting(true)
    try {
      await projectsApi.setReviewers(
        specificationSnapshot.project.id!,
        specificationSnapshot.id!,
        selectedUserIds,
      )
      toastSuccess(
        selectedUserIds.length === 0 && !noInitialUsers
          ? 'Reviewers removed'
          : 'Reviewers updated',
      )
      reloadReviews()
      closeModal()
    } catch (error) {
      console.error('Unable to update revision users', error)
      toastError('Unable to submit review request', 'Try again later')
      captureException(error)
    } finally {
      setSubmitting(false)
    }
  }, [
    specificationSnapshot.project.id,
    specificationSnapshot.id,
    selectedUserIds,
    noInitialUsers,
    closeModal,
    reloadReviews,
  ])

  return (
    <Modal
      title="Manage Shared Specification Reviewers"
      icon={<Add size={20} />}
      onClose={closeModal}
    >
      <div className={styles.specificationReviewers}>
        Select users to review the shared specification. Removing any reviewers
        will permanently delete their associated review data.
        <div
          className={`${styles.reviewerList} ${globalStyles.basicElevation}`}
        >
          <div className={styles.reviewerSearch}>
            <input
              className={`${styles.input} ${styles.basicElevation}`}
              placeholder="Type to find users"
              onChange={(event) => handleSearch(event.target.value)}
            />
          </div>
          {filteredReviewers.map((reviewer) => {
            const isExternalUser = externalUsers.some(
              (user) => user === reviewer,
            )
            return (
              <div key={reviewer.id} className={styles.reviewer}>
                <Avatar
                  firstName={reviewer.firstName}
                  lastName={reviewer.lastName}
                  showName
                  size={AvatarSize.MediumSmall}
                />
                {isExternalUser && (
                  <span className={styles.externalUser}>
                    · {sharedWithTenant}
                  </span>
                )}
                <div className={styles.checkbox}>
                  <Checkbox
                    checked={selectedUserIds.includes(reviewer.id)}
                    onChange={(checked: boolean) =>
                      onSelectChange(reviewer, checked)
                    }
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className={styles.actions}>
          <Button
            onClick={() => assignReviewers()}
            text={
              selectedUserIds.length === 0 && !noInitialUsers
                ? 'Remove All Reviewers'
                : 'Update Reviewers'
            }
            endIcon={<SendAlt />}
            color={BUTTON_COLORS.PRIMARY}
            disabled={!hasChanges() || submitting}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ManageSharedSpecReviewersModal
