import styles from './index.module.css'
import LegalAccordion from './LegalAccordion.tsx'
import SpecificationAccordion from './SpecificationAccordion.tsx'

const UserGuidePage = () => {
  return (
    <div className={styles.container}>
      <h1>Stell Product User Guide</h1>
      <h2 className={styles.spacing}>Specifications</h2>
      <p className={styles.extraSpacing}>
        A specification document outlines the requirements and guidelines for a
        project, process, or system. This may include the technical
        specifications for the product or system, as well as any relevant safety
        and regulatory requirements. In Stell, a specification is any set of
        requirements.
      </p>
      <p className={styles.extraSpacing}>
        Each Specification is designed to be viewed in both Document and Matrix
        view.
      </p>
      <img
        className={styles.guideImage}
        src={'/images/user-guide/header.png'}
        alt="Specification header"
      />
      <p className={styles.extraSpacing}>
        In each Specification there are some elements in the heading that you
        should familiarize yourself with:
      </p>
      <p className={styles.extraSpacing}>
        <strong>Document Number</strong> - this is assigned at time of creation
        of a Specification, and must be a unique number in the tenant. This
        document number is used to generate unique IDs for each of the
        requirements in this Specification, and therefore in the User’s tenant
        as well.
      </p>
      <p className={styles.extraSpacing}>
        <strong>Document Title</strong> - this is the document title, which is
        assigned at time of creation of a Specification. It should help the User
        identify the contents of the Specification at a high level.
      </p>
      <p className={styles.extraSpacing}>
        <strong>Program Tag</strong> - this tag can be used to help categorize
        your Specifications. You can designate a Specification’s program in the
        Specification home page. Program tags can be configured at the tenant
        level by the tenant Admin user within the tenant’s Settings page.
      </p>
      <p className={styles.extraSpacing}>
        <strong>Document View</strong> is where Users can read the text of a
        Specification in its full context, complete with images, tables etc. All
        Non-Requirement blocks are displayed here along with Requirement blocks.
      </p>
      <p className={styles.extraSpacing}>
        <strong>Matrix View</strong> s where you can take action against your
        requirements. Flipping from Document to Matrix view, you should notice
        that only the Requirements will appear here. It presents a quick way to
        manage the attributes of your requirements, but also to link them to
        each other (via Parent/Child relationships) and attach Verification &
        Validation artifacts to them. Use the Display button in the top left to
        show or hide columns.
      </p>
      <SpecificationAccordion />
      <h2 className={styles.extraSpacing}>Legal</h2>
      <LegalAccordion />
    </div>
  )
}

export default UserGuidePage
