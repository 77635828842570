import {
  AddComment,
  CaretDown,
  CheckmarkFilled,
  CircleDash,
  Document,
  ErrorFilled,
  Maximize,
  OverflowMenuHorizontal,
} from '@carbon/icons-react'
import {
  ComponentType,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SharedRequirementColumns.module.css'
import { UUID } from '../../api/utilityTypes'
import { ReviewStatus, SnapshotRequirementComment } from '../../api/v2/projects'
import { DRAWER_TAB } from '../../components/drawer/enums.ts'
import Dropdown from '../../components/dropdown'
import Popover from '../../components/popover/Popover.tsx'
import { TableColumn, TableItem } from '../../components/table/Table'
import Tag, { stylesToTagColor } from '../../components/tag'
import { useAuth } from '../../context/AuthContext'
import {
  SnapshotReviewWithReviewer,
  useSharedSpecificationContext,
} from '../../context/SharedSpecificationContext'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { useModals } from '../../hooks/useModals'
import { entries } from '../../lib/utils.ts'
import { Filter } from '../search/display-filter'

type ExpandingTextProps = {
  isExpanded: boolean
  onExpand: () => void
  text: string
}

const ExpandingText = ({ isExpanded, onExpand, text }: ExpandingTextProps) => {
  return (
    <div className={isExpanded ? styles.expanded : ''}>
      {isExpanded ? (
        text
      ) : (
        <button
          onClick={onExpand}
          className={`${styles.textBlock} ${isExpanded ? styles.expanded : ''}`}
        >
          {text}
        </button>
      )}
    </div>
  )
}

type ExpandingTextListProps = {
  isExpanded: boolean
  onExpand: () => void
  textList: string[]
}

const ExpandingTextList = ({
  isExpanded,
  onExpand,
  textList,
}: ExpandingTextListProps) => {
  return (
    <div className={isExpanded ? styles.expanded : ''}>
      {isExpanded ? (
        <ul className={styles.listWrapper}>
          {textList.map((text) => (
            <li className={styles.listText}>{text}</li>
          ))}
        </ul>
      ) : (
        <button onClick={onExpand} className={styles.firstItem}>
          {textList[0]}
        </button>
      )}
    </div>
  )
}

type OpenReviewDrawerButtonProps = {
  id: UUID
  sectionNumber: string
  shallStatement: string
  title: string
  children: ReactNode
  onClick?: () => void
  className?: string
}

const OpenReviewDrawerButton = ({
  id,
  sectionNumber,
  shallStatement,
  title,
  children,
  onClick,
  className,
}: OpenReviewDrawerButtonProps) => {
  const {
    addRequirementComment,
    deleteRequirementComment,
    toggleResolveRequirementComment,
    commentsByRequirementId,
    requirementIdtoReviews,
    updateRequirementReview,
    specificationSnapshot,
  } = useSharedSpecificationContext()
  const { openSnapshotReviewDrawer, closeModal } = useModals()
  const { userDetails } = useAuth()

  return (
    <button
      className={className || ''}
      onClick={() => {
        onClick?.()
        openSnapshotReviewDrawer({
          updateRequirementReview,
          requirement: {
            id,
            sectionNumber,
            shallStatement,
            title,
          },
          requirementIdtoReviews,
          commentsByRequirementId,
          addRequirementComment,
          deleteRequirementComment,
          toggleResolveRequirementComment,
          closeModal,
          initialTab: DRAWER_TAB.COMMENTS,
          userId: userDetails?.id || '',
          specificationSnapshot,
        })
      }}
    >
      {children}
    </button>
  )
}

const ActionsCell: ComponentType<TableItem> = ({ data }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const ref = useClickOutside(() => setOpen(false), open)

  return (
    <div ref={ref} className={styles.actions}>
      <button onClick={() => setOpen(!open)}>
        <OverflowMenuHorizontal />
      </button>
      <Dropdown className={styles.dropdown} isOpen={open}>
        <button
          className={styles.actionItem}
          onClick={() => {
            navigate(
              `/projects/${data?.snapshot.project.id}/snapshots/${data?.snapshot.id}/document/${data?.id}`,
            )
          }}
        >
          <Document />
          View in document
        </button>
      </Dropdown>
    </div>
  )
}

const IncrementalIdCell: ComponentType<TableItem> = ({ data, isExpanded }) => {
  return (
    <div className={isExpanded ? styles.expanded : ''}>{data.index + 1}</div>
  )
}

const IdCell: ComponentType<TableItem> = ({ data, isExpanded }) => {
  return (
    <div className={isExpanded ? styles.expanded : ''}>
      {data.sectionNumber}
    </div>
  )
}

const NameCell: ComponentType<TableItem> = ({ data, isExpanded }) => {
  return (
    <div className={`${styles.title} ${isExpanded ? styles.expanded : ''}`}>
      {data.title}
    </div>
  )
}

const StatementCell: ComponentType<TableItem> = ({
  data,
  isExpanded,
  onExpand,
}) => {
  return (
    <div className={isExpanded ? styles.expanded : ''}>
      {isExpanded ? (
        data.shallStatement
      ) : (
        <button
          onClick={onExpand}
          className={`${styles.textBlock} ${isExpanded ? styles.expanded : ''}`}
        >
          {data.shallStatement}
        </button>
      )}
    </div>
  )
}

const RationaleCell: ComponentType<TableItem> = ({
  data,
  isExpanded,
  onExpand,
}) => {
  return (
    <ExpandingText
      isExpanded={isExpanded!}
      onExpand={onExpand!}
      text={data.rationale}
    />
  )
}

const TypeCell: ComponentType<TableItem> = ({ data, isExpanded, onExpand }) => {
  const tags = data.types.map((t, idx) => (
    <Tag key={idx} text={t.text} color={stylesToTagColor(t.style)} />
  ))

  const [first, ...rest] = tags

  return (
    <button
      className={`${styles.multiTag} ${isExpanded ? styles.expanded : ''}`}
      onClick={onExpand}
    >
      {tags.length === 0 && null}
      {tags.length > 0 && (
        <>
          <div className={styles.tagList}>
            {first}
            {isExpanded && rest}{' '}
          </div>
          <div>{isExpanded || rest.length === 0 ? null : <CaretDown />} </div>
        </>
      )}
    </button>
  )
}
// might need to move this if the review state is getting used in a bunch of places
// right now its just here and in drawer

// Order of This Objects determines order display in All Reviews column
export const reviewStates: Record<
  ReviewStatus,
  { component: ReactElement; label: string }
> = {
  EMPTY: {
    component: <CircleDash className={styles.pending} />,
    label: 'Pending',
  },
  APPROVED: {
    component: <CheckmarkFilled className={styles.approved} />,
    label: 'Approved',
  },
  NOT_APPLICABLE: {
    component: <ErrorFilled className={styles.notApplicable} />,
    label: 'Not Applicable',
  },
  APPROVED_WITH_COMMENTS: {
    component: <CheckmarkFilled className={styles.approvedWithComments} />,
    label: 'Approved with comments',
  },
  REJECTED: {
    component: <ErrorFilled className={styles.rejected} />,
    label: 'Rejected',
  },
}

const MyReviewCell: ComponentType<TableItem> = (props) => {
  const {
    data: { id, sectionNumber, shallStatement, title, reviews },
    isExpanded,
  } = props

  const { userDetails } = useAuth()
  const { updateRequirementReview } = useSharedSpecificationContext()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const currentReview: SnapshotReviewWithReviewer | null =
    reviews?.filter((r) => r.reviewer.userId === userDetails?.id)?.[0] || null

  if (currentReview === null) {
    return <></>
  }

  const popoverContent = (
    <div className={styles.popoverContentMyReview}>
      <div className={styles.selectReviewStatus}>
        {entries(reviewStates).map(([state, stuff]) => (
          <button
            key={state}
            className={`${styles.selectStatusBtn} ${
              currentReview.status === state ? styles.selected : ''
            }`}
            onClick={() => {
              if (currentReview.status === state) {
                return
              }
              updateRequirementReview(currentReview.id, id, state)
            }}
            disabled={currentReview.status === state}
          >
            {stuff.component}
            {stuff.label}
          </button>
        ))}
      </div>
      <div className={styles.openReviewDrawer}>
        <OpenReviewDrawerButton
          id={id}
          sectionNumber={sectionNumber}
          shallStatement={shallStatement}
          title={title}
          className={styles.openDrawerBtn}
        >
          <span>See more</span>
          <Maximize size={12} />
        </OpenReviewDrawerButton>
      </div>
    </div>
  )

  return (
    <>
      <div
        className={`${styles.cell} ${styles.myReview} ${
          isExpanded ? styles.expanded : ''
        } ${isPopoverOpen ? styles.popoverOpen : 'nope'}`}
      >
        <Popover
          content={popoverContent}
          className={styles.popoverMyReview}
          classNamePopoverContainer={styles.popoverContainerMyReview}
          onVisibilityChange={(isOpen) => setIsPopoverOpen(isOpen)}
          placement={'offsetBottomRight'}
        >
          <div className={styles.reviewStatus}>
            {reviewStates[currentReview.status].component}
          </div>
        </Popover>
      </div>
    </>
  )
}

const AllReviewsCell: ComponentType<TableItem> = ({
  data: { id, sectionNumber, shallStatement, title, reviews },
  isExpanded,
}) => {
  type ReviewStatusData = {
    count: number
    users: string[]
  }

  const [summaryDropdownIsOpen, setSummaryDropdownIsOpen] = useState(false)

  const closeDropdown = useCallback(() => {
    if (summaryDropdownIsOpen) {
      setSummaryDropdownIsOpen(false)
    }
  }, [summaryDropdownIsOpen])

  const summaryDropdownRef = useClickOutside(
    closeDropdown,
    summaryDropdownIsOpen,
  )

  const statusCounts = useMemo(
    () =>
      (reviews || []).reduce(
        (acc: Record<ReviewStatus, ReviewStatusData>, review) => {
          const status = review.status
          const reviewerName = `${review.reviewer.firstName} ${review.reviewer.lastName}`

          if (!acc[status]) {
            acc[status] = {
              count: 0,
              users: [],
            }
          }

          acc[status].count += 1
          acc[status].users.push(reviewerName)

          return acc
        },
        {} as Record<ReviewStatus, ReviewStatusData>,
      ),
    [reviews],
  )

  return (
    <div
      ref={summaryDropdownRef}
      className={`${styles.allReviews} ${isExpanded ? styles.expanded : ''}`}
    >
      <button onClick={() => setSummaryDropdownIsOpen(!summaryDropdownIsOpen)}>
        <div className={styles.allStatuses}>
          {Object.keys(reviewStates).map((key) => {
            const status = key as ReviewStatus
            const reviewData = statusCounts[status] || { count: 0, users: [] }
            return (
              <div
                key={key}
                className={styles.statusGroup}
                style={{ opacity: reviewData.count ? 1 : 0.2 }}
              >
                <div>{reviewStates[key as ReviewStatus].component}</div>
                <div>{reviewData.count}</div>
              </div>
            )
          })}
          <CaretDown />
        </div>
      </button>
      <Dropdown
        className={styles.statusDropdown}
        isOpen={summaryDropdownIsOpen}
      >
        {Object.entries(statusCounts).length ? (
          (
            Object.entries(statusCounts) as Array<
              [ReviewStatus, ReviewStatusData]
            >
          ).map(([status, data]) => {
            const { users } = data
            const remainingCount = users.length - 2
            const displayNames =
              users.length > 2
                ? `${users.slice(0, 2).join(', ')} + ${remainingCount} other${
                    remainingCount === 1 ? '' : 's'
                  }`
                : users.join(', ')

            return (
              <div key={status} className={styles.summaryCard}>
                <div className={styles.status}>
                  {reviewStates[status].component} {reviewStates[status].label}
                </div>
                <div className={styles.names}>{displayNames}</div>
              </div>
            )
          })
        ) : (
          <div className={styles.summaryCard}>No reviewers added yet</div>
        )}
        <div className={styles.openDrawerButton}>
          <OpenReviewDrawerButton
            id={id}
            sectionNumber={sectionNumber}
            shallStatement={shallStatement}
            title={title}
            onClick={closeDropdown}
          >
            <div className={styles.buttonContent}>
              <span>See More</span> <Maximize />
            </div>
          </OpenReviewDrawerButton>
        </div>
      </Dropdown>
    </div>
  )
}

const MyCommentsCell: ComponentType<TableItem> = (props) => {
  const {
    data: { id, sectionNumber, shallStatement, title },
    isExpanded,
  } = props

  const { userDetails } = useAuth()
  const { commentsByRequirementId } = useSharedSpecificationContext()
  const myComments = (commentsByRequirementId[id] || []).filter(
    (c: SnapshotRequirementComment) => c.createdByUserId === userDetails?.id,
  )

  return (
    <div className={isExpanded ? styles.expanded : ''}>
      <OpenReviewDrawerButton
        id={id}
        sectionNumber={sectionNumber}
        shallStatement={shallStatement}
        title={title}
        className={styles.comments}
      >
        <AddComment size={16} />
        {myComments.length > 0 && myComments.length}
      </OpenReviewDrawerButton>
    </div>
  )
}

const AllCommentsCell: ComponentType<TableItem> = (props) => {
  const {
    data: { id, sectionNumber, shallStatement, title },
    isExpanded,
  } = props
  const { commentsByRequirementId } = useSharedSpecificationContext()
  const comments = commentsByRequirementId[id] || []

  return (
    <div className={isExpanded ? styles.expanded : ''}>
      <OpenReviewDrawerButton
        id={id}
        sectionNumber={sectionNumber}
        shallStatement={shallStatement}
        title={title}
        className={styles.comments}
      >
        <AddComment size={16} />
        {comments.length > 0 && comments.length}
      </OpenReviewDrawerButton>
    </div>
  )
}

const VerificationMethodCell: ComponentType<TableItem> = ({
  data,
  isExpanded,
  onExpand,
}) => {
  const tags = data.evidences.map((ev, idx) => {
    return (
      <Tag
        key={idx}
        text={ev.method.text}
        color={stylesToTagColor(ev.method.style)}
      />
    )
  })

  const [first, ...rest] = tags

  return (
    <button
      className={`${styles.multiTag} ${isExpanded ? styles.expanded : ''}`}
      onClick={onExpand}
    >
      {tags.length === 0 && null}
      {tags.length > 0 && (
        <>
          <div className={styles.tagList}>
            {first}
            {isExpanded && rest}{' '}
          </div>
          <div>{isExpanded || rest.length === 0 ? null : <CaretDown />} </div>
        </>
      )}
    </button>
  )
}

const VerificationTitleCell: ComponentType<TableItem> = ({
  data,
  isExpanded,
  onExpand,
}) => {
  const titles = data.evidences.map((ev) => ev?.title)

  return (
    <ExpandingTextList
      isExpanded={isExpanded!}
      onExpand={onExpand!}
      textList={titles}
    />
  )
}

const VerificationDescriptionCell: ComponentType<TableItem> = ({
  data,
  isExpanded,
  onExpand,
}) => {
  const desc = data.evidences.map((ev) => ev?.description) as string[]

  return (
    <ExpandingTextList
      isExpanded={isExpanded!}
      onExpand={onExpand!}
      textList={desc}
    />
  )
}

export enum SharedRequirementColumnId {
  IncrementalId = 'INCREMENTAL_ID',
  MyReview = 'MY_REVIEW',
  AllReviews = 'ALL_REVIEWS',
  MyComments = 'MY_COMMENTS',
  AllComments = 'ALL_COMMENTS',
  RequirementSectionNumber = 'ID',
  RequirementName = 'TITLE',
  RequirementShallStatement = 'STATEMENT',
  RequirementRationale = 'RATIONALE',
  RequirementType = 'TYPE',
  VerificationMethod = 'VERIFICATION_METHOD',
  VerificationTitle = 'VERIFICATION_TITLE',
  VerificationDescription = 'VERIFICATION_DESCRIPTION',
}

enum SharedRequirementColumnLabels {
  IncrementalId = '',
  MyReview = 'My Review',
  AllReviews = 'All Reviews',
  MyComments = 'My Comments',
  AllComments = 'All Comments',
  ReviewerComments = 'Reviewer Comments',
  RequirementSectionNumber = 'Section Number',
  RequirementName = 'Requirement Name',
  RequirementShallStatement = 'Requirement Statement',
  RequirementRationale = 'Rationale',
  RequirementType = 'Type',
  VerificationMethod = 'Verification Method',
  VerificationTitle = 'Verification Title',
  VerificationDescription = 'Verification Description',
}

export const SharedRequirementColumns = (
  userIsSnapshotReviewer: boolean,
): TableColumn[] => [
  { label: '', transparent: true, Component: ActionsCell },
  {
    label: SharedRequirementColumnId.IncrementalId,
    prettyLabel: SharedRequirementColumnLabels.IncrementalId,
    Component: IncrementalIdCell,
  },
  {
    label: SharedRequirementColumnId.MyReview,
    prettyLabel: SharedRequirementColumnLabels.MyReview,
    Component: MyReviewCell,
  },
  {
    label: SharedRequirementColumnId.MyComments,
    prettyLabel: SharedRequirementColumnLabels.MyComments,
    Component: MyCommentsCell,
  },
  {
    label: SharedRequirementColumnId.AllReviews,
    prettyLabel: SharedRequirementColumnLabels.AllReviews,
    minWidth: 186,
    Component: AllReviewsCell,
  },
  {
    label: SharedRequirementColumnId.AllComments,
    prettyLabel: userIsSnapshotReviewer
      ? SharedRequirementColumnLabels.AllComments
      : SharedRequirementColumnLabels.ReviewerComments,
    Component: AllCommentsCell,
  },
  {
    label: SharedRequirementColumnId.RequirementSectionNumber,
    prettyLabel: SharedRequirementColumnLabels.RequirementSectionNumber,
    Component: IdCell,
  },
  {
    label: SharedRequirementColumnId.RequirementName,
    prettyLabel: SharedRequirementColumnLabels.RequirementName,
    Component: NameCell,
  },
  {
    label: SharedRequirementColumnId.RequirementShallStatement,
    prettyLabel: SharedRequirementColumnLabels.RequirementShallStatement,
    Component: StatementCell,
    width: 'minmax(300px, 1fr)',
  },
  {
    label: SharedRequirementColumnId.RequirementRationale,
    prettyLabel: SharedRequirementColumnLabels.RequirementRationale,
    Component: RationaleCell,
    width: 'minmax(300px, 1fr)',
  },
  {
    label: SharedRequirementColumnId.RequirementType,
    prettyLabel: SharedRequirementColumnLabels.RequirementType,
    minWidth: 128,
    Component: TypeCell,
  },
  {
    label: SharedRequirementColumnId.VerificationMethod,
    prettyLabel: SharedRequirementColumnLabels.VerificationMethod,
    Component: VerificationMethodCell,
  },
  {
    label: SharedRequirementColumnId.VerificationTitle,
    prettyLabel: SharedRequirementColumnLabels.VerificationTitle,
    Component: VerificationTitleCell,
  },
  {
    label: SharedRequirementColumnId.VerificationDescription,
    prettyLabel: SharedRequirementColumnLabels.VerificationDescription,
    Component: VerificationDescriptionCell,
    width: 'minmax(300px, 1fr)',
  },
]

export const SharedSpecificationColumnsFilters = (
  userIsSnapshotReviewer: boolean,
): Filter[] => [
  {
    label: SharedRequirementColumnId.RequirementSectionNumber,
    prettyLabel: SharedRequirementColumnLabels.RequirementSectionNumber,
  },
  {
    label: SharedRequirementColumnId.MyReview,
    prettyLabel: SharedRequirementColumnLabels.MyReview,
  },
  {
    label: SharedRequirementColumnId.AllReviews,
    prettyLabel: SharedRequirementColumnLabels.AllReviews,
  },
  {
    label: SharedRequirementColumnId.MyComments,
    prettyLabel: SharedRequirementColumnLabels.MyComments,
  },
  {
    label: SharedRequirementColumnId.AllComments,
    prettyLabel: userIsSnapshotReviewer
      ? SharedRequirementColumnLabels.AllComments
      : SharedRequirementColumnLabels.ReviewerComments,
  },
  {
    label: SharedRequirementColumnId.RequirementName,
    prettyLabel: SharedRequirementColumnLabels.RequirementName,
  },
  {
    label: SharedRequirementColumnId.RequirementShallStatement,
    prettyLabel: SharedRequirementColumnLabels.RequirementShallStatement,
  },
  {
    label: SharedRequirementColumnId.RequirementRationale,
    prettyLabel: SharedRequirementColumnLabels.RequirementRationale,
  },
  {
    label: SharedRequirementColumnId.RequirementType,
    prettyLabel: SharedRequirementColumnLabels.RequirementType,
  },
  {
    label: SharedRequirementColumnId.VerificationMethod,
    prettyLabel: SharedRequirementColumnLabels.VerificationMethod,
  },
  {
    label: SharedRequirementColumnId.VerificationTitle,
    prettyLabel: SharedRequirementColumnLabels.VerificationTitle,
  },
  {
    label: SharedRequirementColumnId.VerificationDescription,
    prettyLabel: SharedRequirementColumnLabels.VerificationDescription,
  },
]

export const REVIEWER_ONLY_COLUMNS = [
  SharedRequirementColumnId.MyReview,
  SharedRequirementColumnId.MyComments,
]
