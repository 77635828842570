import { useState } from 'react'
import styles from './SnapshotCommentForm.module.css'
import Button, { BUTTON_COLORS } from '../../../components/button'

const SnapshotCommentForm = ({
  requirementId,
  onAddComment,
}: {
  requirementId: string
  onAddComment: (requirementId: string, comment: string) => Promise<boolean>
}) => {
  const [newComment, setNewComment] = useState('')
  const [submitting, setSubmitting] = useState(false)

  return (
    <div className={styles.addComment}>
      <textarea
        placeholder="Add a comment..."
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        rows={4}
      />
      <Button
        text="Add comment"
        color={BUTTON_COLORS.PRIMARY}
        onClick={async () => {
          setSubmitting(true)
          const created = await onAddComment(requirementId, newComment)
          if (created) {
            setNewComment('')
          }
          setSubmitting(false)
        }}
        disabled={!newComment || submitting}
      />
    </div>
  )
}

export default SnapshotCommentForm
