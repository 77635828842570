import { Classification, Home, UserMultiple } from '@carbon/icons-react'
import { useState } from 'react'
import MembersSection from './MembersSection.tsx'
import MyAccountSection from './MyAccountSection.tsx'
import OrganizationSection from './OrganizationSection.tsx'
import styles from './SettingsPage.module.css'
import { TenantTier } from '../../api/v2/tenants.ts'
import { useAuth } from '../../context/AuthContext.tsx'
0

const SettingsPage = () => {
  const { userTenant } = useAuth()
  const [selectedOption, setSelectedOption] = useState('myAccount')

  const displayMenu = {
    myAccount: { text: 'My Account', Icon: Home, Component: MyAccountSection },
    orgMember: {
      text: 'Organization Members',
      Icon: UserMultiple,
      Component: MembersSection,
    },
    orgSettings: {
      text: 'Organization Settings',
      Icon: Classification,
      Component: OrganizationSection,
      props: { showTags: userTenant?.tier === TenantTier.Full },
    },
  }

  const SelectedComponent =
    displayMenu[selectedOption]?.Component || MyAccountSection

  return (
    <div className={styles.page}>
      <h1>Settings</h1>
      <div className={styles.displayWrapper}>
        <div className={styles.settingContentSelector}>
          <ul>
            {Object.entries(displayMenu).map(([option, { Icon, text }]) => (
              <li key={option}>
                <button
                  onClick={() => setSelectedOption(option)}
                  className={
                    selectedOption === option
                      ? styles.selectedOption
                      : styles.defaultOption
                  }
                >
                  <Icon className={styles.sectionIcon} />
                  <p>{text}</p>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.selectedContent}>
          <SelectedComponent {...displayMenu[selectedOption]?.props} />
        </div>
      </div>
    </div>
  )
}
export default SettingsPage
