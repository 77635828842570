import {
  CaretRight,
  Catalog,
  ChangeCatalog,
  ChartVennDiagram,
  DocumentPreliminary,
  Home,
  ListChecked,
  Report,
  Search,
  WatsonHealthCdCreateExchange,
} from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './NavDrawer.module.css'
import { TenantTier } from '../../api/v2/tenants.ts'
import StellLogo from '../../assets/StellLogo'
import StellTextLogo from '../../assets/StellTextLogo'
import { useAuth } from '../../context/AuthContext.tsx'
import { LoadingState } from '../../types/enums.ts'

const NAV_ITEMS_FULL_TIER = [
  { to: '/specifications', Icon: DocumentPreliminary, text: 'Specifications' },
  { to: '/search', Icon: Search, text: 'Search' },
  { to: '/dashboard', Icon: Home, text: 'Dashboard' },
  { to: '/evidence', Icon: ListChecked, text: 'Evidence' },
  { to: '/exceptions', Icon: WatsonHealthCdCreateExchange, text: 'Exceptions' },
  { to: '/change-requests', Icon: ChangeCatalog, text: 'Change Requests' },
  { to: '/reports', Icon: Report, text: 'Reports' },
  {
    to: '/public-specifications',
    Icon: Catalog,
    text: 'Stell Standards Library',
  },
  { to: '/projects', Icon: ChartVennDiagram, text: 'Shared Projects' },
]

const NAV_ITEMS_BASIC_TIER = [
  { to: '/projects', Icon: ChartVennDiagram, text: 'Shared Projects' },
]

const NavDrawer = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const { userTenantLoading, userTenant } = useAuth()
  const location = useLocation()

  const onEsc = useCallback((event) => {
    if (event.key === 'Escape') {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false)

    return () => {
      document.removeEventListener('keydown', onEsc, false)
    }
  }, [onEsc])

  const enableStandardsPortal = useFeatureFlagEnabled('standards-portal')
  const enableSharingPortal = useFeatureFlagEnabled('show-sharing-portal')

  const navItems = (
    userTenant?.tier === TenantTier.Full
      ? NAV_ITEMS_FULL_TIER
      : NAV_ITEMS_BASIC_TIER
  )
    .filter(
      (navItem) =>
        navItem.to !== '/public-specifications' || enableStandardsPortal,
    )
    .filter((navItem) => navItem.to !== '/projects' || enableSharingPortal)

  return (
    <div
      className={`${styles.root} 
      ${isOpen ? styles.lockOpen : ''}
      ${!isOpen && isHovered ? styles.open : ''}
      ${!isOpen && !isHovered ? styles.close : ''}
      `}
    >
      <nav
        className={styles.navContent}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Link
          to="/"
          style={{ all: 'unset', width: 'min-content' }}
          draggable="false"
        >
          <div className={styles.logo}>
            <div className={styles.logoWrapper}>
              <StellLogo width={20} />
            </div>
            <div className={styles.logoWrapper}>
              <StellTextLogo height={20} />
            </div>
          </div>
        </Link>
        <div className={styles.navItems}>
          {userTenantLoading === LoadingState.Loaded &&
            navItems.map((navItem) => {
              const { to, Icon, text } = navItem

              const isCurrentRoute =
                (to === '/' && location.pathname === to) ||
                (to !== '/' && location.pathname.startsWith(to))

              return (
                <Link
                  key={text}
                  to={to}
                  style={{ width: 'min-content' }}
                  draggable="false"
                >
                  <div
                    className={`${styles.linkContent} ${
                      isCurrentRoute ? styles.currentRoute : ''
                    }`}
                  >
                    <div className={styles.icon}>
                      <Icon size={20} />
                    </div>
                    <span className={styles.linkText}>{text}</span>
                  </div>
                </Link>
              )
            })}
        </div>
        <div
          className={`${styles.expandCollapse} ${
            isOpen ? styles.navOpen : styles.navClose
          }`}
        >
          <button
            onClick={() => {
              setIsOpen(!isOpen)
              setIsHovered(false)
            }}
          >
            <CaretRight size={24} />
          </button>
        </div>
      </nav>
    </div>
  )
}

export default NavDrawer
