import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useAuth } from '../../context/AuthContext.tsx'

const AnalyticsCapture = () => {
  const posthog = usePostHog()
  const { userDetails } = useAuth()
  const { email, firstName, lastName, id } = userDetails || {}

  useEffect(() => {
    posthog?.capture('$pageview')

    if (id && id.trim().length > 0) {
      posthog?.identify(id, {
        email,
        firstName,
        lastName,
      })
    } else {
      posthog?.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posthog, userDetails?.id]) // userContext changes are noisy

  return null
}

export default AnalyticsCapture
