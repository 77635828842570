import { Table } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'

const AddTableBlockAction = (props: BlockActionItemProps) => {
  const { block, closeMenu } = props
  const { contentIsEditable } = useSpecificationContext()
  const { createTableBlock } = useSectionContext()

  return (
    contentIsEditable && (
      <button
        className={styles.actionItem}
        onClick={async () => {
          closeMenu()
          await createTableBlock(block?.id)
        }}
      >
        <Table />
        Table
      </button>
    )
  )
}

export default AddTableBlockAction
