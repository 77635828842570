import Drawer from './index.tsx'
import styles from './SearchRequirementDrawer.module.css'
import { SearchRequirementContent } from '../../api/v2/search'
import { useAttributesContext } from '../../context/AttributesContext'
import RequirementDetails from '../../pages/search/requirement-details/RequirementDetails.tsx'

const SearchRequirementDrawer = (props: {
  searchResult: SearchRequirementContent
  closeModal: () => void
  navigateToRequirement: () => void
}) => {
  const { searchResult, closeModal } = props
  const { requirementTypes } = useAttributesContext()
  const types = requirementTypes.filter((serverType) =>
    searchResult.requirement.types.some(
      (reqTypeId) => reqTypeId === serverType.id,
    ),
  )

  return (
    <Drawer onClose={closeModal}>
      <div className={styles.scrollable}>
        <RequirementDetails
          specificationId={searchResult.specification.id}
          revisionId={searchResult.revision.id}
          requirementId={searchResult.requirement.id}
          requirementTypes={types}
        />
      </div>
    </Drawer>
  )
}

export default SearchRequirementDrawer
