import { Checkmark } from '@carbon/icons-react'
import { ReactNode } from 'react'
import styles from './Checkbox.module.css'

const Checkbox = (props: {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  size?: number
  children?: ReactNode
}) => {
  const { checked, onChange, disabled, size = 10, children } = props

  return (
    <label
      className={`${styles.checkboxContainer} ${
        disabled ? styles.disabled : ''
      }`}
    >
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        onChange={(event) => onChange(event.target.checked)}
        disabled={disabled}
      />
      <span
        className={styles.checkmark}
        style={{ height: size + 2, width: size + 2 }}
      >
        {checked && <Checkmark size={size} color="white" />}
      </span>
      {children}
    </label>
  )
}

export default Checkbox
