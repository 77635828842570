import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import styles from './SharedImageBlock.module.css'
import * as assetApi from '../../../api/v2/assets.ts'
import { ImageBlockData } from '../../../api/v2/blocks.ts'
import { SharedBlock } from '../../../api/v2/projects.ts'
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator.tsx'
import { LoadingState } from '../../../types/enums.ts'

const SharedImageBlock = (props: {
  specificationId: string
  specificationSnapshotId: string
  block: SharedBlock<ImageBlockData>
}) => {
  const { specificationId, specificationSnapshotId, block } = props
  const assetId: string | undefined = block?.data?.assetId
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Loading,
  )
  const [src, setSrc] = useState<string>()

  useEffect(() => {
    const loadImageSrc = async () => {
      setLoadingState(LoadingState.Loading)
      if (!assetId) {
        setLoadingState(LoadingState.Failed)
        return
      }

      try {
        const blob = await assetApi.getSpecificationSnapshotAsset(
          specificationId,
          specificationSnapshotId,
          assetId,
        )
        setSrc(URL.createObjectURL(blob || ''))
        setLoadingState(LoadingState.Loaded)
      } catch (error) {
        console.error(`Unable to load image for block ${block.id}`, error)
        captureException(error)
        setLoadingState(LoadingState.Failed)
      }
    }

    loadImageSrc()
  }, [assetId, block.id, specificationId, specificationSnapshotId])

  return (
    <div className={styles.sharedImageBlock}>
      {loadingState === LoadingState.Loading && (
        <div className={styles.loadingContainer}>
          <LoadingIndicator />
        </div>
      )}
      {loadingState === LoadingState.Failed && (
        <div className={styles.errorContainer}>Unable to load image.</div>
      )}
      {loadingState === LoadingState.Loaded && (
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={src}
            alt={`Block Img ${block.id}`}
          />
          <span className={styles.caption}>{block.data.name}</span>
        </div>
      )}
    </div>
  )
}

export default SharedImageBlock
