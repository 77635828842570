import { CarbonIconType } from '@carbon/icons-react/lib/CarbonIcon'
import { RefObject, ReactElement, forwardRef } from 'react'
import styles from './IconButton.module.css'

interface IconButtonProps
  extends Omit<React.ComponentProps<'button'>, 'children'> {
  innerRef?: ((element: HTMLElement | null) => void) | RefObject<any>
  children: ReactElement<CarbonIconType>
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, ref) => {
    const { children, className, ...otherProps } = props

    return (
      <button
        className={`${styles.button} ${className ? className : ''}`}
        ref={ref}
        {...otherProps}
      >
        {children}
      </button>
    )
  },
)

export default IconButton
