import { useEffect, useState } from 'react'
import { useModals } from './useModals.ts'
import { useAuth } from '../context/AuthContext.tsx'
import { getAuthTokenExpiryTime } from '../lib/localstorage.ts'

const WARNING_BEFORE_EXPIRY = 5 * 60 * 1000

const useSessionTimeout = () => {
  const { refreshAuthToken, signOut } = useAuth()
  const { openConfirmationModal } = useModals()
  const [alertShown, setAlertShown] = useState(false)

  useEffect(() => {
    const checkTokenExpiry = async () => {
      if (alertShown) {
        return
      }

      const authTokenExpiryTime = getAuthTokenExpiryTime()
      if (!authTokenExpiryTime) {
        signOut(true, true, location.pathname)
        return
      }

      const timeLeft = authTokenExpiryTime.getTime() - Date.now()
      if (timeLeft <= 0) {
        signOut(true, true, location.pathname)
        return
      }

      if (timeLeft <= WARNING_BEFORE_EXPIRY) {
        setAlertShown(true)

        openConfirmationModal({
          title: 'Session Expiration Warning',
          promptText:
            'Your session is about to expire. Would you like to remain logged in?',
          onConfirm: () => {
            refreshAuthToken()
            setAlertShown(false)
          },
          onCancel: () => {
            setAlertShown(false)
          },
        })
      }
    }

    const intervalId = setInterval(checkTokenExpiry, 3 * 60 * 1000)

    return () => clearInterval(intervalId)
  }, [alertShown, openConfirmationModal, refreshAuthToken, signOut])
}

export default useSessionTimeout
