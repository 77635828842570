import { Add, DocumentPreliminary, VlanIbm } from '@carbon/icons-react'
import { useEffect } from 'react'
import { useNavigate, Link, useLocation, Outlet } from 'react-router-dom'
import styles from './EvidencesPage.module.css'
import Button, { BUTTON_COLORS } from '../../components/button'

const EvidencesPage = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    window.document.title = 'Evidence'
  }, [])

  return (
    <>
      <div className={styles.header}>
        <h1>Evidence</h1>
        <Button
          color={BUTTON_COLORS.PRIMARY}
          onClick={() => navigate('/evidence/create')}
          text="Create"
          endIcon={<Add size={20} />}
        />
      </div>
      <div className={styles.tabs}>
        <Link to="./records">
          <div
            className={`${styles.tab} ${
              pathname.includes('records') ? styles.active : ''
            }`}
          >
            <VlanIbm size={20} />
            Records
          </div>
        </Link>
        <Link to="./activities">
          <div
            className={`${styles.tab} ${
              pathname.includes('activities') ? styles.active : ''
            }`}
          >
            <DocumentPreliminary size={20} />
            Activities
          </div>
        </Link>
      </div>
      <Outlet />
    </>
  )
}

export default EvidencesPage
