import { TextScale } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'

const AddHeadingBlockAction = (props: BlockActionItemProps) => {
  const { block, closeMenu } = props
  const { contentIsEditable } = useSpecificationContext()
  const { createHeadingBlock } = useSectionContext()

  return (
    contentIsEditable && (
      <button
        className={styles.actionItem}
        onClick={async () => {
          await createHeadingBlock(block?.id)
          closeMenu()
        }}
      >
        <TextScale />
        Heading
      </button>
    )
  )
}

export default AddHeadingBlockAction
