import Tag from '.'
import { getCssVar } from '../../lib/string.ts'
import {
  EVIDENCE_ACTIVITY_STATUS_TO_NAME,
  EvidenceActivityStatus,
} from '../../types/enums'

const TAG_COLORS = {
  [EvidenceActivityStatus.Complete]: {
    backgroundColor: getCssVar('--color-green12'),
    fontColor: getCssVar('--color-green13'),
    hoverBackgroundColor: '#74e792',
  },
  [EvidenceActivityStatus.Todo]: {
    backgroundColor: getCssVar('--ui-color-gray-mid'),
    fontColor: '#303030',
    hoverBackgroundColor: '#b4bac4',
  },
  [EvidenceActivityStatus.Canceled]: {
    backgroundColor: '#ffd7d9',
    fontColor: getCssVar('--color-red3'),
    hoverBackgroundColor: '#fdcacd',
  },
  [EvidenceActivityStatus.InProgress]: {
    backgroundColor: getCssVar('--color-purple'),
    fontColor: '#4e00ce',
    hoverBackgroundColor: '#c6b3ff',
  },
}

const EvidenceActivityStatusTag = (props: {
  status: EvidenceActivityStatus
  onCancel?: () => void
  hoverable?: boolean
}) => {
  const { status, onCancel, hoverable } = props
  return (
    <Tag
      text={EVIDENCE_ACTIVITY_STATUS_TO_NAME[status]}
      color={
        hoverable
          ? TAG_COLORS[status]
          : { ...TAG_COLORS[status], hoverBackgroundColor: undefined }
      }
      onCancel={onCancel}
    />
  )
}

export default EvidenceActivityStatusTag
