import { useParams } from 'react-router-dom'
import styles from './RequirementDetailHistory.module.css'
import RequirementHistoryEvents from '../../../components/requirement/RequirementHistoryEvents.tsx'

const RequirementDetailHistory = () => {
  const { id: specificationId, requirementId } = useParams()
  return (
    <div className={styles.requirementDetailHistory}>
      {requirementId && specificationId && (
        <RequirementHistoryEvents
          requirementId={requirementId}
          specificationId={specificationId}
        />
      )}
    </div>
  )
}

export default RequirementDetailHistory
