import styles from './index.module.css'
import Accordion from '../../components/accordion'

const SECTIONS = [
  {
    title: 'Terms of Service',
    children: (
      <div>
        <p className={styles.spacing}>
          Welcome to Stell Engineering’s terms of service.
        </p>
        <p className={styles.spacing}>
          These Terms of Service (“<strong>Terms</strong>”, “
          <strong>Terms of Service</strong>”) govern your use of our web pages
          (our “<strong>Site</strong>”) located at Stell-Engineering.com, and
          the Stell Engineering app (“the App”, “the Stell App”), (the App and
          the Site collectively, “Services”), both operated by Stell
          Engineering, Inc. (“Stell”). These terms of service are entered into
          by and between You and Stell Engineering, Inc. ("Company," "we,"
          "our," or "us"). Please read the Terms of Service carefully. By
          accessing and using the Stell App, you accept and agree to be bound
          and abide by these Terms of Service.
        </p>
        <p className={styles.spacing}>
          <strong>Updates to Terms of Service</strong>
        </p>
        <p className={styles.spacing}>
          We may revise and update these Terms of Service from time to time in
          our sole discretion. All changes are effective immediately when we
          post them and apply to all access to and use of the Website
          thereafter.
        </p>
        <p className={styles.spacing}>
          Continuing to use the Services following the posting of revised Terms
          of Service means that you accept and agree to the changes. You are
          expected to check this page each time you access this Website so you
          are aware of any changes, as they are binding on you.
        </p>
        <p className={styles.spacing}>
          <strong>Prohibited Activities</strong>
        </p>
        <p className={styles.spacing}>
          You may use the Services only for lawful purposes and in accordance
          with these Terms of Service. You agree not to use the Website or App:
        </p>
        <p className={styles.spacing}>
          In any way that violates any applicable federal, state, local or
          international law or regulation (including, without limitation, any
          laws regarding the exports of data software to and from the U.S. or
          other countries). For the purpose of exploiting, harming, or
          attempting to exploit or harm minors in any way by exposing them to
          inappropriate content, asking for personally identifiable information
          or otherwise. To send, knowingly receive, upload, download, use, or
          re-use any material that does not comply with the Submission Standards
          set out in these Terms of Service. To transmit, or procure the sending
          of, any advertising or promotional material, including any "junk
          mail," "chain letter," "spam," or any other similar solicitation. To
          impersonate or attempt to impersonate the Company, a Company employee,
          another user, or any other persona or entity (including, without
          limitation, by using email addresses associated with any of the
          foregoing). To engage in any other conduct that restricts or inhibits
          anyone's use or enjoyment of the website, or which as determined by
          us, may harm the Company or users of the website, or expose them to
          liability.
        </p>
        <p className={styles.spacing}>Additionally, you agree not to:</p>
        <p className={styles.spacing}>
          Use the Services in any manner that could disable, overburden, damage,
          or impair the Website or interfere with any other party's use of the
          Services, including their ability to engage in real-time activities
          through the app. Use any robot, spider, or other automatic device,
          process, or means to access the Services for any purpose, including
          monitoring or copying any of the material. Use any manual process to
          monitor or copy any of the material on the Services, or for any other
          purpose not expressly authorized in these Terms of Service, without
          our prior written consent. Use any device, software, or routine that
          interferes with the proper working of the Services Introduce any
          viruses, Trojan horses, worms, logic bombs, or other material that is
          malicious or technologically harmful. Attempt to gain unauthorized
          access to, interfere with, damage, or disrupt any parts of The
          Services, the server on which the Website is stored, or any server,
          computer, or database connected to the Website. Attack the Stell App
          or Site via a denial-of-service attack or a distributed
          denial-of-service attack. Otherwise attempting to interfere with the
          proper working of the Website.
        </p>
        <p className={styles.spacing}>
          <strong>Intellectual Property Rights</strong>
        </p>
        <p className={styles.spacing}>
          Services and their entire contents, features, and functionality
          (including but not limited to all information, software, source code,
          text, displays, and the design, selection, and arrangement thereof,
          collectively, the “Content”) as well as the trademarks, service marks,
          and logos contained therein (the "Marks") are owned by Stell
          Engineering Inc. and are protected by United States and international
          copyright, trademark, patent, trade secret, and other intellectual
          property or proprietary rights laws
        </p>
        <p className={styles.spacing}>
          Stell’s Content and Marks are provided in or through the Services "AS
          IS" for your internal business purpose only.
        </p>
        <p className={styles.spacing}>
          Except as set out in this section or elsewhere in our Legal Terms, no
          part of the Services and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
        </p>
        <p className={styles.spacing}>
          If you wish to make any use of the Services, Content, or Marks other
          than as set out in this section or elsewhere in our Legal Terms,
          please address your request to: anne@stell-engineering.com
        </p>
        <p className={styles.spacing}>
          Any breach of these Intellectual Property Rights will constitute a
          material breach of our Legal Terms and your right to use our Services
          will terminate immediately.
        </p>
        <p className={styles.spacing}>
          <strong>Your submissions and contributions</strong>
        </p>
        <p className={styles.spacing}>
          Please review this section and the "Prohibited Activities" section
          carefully prior to using our Services to understand the (a) rights you
          give us and (b) obligations you have when you post or upload any
          content through the Services. Submissions: By directly sending us any
          question, comment, suggestion, idea, feedback, or other information
          about the Services ("Submissions"), you agree to assign to us all
          intellectual property rights in such Submission. You agree that we
          shall own this Submission and be entitled to its unrestricted use and
          dissemination for any lawful purpose, commercial or otherwise, without
          acknowledgment or compensation to you. You may contact us directly at
          anne@stell-engineering.com with information and feedback concerning
          errors, suggestions for improvements, ideas, problems, complaints, and
          other matters related to the Services. Contributions: Stell may invite
          you to chat, contribute to, or participate in blogs, message boards,
          online forums, and other functionality during which you may create,
          submit, post, display, transmit, publish, distribute, or broadcast
          content and materials to us including but not limited to text,
          writings, video, audio, photographs, music, graphics, comments,
          reviews, rating suggestions, personal information, or other material
          ("Contributions"). Any Submission that is publicly posted shall also
          be treated as a Contribution. You are responsible for what you post or
          upload. You confirm that you have read and agree with our “Prohibited
          Activities”. We respect the intellectual property rights of others. If
          you believe that any material available on or through the Services
          infringes upon any copyright you own or control:
        </p>
        <p className={styles.spacing}>
          Your computer may temporarily store copies of such material in RAM
          incidental to your accessing and viewing those materials. You may
          store files that are automatically cached by your Web browser for
          display enhancement purposes.
        </p>
        <p className={styles.spacing}>
          <strong>Our Rights</strong>
        </p>
        <p className={styles.spacing}>
          We have the right, without provision of notice to:
        </p>
        <p className={styles.spacing}>
          Take appropriate legal action, including, without limitation, referral
          to or cooperation with law enforcement or regulatory authorities, or
          notifying the harmed party of any illegal or unauthorized use of the
          Website; and Terminate or suspend your access to all or part of the
          Website for any or no reason, including, without limitation, any
          violation of these Terms of Service. YOU WAIVE AND HOLD HARMLESS
          COMPANY AND ITS PARENT, SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE
          DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
          CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY
          AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY
          OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE
          COMPANY OR BY LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p className={styles.spacing}>
          <strong>Third-Party Links and Content</strong>
        </p>
        <p className={styles.spacing}>
          For your convenience, Stell may provide links or pointers to
          third-party sites or third-party content. We make no representations
          about any other websites or third-party content that may be accessed
          from the app. If you choose to access any such sites, you do so at
          your own risk. We have no control over the third-party content or any
          such third-party sites and accept no responsibility for such sites or
          for any loss or damage that may arise from your use of them. You are
          subject to any terms and conditions of such third-party sites.
        </p>
        <p className={styles.spacing}>
          <strong>LIMITATION OF LIABILITY</strong>
        </p>
        <p className={styles.spacing}>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF
          A PERSON, COMPANY AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL
          EQUIPMENT AND TECHNOLOGY SUPPLIERS), STELL SHALL NOT BE RESPONSIBLE OR
          LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS
          AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT
          LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR
          LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF
          SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR
          ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES;
          (C) FOR ANY MATTER BEYOND COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY
          AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS,
          EXCEED $100.
        </p>
        <p className={styles.spacing}>
          Except where such exclusions are prohibited by law, in no event shall
          the company nor its respective directors, officers, employees, agents,
          service providers, contractors, licensors, licensees, suppliers, or
          successors be liable under these terms of service to you or any
          third-party for any consequential, indirect, incidental, exemplary,
          special, or punitive damages whatsoever, including any damages for
          business interruption, loss of use, data, revenue or profit, cost of
          capital, loss of business opportunity, loss of goodwill, whether
          arising out of breach of contract, tort (including negligence), any
          other theory of liability, or otherwise, regardless of whether such
          damages were foreseeable and whether or not the company was advised of
          the possibility of such damages.
        </p>
        <p className={styles.spacing}>
          <strong>Indemnification</strong>
        </p>
        <p className={styles.spacing}>
          To the maximum extent permitted by applicable law, you agree to
          defend, indemnify, and hold harmless Company, its parent,
          subsidiaries, affiliates, and their respective directors, officers,
          employees, agents, service providers, contractors, licensors,
          suppliers, successors, and assigns from and against any claims,
          liabilities, damages, judgments, awards, losses, costs, expenses, or
          fees (including reasonable attorneys' fees) arising out of or relating
          to your breach of these Terms of Service or your use of the Website
          including, but not limited to, third-party sites and content, any use
          of the Website's content and services other than as expressly
          authorized in these Terms of Service or any use of any goods, digital
          products and information purchased from this Website.
        </p>
        <p className={styles.spacing}>
          <strong>Restrictions and Responsibilities </strong>
        </p>
        <p className={styles.spacing}>
          Customer will not, directly or indirectly: reverse engineer,
          decompile, disassemble or otherwise attempt to discover the source
          code, object code or underlying structure, ideas, know-how or
          algorithms relevant to the Services or any software, documentation or
          data related to the Services (“Software”); modify, translate, or
          create derivative works based on the Services or any Software (except
          to the extent expressly permitted by Company or authorized within the
          Services); use the Services or any Software for timesharing or service
          bureau purposes or otherwise for the benefit of a third; or remove any
          proprietary notices or labels.
        </p>
        <p className={styles.spacing}>
          Further, Customer may not remove or export from the United States or
          allow the export or re-export of the Services, Software or anything
          related thereto, or any direct product thereof in violation of any
          restrictions, laws or regulations of the United States Department of
          Commerce, the United States Department of Treasury Office of Foreign
          Assets Control, or any other United States or foreign agency or
          authority. As defined in FAR section 2.101, the Software and
          documentation are “commercial items” and according to DFAR section
          252.227‑7014(a)(1) and (5) are deemed to be “commercial computer
          software” and “commercial computer software documentation.” Consistent
          with DFAR section 227.7202 and FAR section 12.212, any use
          modification, reproduction, release, performance, display, or
          disclosure of such commercial software or commercial software
          documentation by the U.S. Government will be governed solely by the
          terms of this Agreement and will be prohibited except to the extent
          expressly permitted by the terms of this Agreement.
        </p>
        <p className={styles.spacing}>
          Customer represents, covenants, and warrants that Customer will use
          the Services only in compliance with Company’s standard published
          policies then in effect (the “Policy”) and all applicable laws and
          regulations. Although Company has no obligation to monitor Customer’s
          use of the Services, Company may do so and may prohibit any use of the
          Services it believes may be (or alleged to be) in violation of the
          foregoing.
        </p>
        <p className={styles.spacing}>
          Customer shall be responsible for obtaining and maintaining any
          equipment and ancillary services needed to connect to, access or
          otherwise use the Services, including, without limitation, modems,
          hardware, servers, software, operating systems, networking, web
          servers and the like (collectively, “Equipment”). Customer shall also
          be responsible for maintaining the security of the Equipment, Customer
          account, passwords (including but not limited to administrative and
          user passwords) and files, and for all uses of Customer account or the
          Equipment with or without Customer’s knowledge or consent.
        </p>
        <p className={styles.spacing}>
          <strong>Severability</strong>
        </p>
        <p className={styles.spacing}>
          If any provision of these Terms of Service is illegal or unenforceable
          under applicable law, the remainder of the provision will be amended
          to achieve as closely as possible the effect of the original term and
          all other provisions of these Terms of Service will continue in full
          force and effect.
        </p>
        <p className={styles.spacing}>
          <strong>Waiver</strong>
        </p>
        <p className={styles.spacing}>
          No failure to exercise, and no delay in exercising, on the part of
          either party, any right or any power hereunder shall operate as a
          waiver thereof, nor shall any single or partial exercise of any right
          or power hereunder preclude further exercise of that or any other
          right hereunder.
        </p>
        <p className={styles.spacing}>
          <strong>Termination</strong>
        </p>
        <p className={styles.spacing}>
          We may terminate or suspend Your access immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if You breach these Terms and Conditions. Upon termination,
          Your right to use the Service will cease immediately.
        </p>
        <p className={styles.spacing}>
          <strong>Privacy Policy</strong>
        </p>
        <p className={styles.spacing}>
          Your provision of personal information through the Website is governed
          by our Privacy Policy.
        </p>
        <p className={styles.spacing}>
          <strong>Governing Law</strong>
        </p>
        <p className={styles.spacing}>
          These Terms of Service will be governed by and construed in accordance
          with the laws of the State of California and any applicable federal
          laws applicable therein, without giving effect to any choice or
          conflict of law provision, principle, or rule and notwithstanding your
          domicile, residence, or physical location. Any action or proceeding
          arising out of or relating to this Website and/or under these Terms of
          Service will be instituted in the courts of the State of California,
          and each party irrevocably submits to the exclusive jurisdiction of
          such courts in any such action or proceeding. You waive any and all
          objections to the exercise of jurisdiction over you by such courts and
          to the venue of such courts.
        </p>
        <p className={styles.spacing}>
          <strong>United States Legal Compliance</strong>
        </p>
        <p className={styles.spacing}>
          You represent and warrant that (i) You are not located in a country
          that is subject to the United States government embargo, or that has
          been designated by the United States government as a "terrorist
          supporting" country, and (ii) You are not listed on any United States
          government list.
        </p>
        <p className={styles.spacing}>
          <strong>Analytics</strong>
        </p>
        <p className={styles.spacing}>
          We may use third-party Service Providers to monitor and analyze the
          use of the Site or the Stell App.
        </p>
        <p className={styles.spacing}>
          <strong>PostHog Analytics</strong>
        </p>
        <p className={styles.spacing}>
          Stell employs Posthog analytics in its App to collect data on user
          sessions and to help inform our UI research. For more information on
          what type of information Posthog can collect, please visit the Terms
          of Use page of PostHog.
        </p>
        <p className={styles.spacing}>
          <strong>Amendments To These Terms</strong>
        </p>{' '}
        <p className={styles.spacing}>
          We may amend Terms at any time by posting the amended terms in the
          App. It is your responsibility to review these Terms periodically.
          Your continued use of the Stell App following the posting of revised
          Terms means that you accept and agree to the changes. You are expected
          to check this page so you are aware of any changes, as they are
          binding on you. By continuing to access or use Stell’s Website or the
          App, you agree to be bound by the revised terms.
        </p>
        <p className={styles.spacing}>
          <strong>Waiver And Severability</strong>
        </p>
        <p className={styles.spacing}>
          No waiver by Company of any term or condition set forth in Terms shall
          be deemed a further or continuing waiver of such term or condition or
          a waiver of any other term or condition, and any failure of Company to
          assert a right or provision under Terms shall not constitute a waiver
          of such right or provision.
        </p>
        <p className={styles.spacing}>
          If any provision of Terms is held by a court or other tribunal of
          competent jurisdiction to be invalid, illegal or unenforceable for any
          reason, such provision shall be eliminated or limited to the minimum
          extent such that the remaining provisions of Terms will continue in
          full force and effect.
        </p>
        <p className={styles.spacing}>
          <strong>Contact Us</strong>
        </p>
        <p className={styles.spacing}>https://www.Stell-Engineering.com</p>
      </div>
    ),
  },
  {
    title: 'Stell Privacy Policy',
    children: (
      <div>
        <p className={styles.spacing}>Effective date: August 29, 2023</p>
        <ol className={styles.spacedList}>
          <li>
            <p className={styles.spacing}>
              <strong>Introduction</strong>
            </p>
            <p className={styles.spacing}>
              Stell Engineering Inc. (“us”, “we”, or “our”) operates
              www.stell-engineering.com (the “Website”)
            </p>
            <p className={styles.spacing}>
              The products and services we provide via the Stell app will be
              referred to as the “App”, the “Stell App”.
            </p>
            <p className={styles.spacing}>
              The person or entity accessing the Website or the App will be
              referred to as “you” or “your”.
            </p>
            <p className={styles.spacing}>
              Our Privacy Policy governs your use of our Website and the App
              (collectively the “Services”), and explains how we collect,
              safeguard and disclose information that results from your use
              thereof.
            </p>
            <p className={styles.spacing}>
              We use your data to provide and improve the Services. By using the
              Services, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, the terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Information Collection and Use</strong>
            </p>
            <p className={styles.spacing}>
              We collect several different types of Personal Data for various
              purposes to provide and improve our Services to you. For purposes
              of this policy, Personal Data means any information relating to an
              identified or identified natural person.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>
                Types of Data Collected & Personal Data You Provide
              </strong>
            </p>
            <p className={styles.spacing}>
              While using our Service, we may ask you to provide us with certain
              Personal Data, which may include, but is not limited to:
            </p>
            <ol type="a" className={styles.spacedList}>
              <li className={styles.spacing}>Your email address</li>
              <li className={styles.spacing}>Your first name and last name</li>
              <li className={styles.spacing}>
                Your and/or your company’s name and physical address
              </li>
              <li className={styles.spacing}>
                Your and/or your company’s phone number
              </li>
              <li className={styles.spacing}>
                Information you choose to provide us through our Service and{' '}
              </li>
              <li className={styles.spacing}>Payment information.</li>
            </ol>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Personal Data You Provide Usage Data</strong>
            </p>
            <p className={styles.spacing}>We may also collect Usage Data.</p>
            <p className={styles.spacing}>
              This Usage Data may include information such as your computer's
              Internet Protocol address (e.g. IP address, a unique numerical
              address assigned to a computer as it logs on to the internet),
              browser type, browser version, operating system, the web page that
              you were visiting before accessing our Site, the pages of our Site
              that you visit, the time and date of your visit, the time spent on
              those pages, unique device identifiers, the links on our Site that
              you clicked on, and other diagnostic data.
            </p>
            <p className={styles.spacing}>
              When you access Services with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Location Data</strong>
            </p>
            <p className={styles.spacing}>
              We may use and store information about your location if you give
              us permission to do so (“Location Data”). We use this data to
              provide features of our Service, to improve and customize our
              Service.
            </p>
            <p className={styles.spacing}>
              You can enable or disable location services when you use our
              Services at any time by way of your device settings.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Cookies</strong>
            </p>
            <p className={styles.spacing}>
              We use cookies and similar tracking technologies to track the
              activity on our Services and we hold certain information.
            </p>
            <p className={styles.spacing}>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </p>
            <p className={styles.spacing}>
              We may use both session Cookies and persistent Cookies to identify
              that you’ve logged in to the Services and to tell us how and when
              you interact with our Site. We use cookies to understand and save
              your preferences for future visits and compile aggregate data
              about site traffic and site interaction so that we can offer
              better site experiences and tools in the future. We may also use
              Cookies to monitor aggregate usage and web traffic routing on our
              Services and to customize and improve our Services. Unlike
              persistent Cookies, session Cookies are deleted when you log off
              from the Services and close your browser. Although most browsers
              automatically accept Cookies, you can change your browser options
              to stop automatically accepting Cookies or to prompt you before
              accepting Cookies. However, if you do not accept cookies, you may
              not be able to use some portions of our Service.
            </p>
            <p className={styles.spacing}>
              We may also collect information via standard server logs or clear
              GIFs (also known as “Web beacons”). Web beacons and pixel tags are
              images embedded in a webpage or email for the purpose of measuring
              and analyzing usage and activity. We, or third-party service
              providers acting on our behalf, may use web beacons and pixel tags
              to help us analyze usage and improve our functionality. Social
              media tools, like widgets and plug-ins, may be offered so you can
              share information on other sites such Facebook and Twitter. These
              interactive mini-programs collect your IP address, record the
              pages you visit on our Site, and set cookies that will enable the
              widget to function properly. Your interactions with these widgets
              are governed by the privacy policy of the company providing them,
              not by this Privacy Policy. If we link or associate any
              information gathered through passive means with Personal
              Information, we treat the combined information as Personal
              Information under this Privacy Policy. Otherwise, we use
              information collected by passive means in non-personally
              identifiable form only.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Analytics Data</strong>
            </p>
            <p className={styles.spacing}>
              We also collect analytics data about you through our third-party
              analytics provider, Posthog Analytics, in order to monitor and
              analyze the use of our Service. We employ Posthog Analytics to
              collect data on user sessions and to help inform our UI research.
              For more information on what type of information Posthog can
              collect, please visit the Terms of Use page of PostHog:
              https://posthog.com/terms.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Use of Data</strong>
            </p>
            <p className={styles.spacing}>
              Stell uses the collected data for various purposes including, but
              not limited to, the following:
            </p>
            <ol className={styles.spacedList} type="a">
              <li className={styles.spacing}>
                to provide and maintain the Services;
              </li>
              <li className={styles.spacing}>
                to notify you about changes to the Services;
              </li>
              <li className={styles.spacing}>
                to allow you to participate in interactive features of the
                Services when you choose to do so;
              </li>
              <li className={styles.spacing}>to provide customer support;</li>
              <li className={styles.spacing}>
                to gather analysis or valuable information so that we can
                improve the Services;
              </li>
              <li className={styles.spacing}>
                to monitor the usage of the Services;
              </li>
              <li className={styles.spacing}>
                to detect, prevent and address technical issues;
              </li>
              <li className={styles.spacing}>
                to fulfill any other purpose for which you provide it;
              </li>
              <li className={styles.spacing}>
                to carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection;
              </li>
              <li className={styles.spacing}>
                to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </li>
              <li className={styles.spacing}>
                to provide you with news, special offers and general information
                or marketing or promotional materials about other goods,
                services and events which we offer that are similar to those
                that you have already purchased or enquired about unless you
                have opted not to receive such information; We may use your
                Personal Data to contact you with other information that may be
                of interest to you.
              </li>
              <li className={styles.spacing}>
                in any other way we may describe when you provide the
                information;
              </li>
              <li className={styles.spacing}>
                for any other purpose with your consent.
              </li>
            </ol>
            <p className={styles.spacing}>
              Please note that “Do Not Track” options will not affect how we use
              your Personal Data. Although we do our best to honor the privacy
              preferences of our customers, we are not able to respond to
              Do-Not-Track signals from your browser at this time. As discussed
              above, we track websites and app usage information through the use
              of cookies for analytic and internal purposes only. Because we do
              not collect this information to track you across websites or apps
              over time, your selection of the “Do Not Track” option provided by
              your browser will not have any effect on our collection of cookie
              information for analytics or internal purposes.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Retention of Data</strong>
            </p>
            <p className={styles.spacing}>
              We will retain your Personal Data only for as long as is necessary
              to accomplish the purposes for which we collected it. se. We will
              retain and use your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p className={styles.spacing}>
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Transfer of Data</strong>
            </p>
            <p className={styles.spacing}>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>
            <p className={styles.spacing}>
              If you are located outside the United States and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to the United States and process it here.
            </p>
            <p className={styles.spacing}>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Disclosure of Data</strong>
            </p>
            <p className={styles.spacing}>
              We may disclose personal information that we collect, or you
              provide:
            </p>
            <ol type="a">
              <li>
                <p className={styles.spacing}>
                  <strong>Disclosure for Law Enforcement.</strong>
                </p>
                <p className={styles.spacing}>
                  Under certain circumstances, we may be required to disclose
                  your Personal Data if required to do so by law or in response
                  to valid requests by public authorities.
                </p>
              </li>
              <li>
                <p className={styles.spacing}>
                  <strong>Business Transactions</strong>
                </p>
                <p className={styles.spacing}>
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </p>
              </li>
              <li>
                <p className={styles.spacing}>
                  <strong>We may disclose your information also:</strong>
                </p>
                <ol className={styles.spacedList} type="i">
                  <li className={styles.spacing}>
                    to our subsidiaries and affiliates;
                  </li>
                  <li className={styles.spacing}>
                    to contractors, service providers, and other third parties
                    we use to support our business;
                  </li>
                  <li className={styles.spacing}>
                    to other third parties to fulfill the purpose for which you
                    provide it;
                  </li>
                  <li className={styles.spacing}>
                    for the purpose of including your company’s logo on our
                    website;
                  </li>
                  <li className={styles.spacing}>
                    to any otherthird party disclosed by us when you provide the
                    information.
                  </li>
                  <li className={styles.spacing}>
                    if we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of the Company, our
                    customers, or others.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Security of Data</strong>
            </p>
            <p className={styles.spacing}>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Link to Other Sites</strong>
            </p>
            <p className={styles.spacing}>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Children's Privacy</strong>
            </p>
            <p className={styles.spacing}>
              Our Services are not intended for use by children under the age of
              18.
            </p>
            <p className={styles.spacing}>
              We do not knowingly collect personally identifiable information
              from children under 13. If you become aware that a child under 13
              has provided us with Personal Data, please contact us. If we
              become aware that we have collected Personal Data from such
              children without verification of parental consent, we take steps
              to remove that information from our servers.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Changes to This Privacy Policy</strong>
            </p>
            <p className={styles.spacing}>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              The new policy will become effective on “effective date” at the
              top of this Privacy Policy
            </p>
            <p className={styles.spacing}>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </li>
          <li>
            <p className={styles.spacing}>
              <strong>Contact Us</strong>
            </p>
            <p className={styles.spacing}>anne@stell-engineering.com</p>
          </li>
        </ol>
      </div>
    ),
  },
]
const LegalAccordion = () => {
  return <Accordion sections={SECTIONS} />
}

export default LegalAccordion
