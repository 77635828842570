import { CheckmarkFilled, Document } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './ReportPage.module.css'
import {
  CollapsibleTableData,
  getColumnsFromReport,
} from './ReportTableColumns.tsx'
import { getReport, Report, updateReportName } from '../../api/v2/reports.ts'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import {
  getRequirementHistoryWithUsers,
  HistoryEventType,
} from '../../api/v2/specifications.ts'
import * as api from '../../api/v2/users.ts'
import { User } from '../../api/v2/users.ts'
import Button from '../../components/button/index.tsx'
import LoadingIndicator, {
  LoaderSize,
} from '../../components/loading-indicator/LoadingIndicator.tsx'
import Table from '../../components/table/Table.tsx'
import useResizableColumns from '../../components/table/useResizableColumns.ts'
import Tag, {
  EVIDENCE_TYPE_COLORS,
  stylesToTagColor,
} from '../../components/tag/index.tsx'
import { toastError, toastSuccess } from '../../components/toast'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { formatTime, monthLongDayYear } from '../../lib/date.ts'
import { getCssVar } from '../../lib/string.ts'
import { EvidenceType, LoadingState, ReportSource } from '../../types/enums.ts'
import { generateReportPdf } from '../specification/export/PdfGenerator.tsx'

export const EditableReportName = (props: {
  onReportChange: (newReport: Report) => void
  report: Report
}) => {
  const { onReportChange, report } = props
  const { id: reportId, name: reportName } = report
  const { userDetails, isAdmin } = useAuth()

  const [newReportName, setNewReportName] = useState(reportName)
  const [isEditing, setIsEditing] = useState<boolean>()
  const [canSave, setCanSave] = useState<boolean>()

  const resetEditRef = useClickOutside(() => {
    if (isEditing) {
      setNewReportName(reportName)
      setCanSave(false)
      setIsEditing(false)
    }
  })

  useEffect(() => {
    setNewReportName(reportName)
  }, [report, reportName])

  const isValidReportName = (reportName: string, newReportName: string) => {
    if (newReportName === null || newReportName.length < 1) {
      return false
    }

    return reportName !== newReportName
  }

  const isAuthor = report.createdBy === userDetails?.id
  const canEdit = isAdmin || isAuthor

  return (
    <>
      <div className={styles.editableReportName} ref={resetEditRef}>
        <textarea
          disabled={!canEdit}
          className={styles.name}
          value={newReportName}
          onChange={(e) => {
            setIsEditing(true)
            setNewReportName(e.target.value)
            setCanSave(isValidReportName(reportName, e.target.value))
          }}
          data-testid="edit-name-input"
        ></textarea>
        {canEdit && isEditing && (
          <button
            className={styles.saveName}
            disabled={!canSave}
            onClick={() => {
              const formattedReportName = newReportName.replace(/\n/g, '')

              const persistReportName = async () => {
                try {
                  await updateReportName(reportId, formattedReportName)
                  setIsEditing(false)
                  onReportChange({ ...report, name: formattedReportName })
                } catch (e) {
                  console.error('Error updating report name', e)
                  captureException(e)
                }
              }

              persistReportName()
            }}
            data-testid="edit-name-save-btn"
          >
            <CheckmarkFilled
              size={24}
              className={`${styles.saveBtn} ${canSave ? styles.canSave : ''}`}
            />
          </button>
        )}
      </div>
    </>
  )
}

const CreationDetails = (props: { report: Report; createdBy?: User }) => {
  const { report, createdBy } = props
  return (
    <div className={styles.createInfo}>
      <div className={styles.createText}>
        <span className={styles.label}>Created</span>
        {report && (
          <>
            <span>{monthLongDayYear(report.createdOn)}</span>
            <span>{formatTime(report.createdOn)}</span>
          </>
        )}
      </div>
      <div className={styles.createText}>
        <span className={styles.label}>Created By</span>
        {createdBy && (
          <span>{`${createdBy.firstName} ${createdBy.lastName}`}</span>
        )}
        {!createdBy && <span>{`Unknown`}</span>}
      </div>
    </div>
  )
}

interface ReportHeaderProps {
  report: Report
  handleReportUpdate: (report: Report) => void
  createdBy: User | undefined
}

const SpecificationReportHeader = (props: ReportHeaderProps) => {
  const { report, handleReportUpdate, createdBy } = props

  const [showAll, setShowAll] = useState(false)
  const [reviewers, setReviewers] = useState<
    {
      id: string
      name: string
      date: string
    }[]
  >([])

  useEffect(() => {
    const mostRecentRequirement = report.contents[report.contents.length - 1]
    const fetchReviewers = async () => {
      const fetched = await getRequirementHistoryWithUsers(
        mostRecentRequirement.specification.id,
        mostRecentRequirement.requirement.id,
      )

      const reviewerApprovals = fetched
        .filter((event) => {
          return event.type === HistoryEventType.SPEC_VERSION_APPROVED
        })
        .filter(
          (event) =>
            event.data.specificationVersion ===
            mostRecentRequirement.revision.version,
        )
        .map((event) => {
          const { createdBy, createdOn, id } = event
          return {
            id: id,
            name: `${createdBy.firstName} ${createdBy.lastName}`,
            date: monthLongDayYear(createdOn),
          }
        })
      setReviewers(reviewerApprovals)
    }

    fetchReviewers()
  }, [report.contents])

  const reviewersToShow = showAll ? reviewers : reviewers.slice(0, 3)

  const showApprovals =
    report.metadata?.specificationStatus === RevisionStatus.ACTIVE

  return (
    <div className={styles.header}>
      <div className={`${styles.reportHeader} ${styles.basicElevation}`}>
        <EditableReportName
          report={report}
          onReportChange={(newReport: Report) => handleReportUpdate(newReport)}
        />
        {report.contents[0] && (
          <div className={styles.specDetails}>
            <Document size={16} className={styles.docIcon} />
            <span className={styles.specName}>
              {report.contents[0].specification.name}
            </span>
            <Tag
              color={{
                fontColor: getCssVar('--text-color-gray-dark'),
                backgroundColor: getCssVar('--color-green5'),
              }}
              text={`V${report.contents[0].revision.version}`}
            />
          </div>
        )}
        <div className={styles.details}>
          <span className={styles.label}>Created By: </span>
          {createdBy && (
            <span>{`${createdBy.firstName} ${createdBy.lastName}`}</span>
          )}
          {!createdBy && <span>{`Unknown`}</span>}
        </div>
        {report && (
          <p className={styles.specificationCreatedOn}>
            <span>{monthLongDayYear(report.createdOn)}</span>
            <span>{formatTime(report.createdOn)}</span>
          </p>
        )}
      </div>
      {showApprovals && (
        <div className={`${styles.specApprovals} ${styles.basicElevation}`}>
          <div className={styles.title}>
            <CheckmarkFilled size={24} /> Specification approvals
          </div>
          <div className={styles.approvals}>
            {reviewersToShow.map((approval) => {
              return (
                <p className={styles.reviewer} key={approval.id}>
                  <span className={styles.name}>{approval.name}</span>
                  <span className={styles.date}>{approval.date}</span>
                </p>
              )
            })}
            {reviewers.length > 3 && (
              <button onClick={() => setShowAll(!showAll)}>
                {!showAll ? 'See all approvals' : 'Show fewer approvals'}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const SearchReportHeader = (props: ReportHeaderProps) => {
  const { report, handleReportUpdate, createdBy } = props

  return (
    <div className={styles.header}>
      <EditableReportName
        report={report}
        onReportChange={(newReport: Report) => handleReportUpdate(newReport)}
      />
      <CreationDetails report={report} createdBy={createdBy} />
    </div>
  )
}

const EvidenceComplianceReportHeader = (props: ReportHeaderProps) => {
  const { report, handleReportUpdate, createdBy } = props

  const { getEvidenceMethodById, getProgramById } = useAttributesContext()
  const evidence = report.metadata.evidence

  const method = getEvidenceMethodById(evidence?.method || '')
  const program = getProgramById(evidence?.program || '')

  return (
    <div className={styles.evidenceHeader}>
      <div className={styles.reportTitle}>
        <EditableReportName
          report={report}
          onReportChange={(newReport: Report) => handleReportUpdate(newReport)}
        />
        {program && (
          <div className={styles.programTag}>
            <Tag
              text={program.name}
              color={stylesToTagColor(program?.metadata.STYLES)}
            />
          </div>
        )}
      </div>
      <div className={styles.evidenceDetails}>
        <div className={styles.evidenceCard}>
          <div className={styles.tags}>
            <div className={styles.type}>
              <div className={styles.title}>Type</div>
              <Tag
                text={evidence?.type || ''}
                color={
                  EVIDENCE_TYPE_COLORS[evidence?.type || EvidenceType.Unknown]
                }
              />
            </div>
            <div className={styles.method}>
              <div className={styles.title}>Method</div>
              <Tag
                text={method?.name || ''}
                color={stylesToTagColor(method?.metadata.STYLES)}
              />
            </div>
          </div>
          <div>
            <div className={styles.title}>Description of activity</div>
            <div>
              {evidence?.description
                ? evidence.description
                : 'No description of activity'}
            </div>
          </div>
          <div>
            <div className={styles.title}>Compliance statement</div>
            <div>
              {evidence?.compliance
                ? evidence.compliance
                : 'No compliance statement'}
            </div>
          </div>
        </div>
        <CreationDetails report={report} createdBy={createdBy} />
      </div>
    </div>
  )
}

const HEADERS = {
  [ReportSource.SpecificationRequirementV1]: SpecificationReportHeader,
  [ReportSource.SearchRequirementV1]: SearchReportHeader,
  [ReportSource.EvidenceRequirementV1]: EvidenceComplianceReportHeader,
}

const ReportPage = () => {
  const { id } = useParams()
  const { userDetails } = useAuth()

  const [report, setReport] = useState<Report>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [createdBy, setCreatedBy] = useState<User | undefined>()
  const [selectedReqId, setSelectedReqId] = useState<string>()
  const [expandAll, setExpandAll] = useState<boolean>(false)
  const [loadingPdf, setLoadingPdf] = useState<LoadingState>(LoadingState.Start)

  const columnResizer = useResizableColumns(
    getColumnsFromReport(report?.metadata?.columnsToDisplay || []),
    `reportTable-${id}`,
  )

  const tableRef = useClickOutside(() => {
    setSelectedReqId(undefined)
  })

  const onGeneratePdf = useCallback(async () => {
    const columnWidths =
      columnResizer?.resizeRefs?.current?.map((ele) => ele.clientWidth) || []

    if (!report || !tableRef.current) {
      return
    }

    await generateReportPdf({
      report,
      createdBy,
      columnWidths,
      onStart: () => {},
      onComplete: () => {
        toastSuccess('Successfully created pdf export.')
        setLoadingPdf(LoadingState.Start)
      },
      onFailure: (e) => {
        toastError(
          'An error occurred while generating pdf export.',
          'Please refresh the page and try again.',
        )
        console.error('Failed to generate or save pdf export.', e)
        setLoadingPdf(LoadingState.Failed)
      },
    })
  }, [columnResizer?.resizeRefs, createdBy, report, tableRef])

  useEffect(() => {
    if (!id) {
      console.error('Report ID necessary to get report to display.')
      setError(new Error('Report ID necessary'))
      setLoading(false)
      return
    }

    const fetchData = async () => {
      try {
        const reportResponse = await getReport(id)
        setReport(reportResponse)
      } catch (error) {
        console.error('Unable to get report to display.', error)
        setError(error as Error)
        captureException(error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    if (!report) {
      return
    }

    const fetch = async () => {
      const resp = await api.getUser(report.createdBy)
      setCreatedBy(resp)
    }

    fetch()
  }, [report])

  const handleReportUpdate = (report: Report) => {
    setReport(report)
  }

  const rowItems = report
    ? report.contents.map((content) => ({
        ...content,
        id: content.requirement.id,
        collapsed: selectedReqId !== content.requirement.id,
      }))
    : []

  const Header = report ? HEADERS[report.source] : null

  return (
    <div className={styles.root}>
      {loading && (
        <div className={styles.loadingContainer} data-testid="loading">
          <LoadingIndicator />
        </div>
      )}
      {error && (
        <div className={styles.errorContainer}>
          Report does not exist or is unavailable at this time.
        </div>
      )}
      {!loading && !error && report && userDetails?.id && (
        <>
          {Header && (
            <Header
              report={report}
              handleReportUpdate={handleReportUpdate}
              createdBy={createdBy}
            />
          )}
          <div className={styles.actions}>
            <Button
              text={expandAll ? 'Collapse' : 'Expand'}
              onClick={() => setExpandAll(!expandAll)}
            />
            {report.source === ReportSource.SpecificationRequirementV1 && (
              <Button
                text="Export As PDF"
                onClick={() => {
                  setLoadingPdf(LoadingState.Loading)

                  // Give the page a moment to render the loading pdf state before potentially consuming a lot of resources
                  setTimeout(() => onGeneratePdf(), 100)
                }}
              />
            )}
          </div>
          {loadingPdf === LoadingState.Loading && (
            <div className={styles.pdfLoadingContent}>
              <LoadingIndicator size={LoaderSize.XLARGE} />
              <div>
                Generating PDF export... This may take a moment. Please do not
                leave page.
              </div>
            </div>
          )}

          {loadingPdf === LoadingState.Failed && (
            <div className={styles.pdfLoadingContent}>
              Something went wrong. Please refresh the page and try again.
            </div>
          )}

          <div
            className={styles.tableContainer}
            ref={tableRef}
            style={{
              ...(loadingPdf !== LoadingState.Start
                ? { visibility: 'hidden' }
                : {}),
            }}
          >
            {rowItems.length > 0 && (
              <Table<CollapsibleTableData>
                rowItems={rowItems}
                columnResizer={columnResizer}
                onRowClick={(rowData) => {
                  if (!rowData.collapsed) {
                    return
                  }

                  if (rowData.id === selectedReqId) {
                    setSelectedReqId(undefined)
                  } else {
                    setSelectedReqId(rowData.id)
                  }
                }}
                expandAll={expandAll}
              />
            )}
            {rowItems.length === 0 && (
              <div className={styles.errorContainer}>No metadata found.</div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ReportPage
