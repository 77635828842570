import { Add } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import styles from './SharedProjectsPage.module.css'
import SharedProjectsTableColumns from './SharedProjectsTableColumns'
import { Project } from '../../api/v2/projects'
import {
  getTenantProfile,
  TenantProfile,
  TenantTier,
} from '../../api/v2/tenants.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import Table from '../../components/table/Table'
import useResizableColumns from '../../components/table/useResizableColumns'
import { useAuth } from '../../context/AuthContext.tsx'
import { useModals } from '../../hooks/useModals.ts'
import { EntityRole, PermissionEntityType } from '../../types/enums.ts'

export type OrgProfile = {
  createdBy: TenantProfile | undefined
  sharedWith: TenantProfile | undefined
} | null

export type ProjectWithProfile = Project & {
  profile: OrgProfile
}

export const getOrgProfiles = async (project: Project): Promise<OrgProfile> => {
  try {
    const createdBy = await getTenantProfile(project.createdByTenant.id)

    const sharedWith =
      project?.sharedWithTenant &&
      (await getTenantProfile(project?.sharedWithTenant?.id))

    return { createdBy, sharedWith }
  } catch (e) {
    console.error(e)
    captureException(e)
    return null
  }
}

const SharedProjectsPage = () => {
  const { openCreateProjectModal } = useModals()
  const loadedProjects = useLoaderData() as Project[]
  const [projects, setProjects] = useState<ProjectWithProfile[]>([])
  const { userTenant, updateUserPermissions } = useAuth()

  useEffect(() => {
    const loadProjectProfiles = async () => {
      const projectsWithProfiles = await Promise.all(
        loadedProjects
          .sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime())
          .map(async (project, index) => {
            const profile = await getOrgProfiles(project)

            return {
              ...project,
              profile,
              index,
            }
          }),
      )

      setProjects(projectsWithProfiles)
    }

    loadProjectProfiles()
  }, [loadedProjects])

  useEffect(() => {
    window.document.title = 'Shared Projects'
  }, [])

  const columnResizer = useResizableColumns(
    SharedProjectsTableColumns,
    'sharedProjectsColumns',
  )

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h1>Shared Projects</h1>
        {userTenant?.tier === TenantTier.Full && (
          <Button
            text="Create shared project"
            onClick={() =>
              openCreateProjectModal({
                onAddProject: (newProject) => {
                  setProjects((prevProjects) => [
                    ...prevProjects,
                    {
                      ...newProject,
                      index: prevProjects.length,
                    },
                  ])
                  updateUserPermissions(
                    PermissionEntityType.Projects,
                    newProject.id,
                    EntityRole.OWNER,
                  )
                },
              })
            }
            endIcon={<Add size={16} />}
            color={BUTTON_COLORS.PRIMARY}
          />
        )}
      </div>
      {projects.length === 0 ? (
        <div className={styles.noProjectsCopy}>
          No shared projects yet. A list of all your shared projects will be
          displayed here.
        </div>
      ) : (
        <Table rowItems={projects} columnResizer={columnResizer} />
      )}
    </div>
  )
}

export default SharedProjectsPage
