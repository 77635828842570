import { Checkmark } from '@carbon/icons-react'
import { ReactElement } from 'react'
import Modal from '.'
import styles from './ConfirmationModal.module.css'
import Button, { BUTTON_COLORS } from '../button'

export interface ConfirmationModalProps {
  title: string
  titleIcon?: ReactElement
  promptText: string | ReactElement
  confirmText?: string
  confirmIcon?: ReactElement
  onConfirm: () => void
  onCancel?: () => void
  cancelText?: string
  showCancelButton?: boolean
}

const ConfirmationModal = (
  props: ConfirmationModalProps & { closeModal: () => void },
) => {
  const {
    title,
    titleIcon,
    promptText,
    confirmText,
    confirmIcon = <Checkmark size={20} />,
    onConfirm,
    onCancel,
    cancelText,
    showCancelButton = true,
    closeModal,
  } = props

  return (
    <Modal title={title} icon={titleIcon} onClose={closeModal}>
      <div className={styles.content}>
        <div className={styles.prompt}>{promptText}</div>
        <div className={styles.actions}>
          {showCancelButton && (
            <Button
              text={cancelText || 'Cancel'}
              onClick={() => {
                if (onCancel) {
                  onCancel()
                }
                closeModal()
              }}
            />
          )}
          <Button
            className={styles.confirm}
            endIcon={confirmIcon}
            color={BUTTON_COLORS.PRIMARY}
            text={confirmText || 'Confirm'}
            onClick={() => {
              onConfirm()
              closeModal()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
