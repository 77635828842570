import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import StellLogo from '../../../public/images/stell-word-logo-green.png'
import * as assetApi from '../../api/v2/assets.ts'
import * as api from '../../api/v2/attributes.ts'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import * as blockApi from '../../api/v2/blocks.ts'
import { ImageBlockData } from '../../api/v2/blocks.ts'
import { createDocumentSection, SectionType } from '../../api/v2/documents.ts'
import * as evidenceApi from '../../api/v2/evidence.ts'
import { Evidence } from '../../api/v2/evidence.ts'
import * as linkApi from '../../api/v2/links.ts'
import { createEvidenceLink } from '../../api/v2/links.ts'
import {
  ComplianceState,
  createRequirement,
  getRequirement,
  Requirement,
} from '../../api/v2/requirements.ts'
import * as revisionApi from '../../api/v2/revisions.ts'
import {
  createRevision,
  createRevisionReviewReviewer,
  getRevisions,
} from '../../api/v2/revisions.ts'
import * as specApi from '../../api/v2/specifications.ts'
import {
  createSpecification,
  getSpecification,
} from '../../api/v2/specifications.ts'
import { createNewTableBlockData } from '../../components/block-editor/blocks/table/useTableBlock.ts'
import Button from '../../components/button'
import { getTempAttributeId } from '../../components/input/AttributeInput.tsx'
import { getRandomTagColor } from '../../components/tag'
import { toastError, toastInfo, toastSuccess } from '../../components/toast'
import {
  AttributesContextProvider,
  useAttributesContext,
} from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import useDevTools from '../../context/DevToolsContext.tsx'
import { blockLinkToRequirementLink } from '../../context/MatrixContext.tsx'
import { yearMonthDay } from '../../lib/date.ts'
import { EMPTY_DELTA, getCssVar, plaintextToDelta } from '../../lib/string.ts'
import {
  EntityReviewEventOperation,
  EntityReviewReviewerEventOperation,
} from '../../types/api/v2/entity.ts'
import {
  AttributeName,
  EvidenceActivityStatus,
  EvidenceActivityType,
  EvidenceType,
} from '../../types/enums.ts'

const genSpecName = () => {
  const d = new Date()
  return `${
    d.getDate() +
    '/' +
    (d.getMonth() + 1) +
    '/' +
    d.getFullYear() +
    ' - ' +
    d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    })
  }`
}

export const loremIpsum =
  ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam lacinia ultrices mattis. Mauris a lectus sit amet nulla vehicula luctus. Nullam laoreet, metus a fermentum luctus, ex massa ultrices felis, et posuere libero dolor vel elit. In suscipit, ligula vel commodo pellentesque, risus augue aliquet lacus, aliquam ultrices risus arcu sit amet quam. Ut erat purus, semper eget vehicula vel, pretium vitae odio. Praesent mollis elit at purus mollis, sit amet sollicitudin odio posuere. Nam metus ipsum, laoreet id bibendum id, auctor ut nulla.\n' +
  '\n' +
  'Quisque non diam nulla. Aliquam sit amet eros at velit vehicula volutpat. Aenean sed orci urna. Curabitur imperdiet massa et dui malesuada posuere. Maecenas ac velit lorem. Vivamus pretium, justo id condimentum laoreet, felis orci semper lorem, at vehicula augue massa nec leo. In nec erat malesuada, vulputate tortor non, condimentum eros.\n' +
  '\n' +
  'Etiam neque erat, euismod sit amet nisi at, lobortis vulputate mauris. Phasellus risus sapien, sollicitudin in vulputate sit amet, aliquet vel augue. In consectetur, purus et sollicitudin luctus, diam ligula luctus quam, eget lobortis mauris est egestas mauris. Maecenas suscipit ultricies ipsum nec venenatis. Sed a lacus in ex ornare varius vitae sit amet neque. In pretium sem vel volutpat imperdiet. Quisque volutpat blandit lorem eu venenatis. Quisque quis porttitor arcu. Proin sagittis suscipit quam sed rutrum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. '
const random = <T,>(items: T[]): T =>
  items[Math.floor(Math.random() * items.length)]

const createAllBlockTypes = async (spec, revision, documentSection) => {
  toastInfo(<span>Creating all block types...</span>)

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Heading,
    data: { _data: { text: 'All Block Types' } },
  })

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Text,
    data: { _data: { quillDelta: EMPTY_DELTA } },
  })

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Text,
    data: { _data: { quillDelta: plaintextToDelta(loremIpsum) } },
  })

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Heading,
    data: { _data: { text: '' } },
  })

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Heading,
    data: { _data: { text: 'Test Heading Text' } },
  })

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Table,
    data: createNewTableBlockData(),
  })

  const { id: assetId } = await assetApi.createAssetId(spec.id)

  const stellLogoBlob = await fetch(StellLogo).then((r) => r.blob())

  const imageAsset = await assetApi.createImageAsset(
    spec.id,
    assetId,
    stellLogoBlob,
  )

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Image,
    data: {
      assetId: imageAsset.id,
      _data: {},
    } as ImageBlockData,
  })

  await blockApi.createBlock(spec.id, revision.documentId, documentSection.id, {
    type: blockApi.BlockType.Image,
    data: {
      assetId: imageAsset.id,
      name: 'Example Image Name',
      _data: {},
    } as ImageBlockData,
  })
}

const DevCreateLargeSpec = () => {
  const defaultReqCount = 10
  const defaultEvidenceCount = 5
  const defaultLinkMax = defaultReqCount * defaultEvidenceCount
  const defaultActivityCount = 5
  const defaultRevupCount = 0

  const { useDevApi } = useDevTools()
  const { userDetails } = useAuth()
  const {
    evidenceCadences,
    activeEvidenceMethods,
    activeRequirementTypes,
    activeCategories,
    activePhaseStates,
    getAttributeByName,
  } = useAttributesContext()

  const [isAddFrontMatter, setIsAddFrontMatter] = useState(true)
  const [isCreateAllBlockTypes, setCreateAllBlockTypes] =
    useState<boolean>(false)

  const [isCreateProgramEnabled, setIsCreateProgramEnabled] =
    useState<boolean>(true)
  const [isRandomEnabled, setIsRandomEnabled] = useState<boolean>(true)
  const [revupCount, setRevupCount] = useState<number>(defaultRevupCount)
  const [requirementCount, setRequirementCount] =
    useState<number>(defaultReqCount)

  const [verificationEvidenceCount, setVerificationEvidenceCount] =
    useState<number>(defaultEvidenceCount)
  const [verificationActivityCount, setVerificationActivityCount] =
    useState<number>(defaultActivityCount)
  const [verificationLinkMax, setVerificationLinkMax] =
    useState<number>(defaultLinkMax)

  const [validationEvidenceCount, setValidationEvidenceCount] =
    useState<number>(defaultEvidenceCount)
  const [validationLinkMax, setValidationLinkMax] =
    useState<number>(defaultLinkMax)
  const [validationActivityCount, setValidationActivityCount] =
    useState<number>(defaultActivityCount)

  const [bidirectionalParentChild, setBidirectionalParentChild] =
    useState<boolean>(true)

  useEffect(() => {
    setVerificationLinkMax(requirementCount * verificationEvidenceCount)
    setValidationLinkMax(requirementCount * validationEvidenceCount)
  }, [requirementCount, validationEvidenceCount, verificationEvidenceCount])

  const createLargeSpec = useCallback(
    async (specNameParam?: string, suppressSuccess = false) => {
      try {
        toastInfo(<span>Creating spec...</span>)
        const specName = specNameParam || genSpecName()
        const specificationIdentifier = `DEV - ${specName}`

        const createRes = await createSpecification(
          `Dev Tools - ${
            specName + ' - '
          }Validation Link Count: ${validationLinkMax} - Verification Link Count: ${verificationLinkMax}`,
        )

        const spec = await getSpecification(createRes.id)

        let createdProgram = {} as AttributeValueResponse
        if (isCreateProgramEnabled) {
          const attribute = getAttributeByName(
            AttributeName.SpecificationProgram,
          )
          const newTagColor = getRandomTagColor()

          createdProgram = await api.createAttribute(attribute?.id || '', {
            name: getTempAttributeId('DEV-').toUpperCase(),
            metadata: {
              STYLES: {
                COLOR_FONT: newTagColor.fontColor,
                COLOR_BG: newTagColor.backgroundColor,
                COLOR_BG_HOVER: newTagColor.hoverBackgroundColor || '',
              },
            },
          })
        }

        await specApi.updateSpecification(spec.id, {
          specificationIdentifier,
          ...(isCreateProgramEnabled ? { program: createdProgram.id } : {}),
          ...(isRandomEnabled ? { category: random(activeCategories).id } : {}),
          ...(isRandomEnabled ? { phase: random(activePhaseStates).id } : {}),
        })

        const revision = (await getRevisions(spec.id))[0]

        if (isAddFrontMatter) {
          const frontMatterSection = await createDocumentSection(
            spec.id,
            revision.documentId,
            null,
            SectionType.Frontmatter,
          )

          if (isCreateAllBlockTypes) {
            await createAllBlockTypes(spec, revision, frontMatterSection)
          }

          for (let i = 0; i < 10; i++) {
            await blockApi.createBlock(
              spec.id,
              revision.documentId,
              frontMatterSection.id,
              {
                type: blockApi.BlockType.Text,
                data: { _data: { quillDelta: plaintextToDelta(loremIpsum) } },
              },
            )
          }
        }

        const documentSection = await createDocumentSection(
          spec.id,
          revision.documentId,
          null,
        )

        if (isCreateAllBlockTypes) {
          await createAllBlockTypes(spec, revision, documentSection)
        }

        const reqs = [] as Requirement[]
        const verificationEvidences = [] as Evidence[]
        const validationEvidences = [] as Evidence[]

        toastInfo(<span>Finished spec. Starting reqs...</span>)
        for (let i = 0; i < requirementCount; i++) {
          const rationale =
            `Req-${i} Rationale` + (isRandomEnabled ? ' - ' + loremIpsum : '')
          const shallStatement =
            `Req-${i} Shall Statement` +
            (isRandomEnabled ? ' - ' + loremIpsum : '')

          const res = await createRequirement(spec.id, documentSection.id, {
            title: `Req-${i} - ${specName}`,
            shallStatement,
            rationale,
            sectionNumber: `Req-${i} Section Num`,
            ...(isRandomEnabled
              ? { compliance: random(Object.values(ComplianceState)) }
              : {}),

            ...(isRandomEnabled
              ? { types: [random(Object.values(activeRequirementTypes)).id] }
              : {}),
            compliance: ComplianceState.PENDING,
            complianceNotes: loremIpsum,
            data: {
              delta: {
                rationale: plaintextToDelta(rationale),
                shallStatement: plaintextToDelta(shallStatement),
              },
            },
          })

          const req = await getRequirement(spec.id, res.id)

          reqs.push(req)
        }

        const reqIds = reqs.map((req) => req.id)
        toastInfo(<span>Finished reqs. Starting records & activities ...</span>)

        for (let i = 0; i < validationEvidenceCount; i++) {
          const newEvidence = await evidenceApi.createEvidenceRecord({
            method: isRandomEnabled
              ? random(activeEvidenceMethods)?.id
              : activeEvidenceMethods[0].id,
            program: isCreateProgramEnabled ? createdProgram.id : '',
            title: `Validation Evidence ${i} - ${specName}`,
            cadences: isRandomEnabled ? [random(evidenceCadences).id] : [],
            description: isRandomEnabled ? loremIpsum : '',
            complianceStatement: isRandomEnabled ? loremIpsum : '',
            type: EvidenceType.Validation,
          })
          validationEvidences.push(newEvidence)

          for (let j = 0; j < validationActivityCount; j++) {
            await evidenceApi.createEvidenceActivity(newEvidence.id, {
              title: `Verification Activity ${i} - ${specName}`,
              memo: isRandomEnabled ? loremIpsum : '',
              externalUrl: '',
              dueDate: yearMonthDay(new Date(Date.now())),
              status: isRandomEnabled
                ? random(Object.values(EvidenceActivityStatus))
                : EvidenceActivityStatus.Todo,
              type: isRandomEnabled
                ? random(Object.values(EvidenceActivityType))
                : EvidenceActivityType.Document,
              jiraIssueId: '',
            })
          }
        }

        toastInfo(
          <button
            onClick={() => {
              toast.dismiss()
            }}
          >
            Finished validation records & activities. Starting verification
            records...
          </button>,
        )

        for (let i = 0; i < verificationEvidenceCount; i++) {
          const newEvidence = await evidenceApi.createEvidenceRecord({
            method: isRandomEnabled
              ? random(activeEvidenceMethods)?.id
              : activeEvidenceMethods[0].id,
            program: isCreateProgramEnabled ? createdProgram.id : '',
            title: `Verification Evidence ${i} - ${specName}`,
            cadences: isRandomEnabled ? [random(evidenceCadences).id] : [],
            description: isRandomEnabled ? loremIpsum : '',
            complianceStatement: isRandomEnabled ? loremIpsum : '',
            type: EvidenceType.Verification,
          })
          verificationEvidences.push(newEvidence)

          for (let j = 0; j < verificationActivityCount; j++) {
            await evidenceApi.createEvidenceActivity(newEvidence.id, {
              title: `Verification Activity ${i} - ${specName}`,
              memo: isRandomEnabled ? loremIpsum : '',
              externalUrl: '',
              dueDate: yearMonthDay(new Date(Date.now())),
              status: isRandomEnabled
                ? random(Object.values(EvidenceActivityStatus))
                : EvidenceActivityStatus.Todo,
              type: isRandomEnabled
                ? random(Object.values(EvidenceActivityType))
                : EvidenceActivityType.Document,
              jiraIssueId: '',
            })
          }
        }

        toastInfo(
          <button
            onClick={() => {
              toast.dismiss()
            }}
          >
            Finished verification records. Starting verification links...
          </button>,
        )

        let verificationLinkCreationCount = 0

        for (let j = 0; j < verificationEvidences.length; j++) {
          if (verificationLinkCreationCount >= verificationLinkMax) {
            break
          }
          for (let i = 0; i < reqIds.length; i++) {
            if (verificationLinkCreationCount >= verificationLinkMax) {
              break
            }

            await createEvidenceLink(
              revision.documentId,
              reqIds[i],
              verificationEvidences[j].id,
              spec.id,
            )

            verificationLinkCreationCount = verificationLinkCreationCount + 1
          }
        }

        toastInfo(
          <button
            onClick={() => {
              toast.dismiss()
            }}
          >
            Finished verification links. Starting validation links...
          </button>,
        )

        let validationLinkCreationCount = 0

        for (let j = 0; j < validationEvidences.length; j++) {
          if (validationLinkCreationCount >= validationLinkMax) {
            break
          }
          for (let i = 0; i < reqIds.length; i++) {
            if (validationLinkCreationCount >= validationLinkMax) {
              break
            }

            await createEvidenceLink(
              revision.documentId,
              reqIds[i],
              validationEvidences[j].id,
              spec.id,
            )

            validationLinkCreationCount = validationLinkCreationCount + 1
          }
        }

        toastInfo(
          <button
            onClick={() => {
              toast.dismiss()
            }}
          >
            Finished validation links. Starting rev up...
          </button>,
        )

        let revisionId = revision.id

        let revupPerformedCount = 0

        for (let i = 0; i < revupCount; i++) {
          const reviewId = await revisionApi.createRevisionReview(
            spec.id,
            revisionId,
          )

          await createRevisionReviewReviewer(
            spec.id,
            revisionId,
            reviewId,
            userDetails?.id || '',
          )

          await revisionApi.createRevisionReviewReviewerEvent(
            spec.id,
            revisionId,
            reviewId,
            userDetails?.id || '',
            EntityReviewReviewerEventOperation.APPROVE,
          )

          await revisionApi.createRevisionReviewEvent(
            spec.id,
            revisionId,
            reviewId,
            EntityReviewEventOperation.APPROVE,
          )

          const newRevision = await createRevision(spec.id)
          revisionId = newRevision.id
          revupPerformedCount++
        }

        if (!suppressSuccess) {
          toastSuccess(
            <span>
              Finish creating large spec with: <br />
              {revupPerformedCount} revups
              <br />
              {verificationLinkCreationCount + validationLinkCreationCount}{' '}
              record links
              <br />
              <a
                style={{
                  color: getCssVar('--color-green4'),
                }}
                href={`/specifications/${spec.id}/document`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click me to go to document view
              </a>
              <br />
              <a
                style={{
                  color: getCssVar('--color-green4'),
                }}
                href={`/specifications/${spec.id}/matrix`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click me to go to matrix view
              </a>
            </span>,
          )
        }

        return {
          reqs,
          spec,
          revision,
          reqIds,
          verificationEvidences,
          validationEvidences,
        }
      } catch (e) {
        toastError('Failed to make large spec. Check console.', '')
        console.error('Failed to make large spec.', e)
      }
    },
    [
      validationLinkMax,
      verificationLinkMax,
      isCreateProgramEnabled,
      isRandomEnabled,
      activeCategories,
      activePhaseStates,
      isAddFrontMatter,
      isCreateAllBlockTypes,
      getAttributeByName,
      requirementCount,
      activeRequirementTypes,
      validationEvidenceCount,
      activeEvidenceMethods,
      evidenceCadences,
      validationActivityCount,
      verificationEvidenceCount,
      verificationActivityCount,
      revupCount,
      userDetails?.id,
    ],
  )

  const createParentChildSpecs = async (bidirectional: boolean) => {
    const specName = genSpecName()
    const spec1 = await createLargeSpec(
      specName + ` - Parent${bidirectional ? '/Child' : ''} `,
      true,
    )

    if (!spec1) {
      return
    }
    const spec2 = await createLargeSpec(
      specName + ` - Child${bidirectional ? '/Parent' : ''}`,
      true,
    )
    if (!spec2) {
      return
    }

    toastInfo(
      <button
        onClick={() => {
          toast.dismiss()
        }}
      >
        Starting parent child links...
      </button>,
    )

    try {
      let parentChildLinkCount = 0

      const relateSpecs = async (spec1, spec2) => {
        for (let i = 0; i < spec1.reqs.length; i++) {
          for (let j = 0; j < spec2.reqs.length; j++) {
            const parent = {
              documentId: spec1.revision.documentId,
              blockId: spec1.reqs[i].id,
              specificationId: spec1.spec.id,
            }

            const child = {
              documentId: spec2.revision.documentId,
              blockId: spec2.reqs[j].id,
              specificationId: spec2.spec.id,
            }

            const { id: linkId } = await linkApi.createParentChildLink(
              parent,
              child,
            )
            const [link] = await linkApi.getBlockLinks({ id: [linkId] })
            await blockLinkToRequirementLink(link)
            parentChildLinkCount++
          }
        }
      }

      await relateSpecs(spec1, spec2)

      if (bidirectional) {
        await relateSpecs(spec2, spec1)
      }

      toastSuccess(
        <span>
          Finish creating parent\child specs with {parentChildLinkCount}{' '}
          parent\child links!
          <br />
          <a
            style={{
              color: getCssVar('--color-green4'),
            }}
            href={`/specifications/${spec1?.spec?.id}/matrix`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click me to go to parent spec
          </a>
          <br />
          <a
            style={{
              color: getCssVar('--color-green4'),
            }}
            href={`/specifications/${spec2?.spec?.id}/matrix`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click me to go to child spec
          </a>
        </span>,
      )
    } catch (e) {
      toastError(
        'Failed to make relationships between parent child specs. Check console.',
        '',
      )
      console.error('Failed to make large spec.', e)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ fontSize: '16px' }}>Generate Spec</div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginBottom: '-10px',
        }}
      >
        <input
          type="checkbox"
          name="addFrontMatter"
          checked={isAddFrontMatter}
          onChange={() => {
            setIsAddFrontMatter((prev) => !prev)
          }}
        />
        <label htmlFor="addFrontMatter">Add frontmatter</label>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginBottom: '-10px',
        }}
      >
        <input
          type="checkbox"
          name="allBlockTypes"
          checked={isCreateAllBlockTypes}
          onChange={() => {
            setCreateAllBlockTypes((prev) => !prev)
          }}
        />
        <label htmlFor="allBlockTypes">Create all block types</label>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginBottom: '-10px',
        }}
      >
        <input
          type="checkbox"
          name="program"
          checked={isCreateProgramEnabled}
          onChange={() => {
            setIsCreateProgramEnabled((prev) => !prev)
          }}
        />
        <label htmlFor="program">Add new program to Spec/Evidences</label>
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input
          type="checkbox"
          name="random"
          checked={isRandomEnabled}
          onChange={() => {
            setIsRandomEnabled((prev) => !prev)
          }}
        />
        <label htmlFor="random">Use random values</label>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="revupCount"
          min="1"
          step="1"
          value={revupCount}
          onChange={(e) => {
            setRevupCount(Number(e.target.value))
          }}
        />
        <label htmlFor="revupCount">Rev Up Count</label>
      </div>
      <div style={{ fontSize: '12px' }}>Requirements</div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="reqCount"
          min="1"
          step="100"
          value={requirementCount}
          onChange={(e) => {
            setRequirementCount(Number(e.target.value))
          }}
        />
        <label htmlFor="reqCount">Requirement Count</label>
      </div>
      <div style={{ fontSize: '12px' }}>Verification Records</div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="verificationCount"
          min="0"
          step="100"
          value={verificationEvidenceCount}
          onChange={(e) => {
            setVerificationEvidenceCount(Number(e.target.value))
          }}
        />
        <label htmlFor="verificationCount">Count</label>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="verificationActivitiesCount"
          min="0"
          step="100"
          value={verificationActivityCount}
          onChange={(e) => {
            setVerificationActivityCount(Number(e.target.value))
          }}
        />
        <label htmlFor="verificationActivitiesCount">Activities Count</label>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          style={{
            width: '185px',
          }}
          type="number"
          id="quantity"
          name="verificationLinkMax"
          min="0"
          step="100"
          value={verificationLinkMax}
          onChange={(e) => {
            const newLinkMax =
              Number(e.target.value) >
              verificationEvidenceCount * requirementCount
                ? verificationEvidenceCount * requirementCount
                : Number(e.target.value)

            setVerificationLinkMax(newLinkMax)
          }}
        />
        <label htmlFor="verificationLinkMax">Req Link Max</label>
      </div>
      <div style={{ fontSize: '12px' }}>Validation Records</div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="validationCount"
          min="0"
          step="100"
          value={validationEvidenceCount}
          onChange={(e) => {
            setValidationEvidenceCount(Number(e.target.value))
          }}
        />
        <label htmlFor="validationCount">Count</label>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="validationActivitiesCount"
          min="0"
          step="100"
          value={validationActivityCount}
          onChange={(e) => {
            setValidationActivityCount(Number(e.target.value))
          }}
        />
        <label htmlFor="validationActivitiesCount">Activities Count</label>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <input
          type="number"
          id="quantity"
          name="validationLinkMax"
          min="0"
          step="100"
          value={validationLinkMax}
          onChange={(e) => {
            const newLinkMax =
              Number(e.target.value) >
              validationEvidenceCount * requirementCount
                ? validationEvidenceCount * requirementCount
                : Number(e.target.value)
            setValidationLinkMax(newLinkMax)
          }}
        />
        <label htmlFor="validationLinkMax">Req Link Max</label>
      </div>

      <Button
        text={`Create spec ${useDevApi ? 'Disabled on dev' : ''}`}
        disabled={useDevApi}
        onClick={() => {
          createLargeSpec()
        }}
      />
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input
          type="checkbox"
          name="bidirectional"
          checked={bidirectionalParentChild}
          onChange={() => {
            setBidirectionalParentChild((prev) => !prev)
          }}
        />
        <label htmlFor="bidirectional">
          Make bidirectional parent child relationships
        </label>
      </div>
      <Button
        text={`Create parent/child specs ${useDevApi ? 'Disabled on dev' : ''}`}
        disabled={useDevApi}
        onClick={() => createParentChildSpecs(bidirectionalParentChild)}
      />
      <div>
        (Link, req, or evidence creation over 1000 can take several mins. You
        can leave this tab open and inspect the specification.)
      </div>
    </div>
  )
}

export default () => (
  <AttributesContextProvider>
    <DevCreateLargeSpec />
  </AttributesContextProvider>
)
