import { Add, CaretDown, Chat, Copy } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import Quill from 'quill'
import {
  useCallback,
  RefObject,
  useEffect,
  useState,
  ComponentType,
  useRef,
} from 'react'
import styles from './RequirementBlock.module.css'
import { Block, BlockData } from '../../../api/v2/blocks.ts'
import { Requirement } from '../../../api/v2/requirements.ts'
import ItarIcon from '../../../assets/ItarIcon.tsx'
import { useDocumentContext } from '../../../context/DocumentContext.tsx'
import { useSectionContext } from '../../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../../context/SpecificationContext.tsx'
import { useModals } from '../../../hooks/useModals.ts'
import {
  extractRationaleDelta,
  extractShallStatementDelta,
} from '../../../lib/requirement.ts'
import { EMPTY_DELTA } from '../../../lib/string.ts'
import Chip from '../../chip'
import { DRAWER_TAB } from '../../drawer/enums.ts'
import EditableSpan from '../../editable-span/EditableSpan.tsx'
import HoverableSpan from '../../hoverable/HoverableSpan.tsx'
import QuillContent from '../../quill-content/QuillContent.tsx'
import tooltipStyles from '../../tooltip/Tooltip.module.css'
import AddHeadingBlockAction from '../block-actions/AddHeadingBlockAction.tsx'
import AddImageBlockAction from '../block-actions/AddImageBlockAction.tsx'
import AddRequirementBlockAction from '../block-actions/AddRequirementBlockAction.tsx'
import AddTableBlockAction from '../block-actions/AddTableBlockAction.tsx'
import AddTextBlockAction from '../block-actions/AddTextBlockAction.tsx'
import { BlockActionItemProps } from '../block-actions/types.ts'
import SelectableBlock from '../SelectableBlock.tsx'

interface RequirementBlockProps {
  requirementIdx: number
  block: Block<BlockData>
  requirement?: Requirement
  isActionsVisible: boolean
  isHovered: boolean
}

const ADD_MENU_ACTIONS = [
  AddRequirementBlockAction,
  AddTextBlockAction,
  AddHeadingBlockAction,
  AddImageBlockAction,
  AddTableBlockAction,
] as ComponentType<BlockActionItemProps>[]

type props = any
const AddNewBlock = ({
  block,
  isSubmenuOpen,
  setIsSubmenuOpen,
  requirement,
}: props) => {
  return (
    <div
      className={`${styles.expandableSubmenu}  ${
        isSubmenuOpen ? styles.expanded : ''
      } ${styles.basicElevation}`}
      onMouseLeave={() => setIsSubmenuOpen(false)}
    >
      <div className={styles.submenuHeader}>
        Create <CaretDown />
      </div>
      {ADD_MENU_ACTIONS.map((Action, index) => {
        return (
          <Action
            key={`action-${index}`}
            block={block}
            requirement={requirement}
            closeMenu={() => setIsSubmenuOpen(false)}
          />
        )
      })}
    </div>
  )
}

const RequirementBlock = (props: RequirementBlockProps) => {
  const { block, requirement, isHovered, isActionsVisible } = props
  const {
    specification,
    revision,
    isRationaleEnabled,
    isHistoricalRevision,
    createRequirement,
    contentIsEditable,
    publicTenant,
  } = useSpecificationContext()
  const { autoSelectBlockId, setAutoSelectBlockId } = useDocumentContext()

  const { updateRequirementBlock, sectionId, activeQuillRefs } =
    useSectionContext()
  const { openRequirementDrawer } = useModals()
  const [shouldFocusRationale, setShouldFocusRationale] =
    useState<boolean>(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false)
  const [creatingNewBlock, setCreatingNewBlock] = useState<boolean>(false)
  const [copyMessage, setCopyMessage] = useState<string>('')
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
  const toolTipDisplayTime = 1500
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const commentCount = requirement?.commentAndChangeRequestCount || 0
  const isCommentsVisible =
    !publicTenant && (isActionsVisible || commentCount > 0)
  const isCommentsHidden = !isCommentsVisible

  const update: (change: Partial<Requirement>) => Promise<void> = useCallback(
    async (change) => {
      await updateRequirementBlock(block.id, change)
    },
    [block.id, updateRequirementBlock],
  )

  const newRequirementBlockOnCtrlEnter: (
    quillRef?: RefObject<Quill | null>,
  ) => Promise<void> = useCallback(
    async (quillRef) => {
      const rangeIndex = quillRef?.current?.getSelection()?.index || 0
      const length = quillRef?.current?.getLength()

      setCreatingNewBlock(true)
      if (rangeIndex + 1 === length) {
        await createRequirement(sectionId, block.id, {})
      } else {
        const beforeDelta = quillRef?.current?.getContents(0, rangeIndex)
        const afterDelta = quillRef?.current?.getContents(rangeIndex)
        const updatedReqData = requirement?.data
          ? { data: { ...requirement.data } }
          : {
              data: {
                delta: {
                  shallStatement: EMPTY_DELTA,
                  rationale: EMPTY_DELTA,
                },
              },
            }

        if (beforeDelta) {
          updatedReqData.data.delta.shallStatement = JSON.stringify(beforeDelta)
          await update(updatedReqData)
          quillRef?.current?.setContents(beforeDelta)
        }

        const createdId = await createRequirement(sectionId, block.id, {
          data: {
            delta: {
              shallStatement: JSON.stringify(afterDelta),
              rationale: EMPTY_DELTA,
            },
          },
        })

        if (createdId) {
          setAutoSelectBlockId(createdId)
        }
      }
      setCreatingNewBlock(false)
    },
    [
      block.id,
      createRequirement,
      requirement,
      sectionId,
      setAutoSelectBlockId,
      update,
    ],
  )

  const enterWithRationaleEnabled = useCallback(() => {
    setShouldFocusRationale(true)
  }, [setShouldFocusRationale])

  useEffect(() => {
    if (shouldFocusRationale) {
      setShouldFocusRationale(false)
    }
  }, [shouldFocusRationale])

  const copyToClipboard = useCallback(
    async (text: string) => {
      try {
        await navigator.clipboard.writeText(text)
        setCopyMessage(`'${text}' Copied!`)
        setIsTooltipVisible(true)
        timerRef.current = setTimeout(() => {
          setIsTooltipVisible(false)
        }, toolTipDisplayTime)
      } catch (err) {
        setCopyMessage('Failed to copy')
        setIsTooltipVisible(true)
        timerRef.current = setTimeout(() => {
          setIsTooltipVisible(false)
        }, toolTipDisplayTime)
        captureException(err)
      }
    },
    [setCopyMessage, setIsTooltipVisible],
  )

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return (
    <SelectableBlock
      blockId={block.id}
      requirementVersionCount={requirement?.context.requirementVersionCount}
      style={{
        width: '100%',
        maxWidth: '514px',
      }}
    >
      <div className={styles.requirementBlock}>
        <div className={styles.content}>
          <div className={styles.reqHeader}>
            <EditableSpan
              placeholder="Section Number"
              value={requirement?.sectionNumber}
              onValueChange={(value) => {
                if (value !== requirement?.sectionNumber) {
                  update({ sectionNumber: value })
                }
              }}
              focusOnLoad={autoSelectBlockId === block.id}
              readOnly={!contentIsEditable}
              style={{ color: 'var(--text-color-black)', fontSize: 14 }}
            />
            <EditableSpan
              placeholder="Requirement Title"
              value={requirement?.title}
              onValueChange={(value) => {
                if (value === requirement?.title) {
                  return
                }
                update({
                  title: value,
                })
              }}
              readOnly={!contentIsEditable}
              style={{ color: 'var(--text-color-black)', fontSize: 14 }}
            />
            {!specification.external &&
              requirement?.status &&
              !isHistoricalRevision && (
                <Chip
                  className={styles.chip}
                  variant="status"
                  value={requirement.status}
                />
              )}
            {requirement?.exportControlled && (
              <div className={styles.itarContainer}>
                <div className={`${styles.tooltip} ${styles.basicElevation}`}>
                  This requirement contains export controlled technical data!
                </div>
                <ItarIcon />
              </div>
            )}
          </div>
          {/*Position relative for references modal inside of quill content*/}
          <div style={{ position: 'relative' }}>
            {requirement && (
              <QuillContent
                style={{
                  minHeight: '12px',
                }}
                className={styles.quillShallStatement}
                readOnly={!contentIsEditable || creatingNewBlock}
                placeholder="Requirement Statement"
                delta={extractShallStatementDelta(requirement)}
                block={block}
                onEnter={
                  isRationaleEnabled ? enterWithRationaleEnabled : undefined
                }
                onCtrlEnter={newRequirementBlockOnCtrlEnter}
                onValueChange={(delta, str) => {
                  if (delta === requirement?.data?.delta?.shallStatement) {
                    return
                  }

                  update({
                    shallStatement: str,
                    data: {
                      delta: {
                        shallStatement: delta || EMPTY_DELTA,
                        rationale: extractRationaleDelta(requirement),
                      },
                    },
                  })
                }}
                instanceHandler={(instance) => {
                  if (!instance) {
                    return
                  }
                  activeQuillRefs?.current.set(block.id, instance)
                }}
              />
            )}
          </div>
          {/*Position relative for references modal inside of quill content*/}
          <div style={{ position: 'relative' }}>
            {requirement && isRationaleEnabled && (
              <QuillContent
                className={styles.quillRationale}
                focusOnLoad={shouldFocusRationale}
                readOnly={!contentIsEditable || creatingNewBlock}
                placeholder="Rationale"
                delta={extractRationaleDelta(requirement)}
                block={block}
                onCtrlEnter={newRequirementBlockOnCtrlEnter}
                onValueChange={(delta, str) => {
                  if (delta === requirement?.data?.delta?.rationale) {
                    return
                  }
                  update({
                    rationale: str,
                    data: {
                      delta: {
                        shallStatement: extractShallStatementDelta(requirement),
                        rationale: delta || EMPTY_DELTA,
                      },
                    },
                  })
                }}
              />
            )}
          </div>
          {contentIsEditable && (
            <button
              className={`${styles.addRequirementButton} ${
                isHovered || isSubmenuOpen ? '' : styles.hidden
              }`}
              onClick={() => setIsSubmenuOpen(true)}
            >
              <Add />
              <AddNewBlock
                block={block}
                isSubmenuOpen={isSubmenuOpen}
                setIsSubmenuOpen={setIsSubmenuOpen}
                requirement={requirement}
              />
            </button>
          )}
        </div>
        <div className={styles.reqDetails}>
          <div className={styles.reqNum}>
            <div className={tooltipStyles.tooltipContainer}>
              <HoverableSpan
                text={`${
                  specification.specificationIdentifier || 'Document Number'
                }-${requirement?.context?.requirementIdentifier}`}
                hoverContent={
                  <Copy style={{ transform: 'scaleX(-1)' }} size={11} />
                }
                onClick={() =>
                  copyToClipboard(
                    `${
                      specification.specificationIdentifier || 'Document Number'
                    }-${requirement?.context?.requirementIdentifier}`,
                  )
                }
                readOnly={false}
              />
              {isTooltipVisible && (
                <div
                  className={`${tooltipStyles.tooltip} ${tooltipStyles.placeRight}`}
                >
                  <div className={tooltipStyles.textContent}>{copyMessage}</div>
                </div>
              )}
            </div>
          </div>
          <button
            className={`${styles.comments} ${
              isCommentsHidden ? styles.hidden : ''
            }`}
            onClick={() => {
              openRequirementDrawer({
                // @ts-expect-error - using an api v2 req for an api v1 req param
                requirement,
                initialTab: DRAWER_TAB.COMMENTS,
                specificationId: specification.id,
                revisionId: revision.id,
                onAddComment: () => {
                  updateRequirementBlock(block.id, {
                    commentAndChangeRequestCount:
                      (requirement?.commentAndChangeRequestCount || 0) + 1,
                  })
                },
                onDeleteComment: () => {
                  updateRequirementBlock(block.id, {
                    commentAndChangeRequestCount:
                      (requirement?.commentAndChangeRequestCount || 0) - 1,
                  })
                },
              })
            }}
          >
            <Chat size={16} />
            {commentCount === 0 ? '' : <span>{commentCount}</span>}
          </button>
        </div>
      </div>
    </SelectableBlock>
  )
}

export default RequirementBlock
