/**
 * Get a full name from a first and last name.
 *
 * @param {string | undefined} firstName A first name.
 * @param {string | undefined} lastName A last name.
 * @returns {string} The full name, formatted as "<firstName> <lastName>".
 */
export const getFullName = (
  firstName: string | undefined = '',
  lastName: string | undefined = '',
): string => {
  if (!firstName && !lastName) {
    return ''
  }

  if (!firstName) {
    return lastName
  }

  if (!lastName) {
    return firstName
  }

  return `${firstName} ${lastName}`
}

/**
 * Get the initials from a first name and last name.
 *
 * @param {string | undefined} firstName A first name.
 * @param {string | undefined} lastName A last name.
 * @returns {string} The uppercase initials, consisting of the first letter of
 * the first name followed by the first letter of the last name.
 */
export const getInitials = (
  firstName: string | undefined = '',
  lastName: string | undefined = '',
): string => (firstName.slice(0, 1) + lastName.slice(0, 1)).toUpperCase()

/**
 * Get the initials from a full name.
 *
 * @param {string} fullName A full name.
 * @returns {string} The uppercase initials, formed by taking the first letter
 * of each non-empty word.
 */
export const getInitialsFromFullName = (fullName: string): string =>
  fullName
    .split(' ')
    .filter((name: string) => name)
    .map((name: string) => name[0].toUpperCase())
    .join('')

/**
 * Compare two objects based on their first and last names.
 *
 * @param {Object} firstObject The first object with firstName and lastName
 * properties.
 * @param {Object} secondObject The second object with firstName and lastName
 * properties.
 * @returns {number} A negative value if the first argument is less than the
 * second argument, zero if they're equal, and a positive value otherwise.
 */
export const sortByFirstThenLastName = (
  firstObject: { firstName: string; lastName: string },
  secondObject: { firstName: string; lastName: string },
): number => {
  const result = firstObject.firstName.localeCompare(secondObject.firstName)
  return result !== 0
    ? result
    : firstObject.lastName.localeCompare(secondObject.lastName)
}
