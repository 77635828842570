import { ComponentType, MouseEventHandler, ReactElement } from 'react'
import styles from './index.module.css'

interface ButtonColorType {
  className: string
  disabledClassName: string
}

interface ButtonType {
  className?: string
  text?: string
  color?: ButtonColorType
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  fontSize?: string
  frontIcon?: ComponentType | ReactElement
  endIcon?: ComponentType | ReactElement
}

const Button = (props: ButtonType) => {
  const {
    className,
    text,
    color: colorProp,
    onClick,
    disabled,
    fontSize,
    frontIcon,
    endIcon,
  } = props
  const color = colorProp || BUTTON_COLORS.SECONDARY
  const textStyle = { fontSize: fontSize || '14px' }

  return (
    <button
      className={`${className || ''} ${styles.button} ${
        disabled ? styles[color.disabledClassName] : styles[color.className]
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.buttonContent}>
        {frontIcon && <>{frontIcon}</>}
        {text && (
          <span className={styles.text} style={textStyle}>
            {text}
          </span>
        )}
        {endIcon && <>{endIcon}</>}
      </div>
    </button>
  )
}

export const BUTTON_COLORS = {
  PRIMARY: { className: 'primary', disabledClassName: 'primaryDisabled' },
  SECONDARY: { className: 'secondary', disabledClassName: 'secondaryDisabled' },
  GREEN_WHITE_TEXT: {
    className: 'greenWhiteText',
    disabledClassName: 'greenWhiteTextDisabled',
  },
}
export default Button
