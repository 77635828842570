import {
  Add,
  ListBoxes,
  OverflowMenuHorizontal,
  RowDelete,
} from '@carbon/icons-react'
import { ColDef } from 'ag-grid-community'
import {
  AgGridReact,
  CustomCellEditorProps,
  CustomHeaderProps,
} from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styles from './TableBlock.module.css'
import useTableBlock from './useTableBlock.ts'
import { Block, RowData, TableBlockData } from '../../../../api/v2/blocks.ts'
import { useDocumentContext } from '../../../../context/DocumentContext.tsx'
import { useSpecificationContext } from '../../../../context/SpecificationContext.tsx'
import useClickOutside from '../../../../hooks/useClickOutside.ts'
import Dropdown from '../../../dropdown'
import EditableSpan from '../../../editable-span/EditableSpan.tsx'
import IconButton from '../../../icon-button/IconButton.tsx'
import QuillContent from '../../../quill-content/QuillContent.tsx'
import SelectableBlock from '../../SelectableBlock.tsx'

export const COLUMN_HEADER_CLASSNAME = styles.columnHeader

export const CELL_EDITOR_CLASSNAME = styles.cellEditor

interface AdditionalCellEditorProps {
  contentIsEditable: boolean
  displayActions: boolean
  onCellValueChange: ({ rowIndex, field, newValue }) => void
  onDuplicateRow: (duplicateIdx: number) => void
  onDeleteRow: (deleteIdx: number) => void
  block: Block<TableBlockData>
}

const CellEditor = (
  props: CustomCellEditorProps & AdditionalCellEditorProps,
) => {
  const {
    api,
    initialValue,
    value,
    eventKey,
    colDef,
    node,
    contentIsEditable,
    onCellValueChange,
    displayActions,
    onDuplicateRow,
    onDeleteRow,
  } = props
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const containerRef = useClickOutside((e) => {
    if (containerRef.current && !containerRef.current.contains(e?.target)) {
      setIsDropdownOpen(false)
    }
  })

  const cellWrapperRef = useRef<HTMLElement | null>(null)
  const actionsIconRef = useRef<HTMLButtonElement | null>(null)
  const isDisplayingActions = displayActions && contentIsEditable

  useEffect(() => {
    if (isDropdownOpen) {
      api.clearFocusedCell()
    }
  }, [api, isDropdownOpen])

  useEffect(() => {
    if (containerRef.current && !cellWrapperRef.current) {
      cellWrapperRef.current = containerRef.current.closest('.ag-cell-wrapper')
    }
    return () => {
      cellWrapperRef.current = null
    }
  }, [containerRef])

  const currentRowIdx = node.rowIndex || -1
  const emptyRowHeights =
    currentRowIdx > -1 ? [...Array(currentRowIdx + 1).keys()] : []

  const rows = emptyRowHeights.map((idx) => api.getDisplayedRowAtIndex(idx))

  const rowHeights =
    currentRowIdx > -1
      ? rows.reduce((prev, curr) => (curr?.rowHeight || 0) + prev, 0)
      : 0

  const dropdownTop = rowHeights + 30

  return (
    <div className={CELL_EDITOR_CLASSNAME} ref={containerRef}>
      {cellWrapperRef.current &&
        createPortal(
          <>
            {isDisplayingActions && (
              <IconButton
                className="actionsMenu"
                ref={actionsIconRef}
                onClick={() => {
                  setIsDropdownOpen((val) => !val)
                }}
              >
                <OverflowMenuHorizontal size={20} />
              </IconButton>
            )}
          </>,
          cellWrapperRef.current,
        )}
      <QuillContent
        style={{ width: '100%', height: '100%', padding: '10px 0' }}
        className={styles.quill}
        focusOnLoad={eventKey === 'Enter'}
        delta={value || initialValue}
        onValueChange={() => {
          api.resetRowHeights()
        }}
        onBlur={(value) => {
          onCellValueChange({
            rowIndex: node.rowIndex,
            field: colDef.field,
            newValue: value,
          })
          api.stopEditing()
        }}
        onEnter={(quillRef) => {
          if (quillRef?.current) {
            quillRef?.current?.blur()
          }

          api.tabToNextCell()
        }}
        readOnly={!contentIsEditable}
        modalPortal={Portal}
        modalStyle={{
          position: 'absolute',
          top: dropdownTop,
          left: 10,
        }}
      />
      <Portal>
        <Dropdown
          isOpen={isDropdownOpen}
          className={styles.dropdown}
          style={{
            top: dropdownTop,
            left: 10,
          }}
        >
          {contentIsEditable &&
            (api.getColumns()?.length || 0) > 1 &&
            api.getDisplayedRowCount() > 1 && (
              <button
                className={styles.actionItem}
                onClick={() => {
                  if (node.rowIndex !== null) {
                    onDeleteRow(node.rowIndex)
                  }

                  setIsDropdownOpen(false)
                }}
              >
                <RowDelete />
                Delete row
              </button>
            )}
          {contentIsEditable && (
            <button
              className={styles.actionItem}
              onClick={() => {
                if (node.rowIndex !== null) {
                  onDuplicateRow(node.rowIndex)
                }
                setIsDropdownOpen(false)
              }}
            >
              <ListBoxes />
              Duplicate row
            </button>
          )}
        </Dropdown>
      </Portal>
    </div>
  )
}

interface AdditionalColumnHeaderProps {
  contentIsEditable: boolean
  onDeleteColumn: (field?: string) => void
  onDuplicateColumn: (field: string) => void
  onHeaderValueChange: (field: string, val?: string) => void
}
const ColumnHeader = (
  props: CustomHeaderProps & AdditionalColumnHeaderProps,
) => {
  const {
    api,
    onHeaderValueChange,
    column,
    contentIsEditable,
    onDeleteColumn,
    onDuplicateColumn,
  } = props

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const containerRef = useClickOutside(() => {
    // On click of action, use click outside is getting triggered, this staggers the events so the action can fire before closing
    setTimeout(() => setIsDropdownOpen(false), 200)
  })

  const currentColId = column.getId()
  const colDefIdx =
    api.getColumns()?.findIndex((col) => col.getId() === currentColId) || -1

  const columnWidths =
    colDefIdx > -1 && api
      ? (api.getColumns() || []).reduce(
          (prev, curr, idx) =>
            colDefIdx >= idx ? curr.getActualWidth() + prev : prev,
          0,
        )
      : 0

  const dropdownLeft = columnWidths - 20

  const headerName = column.getColDef().headerName

  return (
    <div ref={containerRef} className={COLUMN_HEADER_CLASSNAME}>
      <EditableSpan
        readOnly={!contentIsEditable}
        style={{
          width: '100%',
          minWidth: 0,
          minHeight: '12px',
          padding: '8px 0',
          color: 'var(--text-color-black)',
        }}
        placeholder=""
        onValueChange={(update) => {
          if (update !== headerName) {
            onHeaderValueChange(column.getColDef().field || '', update)
          }
        }}
        value={headerName || ''}
      />
      {contentIsEditable && (
        <IconButton
          className={styles.menu}
          onClick={() => {
            setIsDropdownOpen((val) => !val)
          }}
        >
          <OverflowMenuHorizontal size={20} />
        </IconButton>
      )}
      <Portal>
        <Dropdown
          isOpen={isDropdownOpen}
          className={styles.dropdown}
          style={{
            top: 25,
            left: dropdownLeft,
          }}
        >
          {contentIsEditable && (api.getColumns()?.length || 0) > 1 && (
            <button
              className={styles.actionItem}
              onClick={() => {
                onDeleteColumn(column.getColDef().field)
                setIsDropdownOpen(false)
              }}
            >
              <RowDelete />
              Delete column
            </button>
          )}
          {contentIsEditable && (
            <button
              className={styles.actionItem}
              onClick={() => {
                onDuplicateColumn(column.getColDef().field || '')
                setIsDropdownOpen(false)
              }}
            >
              <ListBoxes />
              Duplicate column
            </button>
          )}
        </Dropdown>
      </Portal>
    </div>
  )
}

const Portal = ({ children }) => {
  const mount = document.getElementById('portal-root')
  return mount ? createPortal(children, mount) : children
}

interface TableBlockProps {
  block: Block<TableBlockData>
}

const TableBlock = (props: TableBlockProps) => {
  const { block } = props
  const { contentIsEditable } = useSpecificationContext()
  const { autoSelectBlockId } = useDocumentContext()

  const {
    agGridRef,
    colDefs,
    rowData,
    isHoveringLastRow,
    isHoveringLastCol,
    onTableNameChange,
    onHeaderValueChange,
    onCellValueChange,
    onCellMouseOverOrOut,
    onAddRow,
    onAddColumn,
    onDeleteColumn,
    onDuplicateColumn,
    onColumnDragEnd,
    onDuplicateRow,
    onDeleteRow,
    onRowDragEnd,
    onGridMouseLeave,
  } = useTableBlock(block)

  const rowDataContents = useMemo(
    () => rowData.map((rowData) => rowData.content),
    [rowData],
  )

  const colDefsWithRenders = colDefs.map((colDef, idx) => ({
    ...colDef,
    // Header comps
    headerComponent: ColumnHeader,
    headerComponentParams: {
      contentIsEditable,
      onDeleteColumn,
      onDuplicateColumn,
      onHeaderValueChange,
      block,
    } as AdditionalColumnHeaderProps,

    // Cells comps
    cellEditor: CellEditor,
    cellEditorParams: {
      displayActions: idx === 0,
      contentIsEditable,
      onCellValueChange,
      onDuplicateRow,
      onDeleteRow,
      block,
    } as AdditionalCellEditorProps,
    cellRenderer: CellEditor,
    cellRendererParams: {
      displayActions: idx === 0,
      contentIsEditable,
      onCellValueChange,
      onDuplicateRow,
      onDeleteRow,
      block,
    } as AdditionalCellEditorProps,
  })) as ColDef<RowData, string>[]

  return (
    <SelectableBlock className={styles.container} blockId={block.id}>
      <div
        className={`ag-theme-quartz ${styles.agContainer}`}
        onMouseLeave={onGridMouseLeave}
      >
        <AgGridReact
          ref={agGridRef}
          className={styles.agGrid}
          rowData={rowDataContents}
          columnDefs={colDefsWithRenders}
          onCellMouseOver={onCellMouseOverOrOut}
          onCellMouseOut={onCellMouseOverOrOut}
          onDragStopped={onColumnDragEnd}
          onRowDragEnd={onRowDragEnd}
          getRowHeight={(params) => {
            const cells = [
              ...document.querySelectorAll(
                `[row-id="${params.node.id}"] .ag-cell`,
              ),
            ]

            const largestCellHeight = cells.reduce(
              (prev, curr) =>
                curr.children[0].getBoundingClientRect().height > prev
                  ? curr.children[0].getBoundingClientRect().height
                  : prev,
              36,
            )

            return largestCellHeight
          }}
          // singleClickEdit
          suppressMovableColumns={!contentIsEditable}
          suppressRowDrag={!contentIsEditable}
          suppressClickEdit={true}
          rowDragManaged
          domLayout="print"
          animateRows={false}
          columnMenu="new"
          rowHeight={36}
          headerHeight={36}
        />
        {contentIsEditable && (
          <IconButton
            className={`${styles.addRow} ${
              isHoveringLastRow ? '' : styles.hidden
            }`}
            onClick={onAddRow}
          >
            <Add size={16} />
          </IconButton>
        )}
        {contentIsEditable && (
          <IconButton
            className={`${styles.addCol} ${
              isHoveringLastCol ? '' : styles.hidden
            }`}
            onClick={onAddColumn}
          >
            <Add size={16} />
          </IconButton>
        )}
        <div id="portal-root" className={styles.portalRoot} />
        <div className={styles.blockName}>
          <EditableSpan
            style={{
              fontSize: 12,
              lineHeight: '18px',
              color: 'var(--text-color-gray-light)',
            }}
            focusOnLoad={autoSelectBlockId === block.id}
            placeholder="Table title..."
            value={block.data.name}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                e.currentTarget?.blur?.()
              }
            }}
            onValueChange={onTableNameChange}
            readOnly={!contentIsEditable}
          />
        </div>
      </div>
    </SelectableBlock>
  )
}

export default TableBlock
