import {
  Document,
  Time,
  RuleTest,
  ScatterMatrix,
  CaretDown,
} from '@carbon/icons-react'
import { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import styles from './SharedSpecificationPage.module.css'
import StatsDropdown from './stats-dropdown/StatsDropdown'
import Button, { BUTTON_COLORS } from '../../components/button'
import { useSharedSpecificationContext } from '../../context/SharedSpecificationContext'
import useClickOutside from '../../hooks/useClickOutside'
import { useModals } from '../../hooks/useModals'
import { monthDayYear } from '../../lib/date'

const SharedSpecificationPage = () => {
  const { pathname } = useLocation()
  const { specificationSnapshot, userIsProjectAdmin, reloadReviews, reviews } =
    useSharedSpecificationContext()
  const { openSharedProjectSpecificationReviewersModal } = useModals()

  const isExternal =
    specificationSnapshot?.contents?.specification?.externalOrigin !== null
  const version = isExternal
    ? specificationSnapshot?.contents?.specification?.externalOrigin?.version
    : `V${specificationSnapshot?.contents?.revision?.version}`
  const [isStatsDropdownOpen, setIsStatsDropdownOpen] = useState(false)
  const containerRef = useClickOutside(() => {
    setIsStatsDropdownOpen(false)
  })

  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.rightHeader}>
          <div className={styles.dateTime}>
            <Time size={16} />
            {specificationSnapshot?.createdOn &&
              monthDayYear(specificationSnapshot.createdOn)}
          </div>
        </div>
        <div className={styles.leftHeader}>
          <div className={styles.pageDescription}>Shared specification</div>
          <div className={styles.headerRow}>
            <div className={styles.title}>
              {specificationSnapshot?.specificationName || 'Untitled'}
            </div>
            <div>
              <span className={styles.version}>{version}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          <Link to={'./document'} draggable="false">
            <div
              className={`${styles.tab} ${
                pathname.includes('document') ? styles.active : ''
              }`}
            >
              <Document size={20} />
              Document
            </div>
          </Link>
          <Link to={`./matrix`} draggable="false">
            <div
              className={`${styles.tab} ${
                pathname.includes('matrix') ? styles.active : ''
              }`}
            >
              <ScatterMatrix size={20} />
              Compliance Matrix
            </div>
          </Link>
        </div>
        <div className={styles.buttons}>
          {userIsProjectAdmin && (
            <Button
              text="Manage Reviewers"
              onClick={() => {
                openSharedProjectSpecificationReviewersModal({
                  specificationSnapshot,
                  reloadReviews,
                })
              }}
              endIcon={<RuleTest size={16} />}
              color={BUTTON_COLORS.PRIMARY}
            />
          )}
          <div className={styles.statsDropdown} ref={containerRef}>
            <Button
              onClick={() => setIsStatsDropdownOpen(!isStatsDropdownOpen)}
              text="Stats"
              endIcon={<CaretDown />}
            />
            <StatsDropdown isOpen={isStatsDropdownOpen} reviews={reviews} />
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default SharedSpecificationPage
