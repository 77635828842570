import {
  Document,
  // Image,
  Row,
  Table,
  // Table
} from '@carbon/icons-react'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import styles from './ReferenceSearchModal.module.css'

import * as blocksApi from '../../api/v2/blocks.ts'
import { GetImageBlockResponse } from '../../api/v2/blocks.ts'
import * as searchApi from '../../api/v2/search.ts'
import { SearchRequirementContent } from '../../api/v2/search.ts'
import * as specsApi from '../../api/v2/specifications.ts'
import { Specification } from '../../api/v2/specifications.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import SearchInput from '../../pages/search/input/SearchInput.tsx'
import { FilterKey } from '../filter-menu/types.ts'

const PAGE_SIZE = 3

type ImageBlockSearchResponse = GetImageBlockResponse['blocks'][0]

interface ReferenceSearchModalProps {
  style?: CSSProperties
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onRequirementClick: (result: SearchRequirementContent) => void
  onSpecificationClick: (result: Specification) => void
  onBlockClick: (result: ImageBlockSearchResponse) => void
}

const ReferenceSearchModal = (props: ReferenceSearchModalProps) => {
  const {
    style,
    isOpen,
    setIsOpen,
    onRequirementClick,
    onSpecificationClick,
    onBlockClick,
  } = props

  const [reqSearchPage, setReqSearchPage] = useState(0)
  const reqSearchTokens = useRef<Array<string | null>>([])
  const [reqSearchResults, setReqSearchResults] = useState<
    Array<SearchRequirementContent>
  >([])
  const [allReqSearchResults, setAllReqSearchResults] = useState<
    Array<SearchRequirementContent>
  >([])

  const [specSearchPage, setSpecSearchPage] = useState(0)
  const specSearchTokens = useRef<Array<string | null>>([])
  const [specSearchResults, setSpecSearchResults] = useState<Specification[]>(
    [],
  )
  const [allSpecSearchResults, setAllSpecSearchResults] = useState<
    Specification[]
  >([])

  const [imageBlockSearchPage, setImageBlockSearchPage] = useState(0)
  const imageBlockSearchTokens = useRef<Array<string | null>>([])
  const [imageBlockSearchResults, setImageBlockSearchResults] = useState<
    Array<any>
  >([])
  const [allImageBlockSearchResults, setAllImageBlockSearchResults] = useState<
    Array<any>
  >([])

  const [tableBlockSearchPage, setTableBlockSearchPage] = useState(0)
  const tableBlockSearchTokens = useRef<Array<string | null>>([])
  const [tableBlockSearchResults, setTableBlockSearchResults] = useState<
    Array<any>
  >([])
  const [allTableBlockSearchResults, setAllTableBlockSearchResults] = useState<
    Array<any>
  >([])

  const previousQuery = useRef({ query: '', filters: [] })
  const [query, setQuery] = useState('')

  const inputRef = useRef<any>(null)

  const modalRef = useClickOutside(() => setIsOpen(false))

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    const loadReqData = async () => {
      if (
        previousQuery.current.query !== query
        // || previousQuery.current.filters !== filters
      ) {
        reqSearchTokens.current = []
        previousQuery.current.query = query
        // previousQuery.current.filters = filters
        if (reqSearchPage !== 0) {
          setReqSearchPage(0)
          return
        }
      }

      const token = reqSearchTokens.current[reqSearchPage] || null
      const {
        contents,
        requestToken,
        nextToken = null,
      } = await searchApi.getSearchRequirements({
        query,
        limit: PAGE_SIZE,
        token,
        // filters
        filters: {} as Record<FilterKey, Array<any>>,
      })
      reqSearchTokens.current[reqSearchPage] = requestToken
      reqSearchTokens.current[reqSearchPage + 1] = nextToken
      setAllReqSearchResults([...allReqSearchResults, ...contents])
      setReqSearchResults(contents)
    }

    loadReqData()
  }, [query, reqSearchPage])

  useEffect(() => {
    const loadSpecData = async () => {
      if (
        previousQuery.current.query !== query
        // || previousQuery.current.filters !== filters
      ) {
        specSearchTokens.current = []
        previousQuery.current.query = query
        // previousQuery.current.filters = filters
        if (specSearchPage !== 0) {
          setSpecSearchPage(0)
          return
        }
      }

      const requestToken = specSearchTokens.current[specSearchPage] || null
      const resp = await specsApi.searchSpecifications({
        query,
        limit: PAGE_SIZE,
        token: requestToken,
        // filters
        // filters: {} as Record<FilterKey, Array<any>>,
      })

      const { specifications, token: nextToken } = resp
      specSearchTokens.current[specSearchPage] = requestToken
      specSearchTokens.current[specSearchPage + 1] = nextToken
      setAllSpecSearchResults([...allSpecSearchResults, ...specifications])
      setSpecSearchResults(specifications)
    }
    loadSpecData()
  }, [query, specSearchPage])

  useEffect(() => {
    const loadImageBlockData = async () => {
      if (previousQuery.current.query !== query) {
        imageBlockSearchTokens.current = []
        previousQuery.current.query = query
        if (imageBlockSearchPage !== 0) {
          setImageBlockSearchPage(0)
          return
        }
      }

      const requestToken =
        imageBlockSearchTokens.current[imageBlockSearchPage] || null
      const resp = await blocksApi.getImageBlocks({
        name: query,
      })

      const { blocks, token: nextToken } = resp
      imageBlockSearchTokens.current[imageBlockSearchPage] = requestToken
      imageBlockSearchTokens.current[imageBlockSearchPage + 1] = nextToken
      setAllImageBlockSearchResults([...allImageBlockSearchResults, ...blocks])
      setImageBlockSearchResults(blocks)
    }

    loadImageBlockData()
  }, [query, imageBlockSearchPage])

  useEffect(() => {
    const loadTableBlockData = async () => {
      if (previousQuery.current.query !== query) {
        tableBlockSearchTokens.current = []
        previousQuery.current.query = query
        if (tableBlockSearchPage !== 0) {
          setTableBlockSearchPage(0)
          return
        }
      }

      const requestToken =
        tableBlockSearchTokens.current[tableBlockSearchPage] || null
      const resp = await blocksApi.getTableBlocks({
        name: query,
      })

      const { blocks, token: nextToken } = resp
      tableBlockSearchTokens.current[tableBlockSearchPage] = requestToken
      tableBlockSearchTokens.current[tableBlockSearchPage + 1] = nextToken
      setAllTableBlockSearchResults([...allTableBlockSearchResults, ...blocks])
      setTableBlockSearchResults(blocks)
    }

    loadTableBlockData()
  }, [query, tableBlockSearchPage])

  const displayMoreReqs =
    !!reqSearchTokens.current[reqSearchPage + 1] &&
    !(reqSearchResults.length < PAGE_SIZE)

  const displayMoreSpecs =
    !!specSearchTokens.current[specSearchPage + 1] &&
    !(specSearchResults.length < PAGE_SIZE)

  const displayMoreImageBlocks =
    !!imageBlockSearchTokens.current[imageBlockSearchPage + 1] &&
    !(imageBlockSearchResults.length < PAGE_SIZE)

  const displayMoreTableBlocks =
    !!tableBlockSearchTokens.current[tableBlockSearchPage + 1] &&
    !(tableBlockSearchResults.length < PAGE_SIZE)

  return (
    <div ref={modalRef} className={styles.container} style={style}>
      <SearchInput
        className={styles.searchInput}
        displayBtn={false}
        setQueryOnKeyUp
        innerRef={inputRef}
        query={query}
        setQuery={(query) => {
          if (previousQuery.current.query !== query) {
            setAllSpecSearchResults([])
            setAllReqSearchResults([])
            setAllImageBlockSearchResults([])
            setAllTableBlockSearchResults([])
          }
          setQuery(query)
        }}
        placeholder="Reference a requirement, specification, or image"
      />
      <div className={styles.subheader}>
        Requirements <Row size={12} />
      </div>
      <div className={styles.resultsSection}>
        {allReqSearchResults.map((result) => {
          return (
            <button
              key={result.requirement.id}
              className={styles.result}
              onClick={() => onRequirementClick(result)}
            >
              <span className={styles.title}>
                {result.requirement.title || 'Untitled'}
              </span>
              <span className={styles.identifier}>
                {result.specification.specificationIdentifier}
              </span>
            </button>
          )
        })}
        {displayMoreReqs && (
          <button
            className={styles.showMore}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              if (reqSearchTokens.current[reqSearchPage + 1]) {
                setReqSearchPage(reqSearchPage + 1)
              }
            }}
          >
            <span>Show more</span>
          </button>
        )}
      </div>
      <div className={styles.subheader}>
        Specifications <Document size={12} />
      </div>
      <div className={styles.resultsSection}>
        {allSpecSearchResults.map((spec) => {
          return (
            <button
              key={spec.id}
              className={styles.result}
              onClick={() => onSpecificationClick(spec)}
            >
              <div className={styles.title}>{spec.name || 'Untitled'}</div>
              <span className={styles.identifier}>
                {spec.specificationIdentifier}
              </span>
            </button>
          )
        })}
        {displayMoreSpecs && (
          <button
            className={styles.showMore}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (specSearchTokens.current[specSearchPage + 1]) {
                setSpecSearchPage(specSearchPage + 1)
              }
            }}
          >
            <span>Show more</span>
          </button>
        )}
      </div>
      <div className={styles.subheader}>
        Images <Document size={12} />
      </div>
      <div className={styles.resultsSection}>
        {allImageBlockSearchResults.map((imageBlock) => {
          return (
            <button
              key={imageBlock.id}
              className={styles.result}
              onClick={() => onBlockClick(imageBlock)}
            >
              <div className={styles.title}>
                {imageBlock.data.name || 'Untitled'}
              </div>
              <span className={styles.identifier}>
                {imageBlock?.specification?.identifier}
              </span>
            </button>
          )
        })}
        {displayMoreImageBlocks && (
          <button
            className={styles.showMore}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (imageBlockSearchTokens.current[imageBlockSearchPage + 1]) {
                setImageBlockSearchPage(imageBlockSearchPage + 1)
              }
            }}
          >
            <span>Show more</span>
          </button>
        )}
      </div>
      <div className={styles.subheader}>
        Tables <Table size={12} />
      </div>
      <div className={styles.resultsSection}>
        {allTableBlockSearchResults.map((tableBlock) => (
          <button
            key={tableBlock.id}
            className={styles.result}
            onClick={() => onBlockClick(tableBlock)}
          >
            <div className={styles.title}>
              {tableBlock.data.name || 'Untitled'}
            </div>
            <span className={styles.identifier}>
              {tableBlock?.specification?.identifier}
            </span>
          </button>
        ))}
        {displayMoreTableBlocks && (
          <button
            className={styles.showMore}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (tableBlockSearchTokens.current[tableBlockSearchPage + 1]) {
                setTableBlockSearchPage(tableBlockSearchPage + 1)
              }
            }}
          >
            <span>Show more</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default ReferenceSearchModal
