import { ColDef } from 'ag-grid-community'
import {
  AgGridReact,
  CustomCellEditorProps,
  CustomHeaderProps,
} from 'ag-grid-react'
import { useMemo } from 'react'
import styles from './SharedTableBlock.module.css'
import { RowData, TableBlockData } from '../../../api/v2/blocks.ts'
import { SharedBlock } from '../../../api/v2/projects.ts'
import useTableBlock from '../../../components/block-editor/blocks/table/useTableBlock.ts'
import QuillContent from '../../../components/quill-content/QuillContent.tsx'

const ColumnHeader = (props: CustomHeaderProps) => {
  const { column } = props

  const headerName = column.getColDef().headerName

  return <div className={styles.columnHeader}>{headerName}</div>
}

const CellRenderer = (props: CustomCellEditorProps) => {
  const { value } = props

  return (
    <div className={styles.cell}>
      <QuillContent
        style={{ width: '100%', height: '100%', padding: '10px 0' }}
        className={styles.quill}
        delta={value}
        readOnly
      />
    </div>
  )
}

const SharedTableBlock = (props: { block: SharedBlock<TableBlockData> }) => {
  const { block } = props
  const { agGridRef, colDefs, rowData } = useTableBlock(block)

  const rowDataContents = useMemo(
    () => rowData.map((rowData) => rowData.content),
    [rowData],
  )

  const colDefsWithRenders = colDefs.map((colDef) => ({
    ...colDef,
    editable: false,
    headerComponent: ColumnHeader,
    cellRenderer: CellRenderer,
  })) as ColDef<RowData, string>[]

  return (
    <div className={styles.container}>
      <div className={`ag-theme-quartz ${styles.agContainer}`}>
        <AgGridReact
          ref={agGridRef}
          className={styles.agGrid}
          rowData={rowDataContents}
          columnDefs={colDefsWithRenders}
          suppressMovableColumns
          suppressRowDrag
          suppressClickEdit
          rowDragManaged
          domLayout="print"
          animateRows={false}
          columnMenu="new"
          rowHeight={36}
          headerHeight={36}
        />
        {block.data.name && (
          <div className={styles.blockName}>
            <span>{block.data.name}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SharedTableBlock
