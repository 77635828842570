const ItarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    viewBox="0 0 23 27"
    fill="none"
  >
    <g filter="url(#filter0_d_358_20863)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 4L5 7.64833C5.15943 12.8301 6.37271 19.1513 11.5 21C16.6837 19.2042 17.78 12.775 18 7.64833L11.5 4Z"
        fill="#DA1E28"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_358_20863"
        x="0"
        y="0"
        width="23"
        height="27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_358_20863"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_358_20863"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default ItarIcon
