import { KeyboardEventHandler, useCallback } from 'react'
import { ImageBlockData } from '../../../api/v2/blocks.ts'
import { Block } from '../../../api/v2/blocks.ts'
import { useDocumentContext } from '../../../context/DocumentContext.tsx'
import { useSectionContext } from '../../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../../context/SpecificationContext.tsx'
import EditableSpan from '../../editable-span/EditableSpan.tsx'
import SelectableBlock from '../SelectableBlock.tsx'

interface ImageBlockProps {
  block: Block<ImageBlockData>
  imgSrc: string
}

const ImageBlock = (props: ImageBlockProps) => {
  const { block, imgSrc } = props
  const id = block?.id
  const { autoSelectBlockId } = useDocumentContext()
  const { updateImageBlock, createTextBlock } = useSectionContext()
  const { contentIsEditable } = useSpecificationContext()

  const onKeyDown: KeyboardEventHandler<HTMLSpanElement> = useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        const selection = window.getSelection()
        const offset = selection?.anchorOffset ?? 0
        const length = selection?.anchorNode?.textContent?.trim().length ?? 0
        if (offset === length) {
          await createTextBlock(id)
        } else {
          e.currentTarget?.blur?.()
        }
      }
    },
    [createTextBlock, id],
  )

  return (
    <SelectableBlock style={{ maxWidth: '514px' }} blockId={block.id}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {imgSrc && (
          <img
            style={{
              maxWidth: '514px',
              height: 'auto',
            }}
            src={imgSrc}
            alt={`Block Img ${id}`}
          />
        )}
        <div
          style={{
            alignSelf: 'center',
            marginTop: '10px',
            height: '18px',
          }}
        >
          <EditableSpan
            style={{
              fontSize: 12,
              lineHeight: '18px',
              color: 'var(--text-color-gray-light)',
            }}
            focusOnLoad={autoSelectBlockId === block.id}
            placeholder="Image title..."
            value={block.data.name}
            onKeyDown={onKeyDown}
            onValueChange={(value) => {
              updateImageBlock(block.id, value)
            }}
            readOnly={!contentIsEditable}
          />
        </div>
      </div>
    </SelectableBlock>
  )
}

export default ImageBlock
