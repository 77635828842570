import { CaretDown, CaretUp } from '@carbon/icons-react'
import { useState } from 'react'
import Dropdown from '.'
import styles from './MultiSelectInputDropdown.module.css'
import useClickOutside from '../../hooks/useClickOutside'
import Avatar from '../avatar'
import { AvatarSize } from '../avatar/constants'
import Tag, { TAG_COLORS } from '../tag'

export interface OptionType {
  id: string
  label: string
}

export interface MultiSelectInputDropdownProps {
  options: OptionType[]
  selected: OptionType[]
  placeholder: string
  onSelect: (selectedOptions: OptionType[]) => void
}

const MultiSelectInputDropdown = (props: MultiSelectInputDropdownProps) => {
  const { selected, options, placeholder, onSelect } = props
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useClickOutside(() => {
    setQuery('')
    setIsOpen(false)
  })

  const [query, setQuery] = useState<string>('')

  const unselectedOptions = options.filter(
    (opt) => !selected.some((selectedOpt) => selectedOpt.id === opt.id),
  )

  const filteredOptions = unselectedOptions.filter((opt) =>
    opt.label.toLowerCase().includes(query.toLowerCase()),
  )

  const handleSelect = (option: OptionType) => {
    onSelect([...selected, option])
  }

  const handleCancel = (canceled: OptionType) => {
    onSelect(selected.filter((sel) => sel.id !== canceled.id))
  }

  return (
    <div
      ref={containerRef}
      className={`${styles.container} ${styles.basicElevation}`}
    >
      <div className={styles.tagsInputContainer}>
        {selected.map((sel) => (
          <Tag
            key={sel.id}
            onCancel={() => handleCancel(sel)}
            color={TAG_COLORS.white2}
            uppercase={false}
          >
            <Avatar fullName={sel.label} showName size={AvatarSize.Small} />
          </Tag>
        ))}
        <input
          className={styles.input}
          placeholder={placeholder}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => {
            setIsOpen(true)
            setQuery('')
          }}
        />
      </div>
      <button
        onClick={(e) => {
          e.preventDefault()
          setIsOpen((prev) => !prev)
        }}
      >
        <CaretUp className={isOpen ? '' : styles.hide} />
        <CaretDown className={isOpen ? styles.hide : ''} />
      </button>
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option) => (
            <div key={option.id} className={styles.dropdownItem}>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  handleSelect(option)
                }}
              >
                <div className={styles.label}>
                  <Avatar
                    fullName={option.label}
                    showName
                    size={AvatarSize.Medium}
                  />
                </div>
              </button>
            </div>
          ))
        ) : (
          <div className={styles.label}>No results</div>
        )}
      </Dropdown>
    </div>
  )
}

export default MultiSelectInputDropdown
