import api from './api.ts'
import { AttributeValueResponse } from './attributes.ts'
import { BlockData, BlockType } from './blocks.ts'
import { RequirementData } from './requirements.ts'
import { UsersResponse } from './users.ts'
import { EntityType } from '../../types/enums.ts'
import { UUID } from '../utilityTypes.ts'

const createUrl = (projectId?: string, snapshotId?: string) =>
  `/api/v2/projects${projectId ? `/${projectId}` : ''}${
    snapshotId ? `/snapshots/${snapshotId}` : ''
  }`

export type ProjectMetadataStylesResponse = {
  COLOR_FONT: string
  COLOR_BG: string
  COLOR_BG_HOVER: string
}

export interface ProjectMetadataResponse {
  STYLES: ProjectMetadataStylesResponse
}

export type SharedSpecificationAttributeValue = Omit<
  AttributeValueResponse,
  'entityCount'
>

export interface SharedSpecificationRequirement
  extends Omit<RequirementData, 'data' | 'exportControlled'> {
  id: UUID
  identifier: number
}
interface ProjectResponse {
  id: string
  name: string
  createdByTenant: { id: string; name: string }
  createdOn: string
  sharedWithTenant?: { id: string; name: string }
  metadata?: ProjectMetadataResponse
}

export interface Project extends Omit<ProjectResponse, 'createdOn'> {
  createdOn: Date
}

const withDates = (proj: ProjectResponse): Project => ({
  ...proj,
  createdOn: new Date(proj.createdOn),
})

interface CreateProjectRequest {
  name: string
  metadata: ProjectMetadataResponse
}

export const createProject = async (
  project: CreateProjectRequest,
): Promise<Project> => {
  const sharedProject = await api.post(createUrl(), {
    body: project,
  })
  return withDates(sharedProject)
}

export const getProjects = async (): Promise<Project[]> => {
  const { projects } = await api.get(createUrl())
  return projects.map(withDates)
}

export const getProject = async (projectId: string): Promise<Project> => {
  const project = await api.get(createUrl(projectId))
  return withDates(project)
}

type UpdateProjectRequest = {
  program: string | null
}

export const updateProject = async (
  projectId: UUID,
  update: UpdateProjectRequest,
): Promise<Project> => {
  const project = await api.patch(createUrl(projectId), {
    body: update,
  })
  return withDates(project)
}

export type SpecificationSnapshotAttributeValue = Omit<
  AttributeValueResponse,
  'entityCount'
>

export interface SpecificationSnapshotData {
  id: UUID
  specificationName: string
  revisionVersion: number
  requirementCount: number
  createdOn: Date
  specificationId: UUID
  project: {
    id: string
    name: string
    metadata?: ProjectMetadataResponse
  }
  specificationProgram: SpecificationSnapshotAttributeValue
}

export const getSpecificationSnapshots: (
  projectId: string,
) => Promise<SpecificationSnapshotData[]> = async (projectId) => {
  const res = await api.get(`/api/v2/projects/${projectId}/snapshots`)
  return res.snapshots.map((snapshot: SpecificationSnapshotData) => ({
    ...snapshot,
    createdOn: new Date(snapshot.createdOn),
  }))
}

interface SpecificationSnapshotCustomAttribute {
  id: UUID
  name: string
  entityTypes: EntityType[]
  values: SpecificationSnapshotAttributeValue[]
}

export interface SharedBlock<D extends BlockData> {
  id: UUID
  type: BlockType
  data: D
}

interface SpecificationSnapshotRequirement
  extends Omit<RequirementData, 'data' | 'exportControlled'> {
  id: UUID
  identifier: number
}

interface SpecificationEvidence {
  id: UUID
  title: string
  descriptionOfActivity: string
  method: UUID
}

interface SpecificationSnapshotContent {
  id: UUID
  customAttributes: SpecificationSnapshotCustomAttribute[]
  documentBlocks: SharedBlock<BlockData>[]
  requirements: SpecificationSnapshotRequirement[]
  evidence: SpecificationEvidence[]
  revision: {
    id: UUID
    version: number
  }
  specification: {
    id: UUID
    name: string
    identifier: string
    externalOrigin: {
      organizationName: string
      version: string | null
    } | null
  }
  version: number
}

export interface SpecificationSnapshot extends SpecificationSnapshotData {
  contents: SpecificationSnapshotContent
}

export const getSpecificationSnapshot: (
  projectId: UUID,
  snapshotId: UUID,
) => Promise<SpecificationSnapshot> = async (projectId, snapshotId) => {
  const snapshot = await api.get(createUrl(projectId, snapshotId))
  return {
    ...snapshot,
    createdOn: new Date(snapshot.createdOn),
  }
}

export interface SnapshotRequirementComment {
  id: string
  body: string
  createdBy: {
    firstName: string
    lastName: string
  }
  createdByUserId: string
  createdOn: Date
  resolved: boolean
}

export const getSpecificationSnapshotComments = async (
  projectId: string,
  snapshotId: string,
): Promise<Record<string, SnapshotRequirementComment[]>> => {
  const { requirementComments } = (await api.get(
    `/api/v2/projects/${projectId}/snapshots/${snapshotId}/comments`,
  )) as {
    requirementComments: Record<string, SnapshotRequirementComment[]>
  }

  const commentsWithDates = Object.fromEntries(
    Object.entries(requirementComments).map(([requirementId, comments]) => [
      requirementId,
      comments.map((comment) => ({
        ...comment,
        createdOn: new Date(comment.createdOn),
      })),
    ]),
  )

  return commentsWithDates
}

export const addSnapshotRequirementComment = async (
  projectId: UUID,
  snapshotId: UUID,
  requirementId: UUID,
  comment: string,
): Promise<SnapshotRequirementComment> => {
  const commentResponse = await api.post(
    `${createUrl(
      projectId,
      snapshotId,
    )}/requirements/${requirementId}/comments`,
    { body: { body: comment } },
  )

  return {
    ...commentResponse,
    createdOn: new Date(commentResponse.createdOn),
  }
}

export const deleteSnapshotRequirementComment = (
  projectId: UUID,
  snapshotId: UUID,
  requirementId: UUID,
  commentId: UUID,
): Promise<{ id: UUID }> =>
  api.delete(
    `${createUrl(
      projectId,
      snapshotId,
    )}/requirements/${requirementId}/comments/${commentId}`,
  )

type UpdateCommentRequest = {
  resolve: boolean
}

export const updateSnapshotRequirementComment = async (
  projectId: UUID,
  snapshotId: UUID,
  requirementId: UUID,
  commentId: UUID,
  update: UpdateCommentRequest,
): Promise<SnapshotRequirementComment> => {
  const updatedComment = await api.patch(
    `${createUrl(
      projectId,
      snapshotId,
    )}/requirements/${requirementId}/comments/${commentId}`,
    {
      body: update,
    },
  )

  return {
    ...updatedComment,
    createdOn: new Date(updatedComment.createdOn),
  }
}

export const getProjectUsers: (
  projectId: UUID,
  includeTenantUsers?: boolean,
) => Promise<UsersResponse> = async (projectId, includeTenantUsers?) => {
  return await api.get(
    createUrl(projectId) +
      `/users${includeTenantUsers ? '?includeTenantUsers=true' : ''}`,
  )
}

interface AddUserToProjectRequest {
  userId: string
}

export const addUserToProject: (
  projectId: string,
  userRequest: AddUserToProjectRequest,
) => Promise<void> = async (projectId, userRequest) => {
  return await api.post(createUrl(projectId) + `/users`, {
    body: userRequest,
  })
}

export const getRequestedReviewers = async (
  projectId: string,
  snapshotId: string,
): Promise<UsersResponse> => {
  const reviewers = await api.get(
    `${createUrl(projectId, snapshotId)}/reviewers`,
  )
  return reviewers
}

const apiV2: (
  strings: TemplateStringsArray,
  ...variables: string[]
) => string = (strings, ...variables) => {
  const baseUrl = '/api/v2/'

  const result = strings.reduce((acc, cur, idx) => {
    acc += cur
    acc += variables[idx] || ''

    return acc
  }, baseUrl)
  return result
}

export const setReviewers = async (
  projectId: string,
  snapshotId: string,
  userIds: string[],
) => {
  const reviewers = await api.put(
    `${createUrl(projectId, snapshotId)}/reviewers`,
    { body: { userIds } },
  )
  return reviewers
}

export type ReviewStatus =
  | 'APPROVED'
  | 'APPROVED_WITH_COMMENTS'
  | 'NOT_APPLICABLE'
  | 'REJECTED'
  | 'EMPTY'

export type SnapshotRequirementReview = {
  requirementId: UUID
  status: ReviewStatus
}

export type SnapshotReviewer = {
  userId: UUID
  firstName: string
  lastName: string
}

export interface BaseReview {
  id: UUID
  reviewer: SnapshotReviewer
}

export interface SnapshotReview extends BaseReview {
  specificationName: string
  requirements: Record<UUID, SnapshotRequirementReview>
}

export type SnapshotReviewsResponse = { reviews: SnapshotReview[] }

export const getSnapshotReviews: (
  projectId: UUID,
  snapshotId: UUID,
) => Promise<SnapshotReviewsResponse> = async (projectId, snapshotId) => {
  return await api.get(
    apiV2`projects/${projectId}/snapshots/${snapshotId}/reviews`,
  )
}

export const getSnapshotReviewByUser: (
  projectId: UUID,
  snapshotId: UUID,
  userID: UUID,
) => Promise<SnapshotReview[]> = async (projectId, snapshotId, userId) => {
  return await api.get(
    apiV2`projects/${projectId}/snapshots/${snapshotId}/reviews/user/${userId}`,
  )
}

export const updateSnapshotReviews: (
  projectId: UUID,
  snapshotId: UUID,
  reviewId: UUID,
  requirementId: UUID,
  status: ReviewStatus,
) => Promise<SnapshotReview[]> = async (
  projectId,
  snapshotId,
  reviewId,
  requirementId,
  status,
) => {
  return await api.put(
    apiV2`projects/${projectId}/snapshots/${snapshotId}/reviews/${reviewId}/requirements/${requirementId}`,
    { body: { status } },
  )
}

/**
 * HISTORY
 */

export enum SnapshotHistoryEventType {
  UNKNOWN,
  STATUS_UPDATED = 'STATUS_UPDATED',
  REVIEWER_REMOVED = 'REVIEWER_REMOVED',
}

type CreatedByType = {
  id: string
  firstName: string
  lastName: string
  email: string
  userName: string
  teamIds: string[]
}

export interface SpecificationSnapshotRequirementHistoryEvent {
  id: string
  snapshotRequirementId: string
  type: SnapshotHistoryEventType
  data: any
  createdOn: Date
  createdBy: CreatedByType
}

/**
 * Need to fetch all users in the tenant and linked tenant since users
 * can be removed as reviewers/project members but still have history events.
 */
export const getSnapshotRequirementHistoryWithUsers: (
  projectId: string,
  snapshotId: string,
  requirementId: string,
) => Promise<SpecificationSnapshotRequirementHistoryEvent[]> = async (
  projectId,
  snapshotId,
  requirementId,
) => {
  const allTenantUsers = (await getProjectUsers(projectId, true))?.users || []
  const usersById = [...allTenantUsers].reduce(
    (byId, user) => ({ ...byId, [user.id]: user }),
    {},
  )
  const history = await api.get(
    `${createUrl(projectId, snapshotId)}/requirements/${requirementId}/history`,
  )

  const result = (history?.events || []).map((h) => ({
    ...h,
    createdOn: new Date(h.createdOn),
    createdBy: usersById[h.createdBy],
  }))

  return result
}
