import { Add, CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './ProgramSelect.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import Dropdown from '../dropdown'
import Tag from '../tag'

interface ProgramSelectProps {
  className?: string
  onlyDisplayOnHover?: boolean
  readOnly?: boolean
  selectedProgramId?: string
  onCancel?: (program: AttributeValueResponse | null) => void
  onSelect: (program: AttributeValueResponse | null) => void
}
const ProgramSelect = (props: ProgramSelectProps) => {
  const {
    className,
    onlyDisplayOnHover,
    readOnly,
    onCancel,
    onSelect,
    selectedProgramId,
  } = props
  const { programs, activePrograms } = useAttributesContext()

  const selectedProgram = programs.find((p) => p.id === selectedProgramId)

  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen(false)
  }
  const ref = useClickOutside(closeMenu)

  return (
    <div
      ref={ref}
      className={`${styles.program} ${
        onlyDisplayOnHover ? '' : styles.displayAddBtn
      }  ${className}`}
    >
      {selectedProgram && (
        <Tag
          text={selectedProgram.name}
          color={{
            fontColor: selectedProgram.metadata.STYLES.COLOR_FONT,
            backgroundColor: selectedProgram.metadata.STYLES.COLOR_BG,
          }}
          onCancel={
            readOnly || !onCancel ? undefined : () => onCancel(selectedProgram)
          }
        />
      )}
      {!readOnly && activePrograms.length > 0 && (
        <>
          <button
            className={selectedProgram ? '' : styles.addBtn}
            onClick={() => setOpen(!open)}
          >
            {selectedProgram ? <CaretDown size={20} /> : <Add />}
          </button>
          <Dropdown className={styles.dropdown} isOpen={open}>
            {activePrograms
              .filter((p) => p.id !== selectedProgram?.id)
              .map((p) => {
                const { COLOR_FONT: fontColor, COLOR_BG: backgroundColor } =
                  p.metadata.STYLES

                return (
                  <button
                    key={p.id}
                    className={styles.programItem}
                    onClick={() => {
                      closeMenu()
                      onSelect(p)
                    }}
                  >
                    <Tag
                      text={p?.name}
                      color={{ fontColor, backgroundColor }}
                    />
                  </button>
                )
              })}
          </Dropdown>
        </>
      )}
    </div>
  )
}

export default ProgramSelect
