import { Document } from '@carbon/icons-react'
import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './Node.module.css'
import { NodeData } from './types'

interface NodeProps {
  nodeData: NodeData
  setRootHeight?: (rootHeight: number) => void
  isExpanded: boolean
  setExpandedNodeHeight?: (expandedNodeHeight: number) => void
  closeModal: () => void
}

const Node = (props: NodeProps) => {
  const {
    nodeData,
    setRootHeight,
    isExpanded,
    setExpandedNodeHeight,
    closeModal,
  } = props
  const rootRef = useRef<HTMLDivElement>(null)
  const expandedRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!rootRef.current || !setRootHeight) return
    const measuredHeight = rootRef.current.offsetHeight
    setRootHeight(measuredHeight)
  }, [
    rootRef,
    setRootHeight,
    nodeData.displayId,
    nodeData.shallStatement,
    nodeData.specificationName,
  ])

  useEffect(() => {
    if (!expandedRef.current || !setExpandedNodeHeight) return
    const measuredHeight = expandedRef.current.offsetHeight
    setExpandedNodeHeight(measuredHeight)
  }, [expandedRef, isExpanded, setExpandedNodeHeight])

  return (
    <div
      className={styles.requirementCard}
      ref={
        nodeData.position === 'root' ? rootRef : isExpanded ? expandedRef : null
      }
    >
      <div
        className={`${styles.requirementName} ${
          isExpanded || nodeData.position === 'root' ? '' : styles.ellipsis
        }`}
      >
        {nodeData.displayId} · {nodeData.name || 'Untitled'}
      </div>
      {/* TODO - Add support deltas in graph view once backend is ready*/}
      {/*{shallStatementDelta && (*/}
      {/*  <QuillContent readOnly delta={shallStatementDelta} />*/}
      {/*)}*/}
      {nodeData.shallStatement && (
        <div className={styles.shallStatement}>{nodeData.shallStatement}</div>
      )}
      <div className={styles.specification}>
        <Document className={styles.icon} size={16} />
        <Link
          className={styles.specificationName}
          to={`/specifications/${nodeData.specificationId}/matrix/${nodeData.requirementId}`}
          onClick={() => closeModal()}
        >
          {nodeData.specificationName ? nodeData.specificationName : 'Untitled'}
        </Link>
      </div>
    </div>
  )
}

export default Node
