import styles from './RequirementCard.module.css'
import * as requirementApi from '../../../api/v2/requirements'

interface RequirementCardProps {
  requirement: requirementApi.Requirement
}

const RequirementCard = ({ requirement }: RequirementCardProps) => {
  return (
    <div className={styles.requirementCard}>
      <div className={styles.requirementHeader}>
        <div className={styles.requirementSectionNumber}>
          {requirement.sectionNumber}
        </div>
        <div className={styles.requirementTitle}>{requirement.title}</div>
      </div>
      <div className={styles.requirementStatement}>
        {requirement.shallStatement}
      </div>
      <div className={styles.rationaleStatement}>
        Rationale: {requirement.rationale}
      </div>
    </div>
  )
}

export default RequirementCard
