import { IncompleteCancel } from '@carbon/icons-react'
import { useCallback } from 'react'
import styles from './BlockActions.module.css'
import { RequirementBlockActionItemProps } from './types'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'
import { RequirementStatus } from '../../../types/enums'

const ToggleArchiveAction = (props: RequirementBlockActionItemProps) => {
  const { block, requirement, closeMenu } = props
  const { unarchiveRequirement, isArchiveDisabled, contentIsEditable } =
    useSpecificationContext()
  const { deleteBlock, setRequirement } = useSectionContext()

  const unarchive: () => Promise<void> = useCallback(async () => {
    await unarchiveRequirement(block.id)
    setRequirement(block.id, { status: RequirementStatus.Draft })
  }, [unarchiveRequirement, block.id, setRequirement])

  return (
    <>
      {!isArchiveDisabled(requirement?.context.requirementVersionCount) &&
        contentIsEditable && (
          <button
            className={styles.actionItem}
            onClick={async () => {
              closeMenu()
              if (requirement?.status === RequirementStatus.Archived) {
                unarchive()
              } else {
                const archivedReq = await deleteBlock(block?.id)
                if (archivedReq) {
                  setRequirement(block.id, {
                    status: RequirementStatus.Archived,
                  })
                }
              }
            }}
          >
            <IncompleteCancel />
            {requirement?.status === RequirementStatus.Archived
              ? 'Unarchive requirement'
              : 'Archive requirement'}
          </button>
        )}
    </>
  )
}

export default ToggleArchiveAction
