import { CSSProperties } from 'react'
import styles from './index.module.css'

interface DropdownType {
  className?: string
  style?: CSSProperties
  isOpen: boolean
  children: any
}

const Dropdown = (props: DropdownType) => {
  const { className, style, isOpen, children } = props

  return (
    <div
      className={`${isOpen ? styles.dropdown : styles.hidden} ${
        className || ''
      }`}
      style={style}
    >
      {children}
    </div>
  )
}

export default Dropdown
