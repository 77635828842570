import { SendAlt, UserMultiple } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import Modal from './index.tsx'
import styles from './ShareProjectModal.module.css'
import { addUserToProject } from '../../api/v2/projects.ts'
import { getTenantContactUsers } from '../../api/v2/tenants.ts'
import { User } from '../../api/v2/users.ts'
import { useAuth } from '../../context/AuthContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { AvatarSize } from '../avatar/constants.ts'
import Avatar from '../avatar/index.tsx'
import Button, { BUTTON_COLORS } from '../button'
import Checkbox from '../input/Checkbox.tsx'
import { toastError, toastSuccess } from '../toast'

export interface ShareProjectModalProps {
  projectId: string
  contactId: string
  onAddProjectUser: (newUser: User) => void
  projectUsers: User[]
}

const ShareProjectModal = (
  props: ShareProjectModalProps & { closeModal: () => void },
) => {
  const { userTenantId } = useAuth()
  const { closeModal, projectId, contactId, onAddProjectUser, projectUsers } =
    props
  const ref = useClickOutside(() => closeModal())
  const [tenantUsers, setTenantUsers] = useState<User[]>([])
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])

  useEffect(() => {
    const fetchTenantUsers = async () => {
      try {
        const response = await getTenantContactUsers(userTenantId, contactId)
        const filteredUsers = response.users.filter(
          (user) =>
            !projectUsers.some((projectUser) => projectUser.id === user.id),
        )
        setTenantUsers(filteredUsers)
      } catch (error) {
        console.error('Unable to fetch tenant contact users', error)
        captureException(error)
      }
    }
    fetchTenantUsers()
  }, [userTenantId, contactId, projectUsers])

  const handleCheckboxChange = (user: User, selected: boolean) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (selected) {
        return [...prevSelectedUsers, user]
      } else {
        return prevSelectedUsers.filter(
          (selectedUser) => selectedUser.id !== user.id,
        )
      }
    })
  }

  const addUsers = async () => {
    try {
      for (const user of selectedUsers) {
        await addUserToProject(projectId, {
          userId: user.id,
        })
        onAddProjectUser(user)
      }
      closeModal()
      toastSuccess('Users added to project')
    } catch (error) {
      console.error('Unable to share project', error)
      captureException(error)
      toastError('Unable to share project', 'Try again later')
    }
  }

  return (
    <Modal
      title="Add new connection"
      icon={<UserMultiple size={20} />}
      onClose={closeModal}
      innerRef={ref}
    >
      <div className={styles.content}>
        <div className={styles.description}>
          Please select the users you want to connect with on Stell.
          <br />
          They will be added to the project as a viewer/commenter and notified.
        </div>
        <div className={`${styles.userList} ${styles.basicElevation}`}>
          {tenantUsers.map((user) => (
            <div key={user.id} className={styles.userItem}>
              <Avatar
                firstName={user.firstName}
                lastName={user.lastName}
                showName
                size={AvatarSize.MediumSmall}
              />
              <div className={styles.checkbox}>
                <Checkbox
                  checked={selectedUsers.includes(user)}
                  onChange={(checked: boolean) =>
                    handleCheckboxChange(user, checked)
                  }
                />
              </div>
            </div>
          ))}
        </div>
        <Button
          text="Connect"
          onClick={addUsers}
          color={BUTTON_COLORS.PRIMARY}
          endIcon={<SendAlt size={16} />}
          disabled={selectedUsers.length === 0}
        />
      </div>
    </Modal>
  )
}

export default ShareProjectModal
