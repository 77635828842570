import { useEffect, useMemo } from 'react'
import styles from './ChangeRequestsPage.module.css'
import ChangeRequestsTableColumns from './ChangeRequestsTableColumns'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types'
import Table from '../../components/table/Table'
import { useChangeRequestListContext } from '../../context/ChangeRequestListContext'

const FILTER_KEYS = [FilterKey.ChangeRequestSpecificationName]

const ChangeRequestsPage = () => {
  const { changeRequests } = useChangeRequestListContext()

  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)

  const filteredChangeRequests = useMemo(() => {
    return changeRequests
      .filter(
        (r) =>
          filters[FilterKey.ChangeRequestSpecificationName].length === 0 ||
          filters[FilterKey.ChangeRequestSpecificationName].some(
            (f) => f.id === r.specificationId,
          ),
      )
      .sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime())
  }, [changeRequests, filters])

  useEffect(() => {
    window.document.title = 'Change Requests'
  }, [])

  return (
    <>
      <div className={styles.header}>
        <h1>Change Requests</h1>
      </div>
      <div className={styles.actions}>
        <FilterDropdown
          menus={FILTER_KEYS}
          activeFilters={filters}
          onSelectFilter={toggleFilter}
        />
        <AppliedFilters
          filterKeys={FILTER_KEYS}
          filters={filters}
          onRemove={toggleFilter}
        />
      </div>
      <div className={styles.changeRequests}>
        <div className={styles.scrollContainer}>
          <Table
            rowItems={filteredChangeRequests}
            columns={ChangeRequestsTableColumns}
          />
        </div>
      </div>
    </>
  )
}

export default ChangeRequestsPage
