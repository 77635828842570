enum EnvironmentTypes {
  LOCAL = 'local',
  DOCKER = 'docker',
  DEV = 'dev',
  STAGING = 'staging',
  SANDBOX = 'sandbox',
  PROD = 'prod',
}

// Pulled from blazor
const getEnvironment = () => {
  let environment: string
  if (window.location.hostname.includes('localhost')) {
    environment =
      window.location.port === '5000'
        ? EnvironmentTypes.DOCKER
        : EnvironmentTypes.LOCAL
  } else if (window.location.hostname.includes('dev.stell-testing.com')) {
    environment = EnvironmentTypes.DEV
  } else if (window.location.hostname.includes('staging.stell-testing.com')) {
    environment = EnvironmentTypes.STAGING
  } else if (window.location.hostname.includes('sandbox.stell-testing.com')) {
    environment = EnvironmentTypes.SANDBOX
  } else if (
    window.location.hostname.includes('dashboard.stell-engineering.com')
  ) {
    environment = EnvironmentTypes.PROD
  } else {
    // Fail save, if no environments match, assume its production to reduce attack surface
    environment = EnvironmentTypes.PROD
  }

  return environment
}

export const displayDevTools = () => {
  const env = getEnvironment()
  return (
    env === EnvironmentTypes.DOCKER ||
    env === EnvironmentTypes.LOCAL ||
    env === EnvironmentTypes.DEV ||
    env === EnvironmentTypes.STAGING ||
    env === EnvironmentTypes.SANDBOX
  )
}
