import { OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import styles from './ActionsCell.module.css'
import * as api from '../../api/v2/reports.ts'
import Dropdown from '../../components/dropdown'
import { toastError, toastSuccess } from '../../components/toast'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { useModals } from '../../hooks/useModals.ts'

export const ActionsCell = (props) => {
  const { data } = props
  const [submittingDelete, setSubmittingDelete] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    if (open) {
      setOpen(false)
    }
  }

  const ref = useClickOutside(closeMenu)
  const { openConfirmationModal } = useModals()

  const deleteReport = async () => {
    setSubmittingDelete(true)
    try {
      await api.deleteReport(data.id)
      data.onDelete(data.id)
      toastSuccess('Report deleted')
    } catch (error) {
      console.error(error)
      toastError('Unable to delete report', '')
      captureException(error)
    } finally {
      setSubmittingDelete(false)
    }
  }

  const confirmDelete = () => {
    closeMenu()
    openConfirmationModal({
      title: 'Delete Confirmation',
      titleIcon: <TrashCan size={20} />,
      promptText: `Are you sure you want to delete report '${data.name}'?`,
      onConfirm: deleteReport,
    })
  }

  return (
    <div ref={ref} className={styles.actions}>
      <button onClick={() => setOpen(!open)}>
        <OverflowMenuVertical size={24} />
      </button>
      <Dropdown className={styles.dropdown} isOpen={open}>
        <button
          className={styles.actionItem}
          style={{ color: 'red' }}
          onClick={confirmDelete}
          disabled={submittingDelete}
        >
          Delete
        </button>
      </Dropdown>
    </div>
  )
}

export default ActionsCell
