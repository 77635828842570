import { captureException } from '@sentry/react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './ForgotPasswordPage.module.css'
import * as usersApi from '../../api/v2/users.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import { toastError, toastSuccess } from '../../components/toast'

const ForgotPasswordPage = () => {
  const { search } = useLocation()
  const invalidToken =
    new URLSearchParams(search).get('error') === 'invalid-token'
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [email, setEmail] = useState<string>()
  const [validEmail, setValidEmail] = useState<boolean>(true)

  useEffect(() => {
    if (!email) {
      setValidEmail(true)
      return
    }

    setValidEmail(/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email))
  }, [email])

  const sendRequest = useCallback(async () => {
    if (!email || !validEmail) {
      toastError(
        'Unable to request password reset',
        'Enter an email address to proceed',
      )
      return
    }
    setSubmitting(true)
    try {
      await usersApi.requestPasswordReset(email)
      toastSuccess(
        'Password reset link sent',
        `An email with a link and instructions has been sent to ${email}.`,
      )
    } catch (error) {
      console.error('Unable to request reset password', error)
      toastError(
        'Unable to request password reset',
        'Refresh the page and try again',
      )
      captureException(error)
    } finally {
      setSubmitting(false)
    }
  }, [email, validEmail])

  return (
    <div className={styles.forgotPasswordContent}>
      <div className={styles.header}>
        <img
          className={styles.stellWordLogo}
          src={'/images/stell-word-logo-green.png'}
          alt="Stell Logo"
        />
        <span className={styles.pageTitle}>Password reset</span>
      </div>
      {invalidToken && (
        <p className={styles.error}>
          The previous link you requested has expired.
        </p>
      )}
      <p className={styles.copy}>
        Please enter the login you use to login to Stell. If your email is in
        our system, we will send a password reset link.
      </p>
      <div className={styles.form}>
        <label className={styles.fieldEmail}>
          <span className={styles.label}>Email</span>
          <input
            className={styles.input}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && email && !submitting) {
                e.preventDefault()
                sendRequest()
              }
            }}
            autoComplete="username"
          />
        </label>
        <div className={styles.errorMessages}>
          {!validEmail && <div>Enter a valid email address.</div>}
        </div>

        <Button
          className={styles.submitButton}
          onClick={(e) => {
            e.preventDefault()
            sendRequest()
          }}
          text={'Send link'}
          color={BUTTON_COLORS.GREEN_WHITE_TEXT}
          disabled={!email || !validEmail || submitting}
          fontSize="16px"
        />
      </div>
      <Link className={styles.linkToSignIn} to={'/login'}>
        Return to sign in
      </Link>
    </div>
  )
}

export default ForgotPasswordPage
