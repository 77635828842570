interface JwtClaims {
  exp: number
  fullName: string
  image_url: string
  jobTitle: string
  tenant: string
  ['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']: string
  ['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']: string
  ['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']: string
  ['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']: string
  [key: string]: any
}

/**
 * Decodes a JSON Web Token (JWT) and extracts its claims (payload).
 *
 * @param {string} jwtToken The JWT token to decode.
 * @returns The decoded JWT claims if successful, null otherwise.
 */
export const decodeJwtClaims = (jwtToken: string): JwtClaims | null => {
  const [, base64UrlPayload = ''] = jwtToken.split('.')
  const base64Payload = base64UrlPayload.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64Payload)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  )

  try {
    return JSON.parse(jsonPayload)
  } catch (error) {
    console.error('Error parsing JWT', error)
    return null
  }
}

/**
 * Checks if the parsed claims for a JWT (JSON Web Token) are valid based on the
 * expiration time.
 *
 * @param {JwtClaims | null} jwtClaims The decoded JWT claims object containing
 * the expiration time.
 * @returns {boolean} True if the JWT is not expired, false otherwise.
 */
export const isValidJwtClaims = (jwtClaims: JwtClaims | null): boolean =>
  !!jwtClaims && jwtClaims.exp > Math.floor(Date.now() / 1000)

/**
 * Checks if a JWT (JSON Web Token) is valid based on its claims.
 *
 * @param {string | null} jwtToken The JWT token string to validate.
 * @returns {boolean} True if the JWT is not expired, false otherwise.
 */
export const isValidJwtToken = (jwtToken: string | null): boolean =>
  !!jwtToken && isValidJwtClaims(decodeJwtClaims(jwtToken))
