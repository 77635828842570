import { useCallback, useState } from 'react'
import {
  AttributeValueResponse,
  getAttributeValues,
} from '../api/v2/attributes'

const useCustomAttributes: (
  attributeId: string,
) => [
  AttributeValueResponse[],
  () => Promise<void>,
  (id: string) => AttributeValueResponse | null,
] = (attributeId) => {
  const [values, setValues] = useState<AttributeValueResponse[]>([])

  const fetchValues = useCallback(async () => {
    if (attributeId) {
      const { values } = await getAttributeValues(attributeId)
      setValues(values)
    }
  }, [attributeId])

  const getValueById = useCallback(
    (id: string) => values.find((v) => v.id === id) ?? null,
    [values],
  )

  return [values, fetchValues, getValueById]
}

export default useCustomAttributes
