import { useEffect, useRef } from 'react'
import styles from './SharedRequirementBlock.module.css'
import { RequirementBlockData } from '../../../api/v2/blocks.ts'
import { SharedBlock } from '../../../api/v2/projects.ts'
import { useSharedSpecificationContext } from '../../../context/SharedSpecificationContext.tsx'
import globalStyles from '../../../global.module.css'
import SharedRequirementActions from '../SharedRequirementActions.tsx'

const SharedRequirementBlock = (props: {
  block: SharedBlock<RequirementBlockData>
  specificationIdentifier: string
  scrollToOnLoad?: boolean
}) => {
  const { block, specificationIdentifier, scrollToOnLoad } = props
  const { getRequirementById } = useSharedSpecificationContext()
  const requirement = getRequirementById(block.id)

  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollToOnLoad) {
      setTimeout(
        () => scrollRef.current?.scrollIntoView?.({ block: 'center' }),
        0,
      )
    }
  }, [scrollToOnLoad])

  return (
    <div ref={scrollRef} className={styles.requirementBlock}>
      <div
        className={`${styles.content} ${
          scrollToOnLoad ? globalStyles.scrollHighlight : ''
        }`}
      >
        <div className={styles.reqHeader}>
          <div className={styles.reqInfo}>
            <span>{requirement?.sectionNumber || 'No Section Number'}</span>
            <span>{requirement?.title || 'Untitled'}</span>
          </div>
          <div className={styles.actions}>
            <SharedRequirementActions requirement={requirement!} />
          </div>
        </div>
        <div className={styles.shallStatement}>
          {requirement?.shallStatement}
        </div>
        {requirement?.rationale && (
          <div className={styles.rationale}>{requirement.rationale}</div>
        )}
      </div>
      <div className={styles.reqDetails}>
        <div className={styles.reqNum}>{`${
          specificationIdentifier || 'Document Number'
        }-${requirement?.identifier}`}</div>
      </div>
    </div>
  )
}

export default SharedRequirementBlock
