import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SharedProjectsTableColumns.module.css'
import { UUID } from '../../api/utilityTypes.ts'
import { Project } from '../../api/v2/projects'
import * as api from '../../api/v2/projects.ts'
import { ProjectPermission } from '../../api/v2/users.ts'
import AttributeValueSelect, {
  DisplayType,
} from '../../components/attribute-value-select/AttributeValueSelect.tsx'
import { TableColumn } from '../../components/table/Table'
import Tag from '../../components/tag'
import { toastError } from '../../components/toast'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'

const ProjectNumber = ({ data }: { data: Project & { index: number } }) => {
  return <span>{data.index + 1}</span>
}

const ProjectName = ({ data }: { data: Project }) => {
  const navigate = useNavigate()
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    navigate(`/projects/${data.id}`)
  }
  return (
    <div className={styles.projectName}>
      <button
        onClick={handleClick}
        aria-label={`Navigate to project ${data.name}`}
      >
        {data.name}
      </button>
    </div>
  )
}

const SharedBy = ({ data }: { data: any }) => {
  return (
    <span>
      {data.profile?.createdBy?.displayName || data.createdByTenant?.name}
    </span>
  )
}

const SharedWith = ({ data }: { data: any }) => {
  return (
    <span>
      {data.profile?.sharedWith?.displayName || data.sharedWithTenant?.name}
    </span>
  )
}

const Program = ({ data }: { data: any }) => {
  const { programs, activePrograms } = useAttributesContext()
  const { isAdmin, userPermissions } = useAuth()
  const [selectedProgram, setSelectedProgram] = useState(data.program?.id)
  const tenantIsProjectOwner =
    userPermissions?.projects[data.id]?.role === ProjectPermission.Owner
  const userIsProjectAdmin = isAdmin && tenantIsProjectOwner

  const updateProjectProgram = async (
    projectId: UUID,
    programId: string | null,
  ) => {
    try {
      await api.updateProject(projectId, { program: programId })
      setSelectedProgram(programId)
    } catch (error) {
      toastError('Unable to update project program', 'Try again later')
      console.error('Unable to update project program', error)
    }
  }

  return (
    <>
      {tenantIsProjectOwner ? (
        <AttributeValueSelect
          readOnly={!userIsProjectAdmin}
          attributeValues={programs}
          activeAttributeValues={activePrograms}
          displayType={DisplayType.Table}
          onlyDisplayOnHover
          selectedValueId={selectedProgram}
          onSelect={(program) => {
            if (program) {
              updateProjectProgram(data.id, program.id)
            } else {
              console.warn('Cannot update program. No program selected.')
            }
          }}
          onCancel={() => updateProjectProgram(data.id, null)}
        />
      ) : (
        <div>
          {data.program && (
            <Tag
              text={data.program.name}
              color={{
                fontColor: data.program?.metadata.STYLES.COLOR_FONT,
                backgroundColor: data.program?.metadata.STYLES.COLOR_BG,
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

const DateCreated = ({ data }: { data: any }) => {
  return <span>{data.createdOn.toLocaleDateString()}</span>
}

const SharedProjectsTableColumns: Array<TableColumn> = [
  //   { label: '', Component: ActionsCell, transparent: true },
  { label: ' ', Component: ProjectNumber },
  { label: 'Project Name', Component: ProjectName },
  { label: 'Shared By', Component: SharedBy },
  { label: 'Shared With', Component: SharedWith },
  { label: 'Program', Component: Program },
  { label: 'Date Created', Component: DateCreated },
]

export default SharedProjectsTableColumns
