import { Edit } from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { Link } from 'react-router-dom'
import styles from './EvidenceActivityDetails.module.css'
import { Evidence, EvidenceActivity } from '../../api/v2/evidence'
import { User } from '../../api/v2/users'
import { monthDayYear } from '../../lib/date'
import { LoadingState } from '../../types/enums'
import Avatar from '../avatar'
import { AvatarSize } from '../avatar/constants'
import LoadingIndicator from '../loading-indicator/LoadingIndicator'
import EvidenceActivityStatusTag from '../tag/EvidenceActivityStatusTag'
import EvidenceActivityTypeTag from '../tag/EvidenceActivityTypeTag'

export type ActivityWithOwners = EvidenceActivity & { owners: (User | null)[] }

export interface EvidenceActivityDetailsProps {
  activity: ActivityWithOwners
  parentRecord?: Evidence | null
  parentRecordColor?: string | null
  loadingAttachment?: LoadingState
  attachment?: { data: File; filename: string }
  jiraConnectionUrl?: string | null
  closeModal: () => void
}

const EvidenceActivityDetails = (props: EvidenceActivityDetailsProps) => {
  const {
    activity,
    parentRecord,
    parentRecordColor,
    closeModal,
    loadingAttachment,
    attachment,
    jiraConnectionUrl,
  } = props
  const enableJiraIssue = useFeatureFlagEnabled('jira-issue')

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{activity.title}</div>
          <EvidenceActivityStatusTag status={activity.status} />
        </div>
        <Link
          to={`/evidence/${activity.recordId}/activities/${activity.id}?editActivity=true`}
          className={styles.edit}
          onClick={closeModal}
        >
          <Edit />
          Edit activity
        </Link>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>Type</div>
        <EvidenceActivityTypeTag type={activity.type} />
      </div>
      <div className={styles.section}>
        <div className={styles.label}>Parent Record</div>
        <div className={styles.parentRecord}>
          <div
            className={styles.pip}
            style={{ backgroundColor: parentRecordColor || '' }}
          />
          <Link
            to={`/evidence/records/${parentRecord?.id}`}
            onClick={closeModal}
          >
            {parentRecord?.title}
          </Link>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>Memo</div>
        <div className={styles.memo}>{activity.memo}</div>
      </div>
      {enableJiraIssue && jiraConnectionUrl && (
        <div className={styles.section}>
          <div className={styles.label}>Link to JIRA</div>
          {activity.jiraIssueId && (
            <Link
              className={styles.link}
              to={`${jiraConnectionUrl}/browse/${activity.jiraIssueId}`}
            >
              {`${jiraConnectionUrl}/browse/${activity.jiraIssueId}`}
            </Link>
          )}
        </div>
      )}
      {activity.externalUrl && (
        <div className={styles.section}>
          <div className={styles.label}>Link to external</div>
          <Link className={styles.link} to={activity.externalUrl}>
            {activity.externalUrl}
          </Link>
        </div>
      )}
      <div className={styles.section}>
        <div className={styles.label}>Attached file</div>
        {loadingAttachment === LoadingState.Loading && <LoadingIndicator />}
        {loadingAttachment === LoadingState.Failed && (
          <span>Error loading attached file</span>
        )}
        {attachment && loadingAttachment === LoadingState.Loaded && (
          <a
            href={URL.createObjectURL(attachment.data)}
            download={attachment.filename}
            className={styles.link}
          >
            {attachment.filename || 'Attached document'}
          </a>
        )}
        {(loadingAttachment === LoadingState.Loaded && !attachment) ||
          (!activity.assetId && <span>No attached file</span>)}
      </div>
      <div className={styles.section}>
        <div className={styles.label}>Owner</div>
        <div className={styles.owners}>
          {activity.owners?.length
            ? activity.owners.map((user, index) => (
                <>
                  {user && (
                    <div
                      key={user.id || `owner-${index}`}
                      className={styles.owner}
                    >
                      <Avatar
                        firstName={user?.firstName}
                        lastName={user?.lastName}
                        size={AvatarSize.Medium}
                        showName
                      />
                    </div>
                  )}
                </>
              ))
            : 'No owner'}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.label}>Due date</div>
        <div className={styles.date}>{monthDayYear(activity.dueDate)}</div>
      </div>
    </div>
  )
}
export default EvidenceActivityDetails
