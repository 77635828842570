import { RefObject, useEffect, useRef } from 'react'

export enum EDGE {
  Top,
  Bottom,
}

const EDGE_MARGIN = {
  [EDGE.Top]: '0px 0px 10000px 0px',
  [EDGE.Bottom]: '10000px 0px 0px 0px',
}

const getScrollContainer = (el) => {
  while (el) {
    const { overflowY } = getComputedStyle(el)
    if (overflowY !== 'visible') {
      return el
    }
    el = el.parentElement
  }

  return null
}

const useScrollObserver: (
  callback: () => void,
  edge: EDGE,
) => RefObject<any> = (callback, edge) => {
  const element = useRef(null)
  const observer = useRef<IntersectionObserver>()

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect()
    }
    if (element.current) {
      const obs = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            callback()
          }
        },
        {
          root: getScrollContainer(element.current),
          rootMargin: EDGE_MARGIN[edge],
        },
      )

      obs.observe(element.current)
      observer.current = obs
    }
  }, [callback, edge])

  return element
}

export default useScrollObserver
