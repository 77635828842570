const formatDate: (
  isoDate: string | Date,
  options: Intl.DateTimeFormatOptions,
) => string = (isoDate, options) => {
  const date = typeof isoDate === 'string' ? new Date(isoDate) : isoDate
  return date.toLocaleDateString('en-US', options)
}

export const monthDayYear: (isoDate: string | Date) => string = (isoDate) => {
  return formatDate(isoDate, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  })
}

export const monthLongDayYear: (isoDate: string | Date) => string = (
  isoDate,
) => {
  return formatDate(isoDate, {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export const monthDayYearTime: (isoDate: string | Date) => string = (
  isoDate,
) => {
  const formattedDate = formatDate(isoDate, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  })
  const formattedTime = formatTime(isoDate)

  return `${formattedDate} · ${formattedTime}`
}

export const yearMonthDay: (isoDate: string | Date) => string = (isoDate) => {
  const date = typeof isoDate === 'string' ? new Date(isoDate) : isoDate
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const militaryTimeFormatOptions: Intl.DateTimeFormatOptions = {
  timeZoneName: 'short',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
}

export const formatTime: (
  isoDate: string | Date,
  options?: Intl.DateTimeFormatOptions,
) => string = (isoDate, options = militaryTimeFormatOptions) => {
  const date = typeof isoDate === 'string' ? new Date(isoDate) : isoDate
  return date.toLocaleTimeString('en-US', options)
}

export const convertToLocalDate: (dateStr: string) => Date = (
  dateStr: string,
) => {
  const parts = dateStr.split('-')
  const year = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10) - 1
  const day = parseInt(parts[2], 10)
  const localDate = new Date(year, month, day)
  return localDate
}
