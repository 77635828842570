import { Add } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './TreeBuilderNodeDropdown.module.css'
import { SpecificationNodeData } from '../../../api/v2/programs.ts'
import Dropdown from '../../../components/dropdown'
import IconButton from '../../../components/icon-button/IconButton.tsx'
import useClickOutside from '../../../hooks/useClickOutside.ts'

interface TreeBuilderNodeDropdownProps {
  specOptions: SpecificationNodeData[]
  onSelect: (option: SpecificationNodeData) => void
  positionRelative?: boolean
}

const TreeBuilderNodeDropdown = (props: TreeBuilderNodeDropdownProps) => {
  const { specOptions, onSelect, positionRelative = false } = props
  const [open, setOpen] = useState(false)
  const dropdownRef = useClickOutside(() => {
    setOpen(false)
  })

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(!open)
        }}
        className={`${styles.addButton} ${
          positionRelative ? styles.positionRelative : ''
        }`}
      >
        <Add size={20} />
      </IconButton>
      <Dropdown
        className={`${styles.dropdown} ${
          positionRelative ? styles.positionRelative : ''
        }`}
        isOpen={open}
      >
        <div ref={dropdownRef} className={styles.options}>
          {specOptions.length === 0 && (
            <span>No applicable specifications to add</span>
          )}
          {specOptions.map((option) => {
            return (
              <button
                key={option.id}
                className={styles.option}
                onClick={() => {
                  onSelect(option)
                  setOpen(false)
                }}
              >
                <span className={styles.optionIdentifier}>
                  {option.identifier || 'Document Number'}
                </span>
                <span className={styles.optionName}>{option.name}</span>
              </button>
            )
          })}
        </div>
      </Dropdown>
    </>
  )
}

export default TreeBuilderNodeDropdown
