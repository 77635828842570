import {
  CircleDash,
  CircleSolid,
  OverflowMenuVertical,
  TrashCan,
} from '@carbon/icons-react'
import { useState } from 'react'
import styles from './SnapshotComment.module.css'
import { UUID } from '../../../api/utilityTypes.ts'
import { SnapshotRequirementComment } from '../../../api/v2/projects.ts'
import Avatar from '../../../components/avatar'
import { AvatarSize } from '../../../components/avatar/constants.ts'
import useClickOutside from '../../../hooks/useClickOutside.ts'
import { monthDayYearTime } from '../../../lib/date.ts'

type SnapshotCommentProps = {
  comment: SnapshotRequirementComment
  requirementId: UUID
  onDeleteComment: (requirementId: UUID, commentId: UUID) => Promise<void>
  onToggleResolveComment: (
    requirementId: UUID,
    commentId: UUID,
    resolveComment: boolean,
  ) => Promise<void>
  userId?: string
}

const SnapshotComment = ({
  comment,
  ...commentProps
}: SnapshotCommentProps) => {
  const { requirementId, onDeleteComment, onToggleResolveComment, userId } =
    commentProps
  const [actionsOpen, setActionsOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [togglingResolved, setTogglingResolved] = useState(false)
  const ref = useClickOutside(() => setActionsOpen(false), actionsOpen)

  return (
    <div key={comment.id} className={styles.comment}>
      <div className={styles.commentHeader}>
        <span className={styles.leftHeader}>
          <Avatar
            size={AvatarSize.MediumSmall}
            firstName={comment.createdBy?.firstName}
            lastName={comment.createdBy?.lastName}
            showName
          />
          <span className={styles.date}>
            {monthDayYearTime(comment.createdOn)}
          </span>
        </span>
        <span className={styles.rightHeader}>
          <button
            className={styles.resolutionBtn}
            onClick={async () => {
              setTogglingResolved(true)
              await onToggleResolveComment(
                requirementId,
                comment.id,
                !comment.resolved,
              )
              setTogglingResolved(false)
            }}
            disabled={togglingResolved}
          >
            {comment.resolved ? <CircleSolid /> : <CircleDash />}
            <span className={styles.resolutionState}>
              {comment.resolved ? 'Resolved' : 'Resolve'}
            </span>
          </button>
        </span>
      </div>
      <div className={styles.content}>
        <div
          className={`${styles.commentBody} ${
            comment.resolved ? styles.resolved : ''
          }`}
        >
          {comment.body}
        </div>
        {userId === comment.createdByUserId && (
          <div ref={ref} className={styles.actionMenu}>
            <button onClick={() => setActionsOpen(!actionsOpen)}>
              <OverflowMenuVertical size={20} className={styles.icon} />
            </button>
            <div
              className={`${styles.dropdown} ${styles.basicElevation} ${
                actionsOpen ? styles.open : ''
              }`}
            >
              <button
                onClick={async () => {
                  setDeleting(true)
                  await onDeleteComment(requirementId, comment.id)
                  setDeleting(false)
                }}
                disabled={deleting}
              >
                <TrashCan size={16} />
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default SnapshotComment
