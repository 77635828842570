import { useCallback, useState } from 'react'
import { generatePdf } from './PdfGenerator.tsx'
import styles from './SpecificationExportPage.module.css'
import LoadingIndicator, {
  LoaderSize,
} from '../../../components/loading-indicator/LoadingIndicator.tsx'
import { toastError, toastSuccess } from '../../../components/toast'
import { useSpecificationContext } from '../../../context/SpecificationContext.tsx'
import { LoadingState } from '../../../types/enums.ts'

const SpecificationExportPage = () => {
  const { revision, specification, isHistoricalRevision, publicTenant } =
    useSpecificationContext()
  const [loading, setLoading] = useState(LoadingState.Start)

  const onGeneratePdf = useCallback(async () => {
    setLoading(LoadingState.Loading)

    await generatePdf({
      specification,
      revision,
      isHistoricalRevision,
      publicTenant: publicTenant || false,
      onStart: () => {},
      onComplete: () => {
        toastSuccess('Successfully created pdf export.')
        setLoading(LoadingState.Loaded)
      },
      onFailure: () => {
        toastError(
          'An error occurred while generating pdf export.',
          'Please refresh the page and try again.',
        )
        setLoading(LoadingState.Failed)
      },
    })
  }, [isHistoricalRevision, publicTenant, revision, specification])

  return (
    <div className={styles.container}>
      <div>
        {loading === LoadingState.Start && (
          <button
            className={styles.startContent}
            onClick={async () => {
              await onGeneratePdf()
            }}
          >
            Click here to begin PDF export
          </button>
        )}
        {loading === LoadingState.Loading && (
          <div className={styles.loadingContent}>
            <LoadingIndicator size={LoaderSize.XLARGE} />
            <div>
              Generating PDF Export... This may take a moment. Please do not
              leave page.
            </div>
          </div>
        )}
        {loading === LoadingState.Loaded && (
          <div>Your download has finished. You may now navigate away.</div>
        )}
        {loading === LoadingState.Failed && (
          <div>
            Something went wrong. Please refresh the page and try again.
          </div>
        )}
      </div>
    </div>
  )
}

export default SpecificationExportPage
