import { Document, Edit, Report } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './EvidenceRecordDrawer.module.css'
import Drawer from './index.tsx'
import {
  EvidenceActivity,
  getEvidenceActivities,
  getEvidenceActivityOwners,
} from '../../api/v2/evidence.ts'
import { getEvidenceActivityAsset } from '../../api/v2/evidenceActivityAssets.ts'
import { getJiraConnection } from '../../api/v2/integrations.ts'
import { EvidenceRequirementLink } from '../../api/v2/links.ts'
import { getRequirements, Requirement } from '../../api/v2/requirements.ts'
import { getSpecification } from '../../api/v2/specifications.ts'
import { User } from '../../api/v2/users.ts'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import { EvidenceWithLinks } from '../../context/EvidencesContext.tsx'
import { useReports } from '../../hooks/useReports.ts'
import useUsers from '../../hooks/useUsers.ts'
import { monthDayYear } from '../../lib/date.ts'
import {
  EvidenceCadence,
  EvidenceType,
  LoadingState,
} from '../../types/enums.ts'
import Button, { BUTTON_COLORS } from '../button/index.tsx'
import EvidenceActivityDetails from '../evidence/EvidenceActivityDetails.tsx'
import Tag, { TAG_COLORS } from '../tag/index.tsx'

export interface EvidenceRecordDrawerProps {
  evidenceRecord: EvidenceWithLinks
}

const LinkedRequirementCard = ({ link }: { link: EvidenceRequirementLink }) => {
  const [specIdentifier, setSpecIdentifier] = useState('')
  const [requirement, setRequirement] = useState<Requirement | null>(null)

  useEffect(() => {
    const loadSpecification = async () => {
      try {
        const specification = await getSpecification(link.specification_id)
        setSpecIdentifier(specification.specificationIdentifier)

        const req = await getRequirements(link.specification_id, {
          ids: [link.requirement_id],
        })

        if (req.length > 1)
          throw new Error('should only be grabbing one requiremnt')

        setRequirement(req[0])
      } catch (error) {
        console.error('Unable to get specification', error)
        captureException(error)
      }
    }
    loadSpecification()
  }, [link.specification_id, link.requirement_id])

  return (
    <div className={styles.requirementCard}>
      <div className={styles.requirementHeader}>
        <div className={styles.requirementTitleNumber}>
          <span>
            {requirement?.sectionNumber
              ? requirement?.sectionNumber
              : 'No Section Number'}
          </span>
          <Link
            to={`/specifications/${link.specification_id}/matrix/${link.requirement_id}`}
            target="_blank"
          >
            {link.requirement_title ? link.requirement_title : 'Untitled'}
          </Link>
        </div>
        <div className={styles.specification}>
          <Document />
          <span>{specIdentifier}</span>
        </div>
      </div>
      <div className={styles.shallStatement}>
        {requirement?.shallStatement || ''}
      </div>
    </div>
  )
}

enum EvidenceDrawerTab {
  RECORD,
  ACTIVITIES,
}

type ActivityWithOwners = EvidenceActivity & { owners: User[] }

const EvidenceRecordDrawer = (
  props: EvidenceRecordDrawerProps & { closeModal: () => void },
) => {
  const { evidenceRecord, closeModal } = props
  const { getEvidenceMethodById, getEvidenceCadenceById, getProgramById } =
    useAttributesContext()
  const { getUserById } = useUsers()
  const { generateEvidenceComplianceReport } = useReports()
  const { userTenantId } = useAuth()
  const [jiraConnectionUrl, setJiraConnectionUrl] = useState('')
  const enableJiraIssue = useFeatureFlagEnabled('jira-issue')

  useEffect(() => {
    const checkJiraConnection = async () => {
      if (enableJiraIssue) {
        try {
          const response = await getJiraConnection(userTenantId)
          if (response) {
            setJiraConnectionUrl(response.url)
          } else {
            setJiraConnectionUrl('')
          }
        } catch (error) {
          console.error('Error checking JIRA connection', error)
          captureException(error)
          setJiraConnectionUrl('')
        }
      }
    }

    checkJiraConnection()
  }, [userTenantId, enableJiraIssue])

  const [activeTab, setActiveTab] = useState<EvidenceDrawerTab>(
    EvidenceDrawerTab.RECORD,
  )
  const [activities, setActivities] = useState<ActivityWithOwners[]>([])
  const [assetIdToAsset, setAssetIdToAsset] =
    useState<Record<string, { file: File; filename: string }>>()
  const [attachmentLoadingStates, setAttachmentLoadingStates] = useState<
    Record<string, LoadingState>
  >({})
  const navigate = useNavigate()

  useEffect(() => {
    const fetchActivities = async () => {
      const res = await getEvidenceActivities(evidenceRecord.id)
      const activitiesWithOwners = await Promise.all(
        res.map(async (activity) => {
          const owners = (
            await getEvidenceActivityOwners(evidenceRecord.id, activity.id)
          ).map(getUserById)

          return { ...activity, owners }
        }),
      )
      setActivities(activitiesWithOwners as ActivityWithOwners[])
    }
    fetchActivities()
  }, [evidenceRecord.id, getUserById])

  useEffect(() => {
    const loadFiles = async () => {
      if (!activities || activities.length === 0) {
        return
      }
      const newLoadingStates: Record<string, LoadingState> = {}

      const attachments = (
        await Promise.all(
          activities.map(async (activity) => {
            if (!activity.assetId) {
              newLoadingStates[activity.id] = LoadingState.Loaded
              return null
            }

            newLoadingStates[activity.id] = LoadingState.Loading
            setAttachmentLoadingStates((prev) => ({
              ...prev,
              [activity.id]: LoadingState.Loading,
            }))
            try {
              const attachment = await getEvidenceActivityAsset(
                evidenceRecord.id,
                activity.id,
                activity.assetId,
              )
              newLoadingStates[activity.id] = LoadingState.Loaded
              return { assetId: activity.assetId, attachment }
            } catch (error) {
              console.error(`Unable to load asset ${activity.assetId}`)
              captureException(error)
              newLoadingStates[activity.id] = LoadingState.Failed
              return null
            }
          }),
        )
      ).filter((file) => file !== null)

      const attachmentByAssetId = attachments.reduce((acc, file) => {
        if (file?.assetId && file?.attachment) {
          return {
            ...acc,
            [file.assetId]: {
              file: file.attachment.data,
              filename: file.attachment.filename,
            },
          }
        }
        return acc
      }, {})

      setAssetIdToAsset(attachmentByAssetId)
      setAttachmentLoadingStates((prev) => ({ ...prev, ...newLoadingStates }))
    }
    loadFiles()
  }, [evidenceRecord.id, activities])

  const method = getEvidenceMethodById(evidenceRecord.method)
  const program = getProgramById(evidenceRecord.program)
  const methodTagColor = method
    ? {
        fontColor: method.metadata.STYLES.COLOR_FONT,
        backgroundColor: method.metadata.STYLES.COLOR_BG,
      }
    : TAG_COLORS.gray3NoHover
  const programTagColor = program
    ? {
        fontColor: program.metadata.STYLES.COLOR_FONT,
        backgroundColor: program.metadata.STYLES.COLOR_BG,
      }
    : TAG_COLORS.gray3NoHover

  return (
    <Drawer onClose={closeModal}>
      <div className={styles.drawer}>
        <div className={styles.tabs}>
          <button
            onClick={() => setActiveTab(EvidenceDrawerTab.RECORD)}
            className={`${styles.tabButton} ${
              activeTab === EvidenceDrawerTab.RECORD ? styles.active : ''
            }`}
          >
            Record
          </button>
          <button
            onClick={() => setActiveTab(EvidenceDrawerTab.ACTIVITIES)}
            className={`${styles.tabButton} ${
              activeTab === EvidenceDrawerTab.ACTIVITIES ? styles.active : ''
            }`}
          >
            Activities
          </button>
        </div>
        {activeTab === EvidenceDrawerTab.RECORD && (
          <div className={styles.tab}>
            <div className={styles.record}>
              <div className={styles.header}>
                <span className={styles.title}>
                  {evidenceRecord.title || 'No Record Title'}
                </span>
                <Link
                  className={styles.editRecord}
                  to={`evidence/${evidenceRecord.id}?edit=true`}
                  onClick={() => {
                    closeModal()
                  }}
                >
                  <Edit /> Edit record
                </Link>
              </div>

              <div className={styles.fieldContainer}>
                <span className={styles.fieldTitle}>Type</span>
                <Tag
                  aria-label={evidenceRecord.type}
                  text={evidenceRecord.type.toUpperCase()}
                  color={
                    evidenceRecord.type === EvidenceType.Validation
                      ? TAG_COLORS.blue3
                      : TAG_COLORS.red2
                  }
                />
              </div>
              <div className={styles.fieldContainer}>
                <span className={styles.fieldTitle}>Method</span>
                <Tag
                  aria-label={evidenceRecord.type}
                  text={method ? method.name.toUpperCase() : ''}
                  color={methodTagColor}
                />
              </div>
              <div className={styles.fieldContainer}>
                <span className={styles.fieldTitle}>Program</span>
                {program && (
                  <Tag
                    aria-label={evidenceRecord.type}
                    text={program ? program.name.toUpperCase() : ''}
                    color={programTagColor}
                  />
                )}
              </div>
              <div className={styles.fieldContainer}>
                <span className={styles.fieldTitle}>Cadence</span>
                <div className={styles.cadence}>
                  {evidenceRecord.cadences.map((cadence) => {
                    const cadenceName = getEvidenceCadenceById(cadence)?.name
                    return (
                      <Tag
                        key={cadence}
                        text={cadenceName ? cadenceName : ''}
                        color={
                          cadenceName === EvidenceCadence.Production
                            ? TAG_COLORS.purple2NoHover
                            : TAG_COLORS.green3NoHover
                        }
                      />
                    )
                  })}
                </div>
              </div>
              <div className={styles.fieldContainer}>
                <span className={styles.fieldTitle}>
                  Description of activity
                </span>
                <p>
                  {evidenceRecord.description || 'No description of activity'}
                </p>
              </div>
              <div className={styles.fieldContainer}>
                <span className={styles.fieldTitle}>Compliance statement</span>
                <p>
                  {evidenceRecord.complianceStatement ||
                    'No compliance statement'}
                </p>
              </div>
              <div className={styles.fieldContainer}>
                <span className={styles.fieldTitle}>Date created</span>
                <p>{monthDayYear(evidenceRecord.createdOn)}</p>
              </div>
            </div>
            <div className={styles.linkedRequirements}>
              <div className={styles.header}>
                <span className={styles.title}>Linked Requirements</span>
              </div>
              {evidenceRecord.links.length > 0 ? (
                <>
                  <div>
                    <Button
                      text="Generate compliance matrix"
                      onClick={() => {
                        closeModal()
                        generateEvidenceComplianceReport(evidenceRecord.id)
                      }}
                      endIcon={<Report size={16} />}
                      color={BUTTON_COLORS.PRIMARY}
                    />
                  </div>
                  {evidenceRecord.links.map((link) => (
                    <LinkedRequirementCard link={link} key={link.link_id} />
                  ))}
                </>
              ) : (
                <span>No linked requirements</span>
              )}
            </div>
          </div>
        )}
        {activeTab === EvidenceDrawerTab.ACTIVITIES && (
          <div className={styles.tab}>
            {activities.length === 0 ? (
              <div className={styles.noActivities}>
                <Button
                  text="Create activity"
                  onClick={() => {
                    closeModal()
                    navigate(
                      `/evidence/${evidenceRecord.id}?createActivity=true`,
                    )
                  }}
                  color={BUTTON_COLORS.PRIMARY}
                />
              </div>
            ) : (
              activities.map((activity) => {
                let attachment: { data: File; filename: string } | undefined

                if (
                  activity.assetId &&
                  assetIdToAsset &&
                  assetIdToAsset[activity.assetId]
                ) {
                  const assetData = assetIdToAsset[activity.assetId]
                  attachment = {
                    data: new File([assetData.file], assetData.filename, {
                      type: assetData.file.type,
                    }),
                    filename: assetData.filename,
                  }
                } else {
                  attachment = undefined
                }
                return (
                  <EvidenceActivityDetails
                    activity={activity}
                    parentRecord={evidenceRecord}
                    parentRecordColor={methodTagColor.backgroundColor}
                    attachment={attachment}
                    loadingAttachment={attachmentLoadingStates[activity.id]}
                    jiraConnectionUrl={jiraConnectionUrl}
                    key={activity.id}
                    closeModal={closeModal}
                  />
                )
              })
            )}
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default EvidenceRecordDrawer
