import SnapshotComment from './SnapshotComment.tsx'
import styles from './SnapshotComments.module.css'
import { UUID } from '../../../api/utilityTypes.ts'
import { SnapshotRequirementComment } from '../../../api/v2/projects.ts'

type SnapshotCommentsProps = {
  comments: SnapshotRequirementComment[]
  requirementId: UUID
  onDeleteComment: (requirementId: UUID, commentId: UUID) => Promise<void>
  onToggleResolveComment: (
    requirementId: UUID,
    commentId: UUID,
    resolveComment: boolean,
  ) => Promise<void>
  userId?: string
}

const SnapshotComments = ({
  comments,
  ...commentProps
}: SnapshotCommentsProps) => {
  return (
    <div className={styles.commentsContainer}>
      {comments.map((comment) => (
        <SnapshotComment key={comment.id} comment={comment} {...commentProps} />
      ))}
    </div>
  )
}

export default SnapshotComments
