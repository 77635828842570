import { Add, CheckmarkOutline } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useState } from 'react'
import styles from './CreateProjectModal.module.css'
import Modal from './index.tsx'
import * as api from '../../api/v2/projects.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import {
  getOrgProfiles,
  ProjectWithProfile,
} from '../../pages/shared-projects/SharedProjectsPage.tsx'
import Button, { BUTTON_COLORS } from '../button'
import { TextInput } from '../input/TextInput.tsx'
import { getRandomTagColor } from '../tag'
import { toastError, toastSuccess } from '../toast'

export interface CreateProjectModalProps {
  onAddProject: (newProject: ProjectWithProfile) => void
}

const CreateProjectModal = (
  props: CreateProjectModalProps & { closeModal: () => void },
) => {
  const { onAddProject, closeModal } = props
  const ref = useClickOutside(() => closeModal())
  const [projectName, setProjectName] = useState('')

  const createProject = async () => {
    const newProjectColor = getRandomTagColor()
    try {
      const newProject = await api.createProject({
        name: projectName,
        metadata: {
          STYLES: {
            COLOR_FONT: newProjectColor.fontColor,
            COLOR_BG: newProjectColor.backgroundColor,
            COLOR_BG_HOVER: newProjectColor.hoverBackgroundColor || '',
          },
        },
      })
      const profile = await getOrgProfiles(newProject)
      onAddProject({ ...newProject, profile })
      closeModal()
      toastSuccess(
        'Project created',
        `View ${newProject.name} project`,
        `/projects/${newProject.id}`,
      )
    } catch (error) {
      console.error('Unable to create project', error)
      captureException(error)
      toastError('Unable to create project', 'Try again later')
    }
  }

  return (
    <Modal
      title="Create new project"
      icon={<Add size={20} />}
      onClose={closeModal}
      innerRef={ref}
    >
      <div className={styles.content}>
        <div className={styles.description}>
          Enter the name of the project you're creating.
        </div>
        <TextInput
          placeholder="Project Name"
          value={projectName}
          setValue={setProjectName}
          className={styles.input}
          focusOnLoad
        />
        <Button
          text="Create project"
          onClick={createProject}
          color={BUTTON_COLORS.PRIMARY}
          endIcon={<CheckmarkOutline size={16} />}
        />
      </div>
    </Modal>
  )
}

export default CreateProjectModal
