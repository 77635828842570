import { Checkmark, Edit } from '@carbon/icons-react'
import { useCallback, useState } from 'react'
import Modal from './index.tsx'
import styles from './NewSpecificationModal.module.css'

import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import { Specification } from '../../api/v2/specifications.ts'
import { MODAL_ID_NEW_SPECIFICATION } from '../../context/GlobalModalContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import * as ls from '../../lib/localstorage.ts'
import { AttributeName } from '../../types/enums.ts'
import { ApiError } from '../../types/errors.ts'
import AttributeValueSelect, {
  DisplayType,
} from '../attribute-value-select/AttributeValueSelect.tsx'
import Button, { BUTTON_COLORS } from '../button'
import { TextInput } from '../input/TextInput.tsx'

export interface NewSpecificationModalProps {
  updateSpecification: ({
    name,
    specificationIdentifier,
    category,
    program,
    phase,
  }: {
    name?: string
    specificationIdentifier?: string
    category?: string | null
    program?: string | null
    phase?: string | null
  }) => Promise<ApiError | undefined | void>
  specification: Specification
  currentProgram: AttributeValueResponse | null
  programs: AttributeValueResponse[]
  activePrograms: AttributeValueResponse[]
}
const NewSpecificationModal = (
  props: NewSpecificationModalProps & {
    closeModal: () => void
  },
) => {
  const {
    closeModal,
    updateSpecification,
    specification,
    currentProgram,
    programs,
    activePrograms,
  } = props
  const MAX_SPEC_IDENTIFIER_LENGTH = 15

  const [identifierErrorMsg, setIdentifierErrorMsg] = useState<string>()
  const [errorMsg, setErrorMsg] = useState<string>()
  const [newSpecIdentifier, setNewSpecIdentifier] = useState<string>(
    specification.specificationIdentifier || '',
  )
  const [newProgram, setNewProgram] = useState<AttributeValueResponse | null>(
    currentProgram,
  )

  const updateNewSpecModalInLS = (specificationId: string) => {
    const dismissedModals = ls.getDismissedModals()
    const newSpecModalsDismissed =
      dismissedModals[MODAL_ID_NEW_SPECIFICATION] || []

    if (!newSpecModalsDismissed.includes(specificationId)) {
      ls.setDismissedModals({
        ...dismissedModals,
        [MODAL_ID_NEW_SPECIFICATION]: [
          ...newSpecModalsDismissed,
          specificationId,
        ],
      })
    }
  }

  const onClickOutside = useCallback(() => {
    updateNewSpecModalInLS(specification.id)
    closeModal()
  }, [closeModal, specification.id])

  const containerRef = useClickOutside(onClickOutside)

  return (
    <Modal
      innerRef={containerRef}
      title="Specification Information"
      onClose={() => {
        updateNewSpecModalInLS(specification.id)
        closeModal()
      }}
      icon={<Edit />}
    >
      <div className={styles.content}>
        {specification.specificationIdentifier ? (
          <div className={styles.text}>
            Add a document number and a program to this specification.
          </div>
        ) : (
          <div className={styles.text}>
            Before getting started on a specification, set a number sequence for
            your requirements. This can be updated at any time before
            specification release by clicking on the number above the
            specification title.
          </div>
        )}
        <div className={styles.fieldContainer}>
          <div>Number</div>
          <TextInput
            className={styles.input}
            placeholder="000-00-000"
            value={newSpecIdentifier}
            setValue={(val) => {
              setIdentifierErrorMsg(undefined)
              setErrorMsg(undefined)
              setNewSpecIdentifier(val)
            }}
            focusOnLoad
            maxLength={MAX_SPEC_IDENTIFIER_LENGTH}
          />
        </div>
        {identifierErrorMsg && (
          <div className={styles.error}>{identifierErrorMsg} </div>
        )}
        {!specification.specificationIdentifier && (
          <div className={styles.text}>
            Optionally, add this specification to a program in your
            organization.
          </div>
        )}
        <div className={styles.fieldContainer}>
          <div>Program</div>
          <AttributeValueSelect
            attributeValues={programs}
            activeAttributeValues={activePrograms}
            attributeGroupName={AttributeName.SpecificationProgram}
            displayType={DisplayType.Form}
            selectedValueId={newProgram?.id}
            onSelect={(program) => {
              setNewProgram(program)
              setErrorMsg(undefined)
            }}
            onCancel={() => {
              setNewProgram(null)
              setErrorMsg(undefined)
            }}
          />
        </div>
        <div className={styles.subtext}>
          Programs can be added by your organization's admin in their settings
          page.
        </div>
        {errorMsg && <div className={styles.error}>{errorMsg} </div>}
        <div className={styles.ctaWrapper}>
          <Button
            className={styles.cta}
            text="Save"
            endIcon={<Checkmark size={16} />}
            color={BUTTON_COLORS.PRIMARY}
            disabled={!newSpecIdentifier || !!errorMsg || !!identifierErrorMsg}
            onClick={async (e) => {
              e.preventDefault()
              if (!newSpecIdentifier) {
                setIdentifierErrorMsg('Document Number is required.')
              }

              if (
                specification.specificationIdentifier === newSpecIdentifier &&
                specification.program === newProgram
              ) {
                closeModal()
                return
              }

              const error = (await updateSpecification({
                specificationIdentifier:
                  specification.specificationIdentifier === newSpecIdentifier
                    ? undefined
                    : newSpecIdentifier,
                program: newProgram?.id || null,
              })) as ApiError & { status: number }

              if (!error) {
                updateNewSpecModalInLS(specification.id)
                closeModal()
              } else if (error?.status === 409) {
                setIdentifierErrorMsg(
                  'Document Number is already in use. Please provide a unique Document Number.',
                )
              } else {
                setErrorMsg('An error occurred. Please try again.')
              }
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default NewSpecificationModal
