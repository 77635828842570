import { captureException } from '@sentry/react'
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useAuth } from './AuthContext.tsx'
import * as api from '../api/v2/notifications'
import {
  Notification,
  NotificationData,
  NotificationType,
} from '../api/v2/notifications'

const NOTIFICATION_FETCH_TIME_MS = 10 * 1000
interface NotificationsContextProps {
  allNotifications: Notification<NotificationData>[] | null
  filteredNotifications: Notification<NotificationData>[] | null
  updateNotificationReadStatus: (
    notificationId: string,
    read: boolean,
  ) => Promise<void>
}

const NotificationsContext = createContext<NotificationsContextProps>({
  allNotifications: null,
  filteredNotifications: null,
  updateNotificationReadStatus: async () => Promise.resolve(),
})

const NotificationsContextProvider = ({ children }) => {
  const { isSignedIn } = useAuth()
  const [allNotifications, setAllNotifications] = useState<
    Notification<NotificationData>[]
  >([])

  const filteredNotifications = allNotifications.filter(
    (notification) =>
      notification.type === NotificationType.CHANGE_REQUEST_ADDED ||
      notification.type === NotificationType.COMMENT_ADDED ||
      notification.type === NotificationType.REVIEW_REQUESTED ||
      notification.type === NotificationType.SPECIFICATION_IMPORTED,
  )

  const fetchNotifications = useCallback(async (): Promise<void> => {
    try {
      if (isSignedIn) {
        const notifications = await api.getAllNotifications(100)
        setAllNotifications(notifications)
      }
    } catch (error) {
      console.error('Failed to fetch notifications', error)
      captureException(error)
    }
  }, [isSignedIn])

  const updateNotificationReadStatus = useCallback(
    async (notificationId: string, read: boolean): Promise<void> => {
      try {
        const updatedNotification = await api.updateNotification(
          notificationId,
          read,
        )
        setAllNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === updatedNotification.id
              ? { ...notification, read: updatedNotification.read }
              : notification,
          ),
        )
      } catch (error) {
        console.error('Failed to update notification read status', error)
        captureException(error)
      }
    },
    [],
  )

  useEffect(() => {
    fetchNotifications()
    const interval = setInterval(() => {
      fetchNotifications()
    }, NOTIFICATION_FETCH_TIME_MS)
    return () => clearInterval(interval)
  }, [fetchNotifications])

  return (
    <NotificationsContext.Provider
      value={{
        allNotifications,
        filteredNotifications,
        updateNotificationReadStatus,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

const useNotifications = () => useContext(NotificationsContext)

export { NotificationsContextProvider, useNotifications }
