import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './ExceptionProgramSelect.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes'
import Tag from '../../components/tag'
import { useAttributesContext } from '../../context/AttributesContext'
import globalStyles from '../../global.module.css'
import useClickOutside from '../../hooks/useClickOutside'
import { AttributeValueStatus } from '../../types/enums'

interface ExceptionProgramSelectProps {
  value: string
  onSelect: (programId: string) => void
}

const getTagColors = (program: AttributeValueResponse) => ({
  fontColor: program.metadata.STYLES.COLOR_FONT,
  backgroundColor: program.metadata.STYLES.COLOR_BG,
  hoverBackgroundColor: program.metadata.STYLES.COLOR_BG_HOVER,
})

const ExceptionProgramSelect = (props: ExceptionProgramSelectProps) => {
  const { value, onSelect: onSelectProp } = props

  const { getProgramById, programs } = useAttributesContext() //change this when not using one program
  const [open, setOpen] = useState(false)
  const selectedProgram = getProgramById(value)
  const ref = useClickOutside(() => setOpen(false), open)

  const onSelect = (id) => {
    onSelectProp(id)
    setOpen(false)
  }

  return (
    <div ref={ref} className={styles.root}>
      <button
        className={`${globalStyles.basicElevation}`}
        onClick={(event) => {
          event.preventDefault()
          setOpen(!open)
        }}
      >
        {selectedProgram ? (
          <Tag
            text={selectedProgram?.name || 'Program'}
            color={getTagColors(selectedProgram!)}
          />
        ) : (
          <span>Select program</span>
        )}
        <CaretDown size={20} />
      </button>
      <ul
        className={`${open ? styles.open : ''} ${globalStyles.basicElevation}`}
      >
        {programs
          .filter((program) => program.status === AttributeValueStatus.Active)
          .map((p) => (
            <li key={p.id} data-current={p.id === selectedProgram?.id}>
              <button
                onClick={(event) => {
                  event.preventDefault()
                  onSelect(p.id)
                }}
              >
                <Tag text={p.name} color={getTagColors(p)} />
              </button>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default ExceptionProgramSelect
