import { animated, useSpring } from '@react-spring/web'
import { pathHorizontalStep } from '@visx/shape'
import { ExpandedNode, LinkData } from './types'
interface AnimatedLinkProps {
  link: LinkData
  nodeWidth: number
  verticalOffset: number
  expansionVerticalOffset: number
  position: string
  expandedNode: ExpandedNode
  index: number
}

const AnimatedLink = (props: AnimatedLinkProps) => {
  const {
    link,
    nodeWidth,
    verticalOffset,
    expansionVerticalOffset,
    position,
    expandedNode,
    index,
  } = props
  const offsetSign = position === 'left' ? -1 : 1

  const isExpandedNode =
    expandedNode.position === position && expandedNode.index === index + 1

  const isAttachedToExpandedNode =
    link.source.data.nodeId === expandedNode.nodeId &&
    expandedNode.position === position

  const isBelowExpandedNode =
    expandedNode.position === position && expandedNode.index < index + 1

  const shiftSourceParent =
    expandedNode.position === position &&
    position === 'left' &&
    !isBelowExpandedNode

  const shiftTargetParent =
    expandedNode.position === position && position === 'left'

  const horizontalOffset = nodeWidth / 2 - 30 // 30 for padding around the source connector range

  const isGrandrelationLink = link.target.data.level > 1

  const calculateSourceXAdjustment = () => {
    let adjustment = 0

    if (isGrandrelationLink) {
      adjustment += verticalOffset * offsetSign

      // Shifts source link attachment to middle of expanded node
      adjustment += isAttachedToExpandedNode ? expansionVerticalOffset / 2 : 0

      if (expandedNode.position === 'left' && position === 'left') {
        adjustment -= expansionVerticalOffset
      }
    }

    return link.source.x + adjustment
  }

  const calculateSourceYBaseAdjustment = () => {
    let adjustment = link.source.y + horizontalOffset * offsetSign

    if (!isGrandrelationLink) {
      adjustment +=
        link.target.data.pathSourceOffset * offsetSign +
        (shiftSourceParent ? 30 : 0)
    }

    return adjustment
  }

  const calculateSourceYGrandrelationAdjustment = () => {
    let adjustment = 0

    if (isGrandrelationLink) {
      // Moves grandrelations further left/right so they don't overlap depth 1 nodes
      if (position === 'right') {
        // Adds 50 when attached to expanded node to account for expanded node width
        adjustment += 280 + (isAttachedToExpandedNode ? 50 : 0)
      } else {
        adjustment -= 580
      }
    }

    return adjustment
  }

  const calculateTargetXBaseAdjustment = () => {
    let adjustment = verticalOffset * offsetSign

    if (shiftTargetParent) {
      adjustment -= expansionVerticalOffset
    }

    if (isBelowExpandedNode) {
      adjustment += expansionVerticalOffset
    } else if (isExpandedNode) {
      adjustment += expansionVerticalOffset / 2
    }

    return link.target.x + adjustment
  }

  const calculateTargetXGrandrelationAdjustment = () => {
    let adjustment = 0

    // Centers target links with their nodes when attached to expanded node
    if (
      isGrandrelationLink &&
      link.source.data.nodeId === expandedNode.nodeId
    ) {
      adjustment -= expansionVerticalOffset / 2
    }

    return adjustment
  }

  const calculateTargetYAdjustment = () => {
    let y = (link.target.y + horizontalOffset - 70) * offsetSign

    if (isGrandrelationLink) {
      if (position === 'right') {
        y += 350
      } else {
        y -= 300
      }
    }

    return y
  }

  const animatedProps = useSpring({
    from: {
      d: pathHorizontalStep({
        source: (link: LinkData) => {
          const x = calculateSourceXAdjustment()
          const y = link.source.y + calculateSourceYGrandrelationAdjustment()
          return { x, y }
        },
        target: (link) => {
          const x =
            link.target.x +
            verticalOffset * offsetSign +
            calculateTargetXGrandrelationAdjustment()
          const y = calculateTargetYAdjustment()
          return { x, y }
        },
        x: (link) => link.y,
        y: (link) => link.x,
        percent: link.target.data.level > 1 ? 0.3 : 0,
      })(link),
      opacity: 0,
    },
    to: {
      d: pathHorizontalStep({
        source: () => {
          // X moves position of line source (starting point) up or down
          // Y moves position of line exiting root node to the left or right
          const x = calculateSourceXAdjustment()
          const y =
            calculateSourceYBaseAdjustment() +
            calculateSourceYGrandrelationAdjustment()
          return { x, y }
        },
        target: () => {
          // X controls length of line exiting root node
          // Y controls length of line entering target node
          const x =
            calculateTargetXBaseAdjustment() +
            calculateTargetXGrandrelationAdjustment()
          const y = calculateTargetYAdjustment()
          return { x, y }
        },
        x: (link) => link.y,
        y: (link) => link.x,
        percent: link.target.data.level > 1 ? 0.3 : 0,
      })(link),
      opacity: 1,
    },
  })

  return (
    <animated.g>
      <animated.path
        fill="none"
        stroke="#8D8D8D"
        strokeWidth="1"
        strokeLinecap="round"
        d={animatedProps.d}
        style={{ opacity: animatedProps.opacity }}
      />
    </animated.g>
  )
}

export default AnimatedLink
