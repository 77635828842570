import { CaretDown, CaretUp } from '@carbon/icons-react'
import { useState } from 'react'
import Dropdown from './index.tsx'
import styles from './InputDropdown.module.css'
import useClickOutside from '../../hooks/useClickOutside.ts'

export interface OptionType {
  id: string
  label: string
  sublabel: string
  data: any
  disabled?: boolean
  publicTenant?: boolean
}

export interface InputDropdownType {
  options: OptionType[]
  selected?: OptionType | undefined
  placeholder: string
  disabled?: boolean
  onQueryOptions: (
    query: string | undefined,
    options: OptionType[],
  ) => OptionType[]
  onSelect: (option: OptionType | undefined) => void
  displayOptionsAbove?: boolean
}

const InputDropdownSelection = (props: InputDropdownType) => {
  const {
    selected,
    onQueryOptions,
    options,
    placeholder,
    onSelect,
    displayOptionsAbove,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useClickOutside(() => {
    setQuery('')
    setIsOpen(false)
  })

  const [query, setQuery] = useState<string>()

  const filteredOptions = onQueryOptions(query, options).slice(0, 50)

  const inputValue = selected ? selected?.label : query

  return (
    <div ref={containerRef} className={styles.container}>
      <input
        className={styles.input}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => {
          onSelect(undefined)
          if (!e.target.value) {
            setQuery('')
            return
          }

          const selectedOption = options.find(
            (opt) => opt.label === e.target.value,
          )

          if (selectedOption) {
            onSelect(selectedOption)
          } else {
            setQuery(e.target.value)
          }
        }}
        onFocus={() => {
          setIsOpen(true)
          setQuery('')
        }}
      />
      <button
        className={styles.caretBtn}
        onClick={() => {
          if (options.length > 0) {
            setIsOpen((prev) => !prev)
          }
        }}
      >
        <CaretUp className={isOpen ? '' : styles.hide} />
        <CaretDown className={isOpen ? styles.hide : ''} />
      </button>
      <Dropdown
        className={`${styles.dropdown} ${
          filteredOptions.length > 0 ? '' : styles.hide
        } ${displayOptionsAbove ? styles.dropdownAbove : ''}`}
        isOpen={isOpen}
      >
        {filteredOptions.map((option) => {
          const isDisabled = option.disabled

          return (
            <div key={option.id} className={styles.dropdownItem}>
              <button
                className={`${styles.notSelected} ${
                  isDisabled ? styles.disabled : ''
                }`}
                disabled={isDisabled}
                onClick={() => {
                  onSelect(option)
                  setIsOpen(false)
                }}
              >
                <div className={styles.labels}>
                  <div className={styles.primary}>{option.label}</div>
                  <div>{option.sublabel}</div>
                </div>
              </button>
            </div>
          )
        })}
      </Dropdown>
    </div>
  )
}

export default InputDropdownSelection
