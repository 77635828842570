import { useCallback } from 'react'
import {
  getSpecificationTreeByProgramId,
  SpecificationTreeNode,
} from '../api/v2/programs.ts'
import { Specification } from '../api/v2/specifications.ts'
import { useAttributesContext } from '../context/AttributesContext.tsx'

type SpecNode = {
  id: string
  name: string
  identifier: string
}
type TreeNode = {
  specification: SpecNode
  children: TreeNode[]
}

const getSpecTreeLevel = (
  tree: TreeNode,
  specId: string,
  level: number,
): number | null => {
  if (!tree) {
    return null
  }

  if (tree.specification.id === specId) {
    return level
  }

  if (tree.children.length === 0) {
    return null
  }

  for (let i = 0; i < tree.children.length; i++) {
    const inTree = getSpecTreeLevel(tree.children[i], specId, level + 1)

    if (inTree !== null) {
      return inTree
    }
  }

  return null
}

const fetchSpecificationTreeBuilder = () => {
  const memoizedResults: Record<string, SpecificationTreeNode> = {}

  return async (programId) => {
    if (!memoizedResults[programId]) {
      try {
        const specificationTree =
          await getSpecificationTreeByProgramId(programId)
        memoizedResults[programId] = specificationTree
      } catch (e) {
        console.error(`Failed to fetch spec tree: ${e}`)
      }
    }
    return memoizedResults[programId]
  }
}

const fetchSpecTree = fetchSpecificationTreeBuilder()

const useSpecTree = () => {
  const { getProgramById } = useAttributesContext()

  const getSpecTreeInfo = useCallback(
    async (allSpecs: Specification[]) => {
      const promises = allSpecs.map(async (spec) => {
        const program = spec.program ? getProgramById(spec.program) : null

        if (!program) {
          return
        }
        const specTree = await fetchSpecTree(program.id)

        const level = getSpecTreeLevel(specTree, spec.id, -1)

        if (!program || level === null) {
          return
        }

        return { specId: spec.id, program, level }
      })

      const settledPromises = await Promise.allSettled(promises)

      const specEntries = settledPromises
        .map((p) => (p.status === 'fulfilled' && p.value) || null)
        .filter((p): p is Exclude<typeof p, null> => p !== null)

      type SpecEntry = (typeof specEntries)[number]

      type ResultType = Record<
        SpecEntry['specId'],
        {
          level: SpecEntry['level']
          program: SpecEntry['program']
        }
      >

      const result = specEntries.reduce((acc, cur) => {
        if (cur === null) {
          return acc
        }
        const { specId, level, program } = cur
        acc[specId] = { level, program }
        return acc
      }, {} as ResultType)
      return result
    },
    [getProgramById],
  )

  return { getSpecTreeInfo }
}

export default useSpecTree
