import api from './api'

export enum TaskStatus {
  Queued = 'QUEUED',
  Started = 'STARTED',
  Failed = 'FAILED',
  Completed = 'COMPLETED',
}

export type TaskResult = {
  specificationId: string
}

type TaskResponse = {
  id: string
  status: TaskStatus
  result: TaskResult
}

export const queryTask: (taskId: string) => Promise<TaskResponse> = (taskId) =>
  api.get(`/api/v2/tasks/${taskId}`)
