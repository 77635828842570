import { captureException } from '@sentry/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import * as api from '../api/v2/evidence'
import { LoadingState } from '../types/enums'

interface EvidenceActivitiesContext {
  evidenceRecords: api.Evidence[]
  evidenceActivities: EvidenceActivityData[]
  deleteEvidenceActivity: (
    evidenceId: string,
    activityId: string,
  ) => Promise<void>
  getEvidenceRecordById: (evidenceId: string) => api.Evidence | null
  evidenceActivitiesLoading: LoadingState
}

export interface EvidenceActivityData extends api.EvidenceActivity {
  recordId: string
  owners: string[]
}

const EvidenceActivitiesCtx = createContext<EvidenceActivitiesContext>({
  evidenceRecords: [],
  evidenceActivities: [],
  deleteEvidenceActivity: () => Promise.resolve(),
  getEvidenceRecordById: () => null,
  evidenceActivitiesLoading: LoadingState.Loading,
})

export const useEvidenceActivitiesContext = () => {
  const ctx = useContext(EvidenceActivitiesCtx)
  if (!ctx) {
    console.error('EvidenceActivitiesContext has no provider')
  }
  return ctx
}

export const EvidenceActivitiesContextProvider = (props) => {
  const [evidenceActivities, setEvidenceActivities] = useState<
    EvidenceActivityData[]
  >([])
  const [evidenceRecords, setEvidenceRecords] = useState<
    Record<string, api.Evidence>
  >({})
  const [evidenceActivitiesLoading, setEvidenceActivitiesLoading] =
    useState<LoadingState>(LoadingState.Loading)

  useEffect(() => {
    const fetchEvidenceActivities = async () => {
      setEvidenceActivitiesLoading(LoadingState.Loading)
      try {
        const records = await api.getEvidenceRecords()

        const activities = (
          await Promise.all(
            records.map(async (record) => api.getEvidenceActivities(record.id)),
          )
        ).flat()

        const activitiesWithOwners = await Promise.all(
          activities.map(async (activity) => {
            const owners = await api.getEvidenceActivityOwners(
              activity.recordId,
              activity.id,
            )
            return {
              ...activity,
              owners,
            }
          }),
        )

        const recordIdToRecord = records.reduce((acc, record) => {
          return {
            ...acc,
            [record.id]: record,
          }
        }, {})

        setEvidenceRecords(recordIdToRecord)
        setEvidenceActivities(activitiesWithOwners)
        setEvidenceActivitiesLoading(LoadingState.Loaded)
      } catch (error) {
        setEvidenceActivitiesLoading(LoadingState.Failed)
        console.error('Unable to load activities', error)
        captureException(error)
      }
    }

    fetchEvidenceActivities()
  }, [])

  const deleteEvidenceActivity = useCallback(
    async (evidenceId: string, activityId: string) => {
      await api.deleteEvidenceActivity(evidenceId, activityId)
      setEvidenceActivities((a) => a.filter((a) => a.id !== activityId))
    },
    [],
  )

  const getEvidenceRecordById = useCallback(
    (evidenceId: string) => evidenceRecords[evidenceId],
    [evidenceRecords],
  )

  return (
    <EvidenceActivitiesCtx.Provider
      value={{
        evidenceRecords: Object.values(evidenceRecords),
        evidenceActivities,
        deleteEvidenceActivity,
        getEvidenceRecordById,
        evidenceActivitiesLoading,
      }}
    >
      {props.children}
    </EvidenceActivitiesCtx.Provider>
  )
}
