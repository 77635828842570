import { CaretLeft, CaretRight } from '@carbon/icons-react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import ActionsCell from './ActionsCell.tsx'
import styles from './index.module.css'
import * as api from '../../api/v2/reports.ts'
import * as specApi from '../../api/v2/specifications.ts'
import * as usersApi from '../../api/v2/users.ts'
import FilterDropdown, { AppliedFilters } from '../../components/filter-menu'
import { FilterKey, FilterSet } from '../../components/filter-menu/types.ts'
import Table from '../../components/table/Table.tsx'
import Tag, { TAG_COLORS } from '../../components/tag'
import { monthDayYear } from '../../lib/date.ts'
import { ReportSource } from '../../types/enums.ts'

const FILTER_KEYS = [FilterKey.ReportSource]

export const DateCreatedCell = ({ data }) => {
  return (
    <div className={styles.dateCreatedCell}>
      {data && data.createdOn && monthDayYear(data.createdOn)}
    </div>
  )
}

export const ReportNameCell = ({ data }) => {
  return (
    <div className={styles.reportNameCell}>
      <Link to={`/reports/${data?.id}`}>{data && data.name}</Link>
    </div>
  )
}

export const SourceCell = ({ data }) => {
  return (
    <div className={styles.sourceCell}>
      {data?.source === ReportSource.SpecificationRequirementV1 && (
        <Tag
          className={styles.sourceTag}
          text="Specification"
          color={TAG_COLORS.purple2}
        />
      )}
      {data?.source === ReportSource.SearchRequirementV1 && (
        <Tag
          className={styles.sourceTag}
          text="Search"
          color={TAG_COLORS.blue}
        />
      )}
      {data?.source === ReportSource.EvidenceRequirementV1 && (
        <Tag
          className={styles.sourceTag}
          text="Evidence"
          color={TAG_COLORS.orange2}
        />
      )}
    </div>
  )
}

export const CreatorCell = ({ data }) => {
  const { createdBy } = data || {}
  const [creator, setCreator] = useState('')

  useEffect(() => {
    const fetch = async () => {
      const { firstName, lastName } = (await usersApi.getUser(createdBy)) || {}
      setCreator(firstName || lastName ? `${firstName} ${lastName}` : '')
    }

    fetch()
  }, [createdBy])

  return <div className={styles.creatorCell}>{creator}</div>
}

const TABLE_COLUMNS = [
  {
    label: '',
    Component: ActionsCell,
    transparent: true,
  },
  {
    label: 'Date Created',
    width: '123px',
    Component: DateCreatedCell,
  },
  {
    label: 'Report Name',
    width: '1fr',
    Component: ReportNameCell,
  },
  {
    label: 'Source',
    width: 'minmax(auto, 229px)',
    Component: SourceCell,
  },
  {
    label: 'Creator',
    Component: CreatorCell,
  },
]

const REPORTS_PER_PAGE = 10

const ReportsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [reports, setReports] = useState<api.BasicReportResponse[]>([])
  const [pages, setPages] = useState<api.BasicReportResponse[][]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const displayedData = pages[currentPage] || []

  const [filterOptions, setFilterOptions] = useState<FilterSet>()
  const [filtersSelected, setFiltersSelected] = useState<FilterSet>(
    FILTER_KEYS.reduce((acc, val) => ({ ...acc, [val]: [] }), {} as FilterSet),
  )

  useEffect(() => {
    window.document.title = 'Reports'
    const loadOptions = async () => {
      const [allReports, specs] = await Promise.all([
        api.getReports(),
        specApi.getAllSpecifications(),
      ])

      const reports = await Promise.all(
        allReports.map((report) => api.getReport(report.id)),
      )

      const uniqueSpecIds = new Set()

      reports.forEach((report) => {
        if (report.source === ReportSource.SpecificationRequirementV1) {
          uniqueSpecIds.add(report.contents[0].specification.id)
        }
      })

      const filteredSpecs = specs.filter((spec) => uniqueSpecIds.has(spec.id))
      const sortedFilteredSpecs = filteredSpecs.sort((a, b) =>
        a.name.localeCompare(b.name),
      )
      const filterOptions = [FilterKey.ReportSource].reduce(
        (acc, val) => ({ ...acc, [val]: [] }),
        {} as FilterSet,
      )
      filterOptions.reportSource = [
        {
          id: 'search',
          label: 'SEARCH',
          source: ReportSource.SearchRequirementV1,
        },
        {
          id: 'evidenceCompliance',
          label: 'EVIDENCE',
          source: ReportSource.EvidenceRequirementV1,
        },
        ...sortedFilteredSpecs.map((spec) => ({
          id: `spec-${spec.id}`,
          label: spec.name,
          source: ReportSource.SpecificationRequirementV1,
          specificationId: spec.id,
        })),
      ]
      setFilterOptions(filterOptions)
    }
    loadOptions()
  }, [])

  useEffect(() => {
    const appliedSources = searchParams.getAll('sources')
    const appliedSpecIds = searchParams.getAll('specificationIds')

    if (!filterOptions) {
      return
    }

    const filters = FILTER_KEYS.reduce(
      (acc, val) => ({ ...acc, [val]: [] }),
      {} as FilterSet,
    )
    appliedSources.forEach((source) => {
      if (source !== ReportSource.SpecificationRequirementV1) {
        const sourceFilter = filterOptions?.reportSource.find(
          (option) => option.source === source,
        )
        filters.reportSource.push(sourceFilter)
        return
      }
      appliedSpecIds.forEach((specId) => {
        const specFilter = filterOptions?.reportSource.find(
          (option) => option.specificationId === specId,
        )
        if (!specFilter) {
          return
        }
        filters.reportSource.push(specFilter)
      })
    })
    setCurrentPage(0)
    setFiltersSelected(filters)
  }, [filterOptions, filterOptions?.reportSource, searchParams])

  useEffect(() => {
    const fetchReports = async () => {
      const appliedSources = searchParams.getAll('sources')
      const appliedSpecIds = searchParams.getAll('specificationIds')
      const reports: api.BasicReportResponse[] = []

      if (!appliedSources?.length && !appliedSpecIds?.length) {
        reports.push(...(await api.getReports({})))
      } else {
        if (appliedSources.includes(ReportSource.SearchRequirementV1)) {
          reports.push(
            ...(await api.getReports({
              sources: [ReportSource.SearchRequirementV1],
            })),
          )
        }

        if (appliedSources.includes(ReportSource.SpecificationRequirementV1)) {
          reports.push(
            ...(await api.getReports({
              sources: [ReportSource.SpecificationRequirementV1],
              specificationIds: appliedSpecIds,
            })),
          )
        }

        if (appliedSources.includes(ReportSource.EvidenceRequirementV1)) {
          reports.push(
            ...(await api.getReports({
              sources: [ReportSource.EvidenceRequirementV1],
            })),
          )
        }
      }

      setReports(reports)
    }

    fetchReports()
  }, [filterOptions?.reportSource, searchParams])

  useEffect(() => {
    const newPages: api.BasicReportResponse[][] = []
    for (let i = 0; i < reports.length; i += REPORTS_PER_PAGE) {
      newPages.push(reports.slice(i, i + REPORTS_PER_PAGE))
    }

    setPages(newPages)
  }, [reports])

  const removeReport = useCallback(
    (reportId: string) => {
      setReports([...reports.filter((report) => report.id !== reportId)])
    },
    [reports],
  )

  const reportCount = pages.reduce((prev, curr) => {
    return prev + curr.length
  }, 0)

  const removeFilter = useCallback(
    (filterKey: FilterKey, filter: any) => {
      if (filterKey === FilterKey.ReportSource) {
        if (filter.source === ReportSource.SpecificationRequirementV1) {
          searchParams.delete('specificationIds', filter.specificationId)
        }
        if (
          filter.source !== ReportSource.SpecificationRequirementV1 ||
          searchParams.get('specificationIds') === null
        ) {
          searchParams.delete('sources', filter.source)
        }
        setSearchParams(searchParams)
      }
    },
    [searchParams, setSearchParams],
  )

  const addFilter = useCallback(
    (filterKey: FilterKey, filter: any) => {
      if (filterKey === FilterKey.ReportSource) {
        if (!searchParams.getAll('sources')?.includes(filter.source)) {
          searchParams.append('sources', filter.source)
        }
        if (filter.specificationId) {
          searchParams.append('specificationIds', filter.specificationId)
        }
        setSearchParams(searchParams)
      }
    },
    [searchParams, setSearchParams],
  )

  const toggleFilter = useCallback(
    (filterKey: FilterKey, filter: any) => {
      const appliedSources = searchParams.getAll('sources')
      const appliedSpecIds = searchParams.getAll('specificationIds')

      if (filterKey === FilterKey.ReportSource) {
        const appliedFilter =
          filter.source === ReportSource.SpecificationRequirementV1
            ? appliedSources.includes(filter.source) &&
              appliedSpecIds.includes(filter.specificationId)
            : appliedSources.includes(filter.source)
        if (appliedFilter) {
          removeFilter(filterKey, filter)
        } else {
          addFilter(filterKey, filter)
        }
      }
    },
    [addFilter, removeFilter, searchParams],
  )

  return (
    <div className={styles.container}>
      <h1>Reports</h1>
      <div className={styles.description}>
        <div className={styles.info}>
          Reports can be created from specifications or from search results.
        </div>
        <div className={styles.reportCount}>
          {reportCount} reports in organization
        </div>
      </div>
      <div className={styles.actions}>
        <FilterDropdown
          menus={FILTER_KEYS}
          filters={filterOptions}
          activeFilters={filtersSelected}
          onSelectFilter={toggleFilter}
        />
        <AppliedFilters
          filterKeys={FILTER_KEYS}
          filters={filtersSelected}
          onRemove={removeFilter}
        />
      </div>
      <div className={styles.tableWrapper}>
        <Table
          columns={TABLE_COLUMNS}
          rowItems={displayedData.map((reportData) => ({
            ...reportData,
            onDelete: removeReport,
          }))}
        />
      </div>
      {pages.length > 1 && (
        <div className={styles.pager}>
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            className={currentPage === 0 ? styles.hidden : ''}
          >
            <CaretLeft size={24} />
          </button>
          {[...Array(pages.length)].map((_, idx) => (
            <button
              key={idx}
              className={`${styles.page} ${
                currentPage === idx ? styles.currentPage : ''
              }`}
              onClick={() => setCurrentPage(idx)}
            >
              {idx + 1}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            className={pages.length > currentPage + 1 ? '' : styles.hidden}
            data-testid="page-next"
          >
            <CaretRight size={24} />
          </button>
        </div>
      )}
    </div>
  )
}

export default ReportsPage
