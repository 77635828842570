import { Outlet } from 'react-router-dom'
import styles from './UnauthenticatedLayout.module.css'

const UnauthenticatedLayout = () => {
  return (
    <div className={`${styles.background}`}>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default UnauthenticatedLayout
