import { Search } from '@carbon/icons-react'
import styles from './LiveSearchInput.module.css'

const LiveSearchInput = (props: {
  className?: string
  placeholder?: string
  query: string
  setQuery: (query: string) => void
}) => {
  const { className, placeholder, query, setQuery } = props

  return (
    <div className={`${styles.root} ${className || ''}`}>
      <input
        value={query}
        placeholder={placeholder}
        onChange={(e) => setQuery(e.target.value)}
        className={`${styles.input} ${styles.basicElevation}`}
      />
      <Search size={16} className={styles.icon} />
    </div>
  )
}

export default LiveSearchInput
