import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useAuth } from './AuthContext.tsx'
import * as api from '../api/v2/attributes'
import { CustomColorSet, stylesToTagColor } from '../components/tag'
import useCustomAttributes from '../hooks/useCustomAttributes'
import { AttributeName, AttributeValueStatus } from '../types/enums'

type AttributeEntity = 'SPECIFICATION' | 'EVIDENCE' | 'REQUIREMENT'

type Attribute = {
  id: string
  name: string
  entityType: AttributeEntity
}

type Attributes = Record<string, Attribute>

type ComplianceStyles = {
  name: string
  styles: CustomColorSet
}

const filterArchivedAttris: (
  attributes: Array<api.AttributeValueResponse>,
) => Array<api.AttributeValueResponse> = (attributes) => {
  return attributes
    .filter((attribute) => attribute.status !== AttributeValueStatus.Archived)
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
}

const AttributesContext = createContext<{
  categories: Array<api.AttributeValueResponse>
  activeCategories: Array<api.AttributeValueResponse>
  getCategoryById: (id: string) => api.AttributeValueResponse | null
  programs: Array<api.AttributeValueResponse>
  activePrograms: Array<api.AttributeValueResponse>
  getProgramById: (id: string) => api.AttributeValueResponse | null
  projects: Array<api.AttributeValueResponse>
  activeProjects: Array<api.AttributeValueResponse>
  projectId: string | null
  getProjectById: (id: string) => api.AttributeValueResponse | null
  requirementTypes: Array<api.AttributeValueResponse>
  activeRequirementTypes: Array<api.AttributeValueResponse>
  getRequirementTypeById: (id: string) => api.AttributeValueResponse | null
  evidenceMethods: Array<api.AttributeValueResponse>
  activeEvidenceMethods: Array<api.AttributeValueResponse>
  getEvidenceMethodById: (id: string) => api.AttributeValueResponse | null
  evidenceCadences: Array<api.AttributeValueResponse>
  getEvidenceCadenceById: (id: string) => api.AttributeValueResponse | null
  complianceStates: Array<api.AttributeValueResponse>
  activeComplianceStates: Array<api.AttributeValueResponse>
  getComplianceById: (id: string) => api.AttributeValueResponse | null
  getComplianceStyles: (complianceName: string) => ComplianceStyles | null
  phases: Array<api.AttributeValueResponse>
  getPhaseById: (id: string) => api.AttributeValueResponse | null
  activePhaseStates: Array<api.AttributeValueResponse>
  allAttributes: Attributes
  getAttributeByName: (name: AttributeName) => Attribute | null
  reload: () => void
}>({
  categories: [],
  activeCategories: [],
  getCategoryById: () => null,
  programs: [],
  activePrograms: [],
  getProgramById: () => null,
  projects: [],
  activeProjects: [],
  projectId: null,
  getProjectById: () => null,
  requirementTypes: [],
  activeRequirementTypes: [],
  getRequirementTypeById: () => null,
  evidenceMethods: [],
  activeEvidenceMethods: [],
  getEvidenceMethodById: () => null,
  evidenceCadences: [],
  getEvidenceCadenceById: () => null,
  complianceStates: [],
  activeComplianceStates: [],
  getComplianceById: () => null,
  getComplianceStyles: () => null,
  phases: [],
  getPhaseById: () => null,
  activePhaseStates: [],
  allAttributes: {},
  getAttributeByName: () => null,
  reload: () => {},
})

const AttributesContextProvider = (props) => {
  const { isSignedIn } = useAuth()
  const [allAttributes, setAllAttributes] = useState<Attributes>({})

  const [categories, fetchCategories, getCategoryById] = useCustomAttributes(
    allAttributes.CATEGORY?.id,
  )
  const [programs, fetchPrograms, getProgramById] = useCustomAttributes(
    allAttributes.PROGRAM?.id,
  )
  const [projects, fetchProjects, getProjectById] = useCustomAttributes(
    allAttributes.PROJECT?.id,
  )

  const [phases, fetchPhases, getPhaseById] = useCustomAttributes(
    allAttributes.PHASE?.id,
  )

  const [requirementTypes, fetchRequirementTypes, getRequirementTypeById] =
    useCustomAttributes(allAttributes.TYPE?.id)
  const [evidenceMethods, fetchEvidenceMethods, getEvidenceMethodById] =
    useCustomAttributes(allAttributes.METHOD?.id)
  const [evidenceCadences, fetchCadences, getEvidenceCadenceById] =
    useCustomAttributes(allAttributes.CADENCE?.id)
  const [complianceStates, fetchCompliances, getComplianceById] =
    useCustomAttributes(allAttributes.COMPLIANCE?.id)

  const getComplianceStyles = useCallback(
    (value) => {
      const curCompliance = complianceStates.find((c) => c.name === value)

      if (!curCompliance) {
        return null
      }

      return {
        name: curCompliance?.name,
        styles: stylesToTagColor(curCompliance?.metadata.STYLES),
      }
    },
    [complianceStates],
  )

  const getAttributeByName = useCallback(
    (name: AttributeName) => {
      return allAttributes[name]
    },
    [allAttributes],
  )

  const fetchAttributes = async () => {
    const { attributes } = await api.getAttributes()
    setAllAttributes(
      attributes.reduce((acc, val) => {
        return { ...acc, [val.name]: val }
      }, {}),
    )
  }

  useEffect(() => {
    if (isSignedIn) {
      fetchAttributes()
    }
  }, [isSignedIn])

  useEffect(() => {
    fetchCategories()
    fetchPrograms()
    fetchProjects()
    fetchRequirementTypes()
    fetchEvidenceMethods()
    fetchCadences()
    fetchCompliances()
    fetchPhases()
  }, [
    allAttributes,
    fetchCadences,
    fetchCategories,
    fetchCompliances,
    fetchEvidenceMethods,
    fetchPhases,
    fetchPrograms,
    fetchProjects,
    fetchRequirementTypes,
  ])

  const reload = useCallback(() => {
    fetchAttributes()
  }, [])

  return (
    <AttributesContext.Provider
      value={{
        categories,
        activeCategories: filterArchivedAttris(categories),
        getCategoryById,
        programs,
        activePrograms: filterArchivedAttris(programs),
        getProgramById,
        projects,
        activeProjects: filterArchivedAttris(projects),
        projectId: allAttributes?.PROJECT?.id,
        getProjectById,
        requirementTypes,
        activeRequirementTypes: filterArchivedAttris(requirementTypes),
        getRequirementTypeById,
        evidenceMethods,
        activeEvidenceMethods: filterArchivedAttris(evidenceMethods),
        getEvidenceMethodById,
        complianceStates,
        activeComplianceStates: filterArchivedAttris(complianceStates),
        getComplianceById,
        getComplianceStyles,
        evidenceCadences,
        getEvidenceCadenceById,
        getAttributeByName,
        phases,
        activePhaseStates: filterArchivedAttris(phases),
        getPhaseById,
        allAttributes,
        reload,
      }}
    >
      {props.children}
    </AttributesContext.Provider>
  )
}

const useAttributesContext = () => useContext(AttributesContext)

export { AttributesContextProvider, useAttributesContext }
