import { Menu } from '@carbon/icons-react'
import { useCallback, useState } from 'react'
import FilterComponents from './FilterComponents'
import styles from './FilterDropdown.module.css'
import { FilterDropdownProps, FilterKey } from './types'
import useClickOutside from '../../hooks/useClickOutside'
import Button from '../button'
import Dropdown from '../dropdown'

const FilterDropdown = (props: FilterDropdownProps) => {
  const { menus, activeFilters, onSelectFilter, filters, filterData } = props

  const [isOpen, setIsOpen] = useState(false)
  const [menu, setMenu] = useState<FilterKey | null>(
    menus?.length === 1 ? menus[0] : null,
  )

  const onClose = useCallback(() => {
    setIsOpen(false)
    setMenu(null)
  }, [])

  const { FilterMenu } = menu ? FilterComponents[menu] : { FilterMenu: null }

  const ref = useClickOutside(onClose)

  return (
    <div ref={ref} className={styles.wrapper}>
      <Button
        text="Filter"
        endIcon={<Menu />}
        onClick={() => {
          if (isOpen) {
            onClose()
          } else {
            setIsOpen(true)
          }
        }}
      />
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        <div
          className={styles.menu}
          style={menu === null ? {} : { display: 'none' }}
        >
          {menus.map((menuKey) => (
            <button key={menuKey} onClick={() => setMenu(menuKey)}>
              <div className={styles.item}>
                {FilterComponents[menuKey].label}
              </div>
            </button>
          ))}
        </div>
        {FilterMenu && (
          <FilterMenu
            filters={filters}
            activeFilters={activeFilters}
            onSelectFilter={onSelectFilter}
            filterData={filterData}
          />
        )}
      </Dropdown>
    </div>
  )
}

export default FilterDropdown
