import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './EvidenceActivityTypeSelect.module.css'
import useClickOutside from '../../hooks/useClickOutside'
import { EvidenceActivityType } from '../../types/enums'
import EvidenceActivityTypeTag from '../tag/EvidenceActivityTypeTag'

interface EvidenceActivityTypeSelectProps {
  value: EvidenceActivityType
  onSelect: (type: EvidenceActivityType) => void
}

const EvidenceActivityTypeSelect = (props: EvidenceActivityTypeSelectProps) => {
  const { value, onSelect: onSelectProp } = props
  const [open, setOpen] = useState(false)
  const ref = useClickOutside(() => setOpen(false), open)
  const onSelect = (id) => {
    onSelectProp(id)
    setOpen(false)
  }

  return (
    <div ref={ref} className={styles.root}>
      <button
        className={`${styles.select} ${styles.basicElevation}`}
        onClick={(event) => {
          event.preventDefault()
          setOpen(!open)
        }}
      >
        {value ? (
          <EvidenceActivityTypeTag type={value} />
        ) : (
          <span>Select type</span>
        )}
        <CaretDown size={20} />
      </button>
      <ul className={`${open ? styles.open : ''} ${styles.basicElevation}`}>
        {Object.values(EvidenceActivityType).map((type) => {
          return (
            <li key={type} data-current={type === value}>
              <button
                key={type}
                className={styles.menuItem}
                onClick={(event) => {
                  event.preventDefault()
                  onSelect(type)
                  setOpen(false)
                }}
              >
                <EvidenceActivityTypeTag hoverable={true} type={type} />
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default EvidenceActivityTypeSelect
