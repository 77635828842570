import { useCallback } from 'react'
import { DEFAULT_SPEC_INSTRUCTIONS } from './TextBlock.tsx'
import { Block, HeadingBlockData } from '../../../api/v2/blocks.ts'
import { useDocumentContext } from '../../../context/DocumentContext.tsx'
import { useSectionContext } from '../../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../../context/SpecificationContext.tsx'
import EditableSpan from '../../editable-span/EditableSpan.tsx'
import SelectableBlock from '../SelectableBlock.tsx'

const HeadingBlock = (props: { block: Block<HeadingBlockData> }) => {
  const { block } = props
  const { contentIsEditable } = useSpecificationContext()
  const { autoSelectBlockId, setAutoSelectBlockId } = useDocumentContext()

  const { blockIds, updateHeadingBlock, createTextBlock, deleteBlock } =
    useSectionContext()
  const { text } = block.data?._data ?? { text: '' }

  const onEnter = useCallback(
    async (e: React.KeyboardEvent<HTMLSpanElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        await createTextBlock(block.id)
      }
    },
    [block.id, createTextBlock],
  )

  const onBackspace = useCallback(
    async (e: React.KeyboardEvent<HTMLSpanElement>) => {
      if (e.key === 'Backspace' && text === '' && blockIds.length > 1) {
        e.preventDefault()
        const focusId =
          blockIds[blockIds.indexOf(block.id) - 1] ??
          blockIds[blockIds.indexOf(block.id) + 1]
        setAutoSelectBlockId(focusId)
        deleteBlock(block.id)
      }
    },
    [block.id, blockIds, deleteBlock, setAutoSelectBlockId, text],
  )

  return (
    <SelectableBlock
      style={{ maxWidth: '514px', width: '100%' }}
      blockId={block.id}
    >
      <h3 style={{ margin: 0 }}>
        <EditableSpan
          readOnly={!contentIsEditable}
          focusOnLoad={autoSelectBlockId === block.id}
          placeholder={
            blockIds.length === 1 ? DEFAULT_SPEC_INSTRUCTIONS : 'Heading'
          }
          style={{
            width: '100%',
            color: 'var(--text-color-black)',
            fontSize: '16px',
          }}
          onValueChange={(update) => {
            if (update !== block.data?._data.text)
              updateHeadingBlock(block.id, update)
          }}
          onKeyDown={(e) => {
            onEnter(e)
            onBackspace(e)
          }}
          value={text}
        />
      </h3>
    </SelectableBlock>
  )
}

export default HeadingBlock
