let navigateFn: (path: string, options?: any) => void

export const setNavigate = (
  navigate: (path: string, options?: any) => void,
) => {
  navigateFn = navigate
}

export const navigateTo = (path: string, options?: any) => {
  if (navigateFn) {
    navigateFn(path, options)
  } else {
    console.error('Navigate function is not set')
  }
}
