import { Add } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoaderData } from 'react-router-dom'
import ExceptionRow from './ExceptionRow'
import styles from './ExceptionsList.module.css'
import { Exception, getAllExceptions } from '../../api/v2/exceptions'
import { getUser, User } from '../../api/v2/users'
import Button, { BUTTON_COLORS } from '../../components/button'

const ExceptionsList = () => {
  const navigate = useNavigate()
  const { allExceptions } = useLoaderData() as { allExceptions: Exception[] }
  const [exceptions, setExceptions] = useState<Exception[]>(allExceptions || [])
  const [users, setUsers] = useState<User[]>([])
  const [reloadFlag, setReloadFlag] = useState(0)

  const reload = () => setReloadFlag((reloadFlag + 1) % 2)

  useEffect(() => {
    const fetchExceptions = async () => {
      const allExceptions = await getAllExceptions()
      setExceptions(
        allExceptions.sort(
          (a, b) => b.createdOn.getTime() - a.createdOn.getTime(),
        ),
      )
    }

    fetchExceptions()
  }, [reloadFlag])

  useEffect(() => {
    window.document.title = 'Exceptions'
    const fetchUsers = async () => {
      try {
        const users = await Promise.all(
          exceptions.map((exception) => getUser(exception.createdBy)),
        )
        setUsers(users)
      } catch (error) {
        console.error('Error fetching exceptions', error)
        captureException(error)
      }
    }

    fetchUsers()
  }, [exceptions])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1>Exceptions</h1>
          <Button
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => navigate('/exceptions/create')}
            text="Create"
            endIcon={<Add size={20} />}
          />
        </div>

        <p>
          The Exceptions database tracks details of Deviations (pre-production
          and in progress) and Waivers (post-production), <br /> or differences
          between an implementation of a system and requirements for that
          system.
        </p>
      </div>

      <div className={styles.container}>
        {exceptions.length === 0 ? (
          <div>
            <p>Nothing to see here. Create a new exception above.</p>
          </div>
        ) : (
          <div className={styles.exceptions}>
            {exceptions.map((exception) => (
              <ExceptionRow
                key={exception.id}
                users={users}
                {...exception}
                reload={reload}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default ExceptionsList
