class EnvConfig {
  static get posthogHost(): string {
    return this.getVariable('VITE_POSTHOG_API_HOST')
  }

  static get posthogKey(): string {
    return this.getVariable('VITE_POSTHOG_API_KEY')
  }

  static get env(): string {
    return this.getVariable('VITE_ENV')
  }

  static get sentryDSN(): string {
    return this.getVariable('VITE_SENTRY_DSN')
  }

  private static getVariable(name: string): string {
    const value = import.meta.env[name]
    if (!value) {
      throw new Error(`Environment variable ${name} is not set.`)
    }

    return value
  }
}

export default EnvConfig
