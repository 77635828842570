import { ImportExport, Upload } from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback, useRef, useState } from 'react'
import Modal from '.'
import styles from './ImportPdfModal.module.css'
import { importWordToSpecification } from '../../api/v2/specifications'
import { useAuth } from '../../context/AuthContext.tsx'
import Button, { BUTTON_COLORS } from '../button'
import { toastError } from '../toast'

const ImportWordModal = (props: { closeModal: () => void }) => {
  const { closeModal } = props
  const { reload } = useAuth()
  const [pendingImport, setPendingImport] = useState(false)
  const llmImportEnabled = useFeatureFlagEnabled('llm-import')

  const fileInput = useRef<HTMLInputElement>(null)
  const openFilePicker = () => {
    fileInput.current?.click()
  }

  const importDOCX = useCallback(async () => {
    const [wordFile] = fileInput.current?.files ?? []
    if (wordFile?.size > 26214400) {
      toastError('File too large', 'Only files less than 25MB can be imported')
      return
    }

    setPendingImport(true)
    await importWordToSpecification(wordFile, llmImportEnabled ?? false, reload)
    closeModal()
    setPendingImport(false)
  }, [closeModal, llmImportEnabled, reload])

  return (
    <Modal
      title="Import Word"
      icon={<ImportExport className={styles.importIcon} size={20} />}
      onClose={closeModal}
    >
      <div className={styles.container}>
        <div className={styles.info}>
          <span className={styles.label}>Instructions</span>
          <p className={styles.instructions}>
            Stell supports importing Word DOCX files and will attempt to parse
            requirements. Large files will import in the background. You will
            receive a notification when your import is complete.
          </p>
        </div>
        <div className={styles.actions}>
          <Button
            text="Import Word DOCX"
            endIcon={<Upload />}
            onClick={openFilePicker}
            color={BUTTON_COLORS.PRIMARY}
            disabled={pendingImport}
          />
          <input
            ref={fileInput}
            style={{ display: 'none' }}
            type="file"
            accept=".docx"
            onChange={importDOCX}
          />
        </div>
        <p className={styles.sizeWarning}>
          Maximum import size for DOCX files is 25mb
        </p>
      </div>
    </Modal>
  )
}

export default ImportWordModal
