import panzoom, { PanZoom } from 'panzoom'
import { useRef, useCallback, RefCallback } from 'react'

interface UseZoom {
  width: number
  height: number
  setIsDragging: (isDragging: boolean) => void
  initialZoom?: number
  ignoreScroll?: boolean
}

const useZoom = ({
  width,
  height,
  setIsDragging,
  initialZoom = 1,
  ignoreScroll,
}: UseZoom): {
  zoomRef: RefCallback<SVGSVGElement>
  zoomIn: () => void
  zoomOut: () => void
} => {
  const zoomInstance = useRef<PanZoom | null>(null)

  const zoomRef = useCallback(
    (element: SVGSVGElement | null) => {
      if (element) {
        zoomInstance.current = panzoom(element, {
          maxZoom: 2,
          minZoom: 0.5,
          initialZoom,
          zoomDoubleClickSpeed: 1,
          ...(ignoreScroll ? { beforeWheel: () => ignoreScroll } : {}),
        })

        zoomInstance.current.on('panstart', () => {
          setIsDragging(true)
        })

        zoomInstance.current.on('panend', () => {
          setIsDragging(false)
        })
      } else if (zoomInstance.current) {
        zoomInstance.current.dispose()
        zoomInstance.current = null
      }
    },
    [initialZoom, setIsDragging],
  )

  const centerX = width / 2
  const centerY = height / 2

  const zoomIn = useCallback(() => {
    if (zoomInstance.current) {
      zoomInstance.current.smoothZoom(centerX, centerY, 1.2)
    }
  }, [centerX, centerY])

  const zoomOut = useCallback(() => {
    if (zoomInstance.current) {
      zoomInstance.current.smoothZoom(centerX, centerY, 0.8)
    }
  }, [centerX, centerY])

  return { zoomRef, zoomIn, zoomOut }
}

export default useZoom
