import { Dispatch, SetStateAction, useEffect } from 'react'
import { VisibleBlock } from './useVisibleBlocks'
import { RevisionStatus } from '../../../api/v2/revisions'
import { RequirementStatus } from '../../../types/enums'

const updateRequirementStatuses =
  (fromStatus: RequirementStatus, toStatus: RequirementStatus) =>
  (visibleBlocks: Record<string, VisibleBlock>) =>
    Object.entries(visibleBlocks).reduce(
      (record, [id, visibleBlock]) =>
        visibleBlock.requirement
          ? {
              ...record,
              [id]: {
                ...visibleBlock,
                requirement: {
                  ...visibleBlock.requirement,
                  status:
                    visibleBlock.requirement?.status === fromStatus
                      ? toStatus
                      : visibleBlock.requirement?.status,
                },
              },
            }
          : { ...record, [id]: visibleBlock },
      {},
    )

const useSyncStatus = (
  setVisibleBlocks: Dispatch<SetStateAction<Record<string, VisibleBlock>>>,
  revisionStatus: RevisionStatus,
) => {
  useEffect(() => {
    // Submitted for review
    if (revisionStatus === RevisionStatus.REVIEW) {
      setVisibleBlocks(
        updateRequirementStatuses(
          RequirementStatus.Draft,
          RequirementStatus.Review,
        ),
      )
    }
    // Published
    if (revisionStatus === RevisionStatus.ACTIVE) {
      setVisibleBlocks(
        updateRequirementStatuses(
          RequirementStatus.Review,
          RequirementStatus.Active,
        ),
      )
    }
    // Recalled to draft
    if (revisionStatus === RevisionStatus.DRAFT) {
      setVisibleBlocks(
        updateRequirementStatuses(
          RequirementStatus.Review,
          RequirementStatus.Draft,
        ),
      )
    }
  }, [revisionStatus, setVisibleBlocks])
}

export default useSyncStatus
