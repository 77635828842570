import { captureException } from '@sentry/react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './EvidenceActivityList.module.css'
import EvidenceActivityTableColumns, {
  EvidenceActivityColumnLabels,
} from './EvidenceActivityTableColumns'
import { getJiraConnection } from '../../api/v2/integrations.ts'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types'
import LiveSearchInput from '../../components/input/LiveSearchInput.tsx'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator'
import Table from '../../components/table/Table'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import { useEvidenceActivitiesContext } from '../../context/EvidenceActivitiesContext'
import useLoadSearchParams, {
  hasLoadedEvidenceProgramFilter,
  LoadSearchParamConfig,
} from '../../hooks/useLoadSearchParams.ts'
import { useModals } from '../../hooks/useModals.ts'
import useUsers from '../../hooks/useUsers.ts'
import { LoadingState } from '../../types/enums'

interface RouteParams extends Record<string, string | undefined> {
  evidenceId?: string
}

const FILTER_KEYS = [
  FilterKey.EvidenceActivityType,
  FilterKey.EvidenceActivityParentRecord,
  FilterKey.EvidenceActivityStatus,
  FilterKey.EvidenceActivityOwner,
  FilterKey.EvidenceProgram,
  FilterKey.EvidenceMethod,
]

const EvidenceActivityList = () => {
  const {
    evidenceActivities,
    evidenceActivitiesLoading,
    getEvidenceRecordById,
  } = useEvidenceActivitiesContext()
  const { getUserById } = useUsers()
  const { activityId: scrollToActivityId } = useParams<RouteParams>()
  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)
  const { openEvidenceActivityDrawer } = useModals()
  const { activePrograms, getEvidenceMethodById } = useAttributesContext()
  const [query, setQuery] = useState('')

  const { userTenantId } = useAuth()
  const [jiraConnectionUrl, setJiraConnectionUrl] = useState('')
  const enableJiraIssue = useFeatureFlagEnabled('jira-issue')
  const loadSearchParamsConfig = useCallback(
    () =>
      [
        {
          key: 'programId',
          hasLoaded: (val) =>
            hasLoadedEvidenceProgramFilter(
              val,
              activePrograms,
              filters,
              toggleFilter,
            ),
        },
        {
          key: 'status',
          hasLoaded: (val) => {
            if (val && filters[FilterKey.EvidenceActivityStatus].length === 0) {
              toggleFilter(FilterKey.EvidenceActivityStatus, val)
              return true
            }
            return false
          },
        },
      ] as LoadSearchParamConfig[],
    [activePrograms, filters, toggleFilter],
  )
  useLoadSearchParams(loadSearchParamsConfig())

  useEffect(() => {
    const checkJiraConnection = async () => {
      if (enableJiraIssue) {
        try {
          const response = await getJiraConnection(userTenantId)
          if (response) {
            setJiraConnectionUrl(response.url)
          } else {
            setJiraConnectionUrl('')
          }
        } catch (error) {
          console.error('Error checking JIRA connection', error)
          setJiraConnectionUrl('')
          captureException(error)
        }
      }
    }

    checkJiraConnection()
  }, [userTenantId, enableJiraIssue])

  let filteredEvidenceActivities = useMemo(() => {
    return evidenceActivities
      .filter((a) => a.title.toLowerCase().includes(query.toLowerCase()))
      .filter(
        (a) =>
          filters[FilterKey.EvidenceActivityType].length === 0 ||
          filters[FilterKey.EvidenceActivityType].includes(a.type),
      )
      .filter(
        (a) =>
          filters[FilterKey.EvidenceActivityParentRecord].length === 0 ||
          filters[FilterKey.EvidenceActivityParentRecord].some(
            (f) => f.id === a.recordId,
          ),
      )
      .filter(
        (a) =>
          filters[FilterKey.EvidenceActivityStatus].length === 0 ||
          filters[FilterKey.EvidenceActivityStatus].includes(a.status),
      )
      .filter((a) => {
        if (filters[FilterKey.EvidenceActivityOwner].length === 0) {
          return true
        }

        return a.owners.reduce((acc, cur) => {
          filters[FilterKey.EvidenceActivityOwner].forEach((f) => {
            acc = acc ? acc : cur.includes(f)
          })
          return acc
        }, false)
      })
      .filter(
        (e) =>
          filters[FilterKey.EvidenceProgram].length === 0 ||
          (() => {
            const record = getEvidenceRecordById(e.recordId)
            return (
              record &&
              filters[FilterKey.EvidenceProgram].includes(record.program)
            )
          })(),
      )
      .filter(
        (e) =>
          filters[FilterKey.EvidenceMethod].length === 0 ||
          (() => {
            const record = getEvidenceRecordById(e.recordId)
            return (
              record &&
              filters[FilterKey.EvidenceMethod].includes(record.method)
            )
          })(),
      )
  }, [evidenceActivities, query, filters, getEvidenceRecordById])

  filteredEvidenceActivities = filteredEvidenceActivities.map((activity) => {
    return {
      ...activity,
      ownerIdsToName: activity.owners.reduce((prev, ownerId) => {
        return {
          ...prev,
          [ownerId]: getUserById(ownerId),
        }
      }, {}),
    }
  })

  const scrollToIndex = filteredEvidenceActivities.findIndex(
    (activity) => activity.id === scrollToActivityId,
  )

  return (
    <>
      <div className={styles.activities}>
        <div className={styles.actions}>
          <LiveSearchInput
            className={styles.search}
            query={query}
            setQuery={(newQuery) => setQuery(newQuery)}
            placeholder="Search for activities"
          />
          <FilterDropdown
            menus={FILTER_KEYS}
            activeFilters={filters}
            onSelectFilter={toggleFilter}
          />
          <AppliedFilters
            filterKeys={FILTER_KEYS}
            filters={filters}
            onRemove={toggleFilter}
          />
        </div>
        {evidenceActivitiesLoading === LoadingState.Loading && (
          <div className={styles.center}>
            <LoadingIndicator />
          </div>
        )}
        {evidenceActivitiesLoading === LoadingState.Loaded &&
          (filteredEvidenceActivities.length > 0 ? (
            <div className={styles.table}>
              <div className={styles.inner}>
                <Table
                  rowItems={filteredEvidenceActivities}
                  columns={EvidenceActivityTableColumns}
                  scrollToIndex={scrollToIndex}
                  onRowClick={(rowData) => {
                    const evidence = getEvidenceRecordById(rowData.recordId)
                    const method = evidence?.method
                      ? getEvidenceMethodById(evidence.method)
                      : undefined
                    const color = method?.metadata.STYLES.COLOR_BG || ''

                    const owners = rowData.owners.map((ownerId) =>
                      getUserById(ownerId),
                    )

                    openEvidenceActivityDrawer({
                      activity: { ...rowData, owners },
                      parentRecord: evidence,
                      parentRecordColor: color,
                      jiraConnectionUrl,
                    })
                  }}
                  ignoredRowClickColumns={[
                    EvidenceActivityColumnLabels.ACTIONS,
                  ]}
                />
              </div>
            </div>
          ) : (
            <div className={styles.noResults}>
              No results matching current query
            </div>
          ))}
        {evidenceActivitiesLoading === LoadingState.Failed && (
          <div className={styles.noResults}>Unable to load results</div>
        )}
      </div>
    </>
  )
}

export default EvidenceActivityList
