import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './EvidenceActivityStatusSelect.module.css'
import useClickOutside from '../../hooks/useClickOutside'
import { EvidenceActivityStatus } from '../../types/enums'
import EvidenceActivityStatusTag from '../tag/EvidenceActivityStatusTag'

const EvidenceActivityStatusSelect = (props: {
  value: EvidenceActivityStatus
  onSelect?: (selectedStatus: EvidenceActivityStatus) => void
}) => {
  const { value, onSelect } = props
  const [isOpen, setIsOpen] = useState(false)
  const ref = useClickOutside(() => setIsOpen(false), isOpen)

  return (
    <div ref={ref} className={styles.status}>
      {!onSelect && <EvidenceActivityStatusTag status={value} />}
      {onSelect && (
        <>
          <button
            onClick={(event) => {
              event.preventDefault()
              setIsOpen(!isOpen)
            }}
          >
            {value ? (
              <EvidenceActivityStatusTag status={value} />
            ) : (
              <span>Select status</span>
            )}
            <CaretDown size={20} />
          </button>
          <ul
            className={`${isOpen ? styles.open : ''} ${styles.basicElevation}`}
          >
            {Object.values(EvidenceActivityStatus).map((status) => {
              return (
                <li key={status} data-current={status === value}>
                  <button
                    key={status}
                    className={styles.menuItem}
                    onClick={(event) => {
                      event.preventDefault()
                      onSelect(status)
                      setIsOpen(false)
                    }}
                  >
                    <EvidenceActivityStatusTag
                      hoverable={true}
                      status={status}
                    />
                  </button>
                </li>
              )
            })}
          </ul>
        </>
      )}
    </div>
  )
}

export default EvidenceActivityStatusSelect
