import Quill from 'quill'
import { RefObject, useCallback, useState } from 'react'
import { Block, TextBlockData } from '../../../api/v2/blocks.ts'
import { useDocumentContext } from '../../../context/DocumentContext.tsx'
import { useSectionContext } from '../../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../../context/SpecificationContext.tsx'
import { EMPTY_DELTA, isEmptyDelta } from '../../../lib/string.ts'
import QuillContent from '../../quill-content/QuillContent.tsx'
import SelectableBlock from '../SelectableBlock.tsx'

interface TextBlockProps {
  block: Block<TextBlockData>
}

export const DEFAULT_SPEC_INSTRUCTIONS =
  'Begin composing your specification here.\nUse the inline menu on the left side to add other types of data.'

const TextBlock = (props: TextBlockProps) => {
  const { block } = props
  const id = block?.id
  const quillDelta = block?.data?._data?.quillDelta ?? EMPTY_DELTA
  const { contentIsEditable } = useSpecificationContext()
  const { autoSelectBlockId, setAutoSelectBlockId } = useDocumentContext()
  const {
    blockIds,
    deleteBlock,
    updateTextBlock,
    createTextBlock,
    mergeBlocks,
    activeQuillRefs,
  } = useSectionContext()
  const [creatingNewBlock, setCreatingNewBlock] = useState<boolean>(false)

  const onBackspaceAtStart = async (quillRef?: RefObject<Quill | null>) => {
    if (!quillRef) {
      return
    }

    if (blockIds.length <= 1) {
      return
    }

    const previousBlockId = blockIds[blockIds.indexOf(block.id) - 1]
    const nextBlockId = blockIds[blockIds.indexOf(block.id) + 1]

    const isEmpty = isEmptyDelta(
      JSON.stringify(quillRef?.current?.getContents()),
    )

    if (isEmpty) {
      const focusId = previousBlockId ?? nextBlockId
      setAutoSelectBlockId(focusId)
      const nextQuill = activeQuillRefs?.current.get(focusId)

      await deleteBlock(block.id)

      if (!nextQuill) {
        // focus block isn't quill content
        return
      }
      const length = nextQuill.getLength() || 0
      nextQuill.setSelection(length, 0)
      return
    }

    if (mergeBlocks && previousBlockId) {
      mergeBlocks(setAutoSelectBlockId)(
        {
          id: previousBlockId,
          type: 'text',
        },
        {
          id: block.id,
          type: 'text',
        },
      )
    }
  }

  const onEnter: (quillRef?: RefObject<Quill | null>) => Promise<void> =
    useCallback(
      async (quillRef) => {
        const range = quillRef?.current?.getSelection()
        const length = quillRef?.current?.getLength()
        if (range?.length === 0) {
          setCreatingNewBlock(true)
          if (range?.index + 1 === length) {
            await createTextBlock(id)
          } else {
            const beforeDelta = quillRef?.current?.getContents(0, range.index)
            const afterDelta = quillRef?.current?.getContents(range.index)
            await updateTextBlock(id, JSON.stringify(beforeDelta))
            await createTextBlock(id, JSON.stringify(afterDelta))
            quillRef?.current?.deleteText(
              range.index,
              afterDelta?.length() ?? 0,
            )
          }

          setCreatingNewBlock(false)
        }
      },
      [createTextBlock, id, updateTextBlock],
    )

  return (
    <SelectableBlock
      style={{ maxWidth: '514px', width: '100%' }}
      blockId={block.id}
    >
      {/*Position relative for references modal inside of quill content*/}
      <div style={{ position: 'relative', maxWidth: '514px', width: '100%' }}>
        <QuillContent
          focusOnLoad={autoSelectBlockId === id}
          placeholder="Type something..."
          delta={quillDelta}
          onEnter={onEnter}
          onBackspaceAtStart={onBackspaceAtStart}
          onFocus={() => {
            if (autoSelectBlockId === id) {
              setAutoSelectBlockId(undefined)
            }
          }}
          onValueChange={(value) => {
            if (value === quillDelta) {
              return
            }
            updateTextBlock(id, value)
          }}
          readOnly={!contentIsEditable || creatingNewBlock}
          block={block}
          instanceHandler={(instance) => {
            if (!instance) {
              return
            }
            activeQuillRefs?.current.set(block.id, instance)
          }}
        />
      </div>
    </SelectableBlock>
  )
}
export default TextBlock
