import { useCallback, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

const usePrintPDF = ({ documentTitle }) => {
  const printRef = useRef(null)
  const onBeforeGetContentResolve = useRef(null)

  const [isPrinting, setIsPrinting] = useState(false)
  const [isPrintLoading, setIsPrintLoading] = useState(false)

  const handleAfterPrint = useCallback(() => {
    setIsPrinting(false)
  }, [])

  const handleOnBeforeGetContent = useCallback(() => {
    setIsPrintLoading(true)
    setIsPrinting(true)

    return new Promise<void>((resolve: any) => {
      onBeforeGetContentResolve.current = resolve

      setTimeout(() => {
        setIsPrintLoading(false)
        resolve()
      }, 2000)
    })
  }, [setIsPrintLoading])

  const content = useCallback(() => {
    return printRef.current

    // Using dependencies as shown in react-to-print documentation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printRef.current])

  const handlePrint = useReactToPrint({
    content,
    documentTitle: documentTitle,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
  })

  const onPrint = useCallback(() => {
    handlePrint()
  }, [handlePrint])

  useEffect(() => {
    if (isPrinting && typeof onBeforeGetContentResolve.current === 'function') {
      ;(onBeforeGetContentResolve as any).current()
    }
    // Using dependencies as shown in react-to-print documentation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBeforeGetContentResolve.current, isPrinting])

  return {
    isPrinting,
    isPrintLoading,
    onPrint,
    printRef,
  }
}

export default usePrintPDF
