import { createContext, useContext, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

type DocumentContext = {
  scrollToBlockId: string | null
  autoSelectBlockId?: string
  setAutoSelectBlockId: (blockId?: string) => void
}

const DocumentCtx = createContext<DocumentContext>({
  scrollToBlockId: null,
  setAutoSelectBlockId: () => {},
})

export const useDocumentContext = () => {
  return useContext(DocumentCtx)
}

export const DocumentContextProvider = (props) => {
  const [autoSelectBlockId, setAutoSelectBlockId] = useState<string>()
  const { requirementId: urlRequirementId } = useParams()
  const [queryParameters] = useSearchParams()

  const scrollToBlockId =
    queryParameters.get('blockId') ||
    urlRequirementId ||
    queryParameters.get('requirementId')

  return (
    <DocumentCtx.Provider
      value={{
        autoSelectBlockId,
        scrollToBlockId,
        setAutoSelectBlockId,
      }}
    >
      {props.children}
    </DocumentCtx.Provider>
  )
}
