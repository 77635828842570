import { SpecificationTreeNode } from '../../../api/v2/programs.ts'

export const addNodeToTree = (
  parentNode: SpecificationTreeNode,
  newNode: SpecificationTreeNode,
  parentNodeId?: string,
): SpecificationTreeNode => {
  if (
    parentNode.id === parentNodeId ||
    (parentNode.id === 'placeholder' && !parentNodeId)
  ) {
    return {
      ...parentNode,
      children: [newNode, ...(parentNode.children ?? [])],
    }
  }

  return {
    ...parentNode,
    children: parentNode.children
      ? parentNode.children.map((child) =>
          addNodeToTree(child, newNode, parentNodeId),
        )
      : [],
  }
}

export const removeNodeFromTree = (
  currentNode: SpecificationTreeNode | null,
  nodeId: string,
): SpecificationTreeNode | null => {
  if (!currentNode) {
    return null
  }

  if (currentNode.id === nodeId) {
    return null
  }

  const updatedChildren = currentNode.children
    ? (currentNode.children
        .map((child) => removeNodeFromTree(child, nodeId))
        .filter((child) => child !== null) as SpecificationTreeNode[])
    : []

  return {
    ...currentNode,
    children: updatedChildren,
  }
}
