import { ListBoxes } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'

const AddRequirementBlockAction = (props: BlockActionItemProps) => {
  const { block, closeMenu } = props
  const { createRequirement, contentIsEditable } = useSpecificationContext()
  const { sectionId } = useSectionContext()

  return (
    contentIsEditable && (
      <button
        className={styles.actionItem}
        onClick={async () => {
          await createRequirement(sectionId, block?.id, {})
          closeMenu()
        }}
      >
        <ListBoxes />
        Requirement
      </button>
    )
  )
}

export default AddRequirementBlockAction
