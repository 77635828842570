import { Search } from '@carbon/icons-react'
import { Ref, useState } from 'react'
import styles from './SearchInput.module.css'
import Button, { BUTTON_COLORS } from '../../../components/button'

const SearchInput = (props: {
  className?: string
  displayBtn?: boolean
  query: string
  setQuery: (query: string) => void
  setQueryOnKeyUp?: boolean
  placeholder?: string
  innerRef?: Ref<any>
}) => {
  const {
    className,
    query,
    setQuery,
    placeholder,
    innerRef,
    setQueryOnKeyUp,
    displayBtn = true,
  } = props
  const [queryInput, setQueryInput] = useState('')
  const isDisabled = query == queryInput

  return (
    <div className={`${styles.root} ${className || ''}`}>
      <input
        ref={innerRef}
        value={queryInput}
        placeholder={placeholder}
        onChange={(e) => {
          setQueryInput(e.target.value)
        }}
        className={`${styles.inputElevation} ${styles.basicElevation} ${
          displayBtn ? '' : styles.inputWNoBtn
        }`}
        onKeyUp={(e) => {
          if (!isDisabled && e.key === 'Enter') {
            setQuery(queryInput)
          }

          if (!isDisabled && setQueryOnKeyUp) {
            setQueryInput((e.target as HTMLInputElement).value)
            setQuery((e.target as HTMLInputElement).value)
          }
        }}
      />
      {displayBtn && (
        <Button
          text="Search"
          color={BUTTON_COLORS.PRIMARY}
          endIcon={<Search />}
          onClick={() => setQuery(queryInput)}
          disabled={isDisabled}
        />
      )}
    </div>
  )
}

export default SearchInput
