import { CaretDown } from '@carbon/icons-react'
import { ReactElement, useState } from 'react'
import styles from './index.module.css'

interface AccordionSectionType {
  title: string
  children: ReactElement
  disabled?: boolean
}

interface AccordionType {
  expandedByDefault?: boolean
  sections: AccordionSectionType[]
  children?: ReactElement
}

const Accordion = (props: AccordionType) => {
  const { sections, expandedByDefault = false, children } = props

  const [sectionIsOpen, setSectionIsOpen] = useState(
    sections.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.title]: expandedByDefault || false,
      }
    }, {}),
  )

  return (
    <div className={styles.container}>
      {children}
      {sections.map((section) => {
        return (
          <div
            key={section.title}
            className={`${styles.section} ${
              sectionIsOpen[section.title] ? styles.openSection : ''
            } ${section.disabled ? styles.disabled : ''}`}
          >
            <button
              className={styles.header}
              onClick={() => {
                if (section.disabled) {
                  return
                }

                setSectionIsOpen({
                  ...sectionIsOpen,
                  [section.title]: !sectionIsOpen[section.title],
                })
              }}
            >
              <span>{section.title}</span>
              {!section.disabled && (
                <div className={styles.caret}>
                  <CaretDown />
                </div>
              )}
            </button>
            <div className={styles.content}>{section.children}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Accordion
