import api from './api'
import { getRequirement, Requirement } from './requirements'

const createUrl = (specificationId: string, contextId: string) =>
  `/api/v2/specifications/${specificationId}/documents/elements/contexts/${contextId}`

interface RequirementContext {
  elements: Record<
    string,
    {
      specification: {
        id: string
        revision: { id: string; version: string; createdOn: Date }
      }
    }
  >
}

const getRequirementContext = async (
  specificationId: string,
  contextId: string,
): Promise<RequirementContext> => {
  const context: {
    elements: Record<
      string,
      {
        specification: {
          id: string
          revision: { id: string; version: string; createdOn: Date }
        }
      }
    >
  } = await api.get(createUrl(specificationId, contextId))

  // Convert string dates to date objects
  const requirementVersions: Record<string, any> = {}
  for (const key in context.elements) {
    const requirementVersion = context.elements[key]
    requirementVersions[key] = {
      ...requirementVersion,
      specification: {
        ...requirementVersion.specification,
        revision: {
          ...requirementVersion.specification.revision,
          createdOn: new Date(
            requirementVersion.specification.revision.createdOn,
          ),
        },
      },
    }
  }

  return { elements: requirementVersions }
}

export const getLatestRequirement: (
  specificationId: string,
  contextId: string,
) => Promise<Requirement> = async (specificationId, contextId) => {
  const { elements } = await getRequirementContext(specificationId, contextId)
  const idToCreatedTime = Object.keys(elements).map((id) => ({
    id,
    createdTime: elements[id].specification.revision.createdOn,
  }))

  const [{ id: latestId }] = idToCreatedTime.sort(
    (a, b) => b.createdTime.getTime() - a.createdTime.getTime(),
  )
  return await getRequirement(specificationId, latestId)
}

export const getRequirementVersions = async (
  specificationId: string,
  contextId: string,
) => {
  const context = await getRequirementContext(specificationId, contextId)
  const requirementVersions = Object.entries(context.elements).map(
    ([key, value]) => ({ ...value, requirementId: key }),
  )

  return requirementVersions.map((version) => ({
    requirementId: version.requirementId,
    version: version.specification.revision.version,
    createdOn: version.specification.revision.createdOn,
  }))
}
