import { Link } from 'react-router-dom'
import styles from './LinkTabs.module.css'

export interface LinkTab {
  name: string
  path: string
}

const LinkTabs = (props: { tabs: LinkTab[] }) => {
  const { tabs } = props

  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <Link key={tab.path} to={tab.path}>
          <div
            className={`${styles.tab} ${
              tab.path === location.pathname ? styles.active : ''
            }`}
          >
            {tab.name}
          </div>
        </Link>
      ))}
    </div>
  )
}

export default LinkTabs
