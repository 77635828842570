import { TreeView } from '@carbon/icons-react'
import Modal from './index.tsx'
import styles from './SpecificationTreeBuilderModal.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes.ts'
import * as api from '../../api/v2/programs.ts'
import { SpecificationTreeNode } from '../../api/v2/programs.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import SpecificationTreeBuilder from '../../pages/dashboard/specification-tree/SpecificationTreeBuilder.tsx'

export interface SpecificationTreeBuilderModalProps {
  program: AttributeValueResponse
  treeData: api.SpecificationTreeNode | null
  onAddSpec: (
    createdNode: SpecificationTreeNode,
    parentNode?: SpecificationTreeNode | null,
  ) => void
  onDeleteSpec: (nodeId: string) => void
}

const SpecificationTreeBuilderModal = (
  props: SpecificationTreeBuilderModalProps & { closeModal: () => void },
) => {
  const { program, treeData, onAddSpec, onDeleteSpec, closeModal } = props
  const ref = useClickOutside(() => {
    closeModal()
  })

  return (
    <Modal
      title={`Build ${program.name} specifications tree`}
      icon={<TreeView />}
      onClose={() => {
        closeModal()
      }}
      innerRef={ref}
    >
      <div className={styles.builder}>
        <SpecificationTreeBuilder
          program={program}
          treeData={treeData}
          onAddSpec={onAddSpec}
          onDeleteSpec={onDeleteSpec}
        />
      </div>
    </Modal>
  )
}

export default SpecificationTreeBuilderModal
