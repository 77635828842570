import { ListChecked, Unlink } from '@carbon/icons-react'
import { Link } from 'react-router-dom'
import Modal from '.'
import styles from './LinkedEvidenceViewModal.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes'
import { Evidence } from '../../api/v2/evidence'
import { useAttributesContext } from '../../context/AttributesContext'
import useClickOutside from '../../hooks/useClickOutside'
import { monthDayYear } from '../../lib/date'
import { EvidenceCadence, EvidenceType } from '../../types/enums'
import Button, { BUTTON_COLORS } from '../button'
import Tag, { TAG_COLORS, stylesToTagColor } from '../tag'

export interface LinkedEvidenceViewModalProps {
  linkedEvidence: Evidence
  method: AttributeValueResponse | null
  program: AttributeValueResponse | null
  userCanEdit?: boolean
  unlinkEvidence?: () => void
}

const LinkedEvidenceViewModal = (
  props: LinkedEvidenceViewModalProps & { closeModal: () => void },
) => {
  const {
    linkedEvidence,
    method,
    program,
    userCanEdit,
    unlinkEvidence,
    closeModal,
  } = props
  const { getEvidenceCadenceById } = useAttributesContext()

  const modalRef = useClickOutside(() => closeModal())

  const unlink = async () => {
    if (typeof unlinkEvidence === 'function') {
      unlinkEvidence()
    }
    closeModal()
  }

  return (
    <Modal
      innerRef={modalRef}
      title={linkedEvidence.title || 'Untitled'}
      icon={<ListChecked />}
      onClose={closeModal}
    >
      <div className={styles.content}>
        <div className={styles.section}>
          <h3 className={styles.sectionHeading}>Type</h3>
          <Tag
            text={linkedEvidence.type}
            color={
              linkedEvidence.type === EvidenceType.Validation
                ? TAG_COLORS.blue3
                : TAG_COLORS.red2
            }
          />
        </div>

        <div className={styles.section}>
          <h3 className={styles.sectionHeading}>Method</h3>
          <Tag
            text={method?.name || 'Method'}
            color={stylesToTagColor(method?.metadata.STYLES)}
          />
        </div>

        <div className={styles.section}>
          <h3 className={styles.sectionHeading}>Program</h3>
          {program && (
            <Tag
              text={program?.name || 'Program'}
              color={stylesToTagColor(program?.metadata.STYLES)}
            />
          )}
        </div>

        {linkedEvidence.cadences.length > 0 && (
          <div className={styles.section}>
            <h3 className={styles.sectionHeading}>Cadence</h3>
            <div style={{ display: 'flex', gap: 8 }}>
              {linkedEvidence.cadences.map((cadenceId) => {
                const cadence = getEvidenceCadenceById(cadenceId)
                return (
                  <Tag
                    key={cadenceId}
                    text={cadence?.name || ''}
                    color={
                      cadence?.name === EvidenceCadence.Production
                        ? TAG_COLORS.purple2NoHover
                        : TAG_COLORS.green3NoHover
                    }
                  />
                )
              })}
            </div>
          </div>
        )}

        <div className={styles.section}>
          <h3>Description of activity</h3>
          <span>{linkedEvidence.description || 'No description'}</span>
        </div>

        <div className={styles.section}>
          <h3>Compliance statement</h3>
          <span>
            {linkedEvidence.complianceStatement || 'No compliance statement'}
          </span>
        </div>

        <div className={styles.section}>
          <h3>Date created</h3>
          <span>{monthDayYear(linkedEvidence.createdOn)}</span>
        </div>

        <div className={styles.actions}>
          <Link
            className={styles.link}
            to={`/evidence/records/${linkedEvidence.id}`}
            onClick={closeModal}
          >
            View record
          </Link>
          {userCanEdit && unlinkEvidence && (
            <Button
              color={BUTTON_COLORS.PRIMARY}
              text="Remove link"
              endIcon={<Unlink />}
              onClick={unlink}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default LinkedEvidenceViewModal
