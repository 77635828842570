import { useEffect, useRef } from 'react'
import styles from './NotificationsPage.module.css'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'
import { NOTIFICATIONS } from '../../components/notifications/NotificationRow.tsx'
import { useNotifications } from '../../context/NotificationsContext'

const NotificationsPage = () => {
  const { filteredNotifications, updateNotificationReadStatus } =
    useNotifications()
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (filteredNotifications) {
      timerRef.current = setTimeout(async () => {
        for (const notification of filteredNotifications) {
          if (!notification.read) {
            await updateNotificationReadStatus(notification.id, true)
          }
        }
      }, 1000)
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [filteredNotifications, updateNotificationReadStatus])

  return (
    <div className={styles.notificationsPage}>
      <h1>Notifications</h1>
      {filteredNotifications ? (
        filteredNotifications.length > 0 ? (
          <>
            {filteredNotifications.map((notification) => {
              const Component = NOTIFICATIONS[notification.type]
              return (
                <Component key={notification.id} notification={notification} />
              )
            })}
            <div className={styles.endNotifications}>
              End of notifications activity
            </div>
          </>
        ) : (
          <div className={styles.endNotifications}>No new notifications</div>
        )
      ) : (
        <div className={styles.center}>
          <LoadingIndicator />
        </div>
      )}
    </div>
  )
}

export default NotificationsPage
