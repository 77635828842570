import { OverflowMenuHorizontal, ScatterMatrix } from '@carbon/icons-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './SharedRequirementActions.module.css'
import { SharedSpecificationRequirement } from '../../api/v2/projects'
import Dropdown from '../../components/dropdown'
import useClickOutside from '../../hooks/useClickOutside'

const SharedRequirementActions = (props: {
  requirement: SharedSpecificationRequirement
}) => {
  const { requirement } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const ref = useClickOutside(() => setIsOpen(false), isOpen)

  return (
    <div ref={ref} className={styles.container}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <OverflowMenuHorizontal />
      </button>
      <Dropdown className={styles.dropdown} isOpen={isOpen}>
        <Link className={styles.link} to={`../matrix/${requirement.id}`}>
          <ScatterMatrix />
          View in compliance matrix
        </Link>
      </Dropdown>
    </div>
  )
}

export default SharedRequirementActions
