import { useCallback } from 'react'
import { useModals } from './useModals.ts'
import { CreateEvidenceComplianceReportRequest } from '../api/v2/evidence.ts'
import { RevisionStatus } from '../api/v2/revisions.ts'
import { CreateReportRequest } from '../api/v2/search.ts'
import { CreateSpecificationReportRequest } from '../api/v2/specifications.ts'
import { COLUMN_ID_TO_NAME } from '../pages/search/SearchTableColumns.tsx'
import { ReportColumnId } from '../types/enums.ts'

const DEFAULT_COLUMN_IDS_SELECTED = [
  ReportColumnId.RequirementIdentifier,
  ReportColumnId.RequirementName,
  ReportColumnId.RequirementShallStatement,
  ReportColumnId.RequirementCompliance,
  ReportColumnId.RequirementStatus,
  ReportColumnId.RequirementType,
  ReportColumnId.SpecificationName,
  ReportColumnId.EvidenceTypeValidation,
  ReportColumnId.EvidenceTypeVerification,
]

const DISABLED_COLUMN_IDS = [
  ReportColumnId.RequirementComplianceNotes,
  ReportColumnId.RequirementSectionNumber,
  ReportColumnId.ParentRequirementName,
  ReportColumnId.ParentRequirementShallStatement,
  ReportColumnId.ChildRequirementName,
  ReportColumnId.ChildRequirementShallStatement,
  ReportColumnId.ValidationRecordTitle,
  ReportColumnId.ValidationDescriptionOfActivity,
  ReportColumnId.VerificationRecordTitle,
  ReportColumnId.VerificationDescriptionOfActivity,
]

const ALLOWED_COLUMN_IDS = (
  Object.keys(COLUMN_ID_TO_NAME) as ReportColumnId[]
).filter((id) => !DISABLED_COLUMN_IDS.includes(id))

export const INHERITED_COLUMN_IDS = [
  ReportColumnId.ParentRequirementShallStatement,
  ReportColumnId.ChildRequirementShallStatement,
  ReportColumnId.ValidationRecordTitle,
  ReportColumnId.ValidationDescriptionOfActivity,
  ReportColumnId.VerificationRecordTitle,
  ReportColumnId.VerificationDescriptionOfActivity,
]

const useReports = () => {
  const { openGenerateReportModal } = useModals()

  const generateSearchReport = useCallback(
    (query) => {
      const req: CreateReportRequest = {
        name: '',
        query,
        metadata: { columnsToDisplay: [] },
      }

      // TODO - Update to use inherited and other fields when available
      openGenerateReportModal({
        req,
        columnIds: ALLOWED_COLUMN_IDS,
        defaultColumnIdsSelected: DEFAULT_COLUMN_IDS_SELECTED,
        inheritedColumnsIds: [],
      })
    },
    [openGenerateReportModal],
  )

  const generateSpecificationReport = useCallback(
    (specificationId: string, revisionId: string, status?: RevisionStatus) => {
      const req: CreateSpecificationReportRequest = {
        revisionId,
        name: '',
        metadata: { columnsToDisplay: [], specificationStatus: status },
      }

      openGenerateReportModal(undefined, {
        req,
        specificationId,
        columnIds: Object.keys(COLUMN_ID_TO_NAME) as ReportColumnId[],
        defaultColumnIdsSelected: DEFAULT_COLUMN_IDS_SELECTED,
        inheritedColumnsIds: INHERITED_COLUMN_IDS,
      })
    },
    [openGenerateReportModal],
  )

  const generateEvidenceComplianceReport = useCallback(
    (evidenceId: string) => {
      const req: CreateEvidenceComplianceReportRequest = {
        evidenceId,
        name: '',
        metadata: { columnsToDisplay: [] },
      }

      // TODO - Update to use inherited and other fields when available
      openGenerateReportModal(undefined, undefined, {
        req,
        evidenceId,
        columnIds: ALLOWED_COLUMN_IDS,
        defaultColumnIdsSelected: DEFAULT_COLUMN_IDS_SELECTED,
        inheritedColumnsIds: [],
      })
    },
    [openGenerateReportModal],
  )

  return {
    generateSearchReport,
    generateSpecificationReport,
    generateEvidenceComplianceReport,
  }
}

export { useReports }
