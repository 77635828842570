import api, { usingPublicTenant } from './api'
import { Block, BlockData } from './blocks.ts'

const createUrl: (specificationId: string, assetId?: string) => string = (
  specificationId,
  assetId,
) =>
  `/api/v2/specifications/${specificationId}/assets${
    assetId ? `/${assetId}` : ''
  }`

export interface Asset {
  id: string
  state: string
}

export const createAssetId: (specificationId: string) => Promise<Asset> = (
  specificationId,
) => api.post(createUrl(specificationId), { body: {} })

export const createImageAsset: (
  specificationId: string,
  assetId: string,
  imageBlob: Blob,
) => Promise<{
  id: string
  contentType: string
}> = async (specificationId, assetId, imageBlob) => {
  const formData = new FormData()
  formData.append('file', imageBlob)
  formData.append('content-type', imageBlob.type)

  return await api.putRaw(createUrl(specificationId, assetId), {
    body: formData,
  })
}

export const getSpecificationAsset = (
  specificationId: string,
  assetId: string,
) => api.getBlob(createUrl(specificationId, assetId))

export const publicTenantMethods = {
  getSpecificationAsset: async (
    specificationId: string,
    assetId: string,
  ): Promise<{ blocks: Array<Block<BlockData>> }> => {
    return await usingPublicTenant(api.getBlob)(
      createUrl(specificationId, assetId),
    )
  },
}

export const getSpecificationSnapshotAsset = (
  specificationId: string,
  snapshotId: string,
  assetId: string,
) =>
  api.getBlob(
    `/api/v2/specifications/${specificationId}/snapshots/${snapshotId}/assets/${assetId}`,
  )
