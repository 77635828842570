import { Link, useRouteError } from 'react-router-dom'
import styles from './SpecificationErrorBoundary.module.css'
import ErrorBoundary from '../../../components/error-boundary/ErrorPage'

const SpecificationErrorBoundary = () => {
  const error: any = useRouteError()

  return error.fetchResponse?.status === 404 ? (
    <div className={styles.error}>
      <span className={styles.label}>
        We weren't able to find this specification
      </span>
      <p className={styles.details}>
        It may have been deleted, or you may not have access to this
        specification
      </p>
      <Link className={styles.link} to="/specifications">
        Return to specifications
      </Link>
    </div>
  ) : (
    <ErrorBoundary />
  )
}
export default SpecificationErrorBoundary
