import { useCallback, useState } from 'react'
import { FilterKey, FilterSet } from './types'

const compare = (filter, value, valueType) => {
  return valueType === 'object' ? filter.id !== value.id : filter !== value
}

const useFilterState: (
  keys: Array<FilterKey>,
  defaultValues?: Record<string, any>,
) => [
  activeFilters: FilterSet,
  toggleFilter: (filterKey: FilterKey, value: any) => void,
] = (keys, defaultValues) => {
  const [activeFilters, setActiveFilters] = useState<FilterSet>(
    keys.reduce(
      (acc, val) => ({
        ...acc,
        [val]: defaultValues && defaultValues[val] ? defaultValues[val] : [],
      }),
      {} as FilterSet,
    ),
  )

  const toggleFilter = useCallback((filterKey, value) => {
    let newFilters
    setActiveFilters((fs) => {
      const valueType = typeof value

      const hasValue =
        valueType === 'object'
          ? fs[filterKey].some((f) => f.id === value.id)
          : fs[filterKey].includes(value)

      const update = hasValue
        ? fs[filterKey].filter((f) => compare(f, value, valueType))
        : [...fs[filterKey], value]
      newFilters = { ...fs, [filterKey]: update }
      return newFilters
    })
  }, [])

  return [activeFilters, toggleFilter]
}

export default useFilterState
