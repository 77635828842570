import { captureException } from '@sentry/react'
import posthog from 'posthog-js'
import { PostHogFeature, PostHogProvider } from 'posthog-js/react'
import { memo, ReactElement, ReactNode, useEffect, useMemo } from 'react'
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useMatches,
  useNavigate,
} from 'react-router-dom'
import { Flip, ToastContainer } from 'react-toastify'
import { getAllExceptions, getException } from './api/v2/exceptions.ts'
import { getEntityLinks, LinkType } from './api/v2/links.ts'
import * as projectApi from './api/v2/projects.ts'
import * as requirementApi from './api/v2/requirements.ts'
import * as revisionApi from './api/v2/revisions.ts'
import * as specificationApi from './api/v2/specifications.ts'
import { TenantTier } from './api/v2/tenants.ts'
import AnalyticsCapture from './components/analytics-capture/AnalyticsCapture.tsx'
import ErrorBoundary from './components/error-boundary/ErrorPage.tsx'
import LoadingIndicator from './components/loading-indicator/LoadingIndicator.tsx'
import ModalManager from './components/modal-manager'
import PrimaryLayout from './components/primary-layout/PrimaryLayout.tsx'
import UnauthenticatedLayout from './components/unauthentiated-layout/UnauthenticatedLayout.tsx'
import { AttributesContextProvider } from './context/AttributesContext.tsx'
import { AuthContextProvider, useAuth } from './context/AuthContext.tsx'
import {
  Breadcrumb,
  BreadcrumbsContextProvider,
} from './context/BreadcrumbsContext.tsx'
import { ChangeRequestListContextProvider } from './context/ChangeRequestListContext.tsx'
import { DevToolsProvider } from './context/DevToolsContext.tsx'
import { DocumentContextProvider } from './context/DocumentContext.tsx'
import { DragDropContextProvider } from './context/DragAndDropContext.tsx'
import { DragSelectContextProvider } from './context/DragSelectContext.tsx'
import { EvidenceActivitiesContextProvider } from './context/EvidenceActivitiesContext.tsx'
import { EvidencesContextProvider } from './context/EvidencesContext.tsx'
import { ModalContext } from './context/GlobalModalContext.tsx'
import { MatrixContextProvider } from './context/MatrixContext.tsx'
import { NotificationsContextProvider } from './context/NotificationsContext.tsx'
import { SharedSpecificationContextProvider } from './context/SharedSpecificationContext.tsx'
import { SpecificationContextProvider } from './context/SpecificationContext.tsx'
import { SpecificationListContextProvider } from './context/SpecificationListContext.tsx'
import EnvConfig from './EnvConfig.ts'
import useSessionTimeout from './hooks/useSessionTimeout.ts'
import { displayDevTools } from './lib/dev-tools.ts'
import { isValidJwtToken } from './lib/jwt.ts'
import { getAuthToken } from './lib/localstorage.ts'
import { setNavigate } from './lib/navigation.ts'
import ChangeRequestsPage from './pages/change-requests/ChangeRequestsPage.tsx'
import CreateEvidencePage from './pages/create-evidence/CreateEvidencePage.tsx'
import DashboardPage from './pages/dashboard/DashboardPage.tsx'
import DevToolsPage from './pages/dev/DevToolsPage.tsx'
import EvidenceActivityList from './pages/evidences/EvidenceActivityList.tsx'
import EvidenceRecordList from './pages/evidences/EvidenceRecordList.tsx'
import EvidencesPage from './pages/evidences/EvidencesPage.tsx'
import ExceptionPage from './pages/exceptions/ExceptionPage.tsx'
import ExceptionsList from './pages/exceptions/ExceptionsList.tsx'
import ForgotPasswordPage from './pages/forgot-password/ForgotPasswordPage.tsx'
import LoginPage from './pages/login/LoginPage.tsx'
import NotificationsPage from './pages/notifications/NotificationsPage.tsx'
import PublicSpecificationPage from './pages/public-specification/PublicSpecificationPage.tsx'
import ReportPage from './pages/report/ReportPage.tsx'
import ReportsPage from './pages/reports'
import ResetPasswordPage from './pages/reset-password/ResetPasswordPage.tsx'
import SearchPage from './pages/search'
import SettingsPage from './pages/settings'
import SharedProjectPage from './pages/shared-project/SharedProjectPage.tsx'
import SharedProjectsPage from './pages/shared-projects/SharedProjectsPage.tsx'
import SharedDocumentView from './pages/shared-specification/SharedDocumentView.tsx'
import SharedRequirementsView from './pages/shared-specification/SharedRequirementsView.tsx'
import SharedSpecificationPage from './pages/shared-specification/SharedSpecificationPage.tsx'
import SpecificationPage from './pages/specification'
import DocumentView from './pages/specification/DocumentView.tsx'
import SpecificationErrorBoundary from './pages/specification/errors/SpecificationErrorBoundary.tsx'
import SpecificationExportPage from './pages/specification/export/SpecificationExportPage.tsx'
import Matrix from './pages/specification/Matrix.tsx'
import RequirementDetailEvidence from './pages/specification/requirement-detail/RequirementDetailEvidence.tsx'
import RequirementDetailExceptions from './pages/specification/requirement-detail/RequirementDetailExceptions.tsx'
import RequirementDetailHistory from './pages/specification/requirement-detail/RequirementDetailHistory.tsx'
import RequirementDetailPage from './pages/specification/requirement-detail/RequirementDetailPage.tsx'
import SpecificationControlsPage from './pages/specification-controls/SpecificationControlsPage.tsx'
import SpecificationHistoryPage from './pages/specification-controls/SpecificationHistoryPage.tsx'
import SpecificationSettingsPage from './pages/specification-controls/SpecificationSettingsPage.tsx'
import SpecificationsPage from './pages/specifications/SpecificationsPage.tsx'
import { UnauthorizedPage } from './pages/unauthorized/UnauthorizedPage.tsx'
import UserGuidePage from './pages/user-guide'
import { LoadingState } from './types/enums.ts'

posthog.init(EnvConfig.posthogKey, {
  api_host: EnvConfig.posthogHost,
  autocapture: false,
})

interface RouteHandle {
  crumbs?: () => Breadcrumb[]
  dragSelectEnabled?: boolean
  isDynamicCrumb?: boolean
  allowedTiers?: TenantTier[]
  IsUnauthorizedChildren?: ReactNode
}

const ProtectedRouteWrapper = (props) => {
  const { isSignedIn, signOut, userTenant, userTenantLoading } = useAuth()

  const matches = useMatches() as { handle: RouteHandle }[]

  useEffect(() => {
    if (!isSignedIn) {
      signOut()
    }
  }, [isSignedIn, signOut])

  const allowedTiers = useMemo(
    () => matches.map((match) => match?.handle?.allowedTiers || []).flat(),
    [matches],
  )

  const IsUnauthorizedChildren = useMemo(
    () =>
      matches.find((match) => !!match?.handle?.IsUnauthorizedChildren)?.handle
        ?.IsUnauthorizedChildren,
    [matches],
  ) as ReactElement

  return (
    <ProtectedRoute
      isSignedIn={isSignedIn}
      userTenantTier={userTenant?.tier}
      userTenantTierLoading={userTenantLoading}
      allowedTiers={allowedTiers}
      IsUnauthorizedChildren={IsUnauthorizedChildren}
    >
      {props.children}
    </ProtectedRoute>
  )
}

const ProtectedRoute = memo(
  (props: {
    isSignedIn: boolean
    userTenantTier: TenantTier | undefined
    userTenantTierLoading: LoadingState
    allowedTiers?: TenantTier[]
    IsUnauthorizedChildren?: ReactElement
    children?: ReactNode
  }) => {
    const {
      isSignedIn,
      userTenantTier,
      userTenantTierLoading,
      allowedTiers,
      IsUnauthorizedChildren,
      children,
    } = props
    const isAuthorizedTenant =
      !allowedTiers ||
      allowedTiers.length === 0 ||
      (!!userTenantTier && allowedTiers?.includes(userTenantTier))

    useSessionTimeout()

    const navigate = useNavigate()
    useEffect(() => {
      setNavigate(navigate)
    }, [navigate])

    if (isSignedIn && isAuthorizedTenant) {
      return children
    } else if (userTenantTierLoading === LoadingState.Loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <LoadingIndicator />
        </div>
      )
    } else if (userTenantTierLoading === LoadingState.Failed) {
      return <ErrorBoundary />
    } else {
      return IsUnauthorizedChildren ? (
        IsUnauthorizedChildren
      ) : (
        <Navigate to="/unauthorized" replace />
      )
    }
  },
)

export const UnprotectedRoute = ({ children }) => {
  const { signOut } = useAuth()

  useEffect(() => {
    if (!isValidJwtToken(getAuthToken())) {
      signOut(false)
    }
  }, [signOut])

  return children
}

const router = createBrowserRouter([
  // unauthenticated routes
  {
    element: (
      <AuthContextProvider>
        <DevToolsProvider>
          <PostHogProvider client={posthog}>
            <AnalyticsCapture />
            <ModalContext>
              <ToastContainer
                position="bottom-center"
                autoClose={4000}
                transition={Flip}
                closeButton={false}
                hideProgressBar
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
              />
              <ModalManager />
              <UnprotectedRoute>
                <UnauthenticatedLayout />
              </UnprotectedRoute>
            </ModalContext>
          </PostHogProvider>
        </DevToolsProvider>
      </AuthContextProvider>
    ),
    children: [
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: '/reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: '/dev',
        element: displayDevTools() ? (
          <DevToolsPage />
        ) : (
          <Navigate replace to="/" />
        ),
        handle: {
          crumbs: () => [
            {
              text: 'Home',
              linkTo: '/',
            },
            {
              text: 'Example Crumb',
              linkTo: '/test',
            },
          ],
        },
      },
    ],
  },
  // Use this Layout Route to provide layout (opt in, always) and global context (for now)
  {
    element: (
      <AuthContextProvider>
        <DevToolsProvider>
          <PostHogProvider client={posthog}>
            <NotificationsContextProvider>
              <AttributesContextProvider>
                <AnalyticsCapture />
                <ModalContext>
                  <DragDropContextProvider>
                    <DragSelectContextProvider>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={4000}
                        transition={Flip}
                        closeButton={false}
                        hideProgressBar
                        newestOnTop={false}
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable={false}
                        pauseOnHover
                      />
                      <ModalManager />
                      <BreadcrumbsContextProvider>
                        <ProtectedRouteWrapper>
                          <PrimaryLayout />
                        </ProtectedRouteWrapper>
                      </BreadcrumbsContextProvider>
                    </DragSelectContextProvider>
                  </DragDropContextProvider>
                </ModalContext>
              </AttributesContextProvider>
            </NotificationsContextProvider>
          </PostHogProvider>
        </DevToolsProvider>
      </AuthContextProvider>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <Navigate to="/specifications" replace />,
        handle: {
          allowedTiers: [TenantTier.Full],
          IsUnauthorizedChildren: <Navigate to="/projects" replace />,
          crumbs: () => {},
        },
      },
      {
        path: '/unauthorized',
        element: <UnauthorizedPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/settings',
        handle: {
          allowedTiers: [TenantTier.Full, TenantTier.Basic],
          crumbs: () => [],
        },
        element: <SettingsPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/search',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: <SearchPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/guide',
        handle: {
          allowedTiers: [TenantTier.Full, TenantTier.Basic],
          crumbs: () => [],
        },
        element: <UserGuidePage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/reports',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: <ReportsPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/reports/:id',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: <ReportPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/dashboard',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: (
          <SpecificationListContextProvider>
            <EvidenceActivitiesContextProvider>
              <DashboardPage />
            </EvidenceActivitiesContextProvider>
          </SpecificationListContextProvider>
        ),
      },
      {
        path: '/specifications',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: (
          <SpecificationListContextProvider>
            <SpecificationsPage />
          </SpecificationListContextProvider>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/specifications/:id/revisions?/:revisionId?',
        loader: async ({ params: { id, revisionId } }) => {
          const specification = await specificationApi.getSpecification(id!)
          const [revision] = revisionId
            ? [await revisionApi.getRevision(id!, revisionId)]
            : await revisionApi.getRevisions(id!)
          const isHistoricalRevision = !!revisionId
          const matrixConfig = (
            await specificationApi.getMatrixViewUIConfig(id!)
          ).matrixViewColumnConfigurations.filter(
            (col) => (col.columnId as string) !== '',
          )

          return {
            specification,
            revision,
            isHistoricalRevision,
            matrixConfig,
          }
        },
        handle: {
          isDynamicCrumb: true,
        },
        element: (
          <SpecificationContextProvider>
            <SpecificationPage />
          </SpecificationContextProvider>
        ),
        errorElement: <SpecificationErrorBoundary />,
        children: [
          { path: '', element: <Navigate to="document" replace /> },
          {
            path: 'export',
            element: (
              <DocumentContextProvider>
                <SpecificationExportPage />
              </DocumentContextProvider>
            ),
          },
          {
            path: 'document/:requirementId?',
            handle: {
              dragSelectEnabled: true,
            },
            element: (
              <DocumentContextProvider>
                <DocumentView />
              </DocumentContextProvider>
            ),
          },
          {
            path: 'matrix/:requirementId?',
            element: (
              <MatrixContextProvider>
                <Matrix />
              </MatrixContextProvider>
            ),
          },
        ],
      },
      {
        path: '/specifications/:id/requirements/:requirementId',
        loader: async ({ params: { id, requirementId } }) => {
          const specification = await specificationApi.getSpecification(id!)
          const requirement = await requirementApi.getRequirement(
            id!,
            requirementId!,
          )

          // See note below, but if we need to use SpecificationContext,
          // call revisionApi.getRevisions(id!) to get the revisions and
          // matrixConfig to pass to the context. Similar to above route.
          return {
            specification,
            requirement,
          }
        },
        handle: {
          isDynamicCrumb: true,
          crumbs: () => [],
        },
        element: (
          // Explicitly not using SpecificationContext as it is
          // expecting revision, isHistoricalRevision and matrixConfig.
          // If we need SpecificationContext will need to provide.
          <PostHogFeature
            flag="requirement-details-page"
            match
            fallback={<ErrorBoundary />}
          >
            <EvidencesContextProvider>
              <EvidenceActivitiesContextProvider>
                <RequirementDetailPage />
              </EvidenceActivitiesContextProvider>
            </EvidencesContextProvider>
          </PostHogFeature>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          { path: '', element: <Navigate to="history" replace /> },

          {
            path: 'history',
            element: <RequirementDetailHistory />,
          },
          {
            path: 'exceptions',
            element: <RequirementDetailExceptions />,
          },
          {
            path: 'evidence',
            element: <RequirementDetailEvidence />,
          },
        ],
      },
      {
        path: '/specifications/:id/settings',
        loader: ({ params }) => specificationApi.getSpecification(params.id!),
        handle: {
          crumbs: (spec: specificationApi.Specification) => [
            { text: 'Specifications', linkTo: '/specifications' },
            { text: spec?.name, linkTo: `/specifications/${spec?.id}` },
            {
              text: 'Settings and History',
              linkTo: `/specifications/${spec?.id}/settings`,
            },
          ],
          allowedTiers: [TenantTier.Full],
        },
        element: <SpecificationControlsPage />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />,
          },
          {
            path: 'settings',
            element: <SpecificationSettingsPage />,
          },
          {
            path: 'history',
            element: <SpecificationHistoryPage />,
          },
        ],
      },
      {
        path: '/change-requests',
        handle: { crumbs: () => [], allowedTiers: [TenantTier.Full] },
        element: (
          <ChangeRequestListContextProvider>
            <ChangeRequestsPage />
          </ChangeRequestListContextProvider>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/evidence',
        handle: { crumbs: () => [], allowedTiers: [TenantTier.Full] },
        element: (
          <EvidencesContextProvider>
            <EvidenceActivitiesContextProvider>
              <EvidencesPage />
            </EvidenceActivitiesContextProvider>
          </EvidencesContextProvider>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          { path: '', element: <Navigate to="records" replace /> },
          {
            path: 'records/:evidenceId?',
            element: <EvidenceRecordList />,
          },
          {
            path: 'activities/:activityId?',
            element: <EvidenceActivityList />,
          },
        ],
      },
      {
        path: '/evidence/create',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: <CreateEvidencePage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/evidence/:evidenceId',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: <CreateEvidencePage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/evidence/:evidenceId/activities/:activityId',
        handle: {
          allowedTiers: [TenantTier.Full],
          crumbs: () => [],
        },
        element: <CreateEvidencePage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/projects',
        handle: {
          crumbs: () => [{ text: 'Shared Projects', linkTo: '/projects' }],
          allowedTiers: [TenantTier.Full, TenantTier.Basic],
        },
        loader: async () => {
          try {
            const projects = await projectApi.getProjects()
            return projects
          } catch (error) {
            console.error('Unable to load shared projects', error)
            captureException(error)
          }
        },
        element: (
          <PostHogFeature
            flag="show-sharing-portal"
            match
            fallback={<ErrorBoundary />}
          >
            <SharedProjectsPage />
          </PostHogFeature>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/projects/:projectId',
        handle: {
          crumbs: (project: projectApi.Project) => [
            { text: 'Shared Projects', linkTo: '/projects' },
            {
              text: project.name,
              linkTo: `/projects/${project.id}`,
            },
          ],
          allowedTiers: [TenantTier.Full, TenantTier.Basic],
        },
        loader: ({ params: { projectId } }) =>
          projectApi.getProject(projectId!),
        element: (
          <PostHogFeature
            flag="show-sharing-portal"
            match
            fallback={<ErrorBoundary />}
          >
            <SharedProjectPage />
          </PostHogFeature>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/projects/:projectId/snapshots/:snapshotId',
        handle: {
          crumbs: (
            snapshot: projectApi.SpecificationSnapshot,
            params: {
              projectId: string
              snapshotId: string
            },
          ) => [
            { text: 'Shared Projects', linkTo: '/projects' },
            {
              text: snapshot.project.name,
              linkTo: `/projects/${params.projectId}`,
            },
            {
              text: snapshot.specificationName,
              linkTo: `/projects/${params.projectId}/snapshots/${snapshot.id}`,
            },
          ],
          allowedTiers: [TenantTier.Full, TenantTier.Basic],
        },
        element: (
          <PostHogFeature
            flag="show-sharing-portal"
            match
            fallback={<ErrorBoundary />}
          >
            <SharedSpecificationContextProvider>
              <SharedSpecificationPage />
            </SharedSpecificationContextProvider>
          </PostHogFeature>
        ),
        loader: ({ params: { projectId, snapshotId } }) =>
          projectApi.getSpecificationSnapshot(projectId!, snapshotId!),
        errorElement: <ErrorBoundary />,
        children: [
          { path: '', element: <Navigate to="document" replace /> },
          {
            path: 'document/:requirementId?',
            element: <SharedDocumentView />,
          },
          {
            path: 'matrix/:requirementId?',
            element: <SharedRequirementsView />,
          },
        ],
      },
      {
        path: '/public-specifications',
        element: (
          <SpecificationListContextProvider publicTenant>
            <SpecificationsPage />
          </SpecificationListContextProvider>
        ),
        errorElement: <ErrorBoundary />,
        handle: {
          allowedTiers: [TenantTier.Full, TenantTier.Basic],
        },
      },
      {
        path: '/public-specifications/:id',
        loader: async ({ params: { id } }) => {
          const specification =
            await specificationApi.publicTenantMethods.getSpecification(id!)
          const [revision] = await revisionApi.publicTenantMethods.getRevisions(
            id!,
          )
          const matrixConfig = (
            await specificationApi.publicTenantMethods.getMatrixViewUIConfig(
              id!,
            )
          ).matrixViewColumnConfigurations.filter(
            (col) => (col.columnId as string) !== '',
          )

          return {
            specification,
            revision,
            matrixConfig,
          }
        },
        element: (
          <SpecificationContextProvider publicTenant>
            <PublicSpecificationPage />
          </SpecificationContextProvider>
        ),
        errorElement: <SpecificationErrorBoundary />,
        handle: {
          allowedTiers: [TenantTier.Full],
          isDynamicCrumb: true,
        },
        children: [
          { path: '', element: <Navigate to="document" replace /> },
          {
            path: 'document/:requirementId?',
            element: (
              <DocumentContextProvider>
                <DocumentView />
              </DocumentContextProvider>
            ),
            handle: {
              dragSelectEnabled: true,
            },
          },
          {
            path: 'matrix/:requirementId?',
            element: (
              <MatrixContextProvider>
                <Matrix />
              </MatrixContextProvider>
            ),
          },
        ],
      },
      {
        path: '/notifications',
        handle: {
          crumbs: () => [],
          allowedTiers: [TenantTier.Full, TenantTier.Basic],
        },
        element: <NotificationsPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/exceptions',
        handle: {
          crumbs: () => [],
          allowedTiers: [TenantTier.Full],
        },
        loader: async () => {
          const allExceptions = await getAllExceptions()
          return { allExceptions }
        },
        element: <ExceptionsList />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/exceptions/:exceptionId',
        handle: {
          crumbs: () => [],
          allowedTiers: [TenantTier.Full],
        },
        loader: async ({ params: { exceptionId } }) => {
          if (exceptionId === 'create') {
            return { loadedException: null, linkedRequirements: [] }
          }
          const [loadedException, linkedRequirements] = await Promise.all([
            getException(exceptionId!),
            getEntityLinks({
              entityId: [exceptionId!],
              link: [LinkType.Exception],
            }),
          ])

          const requirementExceptionLinks = await Promise.all(
            linkedRequirements.map(async (link) => {
              const baseLink = {
                linkId: link.id,
                exceptionId: loadedException.id,
                specificationId: link.data.specificationId,
                documentId: link.documentId,
                requirementId: link.documentBlockId,
                title: 'Unavailable',
                specificationTitle: 'Unavailable',
                text: 'Data unavailable',
                isSpecificationDeleted: false,
                isRequirementDeleted: false,
                error: null,
              }

              try {
                const specification = await specificationApi.getSpecification(
                  link.data.specificationId,
                )
                baseLink.specificationTitle = specification.name

                try {
                  const requirement = await requirementApi.getRequirement(
                    link.data.specificationId,
                    link.documentBlockId,
                  )
                  return {
                    ...baseLink,
                    title: requirement.title,
                    text: requirement.shallStatement,
                  }
                } catch (requirementError) {
                  console.error('Error fetching requirement:', requirementError)
                  captureException(requirementError)
                  return {
                    ...baseLink,
                    title: 'Deleted Requirement',
                    text: 'Requirement data unavailable',
                    isRequirementDeleted: true,
                    error:
                      requirementError instanceof Error
                        ? requirementError.message
                        : 'Unknown error',
                  }
                }
              } catch (specificationError) {
                console.error(
                  'Error fetching specification:',
                  specificationError,
                )
                captureException(specificationError)
                return {
                  ...baseLink,
                  specificationTitle: 'Specification Deleted',
                  isSpecificationDeleted: true,
                  error:
                    specificationError instanceof Error
                      ? specificationError.message
                      : 'Unknown error',
                }
              }
            }),
          )

          return {
            loadedException,
            linkedRequirements: requirementExceptionLinks,
          }
        },
        element: <ExceptionPage />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
])

const App = () => <RouterProvider router={router} />

export default App
