import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import EnvConfig from './EnvConfig'

Sentry.init({
  dsn: EnvConfig.sentryDSN,
  integrations: [Sentry.browserTracingIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api-dev\.stell-testing\.com/,
    /^https:\/\/api\.stell-engineering\.com/,
  ],
  environment: EnvConfig.env,
})

const root = createRoot(document.getElementById('app') as Element)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
