interface ValidatorOptions {
  maxLength?: number
  removeHtml?: boolean
  encodeSpecialChars?: boolean
}

/**
 * Encodes special characters to prevent cross site scripting (XSS)
 * @param text - The text to encode
 * @returns The encoded text
 */
function encodeInput(text: string): string {
  const specialChars: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
  }
  return text.replace(/[&<>"']/g, (char) => specialChars[char] || char)
}

/**
 * Sanitizes and validates input based on specified options
 * @param input - The input  to sanitize and validate
 * @param options - Configuration options for validation
 * @returns The sanitized input, returns null if input cannot be sanitized
 */

export const sanitizeAndValidateInput = (
  input: string,
  options: ValidatorOptions = {},
): string | null => {
  const {
    maxLength = 3000,
    removeHtml = true,
    encodeSpecialChars = true,
  } = options

  const blockList = ['script', 'eval', 'onload', 'onclick', 'onerror']

  let sanitized = input.trim()

  if (removeHtml) {
    sanitized = sanitized.replace(/<[^>]*>/g, '')
  }

  if (encodeSpecialChars) {
    sanitized = encodeInput(sanitized)
  }

  if (sanitized.length > maxLength) {
    return null
  }

  if (blockList.some((word) => sanitized.toLowerCase().includes(word))) {
    return null
  }

  return sanitized
}
