import { OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './ChangeRequestCard.module.css'
import Avatar from '../../../../components/avatar'
import { AvatarSize } from '../../../../components/avatar/constants.ts'
import ChangeRequestStatusSelect from '../../../../components/change-requests/ChangeRequestStatusSelect.tsx'
import { useAuth } from '../../../../context/AuthContext.tsx'
import useClickOutside from '../../../../hooks/useClickOutside.ts'
import { formatTime, monthLongDayYear } from '../../../../lib/date.ts'
import { RequirementChangeRequest } from '../../../../types/api/v2/requirements.ts'
import { ChangeRequestStatus } from '../../../../types/enums.ts'

const ChangeRequestCard = (props: {
  changeRequest: RequirementChangeRequest
  requirementVersion?: string
  onDelete?: () => void
  onStatusChange?: (status: ChangeRequestStatus) => void | Promise<void>
}) => {
  const { changeRequest, requirementVersion, onDelete, onStatusChange } = props
  const { userDetails } = useAuth()
  const userId = userDetails?.id

  const [actionsOpen, setActionsOpen] = useState(false)
  const ref = useClickOutside(() => setActionsOpen(false), actionsOpen)

  return (
    <div
      key={changeRequest.id}
      className={`${styles.changeRequestCard} ${styles.basicElevation}`}
    >
      <div className={styles.indicator}>
        <div className={styles.orb}></div>
      </div>
      <div className={styles.header}>
        <div>
          <span className={styles.headerText}>Change request</span>
          <Link to="/change-requests" className={styles.link}>
            <span>{changeRequest.displayId}</span>
          </Link>
        </div>
        <div>
          <ChangeRequestStatusSelect
            value={changeRequest.status}
            onSelect={onStatusChange}
          />
        </div>
      </div>
      <div className={styles.content}>
        <div>
          <span className={`${styles.blockLabel} ${styles.was}`}>WAS</span>
        </div>
        <div className={styles.wasText}>{changeRequest.current}</div>
        <div>
          <span className={`${styles.blockLabel} ${styles.is}`}>IS</span>
        </div>
        <div className={styles.isText}>{changeRequest.proposed}</div>
      </div>
      {userId === changeRequest.createdBy.id && onDelete && (
        <div ref={ref} className={styles.actionMenu}>
          <button onClick={() => setActionsOpen(!actionsOpen)}>
            <OverflowMenuVertical
              size={20}
              color="var(--text-color-gray-light)"
            />
          </button>
          <div
            className={`${styles.dropdown} ${styles.basicElevation} ${
              actionsOpen ? styles.open : ''
            }`}
          >
            <button onClick={onDelete}>
              <TrashCan size={16} /> Delete
            </button>
          </div>
        </div>
      )}
      <div className={styles.authorAvatar}>
        <Avatar
          firstName={changeRequest.createdBy.firstName}
          lastName={changeRequest.createdBy.lastName}
          size={AvatarSize.Medium}
        />
      </div>
      <div className={styles.memo}>{changeRequest.memo}</div>
      <div className={styles.author}>
        {requirementVersion && (
          <span className={styles.version}>Version {requirementVersion}</span>
        )}
        <span
          className={styles.authorName}
        >{`${changeRequest.createdBy.firstName} ${changeRequest.createdBy.lastName}`}</span>
        <div className={styles.createDate}>
          <span>{monthLongDayYear(changeRequest.createdOn)}</span>
          <span className={styles.time}>
            {formatTime(changeRequest.createdOn)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ChangeRequestCard
