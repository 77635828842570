export enum EntityType {
  ASSET = 'ASSET',
  SPECIFICATION = 'SPECIFICATION',
  SPECIFICATION_REVISION = 'SPECIFICATION_REVISION',
  REQUIREMENT = 'REQUIREMENT',
}

export enum EntityReviewStatus {
  APPROVED = 'APPROVED',
  DISMISSED = 'DISMISSED',
  PENDING = 'PENDING',
}

export enum EntityReviewEventOperation {
  APPROVE = 'APPROVE',
  DISMISS = 'DISMISS',
}

export enum EntityReviewReviewerStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum EntityReviewReviewerEventOperation {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}
