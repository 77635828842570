import { Dispatch, SetStateAction } from 'react'
import { useOutletContext } from 'react-router-dom'
import SpecificationPermissions from './SpecificationPermissions.tsx'
import SpecificationSettings from './SpecificationSettings.tsx'
import styles from './SpecificationSettingsPage.module.css'
import { Specification } from '../../api/v2/specifications.ts'
import Accordion from '../../components/accordion'
import { useAuth } from '../../context/AuthContext.tsx'

const SpecificationSettingsPage = () => {
  const [specification] = useOutletContext() as [
    Specification,
    Dispatch<SetStateAction<Specification>>,
  ]

  const { userIsOwner } = useAuth()
  const canChangeSettings = userIsOwner(specification.id)

  return (
    <div className={styles.specSettingsPage}>
      <Accordion
        expandedByDefault
        sections={[
          {
            title: 'Specification settings',
            children: canChangeSettings ? (
              <SpecificationSettings />
            ) : (
              <div>
                Only specification owners can control specification settings.
              </div>
            ),
            disabled: !canChangeSettings,
          },
          {
            title: 'Specification permissions',
            children: <SpecificationPermissions />,
          },
        ]}
      />
    </div>
  )
}

export default SpecificationSettingsPage
