import Tag, { type CustomColorSet } from '.'

const ComplianceTag = (props: {
  complianceName: string
  color: CustomColorSet
  onCancel?: () => void
}) => {
  const { complianceName, color, onCancel } = props

  return (
    <Tag
      text={complianceName.replace(/_/g, ' ')}
      color={color}
      onCancel={onCancel}
    />
  )
}

export default ComplianceTag
