// import { useState } from 'react'
import { decodeJwtClaims } from './jwt'
import { AttributeValueResponse } from '../api/v2/attributes'
import { FilterKey, FilterSet } from '../components/filter-menu/types'

export const KEY_AUTH_TOKEN = 'authToken'
export const KEY_REFRESH_TOKEN = 'refreshToken'

export const KEY_RECENTLY_USED_DASHBOARD_FILTER = 'recentlyUsedDashboardFilter'

export const KEY_DISMISSED_MODALS = 'dismissedModals'

export const KEY_DEV_TOOLS_DISABLE_MFA = 'disableMFA'

export const KEY_DEV_TOOLS_USE_DEV_API = 'useDevApi'

export const SPEC_FILTER_LOCAL_STORAGE_KEY = 'recentlyUsedSpecificationsFilters'

const getAuthToken = () => {
  const token = localStorage.getItem(KEY_AUTH_TOKEN)?.replace(/"/g, '')

  return token ?? null
}

const getRefreshToken = () => {
  const token = localStorage.getItem(KEY_REFRESH_TOKEN)
  return token?.replace(/"/g, '') || null
}

const getRefreshTokenExpiryTime = () => {
  const token = localStorage.getItem(KEY_REFRESH_TOKEN)
  const { exp } = (token && decodeJwtClaims(token)) || {}
  return exp ? new Date(exp * 1000) : undefined
}

const getAuthTokenExpiryTime = () => {
  const token = localStorage.getItem(KEY_AUTH_TOKEN)
  const { exp } = (token && decodeJwtClaims(token)) || {}
  return exp ? new Date(exp * 1000) : undefined
}

const setAuthTokens = (authToken: string, refreshToken: string) => {
  // Manually fire local storage events
  // localstorage event handlers only listen to other tabs but we want them to respond to local storage changes on same window too
  const authTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_AUTH_TOKEN,
    oldValue: getAuthToken(),
    newValue: authToken,
    url: window.location.href,
  })

  const refreshTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_REFRESH_TOKEN,
    oldValue: getRefreshToken(),
    newValue: refreshToken,
    url: window.location.href,
  })

  localStorage.setItem(KEY_AUTH_TOKEN, authToken)
  localStorage.setItem(KEY_REFRESH_TOKEN, refreshToken)
  window.dispatchEvent(authTokenEvent)
  window.dispatchEvent(refreshTokenEvent)
}

const clearAuthToken = () => {
  // Manually fire local storage events
  // localstorage event handlers only listen to other tabs but we want them to respond to local storage changes on same window too
  const authTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_AUTH_TOKEN,
    oldValue: getAuthToken(),
    newValue: undefined,
    url: window.location.href,
  })

  const refreshTokenEvent = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key: KEY_REFRESH_TOKEN,
    oldValue: getRefreshToken(),
    newValue: undefined,
    url: window.location.href,
  })

  localStorage.removeItem(KEY_AUTH_TOKEN)
  localStorage.removeItem(KEY_REFRESH_TOKEN)
  window.dispatchEvent(authTokenEvent)
  window.dispatchEvent(refreshTokenEvent)
}

const getRecentlyUsedDashboardFilter = (): AttributeValueResponse | null => {
  try {
    const value = localStorage.getItem(KEY_RECENTLY_USED_DASHBOARD_FILTER)
    if (!value) {
      return null
    }

    return JSON.parse(value)
  } catch {
    console.warn(
      'Failed to load default dashboard filter. Clearing from localstorage.',
    )
    try {
      localStorage.setItem(KEY_RECENTLY_USED_DASHBOARD_FILTER, '')
    } catch {
      console.warn('Failed to clear default dashboard filter.')
    }
  }

  return null
}

const getDismissedModals: () => Record<string, any[]> = () => {
  try {
    const value = localStorage.getItem(KEY_DISMISSED_MODALS)
    if (!value) {
      return {}
    }

    return JSON.parse(value)
  } catch {
    console.warn('Failed to load dismissed modals. Clearing from localstorage.')
    try {
      localStorage.setItem(KEY_DISMISSED_MODALS, JSON.stringify({}))
    } catch {
      console.warn('Failed to clear dismissed modals.')
    }
  }

  return {}
}

const setDismissedModals = (dismissedModals: Record<string, any[]>) => {
  try {
    if (!dismissedModals) {
      localStorage.setItem(KEY_DISMISSED_MODALS, JSON.stringify({}))
    } else {
      localStorage.setItem(
        KEY_DISMISSED_MODALS,
        JSON.stringify(dismissedModals),
      )
    }
  } catch {
    console.warn('Failed to save dismissed modals. Clearing from localstorage.')
    try {
      localStorage.setItem(KEY_DISMISSED_MODALS, JSON.stringify({}))
    } catch {
      console.warn('Failed to clear dismissed modals.')
    }
  }
}

const setRecentlyUsedDashboardFilter = (
  filter: AttributeValueResponse | null,
) => {
  try {
    if (!filter) {
      localStorage.setItem(KEY_RECENTLY_USED_DASHBOARD_FILTER, '')
    } else {
      localStorage.setItem(
        KEY_RECENTLY_USED_DASHBOARD_FILTER,
        JSON.stringify(filter),
      )
    }
  } catch {
    console.warn(
      'Failed to save default dashboard filter. Clearing from localstorage.',
    )
    try {
      localStorage.setItem(KEY_RECENTLY_USED_DASHBOARD_FILTER, '')
    } catch {
      console.warn('Failed to clear default dashboard filter.')
    }
  }
}

const getRecentlyUsedSpecificationsFilters: () => FilterSet = () => {
  const initialSpecFilters = {
    [FilterKey.SpecificationProgram]: [] as string[],
    [FilterKey.SpecificationCategory]: [] as string[],
  } as FilterSet

  const value = localStorage.getItem(SPEC_FILTER_LOCAL_STORAGE_KEY)

  if (value === null) {
    return initialSpecFilters
  }

  try {
    return JSON.parse(value)
  } catch (e) {
    console.error(
      `local storage corrupted for: ${SPEC_FILTER_LOCAL_STORAGE_KEY}`,
    )
    setRecentlyUsedSpecificationsFilter(initialSpecFilters)
    return initialSpecFilters
  }
}

const setRecentlyUsedSpecificationsFilter = (filters: FilterSet) => {
  try {
    localStorage.setItem(SPEC_FILTER_LOCAL_STORAGE_KEY, JSON.stringify(filters))
  } catch (e) {
    console.error(
      `error stringifying value for '${SPEC_FILTER_LOCAL_STORAGE_KEY}'`,
      e,
    )
  }
}

const getDevToolsDisableMFA = () => {
  return localStorage.getItem(KEY_DEV_TOOLS_DISABLE_MFA) === 'true'
}

const getDevToolsUseDevApi = () => {
  return localStorage.getItem(KEY_DEV_TOOLS_USE_DEV_API) === 'true'
}

export {
  getAuthToken,
  getDevToolsDisableMFA,
  getDevToolsUseDevApi,
  getRefreshToken,
  getRefreshTokenExpiryTime,
  getAuthTokenExpiryTime,
  setAuthTokens,
  clearAuthToken,
  getDismissedModals,
  setDismissedModals,
  getRecentlyUsedDashboardFilter,
  setRecentlyUsedDashboardFilter,
  getRecentlyUsedSpecificationsFilters,
  setRecentlyUsedSpecificationsFilter,
}
