import Tag, { TAG_COLORS } from './index.tsx'
import { ExceptionType } from '../../types/enums'

interface ExceptionTypeTagProps {
  type: ExceptionType
}

const ExceptionTypeTag = (props: ExceptionTypeTagProps) => {
  const { type } = props

  return (
    <Tag
      text={type.toUpperCase()}
      color={
        type === ExceptionType.Waiver
          ? TAG_COLORS.greenNoHover
          : type === ExceptionType.Deviation
            ? TAG_COLORS.purple3NoHover
            : TAG_COLORS.gray3NoHover
      }
    />
  )
}

export default ExceptionTypeTag
