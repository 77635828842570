import styles from './SharedSpecificationsTableColumns.module.css'
import { TableColumn } from '../../components/table/Table'
import Tag from '../../components/tag'
import { monthDayYearTime } from '../../lib/date'

const SpecificationName = ({ data }) => {
  return (
    <div className={`${styles.specificationName} ${styles.cell}`}>
      {data.specificationName || 'Untitled'}
    </div>
  )
}

const SpecificationVersion = ({ data }) => {
  return <div className={styles.cell}>{data.revisionVersion}</div>
}

const Program = ({ data }) => {
  const { specificationProgram } = data
  return (
    <div>
      {specificationProgram ? (
        <Tag
          text={specificationProgram.name}
          color={{
            fontColor: specificationProgram.metadata.STYLES.COLOR_FONT,
            backgroundColor: specificationProgram.metadata.STYLES.COLOR_BG,
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

const DateShared = ({ data }) => {
  return <div className={styles.cell}>{monthDayYearTime(data.createdOn)}</div>
}

const RequirementCount = ({ data }) => {
  return <div className={styles.cell}>{data.requirementCount}</div>
}

enum SharedSpecificationsColumnLabels {
  SpecificationName = 'Spec Name',
  SpecificationVersion = 'Spec Version',
  Program = 'Program',
  DateShared = 'Date Shared',
  RequirementCount = '# Requirements',
}

const SharedSpecificationsTableColumns: Array<TableColumn> = [
  {
    label: SharedSpecificationsColumnLabels.SpecificationName,
    Component: SpecificationName,
    width: 'minmax(auto, 340px)',
  },
  {
    label: SharedSpecificationsColumnLabels.SpecificationVersion,
    Component: SpecificationVersion,
  },
  {
    label: SharedSpecificationsColumnLabels.Program,
    Component: Program,
  },
  {
    label: SharedSpecificationsColumnLabels.DateShared,
    Component: DateShared,
  },
  {
    label: SharedSpecificationsColumnLabels.RequirementCount,
    Component: RequirementCount,
  },
]

export default SharedSpecificationsTableColumns
