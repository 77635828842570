import { captureException } from '@sentry/react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLoaderData } from 'react-router-dom'
import { useAuth } from './AuthContext'
import { UUID } from '../api/utilityTypes'
import * as projectApi from '../api/v2/projects'
import {
  BaseReview,
  getRequestedReviewers,
  getSnapshotReviews,
  ReviewStatus,
  SnapshotRequirementComment,
  SnapshotReview,
  updateSnapshotReviews,
} from '../api/v2/projects'
import { ProjectPermission, User } from '../api/v2/users'
import { toastError } from '../components/toast'
import { entries, fromEntries } from '../lib/utils'
import {
  AttributeName,
  AttributeValueStatus,
  EntityType,
  LoadingState,
} from '../types/enums'

interface SharedSpecificationCtx {
  specificationSnapshot: projectApi.SpecificationSnapshot
  getRequirementById: (
    requirementId: string,
  ) => projectApi.SharedSpecificationRequirement | null
  typeIdtoType: any
  methodIdtoMethod: any
  evidenceIdtoEvidence: any
  requirementTypes: projectApi.SharedSpecificationAttributeValue[]
  reviews: SnapshotReview[]
  requirementIdtoReviews: Record<UUID, SnapshotReviewWithReviewer[]>
  updateRequirementReview: (
    reviewId: UUID,
    requirementId: UUID,
    status: ReviewStatus,
  ) => Promise<void>
  reviewLoading: LoadingState
  userIsProjectAdmin: boolean
  userIsSnapshotReviewer: boolean
  userIsSnapshotViewer: boolean
  commentsByRequirementId: Record<
    string,
    projectApi.SnapshotRequirementComment[]
  >
  addRequirementComment: (
    requirementId: UUID,
    comment: string,
  ) => Promise<SnapshotRequirementComment | null>
  deleteRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => Promise<{ id: UUID } | null>
  toggleResolveRequirementComment: (
    requirementId: UUID,
    commentId: UUID,
  ) => void
  reloadReviews: () => void
}

export interface SnapshotReviewWithReviewer extends BaseReview {
  status: ReviewStatus
}

const SharedSpecificationContext = createContext<SharedSpecificationCtx>({
  specificationSnapshot: {
    id: '',
    requirementCount: 0,
    revisionVersion: 0,
    specificationId: '',
    specificationName: '',
    project: {
      id: '',
      name: '',
      metadata: {
        STYLES: {
          COLOR_FONT: '#161616',
          COLOR_BG: '#e0e0e0',
          COLOR_BG_HOVER: '#d1d1d1',
        },
      },
    },
    specificationProgram: {
      id: '',
      name: '',
      status: AttributeValueStatus.None,
      metadata: {
        STYLES: {
          COLOR_FONT: '#161616',
          COLOR_BG: '#e0e0e0',
          COLOR_BG_HOVER: '#d1d1d1',
        },
      },
    },
    createdOn: new Date(),
    contents: {
      id: '',
      revision: {
        id: '',
        version: 0,
      },
      specification: {
        id: '',
        name: '',
        identifier: '',
        externalOrigin: null,
      },
      evidence: [],
      customAttributes: [],
      documentBlocks: [],
      requirements: [],
      version: 0,
    },
  } as projectApi.SpecificationSnapshot,
  getRequirementById: () => null,
  typeIdtoType: [],
  methodIdtoMethod: [],
  evidenceIdtoEvidence: [],
  requirementTypes: [],
  reviews: [],
  requirementIdtoReviews: {},
  updateRequirementReview: () => Promise.resolve(),
  reviewLoading: LoadingState.Loading,
  userIsProjectAdmin: false,
  userIsSnapshotReviewer: false,
  userIsSnapshotViewer: false,
  commentsByRequirementId: {},
  addRequirementComment: () => Promise.resolve(null),
  deleteRequirementComment: () => Promise.resolve(null),
  toggleResolveRequirementComment: () => {},
  reloadReviews: () => null,
})

const SharedSpecificationContextProvider = (props) => {
  const specificationSnapshot =
    useLoaderData() as projectApi.SpecificationSnapshot
  const { userDetails, userPermissions, isAdmin } = useAuth()
  const projectId = specificationSnapshot.project.id
  const [reviewLoading, setReviewLoading] = useState<LoadingState>(
    LoadingState.Loading,
  )
  const [reviews, setReviews] = useState<SnapshotReview[]>([])
  const [reviewers, setReviewers] = useState<User[]>([])
  const [commentsByRequirementId, setCommentsByRequirementId] = useState<
    Record<string, projectApi.SnapshotRequirementComment[]>
  >({})

  const userIsProjectAdmin = useMemo(
    () =>
      isAdmin &&
      userPermissions?.projects[specificationSnapshot.project.id]?.role ===
        ProjectPermission.Owner,
    [isAdmin, userPermissions, specificationSnapshot.project.id],
  )

  const userIsSnapshotReviewer = useMemo(
    () => reviewers.some((reviewer) => reviewer.id === userDetails?.id),
    [reviewers, userDetails?.id],
  )

  const userIsSnapshotViewer = useMemo(
    () =>
      userPermissions?.projects?.[specificationSnapshot.id]?.role ===
      ProjectPermission.Viewer,
    [specificationSnapshot.id, userPermissions?.projects],
  )

  const requirementIdToRequirement =
    specificationSnapshot.contents.requirements.reduce((acc, val) => {
      return {
        ...acc,
        [val.id]: val,
      }
    }, {})

  const snapshotCustomAttributesEntries =
    specificationSnapshot.contents.customAttributes.map(({ id, ...rest }) => [
      id,
      rest,
    ]) as [
      string,
      {
        name: string
        entityTypes: EntityType[]
        values: projectApi.SharedSpecificationAttributeValue[]
      },
    ][]

  const typesEntries = snapshotCustomAttributesEntries
    .filter(([_id, ca]) => ca.name === 'TYPE')[0][1]
    .values.map(({ id, ...rest }): [string, typeof rest] => [id, rest])

  const typeIdtoType = fromEntries(typesEntries)

  const methodEntries = snapshotCustomAttributesEntries
    .filter(([_id, ca]) => ca.name === 'METHOD')[0][1]
    .values.map(({ id, ...rest }): [string, typeof rest] => [id, rest])

  const methodIdtoMethod = fromEntries(methodEntries)

  const snapshotEvidenceEntries = specificationSnapshot.contents.evidence.map(
    ({ id, ...rest }): [string, typeof rest] => [id, rest],
  )
  const evidenceIdtoEvidence = fromEntries(snapshotEvidenceEntries)

  const requirementTypes =
    specificationSnapshot.contents.customAttributes.filter(
      (attribute) => attribute.name === AttributeName.RequirementType,
    )[0].values

  const getRequirementById = useCallback(
    (requirementId: string) => requirementIdToRequirement[requirementId],
    [requirementIdToRequirement],
  )

  const loadReviews = useCallback(async () => {
    try {
      return await getSnapshotReviews(projectId, specificationSnapshot.id)
    } catch (error) {
      console.error('error loading reviews', error)
      captureException(error)
      toastError('Failed to load reviews', 'please try again later')
    }
  }, [projectId, specificationSnapshot.id])

  const loadReviewers = useCallback(async () => {
    try {
      return await getRequestedReviewers(projectId, specificationSnapshot.id)
    } catch (error) {
      console.error('error loading reviewers', error)
      captureException(error)
      toastError('Failed to load reviewers', 'please try again later')
    }
  }, [projectId, specificationSnapshot.id])

  const requirementIdtoReviews: Record<UUID, SnapshotReviewWithReviewer[]> =
    useMemo(() => {
      const result = reviews.reduce((acc, cur) => {
        entries(cur.requirements).reduce((reqAcc, [id, rev]) => {
          if (reqAcc[id] === undefined) {
            reqAcc[id] = []
          }
          reqAcc[id].push({
            id: cur.id,
            reviewer: cur.reviewer,
            status: rev.status,
          })
          return reqAcc
        }, acc)
        return acc
      }, {})
      return result
    }, [reviews])

  const updateRequirementReview = useCallback(
    async (reviewId: UUID, requirementId: UUID, status: ReviewStatus) => {
      try {
        await updateSnapshotReviews(
          projectId,
          specificationSnapshot.id,
          reviewId,
          requirementId,
          status,
        )
        setReviewLoading(LoadingState.Start)
      } catch (e) {
        console.error('error updating review', e)
        captureException(e)
        toastError('Failed to submit review', 'please try again later')
      }
    },
    [projectId, specificationSnapshot.id],
  )

  useEffect(() => {
    const loadComments = async () => {
      try {
        const comments = await projectApi.getSpecificationSnapshotComments(
          specificationSnapshot.project.id,
          specificationSnapshot.id,
        )
        setCommentsByRequirementId(comments)
      } catch (error) {
        console.error('Unable to load comments for requirement', error)
        captureException(error)
        toastError(
          'Unable to load comments for requirement',
          'please try again later',
        )
      }
    }

    loadComments()
  }, [specificationSnapshot.id, specificationSnapshot.project.id])

  useEffect(() => {
    const fetchReviewsAndReviewers = async () => {
      try {
        const { reviews: loadedReviews } = (await loadReviews()) ?? {
          reviews: [],
        }
        setReviews(loadedReviews)

        const { users: loadedReviewers } = (await loadReviewers()) ?? {
          users: [],
        }
        setReviewers(loadedReviewers)

        setReviewLoading(LoadingState.Loaded)
      } catch (e) {
        console.error('error loading reviews', e)
        captureException(e)
        setReviewLoading(LoadingState.Failed)
      }
    }
    fetchReviewsAndReviewers()
  }, [
    loadReviews,
    loadReviewers,
    projectId,
    reviewLoading,
    specificationSnapshot.id,
    specificationSnapshot.project.id,
  ])

  const addRequirementComment = useCallback(
    async (requirementId: UUID, comment: string) => {
      try {
        const commentResponse = await projectApi.addSnapshotRequirementComment(
          specificationSnapshot.project.id,
          specificationSnapshot.id,
          requirementId,
          comment,
        )

        setCommentsByRequirementId((prev) => {
          return {
            ...prev,
            [requirementId]: [...(prev[requirementId] || []), commentResponse],
          }
        })

        return commentResponse
      } catch (error) {
        console.error('Unable to add comment', error)
        captureException(error)
        return null
      }
    },
    [specificationSnapshot.id, specificationSnapshot.project.id],
  )

  const deleteRequirementComment = useCallback(
    async (requirementId: UUID, commentId: UUID) => {
      try {
        const deletedComment =
          await projectApi.deleteSnapshotRequirementComment(
            specificationSnapshot.project.id,
            specificationSnapshot.id,
            requirementId,
            commentId,
          )

        setCommentsByRequirementId((prev) => {
          const updatedComments = prev[requirementId].filter(
            (comment) => comment.id !== commentId,
          )
          return {
            ...prev,
            [requirementId]: updatedComments,
          }
        })
        return deletedComment
      } catch (error) {
        console.error('Unable to delete comment', error)
        captureException(error)
        return null
      }
    },
    [specificationSnapshot.id, specificationSnapshot.project.id],
  )

  const toggleResolveRequirementComment = useCallback(
    (requirementId: UUID, commentId: UUID) => {
      setCommentsByRequirementId((prev) => ({
        ...prev,
        [requirementId]: prev[requirementId].map((comment) =>
          comment.id === commentId
            ? { ...comment, resolved: !comment.resolved }
            : comment,
        ),
      }))
    },
    [],
  )

  const reloadReviews = useCallback(() => {
    setReviewLoading(LoadingState.Loading)
  }, [])

  return (
    <SharedSpecificationContext.Provider
      value={{
        specificationSnapshot,
        getRequirementById,
        typeIdtoType,
        methodIdtoMethod,
        evidenceIdtoEvidence,
        requirementTypes,
        reviews,
        requirementIdtoReviews,
        updateRequirementReview,
        reviewLoading,
        userIsProjectAdmin,
        userIsSnapshotReviewer,
        userIsSnapshotViewer,
        commentsByRequirementId,
        addRequirementComment,
        deleteRequirementComment,
        toggleResolveRequirementComment,
        reloadReviews,
      }}
    >
      {props.children}
    </SharedSpecificationContext.Provider>
  )
}

const useSharedSpecificationContext = () => {
  const ctx = useContext(SharedSpecificationContext)
  if (!ctx) {
    console.error('SharedSpecificationContext has no provider')
  }
  return ctx
}

export { SharedSpecificationContextProvider, useSharedSpecificationContext }
