import {
  CSSProperties,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import sanitize from 'sanitize-html'
import styles from './EditableSpan.module.css'
import debounce from '../../lib/debounce'
import { sanitizeAndValidateInput } from '../../lib/input-validation.ts'
import { toastError } from '../toast'

interface EditableSpanProps {
  value?: string
  placeholder?: string
  onValueChange?: (value: string) => void
  onFocus?: () => void
  focusOnLoad?: boolean
  allowNewline?: boolean
  onKeyDown?: KeyboardEventHandler<HTMLSpanElement>
  readOnly?: boolean
  maxLength?: number
  style?: CSSProperties
  className?: string
}

const clean = (content: string) =>
  sanitize(content, { allowedTags: [] })
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')

const nonAdditiveKeys = [
  'Backspace',
  'Delete',
  'Enter',
  'ArrowRight',
  'ArrowLeft',
  'ArrowUp',
  'ArrowDown',
  'Tab',
]

const EditableSpan = (props: EditableSpanProps) => {
  const {
    value = '',
    placeholder = 'Type here...',
    onValueChange = () => {},
    onFocus = () => {},
    focusOnLoad,
    allowNewline,
    onKeyDown,
    readOnly,
    maxLength = -1,
    style = {},
    className = '',
  } = props

  const el = useRef<HTMLSpanElement>(null)
  const initialValue = useRef(value)

  useEffect(() => {
    if (focusOnLoad && el.current) {
      if (value === '') {
        el.current.focus()
      } else {
        const range = document.createRange()
        range.selectNodeContents(el.current)
        range.collapse()
        const selection = window.getSelection()
        selection?.removeAllRanges()
        selection?.addRange(range)
      }
    }
  }, [focusOnLoad, value])

  const handleKeydown = useCallback(
    (e) => {
      if (
        maxLength >= 0 &&
        e.target.innerText.length >= maxLength &&
        !nonAdditiveKeys.includes(e.key)
      ) {
        e.preventDefault()
      }
      if (!allowNewline && e.key === 'Enter') {
        e.preventDefault()
        el.current?.blur?.()
      }
    },
    [allowNewline, maxLength],
  )

  const handleKeyup = useMemo(
    () => debounce(() => onValueChange(clean(el.current!.innerHTML))),
    [onValueChange],
  )
  // Strips HTML from pasted content and inserts as plaintext
  const handlePaste = useCallback(
    (e) => {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      const sanitizedText = sanitizeAndValidateInput(text, {
        maxLength: 3000,
        removeHtml: false,
        encodeSpecialChars: false,
      })
      if (sanitizedText) {
        const selection = document.getSelection()
        if (!selection) return
        const range = selection.getRangeAt(0)
        range.deleteContents()
        range.insertNode(
          new Text(allowNewline ? text : text.replace(/\n/g, ' ')),
        )
        range.collapse() // select nothing
        selection.removeAllRanges() // position caret after inserted text
        selection.addRange(range)
      } else {
        toastError('Sorry, that content cannot be pasted into Stell', '')
      }
    },
    [allowNewline],
  )

  return (
    <span
      ref={el}
      tabIndex={readOnly ? -1 : 0}
      role="textbox"
      className={`${styles.editable} ${className}`}
      style={style}
      contentEditable={`${!readOnly}`}
      onFocus={onFocus}
      onKeyUp={handleKeyup}
      onPaste={handlePaste}
      dangerouslySetInnerHTML={{ __html: clean(initialValue.current) }}
      data-placeholder={placeholder}
      onKeyDown={onKeyDown ? onKeyDown : handleKeydown}
    />
  )
}

export default EditableSpan
