import api, { usingPublicTenant } from './api'
import { getRequirementChangeRequests } from './changeRequests.ts'
import { getAllUsers, User } from './users.ts'
import { EMPTY_DELTA } from '../../lib/string.ts'
import {
  RequirementChangeRequest,
  RequirementCommentType,
  RequirementGraphViewResponse,
} from '../../types/api/v2/requirements'
import { RequirementStatus } from '../../types/enums.ts'
import { UUID } from '../utilityTypes'

const createUrl = (specificationId: string, requirementId?: string) =>
  `/api/v2/specifications/${specificationId}/requirements${
    requirementId ? `/${requirementId}` : ''
  }`

export const exportRequirements = async (
  specificationId: string,
): Promise<void> => {
  const exportResponse = await api.getBlob(
    `${createUrl(specificationId)}/export`,
  )
  const downloadUrl = window.URL.createObjectURL(exportResponse)
  const link = document.createElement('a')
  link.href = downloadUrl

  const dateTime = new Date()
    .toISOString()
    .replace(/[^0-9]/g, '')
    .slice(0, 14)
  link.setAttribute('download', `LinkedRequirementExport_${dateTime}.xlsx`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export enum ComplianceState {
  COMPLIANT = 'COMPLIANT',
  INTEND_TO_COMPLY = 'INTEND TO COMPLY',
  NOT_APPLICABLE = 'N/A',
  NOT_COMPLIANT = 'NOT COMPLIANT',
  PENDING = 'PENDING',
}

export type GetRequirementsWithCustomAttributes = {
  id: UUID
  complianceName: string
  programName
}

export type CreateRequirementResponse = {
  id: UUID
  createdBy: UUID
  createdOn: Date
}

export type RequirementData = {
  title: string
  shallStatement: string
  rationale: string
  sectionNumber: string
  externalIdentifier: string
  exportControlled: boolean
  compliance: ComplianceState | null
  complianceNotes: string | null
  types: string[]
  evidence: { validation: string[]; verification: string[] }
  data: {
    delta: {
      rationale: string
      shallStatement: string
    }
  }
}

export const EMPTY_REQUIREMENT = {
  title: '',
  shallStatement: '',
  rationale: '',
  sectionNumber: '',
  externalIdentifier: '',
  exportControlled: false,
  compliance: null,
  types: [],
  data: { delta: { rationale: EMPTY_DELTA, shallStatement: EMPTY_DELTA } },
}

export const createRequirement: (
  specificationId: string,
  sectionId: string,
  requirement: Partial<RequirementData>,
) => Promise<CreateRequirementResponse> = async (
  specificationId,
  sectionId,
  requirement,
) => {
  const res = await api.post(createUrl(specificationId), {
    body: {
      block: { sectionId },
      requirement: { ...EMPTY_REQUIREMENT, ...requirement },
    },
  })
  return { ...res, createdOn: new Date(res.createdOn) }
}

interface RequirementResponse {
  title: string
  shallStatement: string
  rationale: string
  sectionNumber: string
  externalIdentifier: string
  exportControlled: boolean
  compliance: ComplianceState | null
  complianceNotes: string
  types: string[]
  id: UUID
  revisionId: UUID
  documentId: UUID
  context: {
    id: UUID
    requirementIdentifier: number
    requirementVersionCount: number
  }
  status: RequirementStatus
  commentAndChangeRequestCount: number
  createdBy: UUID
  createdOn: string
  lastModifiedBy: UUID
  lastModifiedOn: string
  data: {
    delta: {
      shallStatement: string
      rationale: string
    }
  }
}

export interface Requirement
  extends Omit<RequirementResponse, 'createdOn' | 'lastModifiedOn'> {
  createdOn: Date
  lastModifiedOn: Date
}

const withDates: (req: RequirementResponse) => Requirement = (req) => ({
  ...req,
  createdOn: new Date(req.createdOn),
  lastModifiedOn: new Date(req.lastModifiedOn),
})

export const getRequirements: (
  specificationId: string,
  query?: {
    ids?: Array<string>
    revisionIds?: Array<string>
    specificationProgram?: string
  },
) => Promise<Requirement[]> = async (specificationId, query) => {
  const idQ = query?.ids?.map((id) => `id=${id}`).join('&') || ''
  const revisionIdQ =
    query?.revisionIds?.map((id) => `revisionId=${id}`).join('&') || ''
  const programQ = query?.specificationProgram
    ? `specificationProgram=${query.specificationProgram}&`
    : ''
  const queryString = [idQ, programQ, revisionIdQ].filter((q) => q).join('&')

  const { requirements } = await api.get(
    `${createUrl(specificationId)}${queryString ? `?${queryString}` : ''}`,
  )

  return requirements.map(withDates)
}

export const getRequirement: (
  specificationId: string,
  requirementId: string,
) => Promise<Requirement> = async (specificationId, requirementId) => {
  const res = await api.get(createUrl(specificationId, requirementId))
  return withDates(res)
}

export const updateRequirement: (
  specificationId: string,
  requirementId: string,
  update: Partial<RequirementData>,
) => Promise<Requirement> = async (specificationId, requirementId, update) =>
  withDates(
    await api.patch(createUrl(specificationId, requirementId), {
      body: update,
    }),
  )

export const deleteRequirement: (
  specificationId: string,
  requirementId: string,
) => Promise<{ id: string }> = (specificationId, requirementId) =>
  api.delete(createUrl(specificationId, requirementId))

/**
 * Requirement Graph View
 */

export const getRequirementGraphView: (
  specificationId: string,
  requirementId: string,
) => Promise<RequirementGraphViewResponse> = (specificationId, requirementId) =>
  api.get(`${createUrl(specificationId, requirementId)}/graphview`)

/**
 * REQUIREMENT COMMENTS
 */

interface RequirementCommentResponse {
  id: UUID
  requirementId: UUID
  body: string
  createdBy: UUID
  createdOn: string
  resolved: boolean
}

export interface RequirementComment
  extends Omit<RequirementCommentResponse, 'createdOn' | 'createdBy'> {
  createdOn: Date
  createdBy: User
}

export interface RequirementCommentWrapper {
  type: RequirementCommentType
  content: RequirementComment | RequirementChangeRequest
  createdOn: Date
  version?: string
}

export const createComment: (
  specificationId: string,
  requirementId: string,
  comment: string,
) => Promise<RequirementCommentResponse> = (
  specificationId,
  requirementId,
  comment,
) =>
  api.post(`${createUrl(specificationId, requirementId)}/comments`, {
    body: { body: comment },
  })

export const deleteComment: (
  specificationId: string,
  requirementId: string,
  commentId: string,
) => Promise<{ id: string }> = (specificationId, requirementId, commentId) =>
  api.delete(
    `${createUrl(specificationId, requirementId)}/comments/${commentId}`,
  )

export const getRequirementCommentsWithUsers: (
  specificationId: string,
  requirementId: string,
) => Promise<RequirementCommentWrapper[]> = async (
  specificationId,
  requirementId,
) => {
  const users = (await getAllUsers())?.users || []
  const usersById = users.reduce(
    (byId, user) => ({ ...byId, [user.id]: user }),
    {},
  )

  const comments = (
    await api.get(`${createUrl(specificationId, requirementId)}/comments`)
  ).comments.map((comment: RequirementCommentResponse) => {
    const createdOn = new Date(comment.createdOn)
    return {
      type: RequirementCommentType.Comment,
      content: {
        ...comment,
        createdBy: usersById[comment.createdBy],
        createdOn,
      },
      createdOn,
    }
  })

  const changeRequests = (
    await getRequirementChangeRequests(specificationId, requirementId)
  ).map((changeRequest) => {
    return {
      type: RequirementCommentType.ChangeRequest,
      content: {
        ...changeRequest,
        createdBy: usersById[changeRequest.createdBy],
      },
      createdOn: changeRequest.createdOn,
    }
  })

  return [...comments, ...changeRequests]
}

export const getSpecificationComments = async (
  specificationId: UUID,
  revisionId?: UUID,
): Promise<Record<UUID, RequirementComment[]>> => {
  const { requirementComments } = (await api.get(
    `${createUrl(specificationId)}/comments${
      revisionId ? `?revisionId=${revisionId}` : ''
    }`,
  )) as {
    requirementComments: Record<string, RequirementComment[]>
  }

  return Object.fromEntries(
    Object.entries(requirementComments).map(([requirementId, comments]) => [
      requirementId,
      comments.map((comment) => ({
        ...comment,
        createdOn: new Date(comment.createdOn),
      })),
    ]),
  )
}

type UpdateRequirementComment = {
  resolve: boolean
}

export const updateRequirementComment = async (
  specificationId: string,
  requirementId: string,
  commentId: string,
  update: UpdateRequirementComment,
): Promise<RequirementComment> => {
  const updatedComment = api.patch(
    `${createUrl(specificationId, requirementId)}/comments/${commentId}`,
    {
      body: update,
    },
  )
  return { ...updatedComment, createdOn: new Date(updatedComment.createdOn) }
}

export const publicTenantMethods = {
  getRequirements: async (
    specificationId: string,
    query?: {
      ids?: Array<string>
      revisionIds?: Array<string>
      specificationProgram?: string
    },
  ): Promise<Requirement[]> => {
    const idQ = query?.ids?.map((id) => `id=${id}`).join('&') || ''
    const revisionIdQ =
      query?.revisionIds?.map((id) => `revisionId=${id}`).join('&') || ''
    const programQ = query?.specificationProgram
      ? `specificationProgram=${query.specificationProgram}&`
      : ''
    const queryString = [idQ, programQ, revisionIdQ].filter((q) => q).join('&')

    const { requirements } = await usingPublicTenant(api.get)(
      `${createUrl(specificationId)}${queryString ? `?${queryString}` : ''}`,
    )

    return requirements.map(withDates)
  },
}

export const getRequirementsCustomAttributes = async (
  programId?: string,
): Promise<{ requirements: GetRequirementsWithCustomAttributes[] }> => {
  const resp = await api.get(
    `/api/v2/specifications/requirements/dashboard${
      programId ? `?program=${programId}` : ''
    }`,
  )
  return resp
}
