import { useEffect } from 'react'
import { useLocation, useNavigate, useRouteError } from 'react-router-dom'
import styles from './ErrorPage.module.css'
import { useAuth } from '../../context/AuthContext.tsx'

const UNAUTHORIZED = 'Unauthorized'

function ErrorBoundary() {
  const error: any = useRouteError()
  const { signOut } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (error?.name === UNAUTHORIZED) {
      navigate('/login', { state: { returnUrl: location.pathname } })
    }
  }, [error, location.pathname, navigate])

  return (
    <div className={styles.errorPage}>
      <div className={styles.header}>Sorry, something went wrong.</div>
      <div className={styles.message}>
        There seems to be a problem on our end. Please try again later or
        <a
          href="/login"
          className={styles.link}
          onClick={() => {
            signOut(true)
          }}
        >
          {' '}
          sign in.{' '}
        </a>
        <br />
        If the issue persists, contact our support team at{' '}
        <a href={`mailto:help@stell-engineering.com`} className={styles.link}>
          help@stell-engineering.com
        </a>
      </div>
    </div>
  )
}

export default ErrorBoundary
