import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  REVIEWER_ONLY_COLUMNS,
  SharedRequirementColumnId,
  SharedRequirementColumns,
  SharedSpecificationColumnsFilters,
} from './SharedRequirementColumns.tsx'
import styles from './SharedRequirementsView.module.css'
import { BlockType } from '../../api/v2/blocks'
import * as api from '../../api/v2/projects.ts'
import Button from '../../components/button/index.tsx'
import FilterDropdown, {
  AppliedFilters,
  useFilterState,
} from '../../components/filter-menu'
import { FilterKey } from '../../components/filter-menu/types'
import useResizableColumns from '../../components/table/useResizableColumns.ts'
import VirtualTable from '../../components/table/VirtualTable.tsx'
import { useSharedSpecificationContext } from '../../context/SharedSpecificationContext'
import DisplayFilter, { FilterGroup } from '../search/display-filter/index.tsx'

const FILTER_KEYS = [
  FilterKey.SharedRequirementType,
  FilterKey.RequirementComment,
]

const SharedRequirementsView = () => {
  const {
    commentsByRequirementId,
    specificationSnapshot,
    getRequirementById,
    typeIdtoType,
    methodIdtoMethod,
    evidenceIdtoEvidence,
    requirementIdtoReviews,
    requirementTypes,
    userIsSnapshotReviewer,
  } = useSharedSpecificationContext()
  const { requirementId: scrollToRequirementId } = useParams()
  const [filters, toggleFilter] = useFilterState(FILTER_KEYS)
  const [displayFilters, setDisplayFilters] = useState<string[]>([])
  const [expandAll, setExpandAll] = useState(false)

  const filteredColumns = useMemo(
    () =>
      SharedRequirementColumns(userIsSnapshotReviewer).filter((col) => {
        const isReviewerOnlyColumn = REVIEWER_ONLY_COLUMNS.includes(
          col.label as SharedRequirementColumnId,
        )

        if (isReviewerOnlyColumn && !userIsSnapshotReviewer) {
          return false
        }

        return !displayFilters.includes(col.label)
      }),
    [displayFilters, userIsSnapshotReviewer],
  )

  const filterGroups = [
    {
      filters: SharedSpecificationColumnsFilters(userIsSnapshotReviewer).filter(
        (f) =>
          userIsSnapshotReviewer ||
          !REVIEWER_ONLY_COLUMNS.includes(f.label as SharedRequirementColumnId),
      ),
    },
  ] as FilterGroup[]

  const columnResizer = useResizableColumns(
    filteredColumns,
    'sharedRequirementColumns',
  )

  const requirementIds = specificationSnapshot.contents.documentBlocks
    .filter((block) => block.type === BlockType.Requirement)
    .map((req) => req.id)

  const requirements = requirementIds
    .map((id) => getRequirementById(id))
    .filter((req) => req !== null)
    .map((req) => req as api.SharedSpecificationRequirement)

  const filterData = {
    [FilterKey.SharedRequirementType]: requirementTypes,
  }

  const filteredRequirements = useMemo(() => {
    return requirements
      .filter(
        (r) =>
          filters[FilterKey.SharedRequirementType].length === 0 ||
          r.types.some((type) =>
            filters[FilterKey.SharedRequirementType].includes(type),
          ),
      )
      .filter(
        (r) =>
          filters[FilterKey.RequirementComment].length === 0 ||
          commentsByRequirementId[r.id]?.some((comment) =>
            filters[FilterKey.RequirementComment].includes(comment.resolved),
          ),
      )
  }, [requirements, filters, commentsByRequirementId])

  const requirementRows = filteredRequirements?.map((requirement) => {
    const {
      sectionNumber,
      title,
      shallStatement,
      rationale,
      types,
      evidence,
      id,
    } = requirement

    const evidences = evidence?.verification

    const rowEvidences = evidences.map((ev) => {
      const method = {
        text: methodIdtoMethod[evidenceIdtoEvidence[ev].method].name,
        style:
          methodIdtoMethod[evidenceIdtoEvidence[ev].method].metadata.STYLES,
      }
      const description = evidenceIdtoEvidence[ev].descriptionOfActivity
      const title = evidenceIdtoEvidence[ev].title

      return {
        method,
        description,
        title,
      }
    })

    const rowTypes = types.map((t) => {
      const text = typeIdtoType[t].name
      const style = typeIdtoType[t].metadata.STYLES

      return {
        text,
        style,
      }
    })

    return {
      id,
      sectionNumber,
      title,
      shallStatement,
      rationale,
      types: rowTypes,
      evidences: rowEvidences,
      reviews: requirementIdtoReviews[id] || [],
    }
  })

  const scrollToIndex = filteredRequirements.findIndex(
    (requirement) => requirement.id === scrollToRequirementId,
  )

  return (
    <div className={styles.content}>
      <div className={styles.actions}>
        <Button
          text={expandAll ? 'Collapse' : 'Expand'}
          onClick={() => setExpandAll(!expandAll)}
        />
        <DisplayFilter
          filterGroups={filterGroups}
          selectedFilters={displayFilters}
          onFilterClick={(filterName) => {
            const idx = displayFilters.indexOf(filterName)
            if (idx > -1) {
              const copy = [...displayFilters]
              copy.splice(idx, 1)
              setDisplayFilters(copy)
            } else {
              setDisplayFilters([...displayFilters, filterName])
            }
          }}
        />
        <FilterDropdown
          menus={FILTER_KEYS}
          activeFilters={filters}
          onSelectFilter={toggleFilter}
          filterData={filterData}
        />
        <AppliedFilters
          filterKeys={FILTER_KEYS}
          filters={filters}
          onRemove={toggleFilter}
          filterData={filterData}
        />
      </div>
      <div className={styles.requirements}>
        <VirtualTable
          rowItems={requirementRows}
          columnResizer={columnResizer}
          snapshot={specificationSnapshot}
          expandAll={expandAll}
          scrollToIndex={scrollToIndex}
        />
      </div>
    </div>
  )
}

export default SharedRequirementsView
