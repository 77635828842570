import { OverflowMenuVertical, TrashCan } from '@carbon/icons-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './SpecificationsTableColumns.module.css'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import AttributeValueSelect, {
  DisplayType,
} from '../../components/attribute-value-select/AttributeValueSelect.tsx'
import Chip from '../../components/chip/index.tsx'
import Dropdown from '../../components/dropdown/index.tsx'
import { TableColumn, TableItem } from '../../components/table/Table.tsx'
import Tag from '../../components/tag/index.tsx'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import { useSpecificationListContext } from '../../context/SpecificationListContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { useModals } from '../../hooks/useModals.ts'
import { monthLongDayYear } from '../../lib/date.ts'
import { getCssVar } from '../../lib/string.ts'

const ActionsCell = (props) => {
  const { data } = props
  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    if (open) {
      setOpen(false)
    }
  }

  const ref = useClickOutside(closeMenu)
  const { userIsOwner } = useAuth()
  const { openDuplicateSpecificationModal, openConfirmationModal } = useModals()
  const { deleteSpecification, publicTenant } = useSpecificationListContext()

  const onDelete = () => {
    closeMenu()
    openConfirmationModal({
      title: 'Delete Specification',
      titleIcon: <TrashCan size={20} />,
      promptText: `Are you sure you want to delete "${data.name}"?`,
      onConfirm: () => deleteSpecification(data.id),
    })
  }

  return (
    <div ref={ref} className={styles.actions}>
      <button onClick={() => setOpen(!open)}>
        <OverflowMenuVertical size={24} />
      </button>
      <Dropdown className={styles.dropdown} isOpen={open}>
        <button
          className={styles.actionItem}
          onClick={() => {
            openDuplicateSpecificationModal({ specification: data })
            closeMenu()
          }}
        >
          Duplicate
        </button>
        {!publicTenant && userIsOwner(data.id) && (
          <button
            className={styles.actionItem}
            style={{ color: 'red' }}
            onClick={onDelete}
          >
            Delete
          </button>
        )}
      </Dropdown>
    </div>
  )
}

const SpecNumber = ({ data }) => {
  return (
    <div className={styles.specNumber}>
      <Link to={`/specifications/${data.id}`}>
        {data.specificationIdentifier}
      </Link>
    </div>
  )
}

const Name = ({ data }) => {
  return (
    <div>
      <Link
        className={styles.specName}
        to={`${
          data.publicTenant ? '/public-specifications' : '/specifications'
        }/${data.id}`}
      >
        {data.name}
      </Link>
    </div>
  )
}

const Version = ({ data }: TableItem) => {
  return (
    <div>
      {data.version && (
        <Tag
          text={data.external ? data.version : `V${data.version}`}
          color={{
            fontColor: getCssVar('--text-color-black'),
            backgroundColor: getCssVar('--color-green5'),
          }}
        />
      )}
    </div>
  )
}

const Program = ({ data }) => {
  const { updateSpecificationProgram } = useSpecificationListContext()
  const { programs, activePrograms } = useAttributesContext()
  const { userIsOwner } = useAuth()

  return (
    <AttributeValueSelect
      readOnly={!userIsOwner(data.id)}
      attributeValues={programs}
      activeAttributeValues={activePrograms}
      displayType={DisplayType.Table}
      onlyDisplayOnHover
      selectedValueId={data.program}
      onSelect={(program) => {
        if (program) {
          updateSpecificationProgram(data.id, program.id)
        } else {
          console.warn('Cannot update program. No program selected.')
        }
      }}
      onCancel={() => updateSpecificationProgram(data.id, null)}
    />
  )
}

const Organization = ({ data }: TableItem) => {
  const { organization } = data
  return <div className={styles.organization}>{organization}</div>
}

const RequirementCount = ({ data }) => (
  <div className={styles.requirementCount}>{data.requirementCount}</div>
)

const LastEdited = ({ data }) => {
  return (
    <div className={styles.lastEdited}>
      {monthLongDayYear(data.lastModifiedOn)}
    </div>
  )
}

const Category = ({ data }) => {
  const { updateSpecificationCategory } = useSpecificationListContext()
  const { categories, activeCategories } = useAttributesContext()

  return (
    <AttributeValueSelect
      attributeValues={categories}
      activeAttributeValues={activeCategories}
      displayType={DisplayType.Table}
      onlyDisplayOnHover
      selectedValueId={data.category}
      onSelect={(category) => {
        if (category) {
          updateSpecificationCategory(data.id, category.id)
        } else {
          console.warn('Cannot update category. No category selected.')
        }
      }}
      onCancel={() => updateSpecificationCategory(data.id, null)}
    />
  )
}

const Status = ({ data }) => {
  return data.external ? (
    <div>
      <Tag text="External" />
    </div>
  ) : (
    <div>
      <Chip variant="status" value={RevisionStatus[data.status]} />
    </div>
  )
}

const Phase = ({ data }) => {
  const { updateSpecificationPhase } = useSpecificationListContext()
  const { phases, activePhaseStates } = useAttributesContext()

  return (
    <AttributeValueSelect
      attributeValues={phases}
      activeAttributeValues={activePhaseStates}
      displayType={DisplayType.Table}
      onlyDisplayOnHover
      selectedValueId={data.phase}
      onSelect={(phase) => {
        if (phase) {
          updateSpecificationPhase(data.id, phase.id)
        } else {
          console.warn('Cannot update phase. No phase selected.')
        }
      }}
      onCancel={() => updateSpecificationPhase(data.id, null)}
    />
  )
}

const SpecificationsTableColumns: Array<TableColumn> = [
  { label: '', Component: ActionsCell, transparent: true },
  { label: 'Number', Component: SpecNumber },
  { label: 'Specification Name', Component: Name, width: 'minmax(150px, 1fr)' },
  { label: 'Version', Component: Version },
  { label: 'Program', Component: Program },
  { label: 'Phase', Component: Phase },
  { label: 'Organization', Component: Organization },
  { label: '# Requirements', Component: RequirementCount },
  { label: 'Last Edited', Component: LastEdited },
  { label: 'Category', Component: Category },
  { label: 'Status', Component: Status },
]

const Published = ({ data }) => {
  return <div>{monthLongDayYear(data.createdOn)}</div>
}

export const PublicSpecificationsTableColumns: Array<TableColumn> = [
  { label: '', Component: ActionsCell, transparent: true },
  { label: 'Number', Component: SpecNumber },
  { label: 'Specification Name', Component: Name, width: 'minmax(150px, 1fr)' },
  { label: 'Version', Component: Version },
  { label: 'Organization', Component: Organization },
  { label: '# Requirements', Component: RequirementCount },
  { label: 'Category', Component: Category },
  { label: 'Published', Component: Published },
]

export default SpecificationsTableColumns
