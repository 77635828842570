import { CheckmarkOutline, Edit } from '@carbon/icons-react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useBlocker } from 'react-router-dom'
import styles from './EvidenceRecordDetails.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes'
import { CreateEvidenceRequest, Evidence } from '../../api/v2/evidence'
import Button, { BUTTON_COLORS } from '../../components/button'
import EvidenceMethodSelect from '../../components/evidence/EvidenceMethodSelect'
import EvidenceProgramSelect from '../../components/evidence/EvidenceProgramSelect'
import Tag, { TAG_COLORS } from '../../components/tag'
import { useAttributesContext } from '../../context/AttributesContext'
import { useModals } from '../../hooks/useModals'
import { EvidenceType } from '../../types/enums'

interface EvidenceRecordDetailsProps {
  isLocked: boolean
  setIsLocked: (isLocked: boolean) => void
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  onUpdate: () => void
  onCreate: () => void
  evidenceRecord: CreateEvidenceRequest
  setEvidenceRecord: Dispatch<SetStateAction<CreateEvidenceRequest>>
  savedEvidenceRecord: Evidence | undefined
}

const fieldsToCompare = [
  'type',
  'method',
  'program',
  'title',
  'cadences',
  'description',
  'complianceStatement',
]

const areRecordsEqual = (
  record1: Partial<Evidence>,
  record2: Partial<Evidence>,
) => {
  for (const key of fieldsToCompare) {
    const val1 = record1[key]
    const val2 = record2[key]

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!areArraysEqual(val1, val2)) {
        return false
      }
    } else if (val1 !== val2) {
      return false
    }
  }
  return true
}

const areArraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false
  }
  const sorted1 = arr1.sort()
  const sorted2 = arr2.sort()
  return sorted1.every(
    (value: string, index: number) => value === sorted2[index],
  )
}

const EvidenceRecordDetails = (props: EvidenceRecordDetailsProps) => {
  const {
    isLocked,
    setIsLocked,
    isEditing,
    setIsEditing,
    onUpdate,
    onCreate,
    evidenceRecord,
    setEvidenceRecord,
    savedEvidenceRecord,
  } = props
  const { evidenceCadences, getEvidenceMethodById, getProgramById } =
    useAttributesContext()
  const { openConfirmationModal } = useModals()
  const [evidenceMethod, setEvidenceMethod] = useState<AttributeValueResponse>()
  const [evidenceProgram, setEvidenceProgram] =
    useState<AttributeValueResponse>()

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const defaultEvidenceRecord = {
      type: EvidenceType.Validation,
      method: '',
      title: '',
      cadences: [],
      description: '',
      complianceStatement: '',
    }

    if (currentLocation.pathname === nextLocation.pathname || isLocked) {
      return false
    }

    if (
      (!savedEvidenceRecord &&
        !areRecordsEqual(evidenceRecord, defaultEvidenceRecord)) ||
      (savedEvidenceRecord &&
        !areRecordsEqual(evidenceRecord, savedEvidenceRecord))
    ) {
      return true
    }

    return false
  })

  useEffect(() => {
    if (blocker.state === 'blocked') {
      openConfirmationModal({
        title: 'Unsaved Changes',
        promptText: (
          <span style={{ marginBottom: '12px' }}>
            Your changes have not been saved. Are you sure you want to leave?
          </span>
        ),
        onConfirm: () => blocker.proceed(),
        onCancel: () => blocker.reset(),
      })
    }
  }, [blocker, openConfirmationModal])

  useEffect(() => {
    const getEvidenceMethod = () => {
      if (!savedEvidenceRecord) {
        return
      }
      const evidenceMethodData = getEvidenceMethodById(
        savedEvidenceRecord.method,
      )
      if (evidenceMethodData) {
        setEvidenceMethod(evidenceMethodData)
      }
    }
    getEvidenceMethod()
  }, [savedEvidenceRecord, getEvidenceMethodById])

  useEffect(() => {
    const getEvidenceProgram = () => {
      if (!savedEvidenceRecord) {
        return
      }
      const evidenceProgramData = getProgramById(savedEvidenceRecord.program)
      if (evidenceProgramData) {
        setEvidenceProgram(evidenceProgramData)
      }
    }
    getEvidenceProgram()
  }, [savedEvidenceRecord, getProgramById])

  const handleInputChange = (field: string, value: string) => {
    setEvidenceRecord((prevRecord) => ({
      ...prevRecord,
      [field]: value,
    }))
  }

  const toggleCadence = (cadenceId: string) => {
    setEvidenceRecord((prevRecord) => {
      const cadences = prevRecord.cadences || []
      if (cadences.includes(cadenceId)) {
        return {
          ...prevRecord,
          cadences: cadences.filter((id) => id !== cadenceId),
        }
      } else {
        return {
          ...prevRecord,
          cadences: [...cadences, cadenceId],
        }
      }
    })
  }

  return (
    <div className={styles.evidenceRecord}>
      <div className={styles.header}>
        <h1>Evidence Details</h1>
        {isLocked && (
          <button
            onClick={() => {
              setIsLocked(!isLocked)
              setIsEditing(true)
            }}
          >
            <Edit size={16} />
          </button>
        )}
      </div>
      {isLocked && (
        <div className={styles.lockedForm}>
          <div className={styles.formItem}>
            <div className={`${styles.formItemLabel} ${styles.bold}`}>
              Record type
            </div>
            <div className={styles.radioButtons}>
              {Object.values(EvidenceType)
                .filter((type) => type !== EvidenceType.Unknown)
                .map((type) => {
                  return (
                    <div className={styles.option} key={type}>
                      <input
                        type="radio"
                        value={type}
                        checked={evidenceRecord.type === type}
                        onChange={() => {
                          return false
                        }}
                      />
                      <span className={styles.radioLabel}>
                        {type.toLowerCase()}
                      </span>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className={styles.formItem}>
            <div className={`${styles.formItemLabel} ${styles.bold}`}>
              Method
            </div>
            <Tag
              className={styles.evidenceMethodTag}
              text={evidenceMethod?.name || ''}
              color={
                evidenceMethod
                  ? {
                      fontColor: evidenceMethod?.metadata.STYLES.COLOR_FONT,
                      backgroundColor: evidenceMethod?.metadata.STYLES.COLOR_BG,
                    }
                  : TAG_COLORS.gray3
              }
            />
          </div>
          <div className={styles.formItem}>
            <div className={`${styles.formItemLabel} ${styles.bold}`}>
              Program
            </div>
            {evidenceProgram && (
              <Tag
                className={styles.evidenceMethodTag}
                text={evidenceProgram?.name || ''}
                color={
                  evidenceProgram
                    ? {
                        fontColor: evidenceProgram?.metadata.STYLES.COLOR_FONT,
                        backgroundColor:
                          evidenceProgram?.metadata.STYLES.COLOR_BG,
                      }
                    : TAG_COLORS.gray3
                }
              />
            )}
          </div>
          <div className={styles.formItem}>
            <div className={`${styles.formItemLabel} ${styles.bold}`}>
              Record title
            </div>
            <div>{evidenceRecord.title}</div>
          </div>
          <div className={styles.formItem}>
            <div className={`${styles.formItemLabel} ${styles.bold}`}>
              Cadence
            </div>
            <div className={styles.checkboxes}>
              {evidenceCadences.map((cadence) => (
                <div className={styles.checkbox} key={cadence.id}>
                  <input
                    type="checkbox"
                    id={cadence.id}
                    checked={evidenceRecord.cadences?.includes(cadence.id)}
                    onChange={() => false}
                  />
                  <div className={styles.checkboxLabel}>
                    {cadence.name.toLowerCase()}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.formItem}>
            <div className={`${styles.formItemLabel} ${styles.bold}`}>
              Description of activity
            </div>
            <div>{evidenceRecord.description}</div>
          </div>
          <div className={styles.formItem}>
            <div className={`${styles.formItemLabel} ${styles.bold}`}>
              Compliance statement
            </div>
            <div>{evidenceRecord.complianceStatement}</div>
          </div>
          <div className={styles.actions}></div>
        </div>
      )}
      {!isLocked && (
        <form>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Record type</div>
            <div className={styles.radioButtons}>
              {Object.values(EvidenceType)
                .filter((type) => type !== EvidenceType.Unknown)
                .map((type) => {
                  return (
                    <label key={type}>
                      <input
                        className={styles.editable}
                        type="radio"
                        value={type}
                        checked={evidenceRecord.type === type}
                        onChange={() => handleInputChange('type', type)}
                      />
                      <span
                        className={`${styles.radioLabel} ${styles.editable}`}
                      >
                        {type.toLowerCase()}
                      </span>
                    </label>
                  )
                })}
            </div>
          </div>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Method</div>
            <EvidenceMethodSelect
              value={evidenceRecord.method || ''}
              onSelect={(method) => handleInputChange('method', method)}
            />
          </div>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Program</div>
            <EvidenceProgramSelect
              value={evidenceRecord.program || ''}
              onSelect={(program) => handleInputChange('program', program)}
            />
          </div>
          <label className={styles.formItem}>
            <div className={styles.formItemLabel}>Record title</div>
            <input
              className={styles.basicElevation}
              placeholder="Add title"
              value={evidenceRecord.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              maxLength={200}
            />
          </label>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Cadence</div>
            <div className={styles.checkboxes}>
              {evidenceCadences.map((cadence) => (
                <label className={styles.checkbox} key={cadence.id}>
                  <input
                    className={styles.editable}
                    type="checkbox"
                    id={cadence.id}
                    checked={evidenceRecord.cadences?.includes(cadence.id)}
                    onChange={() => toggleCadence(cadence.id)}
                  />
                  <div className={`${styles.checkboxLabel} ${styles.editable}`}>
                    {cadence.name.toLowerCase()}
                  </div>
                </label>
              ))}
            </div>
          </div>
          <label className={styles.formItem}>
            <div className={styles.formItemLabel}>Description of activity</div>
            <textarea
              className={styles.basicElevation}
              placeholder="Add description"
              value={evidenceRecord.description}
              onChange={(e) => handleInputChange('description', e.target.value)}
              rows={4}
              maxLength={524288}
            />
          </label>
          <label className={styles.formItem}>
            <div className={styles.formItemLabel}>Compliance statement</div>
            <textarea
              className={styles.basicElevation}
              placeholder="Add compliance statement"
              value={evidenceRecord.complianceStatement}
              onChange={(e) =>
                handleInputChange('complianceStatement', e.target.value)
              }
              rows={3}
              maxLength={524288}
            />
          </label>
          <div className={styles.actions}>
            <Button
              text={savedEvidenceRecord ? 'Save record' : 'Create record'}
              endIcon={<CheckmarkOutline size={16} />}
              onClick={(e) => {
                e.preventDefault()
                isEditing ? onUpdate() : onCreate()
              }}
              color={BUTTON_COLORS.PRIMARY}
            />
          </div>
        </form>
      )}
    </div>
  )
}

export default EvidenceRecordDetails
