import { Image, Styles, StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  Block,
  BlockData,
  ColumnData,
  HeadingBlockData,
  ImageBlockData,
  RequirementBlockData,
  TableBlockData,
  TextBlockData,
} from '../../../api/v2/blocks.ts'
import { Requirement } from '../../../api/v2/requirements.ts'
import { Specification } from '../../../api/v2/specifications.ts'
import { DEFAULT_COLUMN_WIDTH_PX } from '../../../components/block-editor/blocks/table/useTableBlock.ts'
import { REQUIREMENT_STATUS_COLORS } from '../../../components/tag/RequirementStatusTag.tsx'
import { deltaToPlaintext, getCssVar } from '../../../lib/string.ts'

interface BlockProps<T extends BlockData> {
  block: Block<T>
  specification: Specification
  isExternal: boolean
  version: string
  isHistoricalRevision: boolean

  // When block type is requirement
  requirement?: Requirement

  // When block type is image
  imageSrc?: string
}

export const TextPdfBlock = (props: BlockProps<TextBlockData>) => {
  const { block } = props
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      minHeight: '20px',
    },
    p: {
      flexDirection: 'row',
      color: getCssVar('--text-color-gray-light'),
      fontSize: '12px',
    },
  })

  return (
    <View style={styles.container}>
      <Text style={styles.p}>
        {deltaToPlaintext(block.data._data.quillDelta) || 'Empty text'}
      </Text>
    </View>
  )
}

export const ImagePdfBlock = (props: BlockProps<ImageBlockData>) => {
  const { imageSrc, block } = props

  const styles = StyleSheet.create({
    titleWrapper: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    title: {
      marginTop: '10px',
      color: getCssVar('--text-color-gray-light'),
    },
  })

  return (
    <View>
      <Image src={imageSrc} />
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{block.data.name || 'Image title...'}</Text>
      </View>
    </View>
  )
}

export const RequirementPdfBlock = (
  props: BlockProps<RequirementBlockData>,
) => {
  const {
    requirement = {} as Requirement,
    specification,
    isHistoricalRevision,
  } = props

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-end',
      color: getCssVar('--text-color-black'),
    },

    identifier: {
      fontSize: '12px',
      margin: ' 0 0 8px 0',
      color: getCssVar('--text-color-gray-dark'),
    },

    block: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#E4E4E4',
      padding: '14px',
      borderRadius: '4px',
      fontSize: '10px',
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '14px',
      marginBottom: '8px',
    },
    sectionNumber: {
      marginRight: '8px',
      ...(requirement.sectionNumber
        ? {}
        : {
            color: getCssVar('--text-color-gray-light'),
          }),
    },
    title: {
      marginRight: '8px',
      ...(requirement.title
        ? {}
        : {
            color: getCssVar('--text-color-gray-light'),
          }),
    },
    status: {
      alignItems: 'center',
      justifyContent: 'center',
      height: '20px',
      padding: '4px 6px',
      borderRadius: '6px',
      backgroundColor:
        REQUIREMENT_STATUS_COLORS[requirement.status]?.backgroundColor,
    },
    statusText: {
      fontSize: '10px',
      fontWeight: 400,
      color: REQUIREMENT_STATUS_COLORS[requirement.status]?.fontColor,
    },
    shallStatement: {
      ...(requirement.shallStatement
        ? {}
        : {
            color: getCssVar('--text-color-gray-light'),
          }),
    },
    rationale: { marginTop: '10px', fontStyle: 'italic' },
  })

  return (
    <View style={styles.container}>
      <Text style={styles.identifier}>
        {`${specification.specificationIdentifier || 'Document Number'}-${
          requirement?.context?.requirementIdentifier || 0
        }`}
      </Text>
      <View style={styles.block}>
        <View style={styles.header}>
          <Text style={styles.sectionNumber}>
            {requirement.sectionNumber || 'Section Number'}
          </Text>
          <Text style={styles.title}>
            {requirement.title || 'Requirement Title'}
          </Text>
          {!specification.external &&
            requirement?.status &&
            !isHistoricalRevision && (
              <View style={styles.status}>
                <Text style={styles.statusText}>{requirement.status}</Text>
              </View>
            )}
        </View>
        <Text style={styles.shallStatement}>
          {requirement.shallStatement || 'Requirement Statement'}
        </Text>
        {requirement.rationale && (
          <Text style={styles.rationale}>{requirement.rationale}</Text>
        )}
      </View>
    </View>
  )
}

export const TablePdfBlock = (props: BlockProps<TableBlockData>) => {
  const { block } = props

  const createStyles = (cols: ColumnData[]) => {
    const tableWidth = cols.reduce(
      (prev, curr) => prev + (curr?.width || DEFAULT_COLUMN_WIDTH_PX),
      0,
    )

    const styles = {
      table: {
        width: tableWidth,
        maxWidth: '100%',
        borderLeft: '1px solid #ddddde',
        borderBottom: '1px solid #ddddde',
        fontSize: '12px',
      },
      headerRow: {
        minHeight: '36px',
        flexDirection: 'row',
        flexWrap: 'none',
        backgroundColor: '#fafafa',
        fontWeight: 500,
      },
      row: {
        flexDirection: 'row',
        flexWrap: 'none',
        minHeight: '36px',
      },

      titleWrapper: {
        width: tableWidth,
        maxWidth: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      title: {
        marginTop: '10px',
        color: getCssVar('--text-color-gray-light'),
      },
    }

    cols.forEach((col) => {
      const cellStyles = {
        width: col.width,
        padding: '8px',
        borderRight: '1px solid #ddddde',
        borderTop: '1px solid #ddddde',
      }

      styles[`cell-col-${col.id}`] = cellStyles

      styles[`header-cell-col-${col.id}`] = {
        ...cellStyles,
        display: 'flex',
        justifyContent: 'center',
      }
    })

    return StyleSheet.create(styles as Styles)
  }

  const styles = createStyles(block.data._data.columns)

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.headerRow} fixed>
          {block.data._data.columns.map((col, idx) => (
            <View key={idx} style={styles[`header-cell-col-${col.id}`]}>
              <Text>{col.title}</Text>
            </View>
          ))}
        </View>
        {block.data._data.rows.map((row, idx) => (
          <View key={idx} style={styles.row}>
            {block.data._data.columns.map((col, idx) => (
              <View key={idx} style={styles[`cell-col-${col.id}`]}>
                <Text>
                  {row.content[col.id]
                    ? deltaToPlaintext(row.content[col.id])
                    : ''}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{block.data.name || 'Table title...'}</Text>
      </View>
    </View>
  )
}

export const HeadingPdfBlock = (props: BlockProps<HeadingBlockData>) => {
  const { block } = props
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      minHeight: '20px',
      color: block.data._data.text
        ? getCssVar('--text-color-black')
        : getCssVar('--text-color-gray-light'),
    },
    h: {
      flexDirection: 'row',
      fontSize: '16px',
    },
  })

  return (
    <View style={styles.container}>
      <Text style={styles.h}>{block.data._data.text || 'Heading'}</Text>
    </View>
  )
}
