import { AddFilled, Report } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './GenerateReportModal.module.css'
import Modal from './index.tsx'
import {
  CreateEvidenceComplianceReportRequest,
  createEvidenceComplianceReport,
} from '../../api/v2/evidence.ts'
import { CreateReportRequest, createSearchReport } from '../../api/v2/search.ts'
import {
  createSpecificationReport,
  CreateSpecificationReportRequest,
} from '../../api/v2/specifications.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { COLUMN_ID_TO_NAME } from '../../pages/search/SearchTableColumns.tsx'
import { ReportColumnId } from '../../types/enums.ts'
import Button, { BUTTON_COLORS } from '../button'
import Tag, { TAG_COLORS } from '../tag'
import { toastError, toastSuccess } from '../toast'

export interface CreateSearchReportAction {
  req: CreateReportRequest
  columnIds: ReportColumnId[]
  defaultColumnIdsSelected: ReportColumnId[]
  inheritedColumnsIds: ReportColumnId[]
}

export interface CreateSpecificationReportAction {
  req: CreateSpecificationReportRequest
  specificationId: string
  columnIds: ReportColumnId[]
  defaultColumnIdsSelected: ReportColumnId[]
  inheritedColumnsIds: ReportColumnId[]
}

export interface CreateEvidenceComplianceReportAction {
  req: CreateEvidenceComplianceReportRequest
  evidenceId: string
  columnIds: ReportColumnId[]
  defaultColumnIdsSelected: ReportColumnId[]
  inheritedColumnsIds: ReportColumnId[]
}

interface GenerateReportModalType {
  isOpen: boolean
  closeModal: () => void
  searchReport?: CreateSearchReportAction
  specReport?: CreateSpecificationReportAction
  evidenceReport?: CreateEvidenceComplianceReportAction
}

const GenerateReportModal = (props: GenerateReportModalType) => {
  const { closeModal, searchReport, specReport, evidenceReport } = props

  const columnIds =
    (searchReport || specReport || evidenceReport)?.columnIds || []
  const defaultColumnIdsSelected =
    (searchReport || specReport || evidenceReport)?.defaultColumnIdsSelected ||
    []
  const inheritedColumnsIds =
    (searchReport || specReport || evidenceReport)?.inheritedColumnsIds || []

  const navigate = useNavigate()

  const modalRef = useClickOutside(() => closeModal())

  const [requestProcessing, setRequestProcessing] = useState<boolean>(false)

  const [selectedColumns, setSelectedColumns] = useState<
    Record<ReportColumnId, boolean>
  >(
    columnIds.reduce(
      (prev, columnId) => ({
        ...prev,
        [columnId]: defaultColumnIdsSelected.includes(columnId),
      }),
      {} as Record<ReportColumnId, boolean>,
    ),
  )

  const [reportName, setReportName] = useState<string>('')

  const onColumnTagClick = useCallback(
    (columnName: string) => {
      setSelectedColumns({
        ...selectedColumns,
        [columnName]: !selectedColumns[columnName],
      })
    },
    [selectedColumns],
  )

  const isValidReport = () =>
    reportName && Object.values(selectedColumns).includes(true)

  return (
    <Modal
      innerRef={modalRef}
      icon={<Report />}
      title="Generate Report"
      onClose={closeModal}
    >
      <div className={styles.container}>
        <input
          placeholder="Report title..."
          onChange={(e) => setReportName(e.target.value)}
        />
        <span className={styles.description}>
          Add or remove columns to this report.
        </span>
        <div className={styles.tags}>
          {columnIds
            .filter((columnId) => !inheritedColumnsIds.includes(columnId))
            .map((columnId) => (
              <button key={columnId} onClick={() => onColumnTagClick(columnId)}>
                <Tag
                  uppercase={false}
                  text={COLUMN_ID_TO_NAME[columnId]}
                  color={
                    selectedColumns[columnId]
                      ? TAG_COLORS.primary
                      : TAG_COLORS.secondary
                  }
                />
              </button>
            ))}
        </div>
        {inheritedColumnsIds.length > 0 && (
          <>
            <span className={styles.description}>Select inherited fields</span>
            <div className={styles.tags}>
              {inheritedColumnsIds.map((columnId) => (
                <button
                  key={columnId}
                  onClick={() => onColumnTagClick(columnId)}
                >
                  <Tag
                    uppercase={false}
                    text={COLUMN_ID_TO_NAME[columnId]}
                    color={
                      selectedColumns[columnId]
                        ? TAG_COLORS.primary
                        : TAG_COLORS.secondary
                    }
                  />
                </button>
              ))}
            </div>
          </>
        )}
        <Button
          className={styles.generateBtn}
          text="Generate report"
          disabled={!isValidReport() || requestProcessing}
          endIcon={<AddFilled size={14} />}
          color={BUTTON_COLORS.PRIMARY}
          onClick={async () => {
            const selectedColumnIds = Object.keys(COLUMN_ID_TO_NAME).filter(
              (columnId) => selectedColumns[columnId],
            ) as Array<ReportColumnId>
            setRequestProcessing(true)

            const req = (searchReport || specReport || evidenceReport)?.req

            if (!req) {
              toastError(
                'Error, no report data provided when attempting to create report',
                'Please refresh and try again.',
              )
              return
            }

            try {
              const newReq = {
                ...req,
                name: reportName,
              }

              newReq.metadata.columnsToDisplay = selectedColumnIds

              let resp

              if (searchReport) {
                resp = await createSearchReport(newReq as CreateReportRequest)
              } else if (specReport) {
                resp = await createSpecificationReport(
                  newReq as CreateSpecificationReportRequest,
                  specReport?.specificationId || '',
                )
              } else if (evidenceReport) {
                resp = await createEvidenceComplianceReport(
                  newReq as CreateEvidenceComplianceReportRequest,
                  evidenceReport?.evidenceId || '',
                )
              }

              const id = resp.id ? resp.id : resp.reportId

              closeModal()
              navigate(`/reports/${id}`)
              toastSuccess('Successfully created report.')
            } catch (e) {
              console.error('Failed to create report', e)
              captureException(e)
              toastError(
                'Failed to create report.',
                'Please try again or refresh the page.',
              )
            } finally {
              setRequestProcessing(false)
            }
          }}
        />
      </div>
    </Modal>
  )
}

export default GenerateReportModal
