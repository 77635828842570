import styles from './LoadingIndicator.module.css'

export enum LoaderSize {
  XSMALL = 'extraSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'extraLarge',
}

const LoadingIndicator = (props: { size?: LoaderSize }) => {
  const { size = LoaderSize.MEDIUM } = props
  return <div className={`${styles.loadingSpinner} ${styles[size]}`}></div>
}

export default LoadingIndicator
