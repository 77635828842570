import { SendAlt } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useCallback, useEffect, useState } from 'react'
import Modal from './index.tsx'
import styles from './SpecificationReviewersModal.module.css'
import {
  getRevisionReviewReviewers,
  Revision,
  RevisionStatus,
} from '../../api/v2/revisions.ts'
import { Specification } from '../../api/v2/specifications.ts'
import * as usersApi from '../../api/v2/users.ts'
import useClickOutside from '../../hooks/useClickOutside.ts'
import { monthDayYear } from '../../lib/date.ts'
import { getFullName, sortByFirstThenLastName } from '../../lib/user.ts'
import {
  EntityReviewEventOperation,
  EntityReviewReviewerStatus,
} from '../../types/api/v2/entity.ts'
import {
  RevisionReview,
  RevisionReviewReviewer,
} from '../../types/api/v2/revisions.ts'
import Avatar from '../avatar'
import { AvatarSize } from '../avatar/constants.ts'
import Button, { BUTTON_COLORS } from '../button'
import Checkbox from '../input/Checkbox.tsx'
import { toastError, toastSuccess } from '../toast'

const SpecificationReviewersModal = (props: {
  specification: Specification
  revision: Revision
  pendingReview: RevisionReview
  createReview: (userIds: string[]) => Promise<void>
  updateReview: (action: EntityReviewEventOperation) => Promise<void>
  closeModal: () => void
}) => {
  const {
    specification,
    revision,
    pendingReview,
    createReview,
    updateReview,
    closeModal,
  } = props
  const ref = useClickOutside(() => closeModal())
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [userIdToReviewer, setUserIdToReviewer] = useState<
    Record<string, RevisionReviewReviewer>
  >({})
  useEffect(() => {
    const loadRequestedReviewers = async () => {
      if (!pendingReview) {
        return
      }

      const reviewers = await getRevisionReviewReviewers(
        specification.id,
        revision.id,
        pendingReview.id,
      )

      const reviewerIds = reviewers.map((reviewer) => reviewer.userId)
      setSelectedUserIds(reviewerIds)

      const userIdToReviewers = reviewers.reduce((acc, reviewer) => {
        return { ...acc, [reviewer.userId]: reviewer }
      }, {})
      setUserIdToReviewer(userIdToReviewers)
    }

    loadRequestedReviewers()
  }, [pendingReview, revision.id, specification.id])

  const onSelectChange = (reviewer: usersApi.User, selected: boolean) =>
    setSelectedUserIds(
      selected
        ? [...selectedUserIds, reviewer.id]
        : selectedUserIds.filter((id) => id !== reviewer.id),
    )

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submittingRecall, setSubmittingRecall] = useState<boolean>(false)

  const assignReviewers = useCallback(async () => {
    setSubmitting(true)
    try {
      await createReview(selectedUserIds)
      toastSuccess('Reviews requested')
      closeModal()
    } catch (error) {
      console.error('Unable to update revision users', error)
      captureException(error)
      toastError('Unable to submit review request', 'Try again later')
    } finally {
      setSubmitting(false)
    }
  }, [createReview, selectedUserIds, closeModal])

  const [availableUsers, setAvailableUsers] = useState<usersApi.User[]>([])
  const [filteredReviewers, setFilteredReviewers] = useState<usersApi.User[]>(
    [],
  )

  useEffect(() => {
    const loadUsers = async () => {
      const users = (await usersApi.getAllUsers())?.users || []

      // Stell admin is a special root user that should not display in the set
      // of options to add as a reviewer.
      const reviewers = users
        .filter((user) => user.userName != 'admin@root.com')
        .sort(sortByFirstThenLastName)

      setAvailableUsers(reviewers)
      setFilteredReviewers(reviewers)
    }

    loadUsers()
  }, [])

  const handleSearch = (query: string) => {
    if (!availableUsers) {
      setFilteredReviewers([])
      return
    }

    const filtered = availableUsers.filter((user) =>
      getFullName(user.firstName, user.lastName)
        .toLowerCase()
        .includes(query.toLowerCase()),
    )
    setFilteredReviewers(filtered)
  }

  const recallDraft = useCallback(async () => {
    setSubmittingRecall(true)
    try {
      await updateReview(EntityReviewEventOperation.DISMISS)
      closeModal()
    } catch (error) {
      console.error('Unable to recall specification to draft', error)
      captureException(error)
      toastError('Unable to recall specification to draft', '')
    } finally {
      setSubmittingRecall(false)
    }
  }, [closeModal, updateReview])

  return (
    <Modal
      title={
        revision?.status === RevisionStatus.DRAFT
          ? 'Request reviewers'
          : 'Reviewers'
      }
      onClose={closeModal}
      icon={<SendAlt size={20} />}
      innerRef={ref}
    >
      <div className={styles.specificationReviewers}>
        <div>
          {revision?.status == RevisionStatus.DRAFT
            ? 'Select the members of your organization you would like to review this specification.'
            : `Reviewers previously requested for specification "${
                specification.name || 'Untitled'
              }".`}
        </div>
        <div className={`${styles.reviewerList} ${styles.basicElevation}`}>
          <div className={styles.reviewerSearch}>
            <input
              className={`${styles.input} ${styles.basicElevation}`}
              placeholder="Type to find users"
              onChange={(event) => handleSearch(event.target.value)}
            />
          </div>
          {filteredReviewers.map((reviewer) => (
            <div key={reviewer.id} className={styles.reviewer}>
              <Avatar
                firstName={reviewer.firstName}
                lastName={reviewer.lastName}
                showName
                size={AvatarSize.MediumSmall}
              />
              <div className={styles.checkbox}>
                <Checkbox
                  checked={selectedUserIds.includes(reviewer.id)}
                  onChange={(checked: boolean) =>
                    onSelectChange(reviewer, checked)
                  }
                  disabled={revision?.status === RevisionStatus.REVIEW}
                />
              </div>
              <div
                className={`${styles.approvalNote} ${
                  Object.keys(userIdToReviewer).length > 0 ? '' : styles.hidden
                }`}
              >
                {userIdToReviewer[reviewer.id] &&
                  userIdToReviewer[reviewer.id].status ===
                    EntityReviewReviewerStatus.APPROVED && (
                    <span>
                      {`Approved ${monthDayYear(
                        userIdToReviewer[reviewer.id].updatedOn,
                      )}`}
                    </span>
                  )}
                {userIdToReviewer[reviewer.id] &&
                  userIdToReviewer[reviewer.id].status !==
                    EntityReviewReviewerStatus.APPROVED && (
                    <span>
                      {`Requested ${monthDayYear(
                        userIdToReviewer[reviewer.id].requestedOn,
                      )}`}
                    </span>
                  )}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.actions}>
          <Button
            onClick={recallDraft}
            text="Recall draft"
            disabled={
              submittingRecall || revision?.status !== RevisionStatus.REVIEW
            }
          />
          <Button
            onClick={() => assignReviewers()}
            text="Request"
            endIcon={<SendAlt />}
            color={BUTTON_COLORS.PRIMARY}
            disabled={
              submitting ||
              selectedUserIds.length === 0 ||
              revision?.status === RevisionStatus.REVIEW
            }
          />
        </div>
      </div>
    </Modal>
  )
}

export default SpecificationReviewersModal
