import type { UIMatch } from '@remix-run/router'
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useMatches } from 'react-router-dom'
import { useModals } from '../hooks/useModals.ts'

interface DragSelectCtx {
  isDragSelectEnabled: boolean
}

interface DragSelectMatch extends UIMatch {
  handle: {
    dragSelectEnabled: boolean
  }
}

const DragSelectContext = createContext<DragSelectCtx>({
  isDragSelectEnabled: false,
})

const DragSelectContextProvider = (props) => {
  const { isOpen } = useModals()
  const matches = useMatches() as DragSelectMatch[]

  const checkIsDragSelectEnabled = useCallback<() => boolean>(() => {
    const match = matches.find((match) => match?.handle?.dragSelectEnabled)
    return !!match?.handle?.dragSelectEnabled && !isOpen
  }, [matches, isOpen])

  const [isDragSelectEnabled, setIsDragSelectEnabled] = useState<boolean>(
    checkIsDragSelectEnabled(),
  )

  useEffect(() => {
    setIsDragSelectEnabled(checkIsDragSelectEnabled())
  }, [checkIsDragSelectEnabled, matches])

  return (
    <DragSelectContext.Provider value={{ isDragSelectEnabled }}>
      {props.children}
    </DragSelectContext.Provider>
  )
}

const useDragSelectContext = () => {
  const ctx = useContext(DragSelectContext)
  if (!ctx) {
    console.error('DragSelectContext has no provider')
  }
  return ctx
}

export { DragSelectContextProvider, useDragSelectContext }
