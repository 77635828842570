import { Add, DocumentExport, Subtract } from '@carbon/icons-react'
import { Group } from '@visx/group'
import { hierarchy, Tree } from '@visx/hierarchy'
import { HierarchyPointNode } from '@visx/hierarchy/lib/types'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { LinkVerticalStep } from '@visx/shape'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './SpecificationTree.module.css'
import * as api from '../../../api/v2/programs.ts'
import LoadingIndicator from '../../../components/loading-indicator/LoadingIndicator.tsx'
import Tag, { TAG_COLORS_LEVELS } from '../../../components/tag'
import { LoadingState } from '../../../types/enums.ts'
import useZoom from '../../specification/requirement-graph/useZoom.ts'

const nodeSize = {
  height: 75,
  width: 250,
}

function Node(props: { node: HierarchyPointNode<api.SpecificationTreeNode> }) {
  const { node } = props
  const centerX = -nodeSize.width / 2
  const centerY = -nodeSize.height / 2

  const nodeColor = TAG_COLORS_LEVELS[node.depth % 10]

  return (
    <Group top={node.y} left={node.x}>
      <foreignObject
        height={nodeSize.height}
        width={nodeSize.width}
        y={centerY}
        x={centerX}
      >
        <div
          className={styles.node}
          style={{
            width: nodeSize.width,
            height: nodeSize.height,
          }}
        >
          <div className={styles.nodeHeader}>
            <Tag text={`LEVEL ${node.depth - 1}`} color={nodeColor} />
            <span className={`${styles.specIdentifier} ${styles.ellipsis}`}>
              {node.data.specification.identifier}
            </span>
            <div className={styles.link}>
              <Link to={`/specifications/${node.data.specification.id}`}>
                <DocumentExport size={14} />
              </Link>
            </div>
          </div>
          <span className={`${styles.specName} ${styles.ellipsis}`}>
            {node.data.specification.name}
          </span>
        </div>
      </foreignObject>
    </Group>
  )
}

function SpecTree(props: {
  width: number
  height: number
  treeData: api.SpecificationTreeNode
}) {
  const { width, height, treeData } = props
  const margin = { top: 10, right: 10, bottom: 10, left: 10 }
  const sizeWidth = width - margin.left - margin.right
  const sizeHeight = height - margin.top - margin.bottom
  const origin = {
    x: sizeWidth / 2,
    y: margin.top + nodeSize.height / 2,
  }

  const [isDragging, setIsDragging] = useState(false)
  const { zoomRef, zoomIn, zoomOut } = useZoom({
    width,
    height,
    setIsDragging,
    ignoreScroll: true,
  })

  return (
    <div
      className={`${styles.specTreeContainer} ${
        isDragging ? styles.dragging : ''
      }`}
    >
      <svg ref={zoomRef} width={width} height={height} className={styles.svg}>
        <Tree<api.SpecificationTreeNode>
          root={hierarchy(treeData)}
          size={[sizeWidth, sizeHeight]}
          separation={(a, b) => (a.parent === b.parent ? 1.5 : 1.5)}
          nodeSize={[nodeSize.width, nodeSize.height * 1.4]}
        >
          {(tree) => (
            <Group top={origin.y} left={origin.x}>
              {tree.links().map((link, i) => {
                if (link.source.depth === 0) {
                  return
                }
                return (
                  <LinkVerticalStep
                    key={`link-${i}`}
                    data={link}
                    stroke={'var(--ui-color-gray-mid)'}
                    strokeWidth="1"
                    fill="none"
                  />
                )
              })}
              {tree.descendants().map((node, i) => {
                if (node.depth === 0) {
                  return
                }
                return <Node key={`node-${i}`} node={node} />
              })}
            </Group>
          )}
        </Tree>
      </svg>
      <div className={styles.controls} style={{ cursor: 'auto' }}>
        <button className={styles.btnZoom} onClick={() => zoomIn()}>
          <Add size={20} />
        </button>
        <button className={styles.btnZoom} onClick={() => zoomOut()}>
          <Subtract size={20} />
        </button>
      </div>
    </div>
  )
}

export default function SpecificationTree(props: {
  treeData: api.SpecificationTreeNode | null
  treeDataLoading: {
    state: LoadingState
    message: string | null
    error: number | null
  }
}) {
  const { treeData, treeDataLoading } = props

  return (
    <>
      {treeDataLoading.state === LoadingState.Loading && (
        <div className={styles.loadingContainer}>
          <LoadingIndicator />
        </div>
      )}
      {treeDataLoading.state === LoadingState.Loaded &&
        treeData &&
        treeData.children?.length > 0 && (
          <ParentSize>
            {({ width, height }) => (
              <SpecTree width={width} height={height} treeData={treeData} />
            )}
          </ParentSize>
        )}
      {treeDataLoading.state === LoadingState.Failed && (
        <div className={styles.errorContainer}>{treeDataLoading.message}</div>
      )}
      {treeDataLoading.state === LoadingState.Loaded &&
        (!treeData || treeData?.children?.length === 0) && (
          <div className={styles.errorContainer}>
            The specification tree hasn't been created yet.
          </div>
        )}
    </>
  )
}
