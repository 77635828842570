import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { FetchAllBlockEditor } from './BlockEditor'
import styles from './CollapsableBlockEditor.module.css'
import { useDocumentContext } from '../../context/DocumentContext'
import { useSectionContext } from '../../context/SectionContext'
import IconButton from '../icon-button/IconButton'

const CollapsableBlockEditor = (props: { label?: string }) => {
  const { label = 'Frontmatter' } = props
  const { scrollToBlockId } = useDocumentContext()
  const { blockIds } = useSectionContext()
  const [expanded, setExpanded] = useState(
    scrollToBlockId && blockIds.includes(scrollToBlockId),
  )

  if (blockIds.length === 0) {
    return null
  }

  return (
    <div className={styles.container} data-expanded={expanded}>
      <div className={styles.header}>
        <IconButton onClick={() => setExpanded(!expanded)}>
          <CaretDown className={styles.chevron} size={20} />
        </IconButton>
        <h2 className={styles.label}>{label}</h2>
      </div>
      <AnimateHeight height={expanded ? 'auto' : 0}>
        <FetchAllBlockEditor className={styles.editor} />
      </AnimateHeight>
    </div>
  )
}

export default CollapsableBlockEditor
