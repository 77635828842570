import { ViewFilled, ViewOffFilled } from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useFeatureFlagPayload } from 'posthog-js/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import styles from './LoginPage.module.css'
import Button, { BUTTON_COLORS } from '../../components/button'
import { toastError } from '../../components/toast'
import { useAuth } from '../../context/AuthContext.tsx'
import useDevTools from '../../context/DevToolsContext.tsx'
import { ApiError } from '../../types/errors.ts'

const LoginPage = () => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [mfaCode, setMfaCode] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>()
  const [canSubmit, setCanSubmit] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const [userId, setUserId] = useState<string | null>(null)
  const [isLocked, setIsLocked] = useState<boolean>(false)
  const { state } = useLocation()
  const { isSignedIn, login, verifyMfa, resendMfa, setAuthTokens } = useAuth()
  const navigate = useNavigate()
  const { disableMFA } = useDevTools()
  const [urlParams] = useSearchParams()
  const isSessionTimeout = urlParams.get('sessionTimeout') === 'true'
  const isSessionTimeoutHandled = useRef(false)

  const domainsToBypassMfa = useFeatureFlagPayload('bypass-mfa-domains') || ''
  const emailsToBypassMfa = useFeatureFlagPayload('bypass-mfa-emails') || ''
  const shouldBypassMfa: boolean = useMemo(() => {
    const currentDomain = window.location.hostname
    const allowedDomains =
      typeof domainsToBypassMfa === 'string'
        ? domainsToBypassMfa.split(',').map((domain) => domain.trim())
        : []
    const allowedEmails =
      typeof emailsToBypassMfa === 'string'
        ? emailsToBypassMfa.split(',').map((email) => email.trim())
        : []

    return (
      disableMFA ||
      allowedEmails.includes(email) ||
      allowedDomains.includes(currentDomain)
    )
  }, [email, domainsToBypassMfa, emailsToBypassMfa, disableMFA])

  useEffect(() => {
    setCanSubmit(!!email && !!password && !submitting)
    setErrorMessage(undefined)
  }, [email, password, submitting])

  useEffect(() => {
    if (isSignedIn) {
      navigate(state?.returnUrl || '/')
    }
  }, [isSignedIn, navigate, state?.returnUrl])

  useEffect(() => {
    if (isSessionTimeout && !isSessionTimeoutHandled.current) {
      toastError('Your session has expired', 'Log in again')
      isSessionTimeoutHandled.current = true
    }
  }, [isSessionTimeout])

  const tryLogin = useCallback(async () => {
    if (!email || !password) {
      setErrorMessage('Enter your email and password to proceed.')
      return
    }

    try {
      const headers: Record<string, string> = shouldBypassMfa
        ? { 'X-Bypass-Mfa': 'true' }
        : {}

      const response = await login(email, password, headers)

      // Login endpoint now either returns MFA userId or tokens (in bypass)
      if ('userId' in response) {
        setErrorMessage(undefined)
        setIsLocked(false)
        setUserId(response.userId!)
      } else if ('token' in response) {
        setAuthTokens(response.token!, response.refreshToken!)
        navigate(state?.returnUrl || '/')
      }
    } catch (error) {
      captureException(error)
      if (error instanceof ApiError && error.fetchResponse.status === 401) {
        setErrorMessage(
          'Invalid username or password. Retry with correct credentials.',
        )
      } else if (
        error instanceof ApiError &&
        error.fetchResponse.status === 423
      ) {
        setIsLocked(true)
        setErrorMessage(
          <>
            Your account has been locked for security purposes after multiple
            failed login attempts. Please contact{' '}
            <a
              className={styles.helpLink}
              href="mailto:help@stell-engineering.com"
            >
              help@stell-engineering.com
            </a>{' '}
            for assistance.
          </>,
        )
      } else {
        setErrorMessage('An unexpected issue occurred. Retry at a later time.')
        console.error('Unexpected error at login', error)
      }
      return
    } finally {
      setSubmitting(false)
    }
  }, [
    email,
    password,
    shouldBypassMfa,
    login,
    setAuthTokens,
    navigate,
    state?.returnUrl,
  ])

  const tryVerifyMfa = useCallback(async () => {
    if (!mfaCode) {
      setErrorMessage('Enter the MFA code to proceed.')
      return
    }

    try {
      await verifyMfa(userId!, mfaCode, state?.returnUrl)
    } catch (error) {
      captureException(error)
      if (error instanceof ApiError && error.fetchResponse.status === 401) {
        setErrorMessage(
          'The code you entered is incorrect or is no longer valid. Please request a new code and try again.',
        )
      } else if (
        error instanceof ApiError &&
        error.fetchResponse.status === 423
      ) {
        setIsLocked(true)
        setErrorMessage(
          <>
            Your account has been locked for security purposes after multiple
            failed login attempts. Please contact{' '}
            <a
              className={styles.helpLink}
              href="mailto:help@stell-engineering.com"
            >
              help@stell-engineering.com
            </a>{' '}
            for assistance.
          </>,
        )
      } else {
        setErrorMessage('An unexpected issue occurred. Retry at a later time.')
      }
      return
    } finally {
      setSubmitting(false)
    }
  }, [mfaCode, verifyMfa, userId, state?.returnUrl])

  const handleResendCode = useCallback(async () => {
    try {
      await resendMfa(userId!)
      setErrorMessage('A new MFA code has been sent to your email.')
    } catch (error) {
      captureException(error)
      setErrorMessage('An unexpected issue occurred. Retry at a later time.')
    }
  }, [resendMfa, userId])

  return (
    <div className={styles.loginPage}>
      <div className={styles.header}>
        <img
          className={styles.stellWordLogo}
          src="/images/stell-word-logo-green.png"
          alt="Stell Logo"
        />
        {userId === null && <span className={styles.pageTitle}>Sign in</span>}
      </div>
      {userId === null ? (
        <div className={styles.formContent}>
          <div className={styles.copy}>
            Enter your credentials to get started.
          </div>
          <div className={styles.form}>
            <label className={styles.field}>
              <span className={styles.label}>Email</span>
              <div className={styles.input}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="username"
                />
              </div>
            </label>
            <label className={styles.field}>
              <span className={styles.label}>Password</span>
              <div className={`${styles.input} ${styles.endIconBtn}`}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter' && canSubmit) {
                      await tryLogin()
                    }
                  }}
                  autoComplete="current-password"
                />
                <button
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <ViewFilled /> : <ViewOffFilled />}
                </button>
              </div>
            </label>
            <div className={styles.errorMessage}>
              {errorMessage && <div>{errorMessage}</div>}
            </div>

            <Button
              className={styles.submitButton}
              onClick={tryLogin}
              text={'Sign in'}
              color={BUTTON_COLORS.GREEN_WHITE_TEXT}
              disabled={!canSubmit}
              fontSize="16px"
            />
          </div>
          <Link className={styles.linkToForgotPassword} to={'/forgot-password'}>
            Forgot Password
          </Link>
        </div>
      ) : (
        <div className={styles.formContent}>
          <div className={styles.copy}>
            For security purposes, please enter the code that was just emailed
            to you.
          </div>
          <div className={styles.form}>
            <label className={styles.field}>
              <span className={styles.mfaLabel}>8 digit code</span>
              <div className={styles.input}>
                <input
                  type="text"
                  value={mfaCode}
                  onChange={(e) => setMfaCode(e.target.value)}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter' && canSubmit) {
                      await tryVerifyMfa()
                    }
                  }}
                  disabled={isLocked}
                />
              </div>
            </label>
            <div className={styles.errorMessage}>
              {errorMessage && <div>{errorMessage}</div>}
            </div>

            <Button
              className={styles.submitButton}
              onClick={tryVerifyMfa}
              text={'Verify'}
              color={BUTTON_COLORS.GREEN_WHITE_TEXT}
              disabled={!canSubmit}
              fontSize="16px"
            />
          </div>
          {!isLocked && (
            <Link
              className={styles.resendMfaLink}
              to="#"
              onClick={handleResendCode}
            >
              Resend code
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default LoginPage
