import { HeadingBlockData } from '../../../api/v2/blocks.ts'
import { SharedBlock } from '../../../api/v2/projects.ts'

const SharedHeadingBlock = (props: {
  block: SharedBlock<HeadingBlockData>
}) => {
  const { block } = props
  const { text } = block.data?._data ?? { text: '' }

  return (
    <div
      style={{
        fontSize: '16px',
        color: 'var(--text-color-black)',
        maxWidth: '514px',
      }}
    >
      {text}
    </div>
  )
}

export default SharedHeadingBlock
