import {
  Document,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer'
import { Report } from '../../../api/v2/reports.ts'
import { User } from '../../../api/v2/users.ts'
import { formatTime, monthLongDayYear } from '../../../lib/date.ts'
import { EvidenceType, ReportColumnId } from '../../../types/enums.ts'
import {
  getColumnsFromReport,
  getEvidenceTypeTags,
} from '../../report/ReportTableColumns.tsx'

const pxToPt = (pixels: number, dpi = 300) => {
  return pixels * (72 / dpi)
}

const withDividers = (items: any[] = [], mapper: (item: any) => string) => {
  return (
    <>
      {items.map((item, idx) => (
        <View>
          {idx !== 0 && (
            <View
              style={{
                transform: 'scaleY(.5)',
                borderBottom: '1pt solid #ddddde',
                fontSize: 2,

                marginTop: 2,
                marginBottom: 2,
              }}
            />
          )}
          <Text>{mapper(item)}</Text>
        </View>
      ))}
    </>
  )
}

const DocumentIcon = () => {
  return (
    <div
      style={{
        marginRight: -8,
        transform: 'translate(-10%,0)',
      }}
    >
      <Svg
        preserveAspectRatio="xMidYMid meet"
        fill="currentColor"
        width="8"
        height="8"
        viewBox="0 0 32 32"
        aria-hidden="true"
      >
        <Path d="M25.7,9.3l-7-7C18.5,2.1,18.3,2,18,2H8C6.9,2,6,2.9,6,4v24c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V10C26,9.7,25.9,9.5,25.7,9.3 z M18,4.4l5.6,5.6H18V4.4z M24,28H8V4h8v6c0,1.1,0.9,2,2,2h6V28z"></Path>
        <Path d="M10 22H22V24H10zM10 16H22V18H10z"></Path>
      </Svg>
    </div>
  )
}

const VersionTag = (props: { version: string }) => {
  const { version } = props

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: 9,
        marginLeft: 2,
        padding: '0 2pt',
        borderRadius: 3,
        backgroundColor: '#a9ebd5',
      }}
    >
      <Text style={{ fontSize: 5 }}>{version}</Text>
    </View>
  )
}

const getCell = (colId: string, rowItem: any) => {
  return (
    <>
      {colId === ReportColumnId.RequirementIdentifier && (
        <Text>{`${
          rowItem?.specification?.specificationIdentifier || 'Document Number'
        }-${rowItem?.requirement?.rootRequirementIdentifier || ''}`}</Text>
      )}
      {colId === ReportColumnId.RequirementSectionNumber && (
        <Text>{rowItem?.requirement?.sectionNumber}</Text>
      )}
      {colId === ReportColumnId.RequirementName && (
        <Text>{rowItem?.requirement?.title || 'Untitled'}</Text>
      )}
      {colId === ReportColumnId.RequirementShallStatement && (
        <Text>{rowItem?.requirement?.shallStatement}</Text>
      )}
      {colId === ReportColumnId.RequirementCompliance && (
        <Text>{rowItem?.requirement?.attributes?.COMPLIANCE?.[0]?.name}</Text>
      )}
      {colId === ReportColumnId.RequirementComplianceNotes && (
        <Text>{rowItem?.requirement?.complianceNotes}</Text>
      )}
      {colId === ReportColumnId.RequirementRationale && (
        <Text>{rowItem?.requirement?.rationale}</Text>
      )}
      {colId === ReportColumnId.RequirementType && (
        <Text>{rowItem?.requirement?.attributes?.TYPE?.[0]?.name}</Text>
      )}
      {colId === ReportColumnId.SpecificationName && (
        <Text>{rowItem.specification?.name}</Text>
      )}
      {colId === ReportColumnId.SpecificationIdentifier && (
        <Text>{rowItem?.specification?.specificationIdentifier}</Text>
      )}
      {colId === ReportColumnId.SpecificationRevision && (
        <>
          {rowItem?.revision?.version && (
            <Text>
              {rowItem?.requirement?.externalIdentifier
                ? rowItem?.revision?.version
                : `V${rowItem?.revision?.version}`}
            </Text>
          )}
        </>
      )}
      {colId === ReportColumnId.RequirementStatus && (
        <Text>{rowItem.requirement?.status}</Text>
      )}
      {colId === ReportColumnId.SpecificationProgramName && (
        <Text>{rowItem?.specification?.attributes?.PROGRAM?.[0]?.name}</Text>
      )}
      {colId === ReportColumnId.ParentRequirementName && (
        <>
          {withDividers(
            rowItem['parentRequirement[]'],
            (link) => link.name || 'Untitled',
          )}
        </>
      )}
      {colId === ReportColumnId.ParentRequirementShallStatement && (
        <>
          {withDividers(
            rowItem['parentRequirement[]'],
            (link) => link.shallStatement || 'Shall Statement',
          )}
        </>
      )}
      {colId === ReportColumnId.ChildRequirementName && (
        <>
          {withDividers(
            rowItem['childRequirement[]'],
            (link) => link.name || 'Untitled',
          )}
        </>
      )}
      {colId === ReportColumnId.ChildRequirementShallStatement && (
        <>
          {withDividers(
            rowItem['childRequirement[]'],
            (link) => link.shallStatement || 'Shall Statement',
          )}
        </>
      )}
      {/*childRequirement[]*/}
      {colId === ReportColumnId.EvidenceTypeValidation && (
        <>
          {withDividers(
            getEvidenceTypeTags(rowItem, EvidenceType.Validation),
            (tag) => tag.text,
          )}
        </>
      )}
      {colId === ReportColumnId.ValidationRecordTitle && (
        <>
          {withDividers(
            rowItem['evidence[]'].filter(
              (record) => record.type === EvidenceType.Validation,
            ),
            (record) => record.title,
          )}
        </>
      )}
      {colId === ReportColumnId.ValidationDescriptionOfActivity && (
        <>
          {withDividers(
            rowItem['evidence[]'].filter(
              (record) => record.type === EvidenceType.Validation,
            ),
            (record) => record.description,
          )}
        </>
      )}
      {colId === ReportColumnId.EvidenceTypeVerification && (
        <>
          {withDividers(
            getEvidenceTypeTags(rowItem, EvidenceType.Verification),
            (tag) => tag.text,
          )}
        </>
      )}
      {colId === ReportColumnId.VerificationRecordTitle && (
        <>
          {withDividers(
            rowItem['evidence[]'].filter(
              (record) => record.type === EvidenceType.Verification,
            ),
            (record) => record.title,
          )}
        </>
      )}
      {colId === ReportColumnId.VerificationDescriptionOfActivity && (
        <>
          {withDividers(
            rowItem['evidence[]'].filter(
              (record) => record.type === EvidenceType.Verification,
            ),
            (record) => record.description,
          )}
        </>
      )}
    </>
  )
}
export const ReportPdfDocument = (props: {
  report: Report
  createdBy?: User
  columnWidths: number[]
}) => {
  const { report, createdBy, columnWidths } = props
  const columns = getColumnsFromReport(report.metadata.columnsToDisplay || [])
  const rowItems = report
    ? report.contents.map((content) => ({
        ...content,
        id: content.requirement.id,
      }))
    : []

  const styles = StyleSheet.create({
    header: {
      flexDirection: 'column',
      width: '100%',
      gap: 8,
      paddingBottom: 18,
    },
    overview: {
      flexDirection: 'column',
      maxWidth: 200,
    },
    reportName: {
      fontSize: 12,
      fontWeight: 800,
    },
    specDescription: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 4,
      margin: 8,
    },
    specName: {
      fontSize: 8,
      maxWidth: 135,
      color: '#444444',
    },
    createdBy: {
      flexDirection: 'column',
      gap: 2,
      color: '#848383',
    },
    createdByRow: {
      flexDirection: 'row',
      width: '100%',
      fontSize: 6,
    },
  })

  const tableWidth = pxToPt(
    columnWidths.reduce((prev, width) => prev + width, 0),
  )

  const MAX_TABLE_WIDTH_PT = 762

  const scaleFactor =
    tableWidth > MAX_TABLE_WIDTH_PT ? MAX_TABLE_WIDTH_PT / tableWidth : 1

  return (
    <Document>
      <Page
        size={{
          width: 3508,
          height: 2480,
        }}
        dpi={300}
        style={{
          padding: 40,
        }}
      >
        <View style={styles.header}>
          <View style={styles.overview}>
            <Text style={styles.reportName}>
              {report.name || 'Untitled Report'}
            </Text>
            <View style={styles.specDescription}>
              <DocumentIcon />
              <Text style={styles.specName}>
                {report.contents[0].specification.name ||
                  'Untitled Specification'}
              </Text>
              <VersionTag version={`V${report.contents[0].revision.version}`} />
            </View>
            <View style={styles.createdBy}>
              <View style={styles.createdByRow}>
                <Text>Created By: </Text>
                <Text>{`${createdBy?.firstName} ${createdBy?.lastName}`}</Text>
              </View>
              <View style={styles.createdByRow}>
                <Text>Created: </Text>
                <Text>{monthLongDayYear(report.createdOn)}</Text>
                <Text>{formatTime(report.createdOn)}</Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            transform: `scaleX(${scaleFactor})`,
            transformOrigin: 'top left',
            width: tableWidth,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            {columns.map((col, idx) => {
              return (
                <View
                  key={col.id}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    width: columnWidths[idx],
                    padding: '2pt 4pt',
                    fontSize: 3,

                    backgroundColor: '#fafafa',
                    borderTop: '1px solid #ddddde',
                    borderBottom: '1px solid #ddddde',
                    borderRight: '1px solid #ddddde',
                    ...(idx === 0 ? { borderLeft: '1px solid #ddddde' } : {}),
                  }}
                >
                  <Text
                    style={{
                      color: '#6f6f6f',
                    }}
                  >
                    {col.prettyLabel || col.label}
                  </Text>
                </View>
              )
            })}
          </View>
        </View>
        {rowItems.map((rowItem) => {
          return (
            <View
              style={{
                transform: `scaleX(${scaleFactor})`,
                transformOrigin: 'top left',
                width: tableWidth,
              }}
            >
              <View
                key={rowItem.id}
                style={{
                  flexDirection: 'row',
                }}
              >
                {columns.map((col, colIdx) => {
                  return (
                    <View
                      key={col.id}
                      style={{
                        width: columnWidths[colIdx],
                        padding: 4,
                        fontSize: 3,
                        ...(colIdx === 0
                          ? { borderLeft: '1px solid #ddddde' }
                          : {}),
                        borderBottom: '1px solid #ddddde',
                        borderRight: '1px solid #ddddde',
                      }}
                    >
                      {getCell(col.id, rowItem)}
                    </View>
                  )
                })}
              </View>
            </View>
          )
        })}
      </Page>
    </Document>
  )
}
