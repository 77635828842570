import { captureException } from '@sentry/react'
import * as tokensApi from './v2/tokens.ts'
import { displayDevTools } from '../lib/dev-tools.ts'
import {
  clearAuthToken,
  getAuthToken,
  getAuthTokenExpiryTime,
  getDevToolsUseDevApi,
  getRefreshToken,
  getRefreshTokenExpiryTime,
  setAuthTokens,
} from '../lib/localstorage.ts'
import { navigateTo } from '../lib/navigation.ts'
import { ApiError } from '../types/errors.ts'

enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

enum ResponseType {
  JSON = 'json',
  BLOB = 'blob',
}

// TODO: move this logic to be config-driven
export const getApiUrl = () => {
  let apiBaseUrl = 'https://api-dev.stell-testing.com'

  if (window.location.host === 'localhost:5000') {
    apiBaseUrl =
      displayDevTools() && getDevToolsUseDevApi()
        ? 'https://api-dev.stell-testing.com'
        : 'http://localhost:8000'
  } else if (window.location.host === 'dev.stell-testing.com') {
    apiBaseUrl = 'https://api-dev.stell-testing.com'
  } else if (window.location.host === 'staging.stell-testing.com') {
    apiBaseUrl = 'https://api-staging.stell-testing.com'
  } else if (window.location.host === 'sandbox.stell-testing.com') {
    apiBaseUrl = 'https://api-sandbox.stell-testing.com'
  } else if (window.location.host === 'dashboard.stell-engineering.com') {
    apiBaseUrl = 'https://api.stell-engineering.com'
  }

  return apiBaseUrl
}

const getContentTypeHeader = (method: Method, rawData?: boolean) => {
  switch (method) {
    case Method.PUT:
    case Method.PATCH:
    case Method.POST:
      return rawData ? {} : { 'Content-Type': 'application/json' }

    default:
      return {}
  }
}

interface ApiConfig {
  authenticated?: boolean
  responseType?: ResponseType
  v2?: boolean
  rawData?: boolean
  returnHeaders?: boolean
}

const DEFAULT_CONFIG = {
  authenticated: true,
  responseType: ResponseType.JSON,
  v2: false,
}

const handleAuthFailure = () => {
  clearAuthToken()
  navigateTo('/login?sessionTimeout=true', {
    replace: true,
    state: { returnUrl: location.pathname },
  })
}

const isTokenExpired = (expiryDate: Date) => {
  return expiryDate < new Date()
}

const fetchApi = async (
  method: Method,
  path: string,
  options: any = {},
  configOpt?: ApiConfig,
) => {
  const config = { ...DEFAULT_CONFIG, ...(configOpt || {}) }
  const authToken = getAuthToken()
  const authTokenExpiryTime = getAuthTokenExpiryTime()
  const refreshToken = getRefreshToken()
  const refreshTokenExpiryTime = getRefreshTokenExpiryTime()

  if (config.authenticated) {
    // Auth token is stale; user needs to log in manually
    if (
      !authToken ||
      !authTokenExpiryTime ||
      isTokenExpired(authTokenExpiryTime)
    ) {
      handleAuthFailure()
      return
    }

    // Refresh token is stale; user needs to log in manually
    if (
      !refreshToken ||
      !refreshTokenExpiryTime ||
      isTokenExpired(refreshTokenExpiryTime)
    ) {
      handleAuthFailure()
      return
    }

    const { skipRefresh = false } = options
    const REFRESH_WINDOW = 10 * 60 * 1000
    const timeLeft = authTokenExpiryTime.getTime() - Date.now()

    // Refresh auth token if close to expiration
    if (!skipRefresh && timeLeft <= REFRESH_WINDOW) {
      try {
        const res = await tokensApi.refreshToken(refreshToken)
        const newAuthToken = JSON.stringify(res.accessToken || null)
        const newRefreshToken = JSON.stringify(res.refreshToken || null)

        if (newAuthToken !== authToken || newRefreshToken !== refreshToken) {
          setAuthTokens(newAuthToken, newRefreshToken)
        }
      } catch (error) {
        console.error('Unable to refresh token', error)
        captureException(error)
        handleAuthFailure()
        return
      }
    }
  }

  const { body: bodyOption, headers: headersOption = {}, ...rest } = options

  const headers = {
    ...getContentTypeHeader(method, config.rawData),
    ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    ...headersOption,
  }
  let body = bodyOption
  if (body && !config.rawData && !(typeof body === 'string')) {
    body = JSON.stringify(body)
  }

  const res = await fetch(`${getApiUrl()}${path}`, {
    method,
    headers,
    ...(body ? { body } : {}),
    ...rest,
  })

  if (!res.ok) {
    throw new ApiError('API request responded with error', res)
  }

  // Handle null response body
  const contentLength = res.headers.get('content-length')
  const contentType = res.headers.get('content-type')
  if (contentLength === '0' || !contentType) {
    return null
  }

  if (typeof res[config.responseType] === 'function') {
    const data = await res[config.responseType]()

    if (config.returnHeaders) {
      return { data, headers: res.headers }
    }
    return data
  } else {
    throw new Error(`Response type '${config.responseType}' is not a function`)
  }
}

const get = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.GET, path, options, { v2 })

const getBlob = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.GET, path, options, { responseType: ResponseType.BLOB, v2 })

const getBlobWithHeaders = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.GET, path, options, {
    responseType: ResponseType.BLOB,
    returnHeaders: true,
    v2,
  })

const post = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.POST, path, options, { v2 })

const postRaw = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.POST, path, options, { v2, rawData: true })

const put = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.PUT, path, options, { v2 })

const putRaw = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.PUT, path, options, { v2, rawData: true })

const patch = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.PATCH, path, options, { v2 })

const deleteRequest = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.DELETE, path, options, { v2 })

const getUnauthenticated = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.GET, path, options, { authenticated: false, v2 })

const postUnauthenticated = (path: string, options?: any, v2?: boolean) =>
  fetchApi(Method.POST, path, options, { authenticated: false, v2 })

type ApiMethod = (path: string, options?: any, v2?: boolean) => any

export type ApiMethods = {
  get: ApiMethod
  getBlob: ApiMethod
  post: ApiMethod
  postRaw: ApiMethod
  put: ApiMethod
  putRaw: ApiMethod
  patch: ApiMethod
  delete: ApiMethod
  getUnauthenticated: ApiMethod
  postUnauthenticated: ApiMethod
}

export default {
  get,
  getBlob,
  getBlobWithHeaders,
  post,
  postRaw,
  put,
  putRaw,
  patch,
  delete: deleteRequest,
  getUnauthenticated,
  postUnauthenticated,
}
