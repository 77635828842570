const StellLogo = (props) => {
  const { width = 40 } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 40 36"
      fill="none"
    >
      <path
        d="M31.3911 0.5V4.70627H26.4686C22.7001 4.70627 19.095 6.2012 16.5776 8.80781C14.2922 11.1742 12.96 14.2852 12.8264 17.5677L12.8149 17.8499C12.5878 17.8326 12.3565 17.8241 12.1215 17.8241H8.61121C8.64902 15.5521 9.11399 13.3468 9.99483 11.2646C10.8292 9.29203 12.0038 7.50545 13.4863 5.9544L14.3575 5.04302L13.1679 5.46055C11.269 6.127 9.57543 7.19571 8.13427 8.63686C5.67091 11.1002 4.28306 14.3526 4.20698 17.8241H0C0.0378076 15.5524 0.502771 13.3468 1.38362 11.2646C2.27151 9.16546 3.54217 7.28072 5.16015 5.6625C6.77837 4.04429 8.66311 2.77362 10.7623 1.88597C12.9368 0.966372 15.2452 0.5 17.6238 0.5H31.3911M20.0009 11.1117C20.4274 14.057 22.4911 16.5746 25.356 17.5364C25.8151 17.6905 26.3078 17.7987 26.8291 17.86C23.2439 18.3398 20.4964 21.1181 19.9998 24.6243C19.5966 21.8143 17.7214 19.4073 15.0357 18.3452C14.4512 18.1141 13.82 17.9591 13.15 17.8816L13.1688 17.8791C16.7561 17.4002 19.5054 14.6199 20.0012 11.1113M27.1851 17.8941C27.4133 17.9112 27.6465 17.9199 27.8842 17.9199H31.3888C31.351 20.1917 30.886 22.3972 30.0052 24.4794C29.1708 26.4518 27.9962 28.2386 26.5137 29.7896L25.6425 30.701L26.8321 30.2835C28.731 29.617 30.4246 28.5483 31.8657 27.1072C34.3291 24.6438 35.7169 21.3914 35.793 17.9199H40C39.9622 20.1919 39.4972 22.3972 38.6164 24.4794C37.7285 26.5786 36.4578 28.4633 34.8398 30.0815C33.2216 31.6997 31.3369 32.9704 29.2377 33.8581C27.0632 34.7777 24.7548 35.244 22.3762 35.244H8.60887V31.038H13.5516C17.1788 31.038 20.5584 29.707 23.0683 27.2899C25.576 24.8749 27.0339 21.6366 27.1736 18.1712L27.1849 17.8941"
        fill="#10CB81"
      />
    </svg>
  )
}

export default StellLogo
