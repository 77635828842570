import { Time, Document, ScatterMatrix } from '@carbon/icons-react'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import styles from './PublicSpecificationPage.module.css'
import Tag, { TAG_COLORS } from '../../components/tag'
import { useSpecificationContext } from '../../context/SpecificationContext'
import { useModals } from '../../hooks/useModals'
import { monthDayYear } from '../../lib/date'
import specPageStyles from '../specification/SpecificationPage.module.css'

const PublicSpecificationPage = () => {
  const { specification, document, revision } = useSpecificationContext()

  const { pathname } = useLocation()

  const { openSpecificationPdfViewerModal } = useModals()

  const viewPdf = () => {
    if (document) {
      openSpecificationPdfViewerModal({
        specificationId: specification.id,
        assetId: document.sourceAssetId!,
        uploadedOn: document.createdOn,
      })
    }
  }

  // Error this page if standards portal is disabled
  const enableStandardsPortal = useFeatureFlagEnabled('standards-portal')
  if (enableStandardsPortal === false) {
    throw new Error()
  }

  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.leftHeader}>
          <span className={styles.title}>{specification.name}</span>
          <Tag text={`${revision.version}`} />
        </div>
        <div className={styles.rightHeader}>
          <div className={styles.info}>
            <Tag text="STANDARD" color={TAG_COLORS.blue2} />
            <div className={styles.addedDate}>
              <Time size={16} />
              Added {monthDayYear(revision.createdOn)}
            </div>
          </div>
          {document?.sourceAssetId && (
            <button className={styles.viewPdf} onClick={viewPdf}>
              View original
            </button>
          )}
        </div>
      </div>
      <div className={specPageStyles.tabs}>
        <div className={specPageStyles.leftTabs}>
          <Link to={`./document`} draggable="false">
            <div
              className={`${specPageStyles.tab} ${
                pathname.includes('document') ? specPageStyles.active : ''
              }`}
            >
              <Document size={24} />
              Document
            </div>
          </Link>
          <Link to={`./matrix`} draggable="false">
            <div
              className={`${specPageStyles.tab} ${
                pathname.includes('matrix') ? specPageStyles.active : ''
              }`}
            >
              <ScatterMatrix size={24} />
              Matrix
            </div>
          </Link>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default PublicSpecificationPage
