import { CaretDown } from '@carbon/icons-react'
import { useState } from 'react'
import styles from './EvidenceMethodSelect.module.css'
import { AttributeValueResponse } from '../../api/v2/attributes'
import { useAttributesContext } from '../../context/AttributesContext'
import useClickOutside from '../../hooks/useClickOutside'
import { AttributeValueStatus } from '../../types/enums'
import Tag from '../tag'

interface EvidenceMethodSelectProps {
  value: string
  onSelect: (methodId: string) => void
}

const getTagColors = (method: AttributeValueResponse) => ({
  fontColor: method.metadata.STYLES.COLOR_FONT,
  backgroundColor: method.metadata.STYLES.COLOR_BG,
  hoverBackgroundColor: method.metadata.STYLES.COLOR_BG_HOVER,
})

const EvidenceMethodSelect = (props: EvidenceMethodSelectProps) => {
  const { value, onSelect: onSelectProp } = props

  const { evidenceMethods } = useAttributesContext()
  const [open, setOpen] = useState(false)
  const selectedEvidence = evidenceMethods.find((e) => e.id === value)
  const ref = useClickOutside(() => setOpen(false), open)

  const onSelect = (id) => {
    onSelectProp(id)
    setOpen(false)
  }

  return (
    <div ref={ref} className={styles.root}>
      <button
        className={`${styles.select} ${styles.basicElevation}`}
        onClick={(event) => {
          event.preventDefault()
          setOpen(!open)
        }}
      >
        {selectedEvidence ? (
          <Tag
            text={selectedEvidence?.name || 'Method'}
            color={getTagColors(selectedEvidence!)}
          />
        ) : (
          <span>Select method</span>
        )}
        <CaretDown size={20} />
      </button>
      <ul className={`${open ? styles.open : ''} ${styles.basicElevation}`}>
        {evidenceMethods
          .filter((method) => method.status === AttributeValueStatus.Active)
          .map((e) => (
            <li key={e.id} data-current={e.id === selectedEvidence?.id}>
              <button
                onClick={(event) => {
                  event.preventDefault()
                  onSelect(e.id)
                }}
              >
                <Tag text={e.name} color={getTagColors(e)} />
              </button>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default EvidenceMethodSelect
