import { captureException } from '@sentry/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './RequirementDetails.module.css'
import { AttributeValueResponse } from '../../../api/v2/attributes.ts'
import { Requirement, getRequirement } from '../../../api/v2/requirements.ts'
import Chip from '../../../components/chip'
import QuillContent from '../../../components/quill-content/QuillContent.tsx'
import Tag from '../../../components/tag/index.tsx'
import { extractShallStatementDelta } from '../../../lib/requirement.ts'

const RequirementDetails = (props: {
  specificationId: string
  revisionId: string
  requirementId: string
  requirementTypes: Array<AttributeValueResponse>
}) => {
  const { specificationId, revisionId, requirementId, requirementTypes } = props
  const [requirement, setRequirement] = useState<Requirement>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requirementResponse = await getRequirement(
          specificationId,
          requirementId,
        )
        setRequirement(requirementResponse)
      } catch (error) {
        console.error('Unable to get requirement to display.')
        captureException(error)
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [requirementId, revisionId, specificationId])

  if (loading) {
    return <div className={styles.loading} data-testid="loading"></div>
  }

  if (error || !requirement) {
    return (
      <div className={styles.error}>
        Unable to load requirement details at this time.
      </div>
    )
  }

  const shallStatementDelta = extractShallStatementDelta(requirement)
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {requirement.sectionNumber && (
          <span className={styles.identifier}>{requirement.sectionNumber}</span>
        )}
        <span className={styles.title}>{requirement.title || 'Untitled'}</span>
        <div>
          <Chip variant="status" value={requirement.status.toUpperCase()} />
        </div>
      </div>
      <div className={styles.typeTags}>
        {requirementTypes?.length > 0 &&
          requirementTypes.map((tag) => (
            <Tag
              key={tag.id}
              text={tag.name.toUpperCase()}
              color={{
                fontColor: tag.metadata.STYLES.COLOR_FONT,
                backgroundColor: tag.metadata.STYLES.COLOR_BG,
              }}
            />
          ))}
      </div>
      <div className={styles.shallStatement}>
        {shallStatementDelta && (
          <QuillContent
            readOnly
            placeholder="Untitled"
            delta={shallStatementDelta}
          />
        )}
      </div>
      <div>
        <Link
          className={styles.linkViewRequirement}
          to={`/specifications/${specificationId}/matrix/${requirementId}`}
          target="_blank"
        >
          View requirement in specification
        </Link>
      </div>
    </div>
  )
}

export default RequirementDetails
