import { RowDelete } from '@carbon/icons-react'
import styles from './BlockActions.module.css'
import { BlockActionItemProps } from './types'
import { BlockType } from '../../../api/v2/blocks'
import { useSectionContext } from '../../../context/SectionContext'
import { useSpecificationContext } from '../../../context/SpecificationContext'

const DeleteBlockAction = (props: BlockActionItemProps) => {
  const { block, requirement } = props
  const { blockIds, deleteBlock } = useSectionContext()
  const { isDeleteDisabled, contentIsEditable } = useSpecificationContext()
  const isOnlyBlockAndText =
    block.type === BlockType.Text && blockIds.length === 1

  return (
    <>
      {contentIsEditable &&
        !isDeleteDisabled(
          block.id,
          requirement?.context.requirementVersionCount,
        ) &&
        !isOnlyBlockAndText && (
          <button
            className={`${styles.actionItem} ${styles.actionDelete}`}
            onClick={() => deleteBlock(block?.id)}
          >
            <RowDelete />
            Delete block
          </button>
        )}
    </>
  )
}

export default DeleteBlockAction
