import { Close, Locked } from '@carbon/icons-react'
import styles from './Chip.module.css'
import { RevisionStatus } from '../../api/v2/revisions.ts'
import { getCssVar } from '../../lib/string.ts'
import { RequirementStatus } from '../../types/enums.ts'

const TEXT = {
  status: {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED',
    REVIEW: 'IN REVIEW',
    SUPERSEDED: 'SUPERSEDED',
  },
}

export const LOCKED_STATES = [
  RequirementStatus.Active,
  RequirementStatus.Review,
  RevisionStatus.SUPERSEDED,
]

export const CHIP_COLORS = {
  [RequirementStatus.Draft]: {
    backgroundColor: getCssVar('--color-draft-bg'),
    color: getCssVar('--color-draft-text'),
  },
  [RequirementStatus.Active]: {
    backgroundColor: getCssVar('--color-active-bg'),
    color: getCssVar('--color-active-text'),
  },
  [RequirementStatus.Review]: {
    backgroundColor: getCssVar('--color-review-bg'),
    color: getCssVar('--color-review-text'),
  },
  [RequirementStatus.Archived]: {
    backgroundColor: getCssVar('--color-archived-bg'),
    color: getCssVar('--color-archived-text'),
  },
  [RevisionStatus.SUPERSEDED]: {
    backgroundColor: getCssVar('--color-archived-bg'),
    color: getCssVar('--color-archived-text'),
  },
}

const Chip = (props) => {
  const {
    className,
    variant = 'status',
    value,
    onRemove,
    enableLockIcon,
  } = props
  return (
    <div
      className={`  ${className || ''} ${styles.chip} `}
      style={{ ...(value ? { ...CHIP_COLORS[value] } : {}) }}
    >
      {enableLockIcon && LOCKED_STATES.includes(value) && <Locked size={12} />}
      {TEXT[variant][value]}
      {onRemove && (
        <button onClick={onRemove}>
          <Close size={16} />
        </button>
      )}
    </div>
  )
}

export default Chip
