import { Close } from '@carbon/icons-react'
import { ReactNode, RefObject, useCallback, useEffect } from 'react'
import styles from './index.module.css'
import useClickOutside from '../../hooks/useClickOutside.ts'

interface DrawerProps {
  className?: string
  onClose: () => void
  children: ReactNode
  innerRef?: RefObject<HTMLDivElement>
  size?: 'large'
}

const Drawer = (props: DrawerProps) => {
  const { className, onClose, children, size } = props
  const ref = useClickOutside(() => {
    onClose()
  })

  const onEsc = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false)

    return () => {
      document.removeEventListener('keydown', onEsc, false)
    }
  }, [onEsc])

  return (
    <div
      className={`${styles.container} ${className || ''} ${
        size === 'large' ? styles.large : ''
      }`}
      ref={ref}
    >
      <div className={styles.header}>
        <button onClick={() => onClose()}>
          <Close />
        </button>
      </div>
      {children}
    </div>
  )
}

export default Drawer
