import Tag from '.'
import { getCssVar } from '../../lib/string.ts'
import { EvidenceActivityType } from '../../types/enums'

export const EVIDENCE_ACTIVITY_TAG_COLORS = {
  [EvidenceActivityType.Meeting]: {
    backgroundColor: getCssVar('--color-green'),
    fontColor: '#29ce00',
    hoverBackgroundColor: '#d5ffb3',
  },
  [EvidenceActivityType.Document]: {
    backgroundColor: getCssVar('--color-blue2'),
    fontColor: '#0090ce',
    hoverBackgroundColor: '#b3e8ff',
  },
  [EvidenceActivityType.Review]: {
    backgroundColor: getCssVar('--color-red'),
    fontColor: '#ce0094',
    hoverBackgroundColor: '#ffb3e0',
  },
  [EvidenceActivityType.Test]: {
    backgroundColor: '#ebe6fe',
    fontColor: '#9d84fa',
    hoverBackgroundColor: '#c4b5fc',
  },
}

const EvidenceActivityTypeTag = (props: {
  type: EvidenceActivityType
  onCancel?: () => void
  hoverable?: boolean
}) => {
  const { type, onCancel, hoverable } = props
  return (
    <Tag
      text={type}
      color={
        hoverable
          ? EVIDENCE_ACTIVITY_TAG_COLORS[type]
          : {
              ...EVIDENCE_ACTIVITY_TAG_COLORS[type],
              hoverBackgroundColor: undefined,
            }
      }
      onCancel={onCancel}
    />
  )
}

export default EvidenceActivityTypeTag
