const MIN_PASSWORD_LENGTH = 10

/**
 * Password Requirements:
 *  at least 1 uppercase character (A-Z)
 *  at least 1 lowercase character (a-z)
 *  at least 1 digit (0-9)
 *  at least 1 special character (punctuation) — do not forget to treat space as special characters too
 *  at least 10 characters
 */
const passwordRules: {
  isValid: (password: string) => boolean
  errorMessage: string
}[] = [
  {
    isValid: (password) => password.search(/[A-Z]/) >= 0,
    errorMessage: 'Password must have at least 1 uppercase character',
  },
  {
    isValid: (password) => password.search(/[a-z]/) >= 0,
    errorMessage: 'Password must have at least 1 lowercase character',
  },
  {
    isValid: (password) => password.search(/[0-9]/) >= 0,
    errorMessage: 'Password must have at least 1 digit',
  },
  {
    isValid: (password) => password.search(/[^a-zA-Z0-9]/) >= 0,
    errorMessage: 'Password must have at least 1 special character',
  },
  {
    isValid: (password) => password.length >= 10,
    errorMessage: `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
  },
]

export const validatePassword: (password: string) => {
  valid: boolean
  errorMessages?: string[]
} = (password) => {
  const errorMessages = passwordRules
    .filter((rule) => !rule.isValid(password))
    .map((rule) => rule.errorMessage)

  return {
    valid: errorMessages.length === 0,
    errorMessages: errorMessages.length > 0 ? errorMessages : undefined,
  }
}
