/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as QuillNamespace from 'quill'
import {
  CSSProperties,
  Fragment,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import '../../assets/css/quill.bubble.css'
import './override-quill-content.css'
import { renderToString } from 'react-dom/server'
import ReferenceSearchModal from './ReferenceSearchModal.tsx'
import {
  Block,
  BlockData,
  BlockType,
  ImageBlockData,
  TableBlockData,
  createBlock,
  deleteBlock,
  updateBlock,
} from '../../api/v2/blocks.ts'
import { reorderSectionElements } from '../../api/v2/documents.ts'
import { SharedBlock } from '../../api/v2/projects.ts'
import * as requirementApi from '../../api/v2/requirements.ts'
import { useSectionContext } from '../../context/SectionContext.tsx'
import { useSpecificationContext } from '../../context/SpecificationContext.tsx'
import debounce from '../../lib/debounce.ts'
import { sanitizeAndValidateInput } from '../../lib/input-validation.ts'
import { EMPTY_DELTA, deltaObjectToPlainText } from '../../lib/string.ts'
import { toastError } from '../toast'

const Quill: any = QuillNamespace.default || QuillNamespace
const Embed = Quill.import('blots/embed')
const Parchment = Quill.import('parchment')
const ToolbarIcons = Quill.import('ui/icons')

const Clipboard = Quill.import('modules/clipboard')
const Delta = Quill.import('delta')

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault()
    const range = this.quill.getSelection()
    const text = e.clipboardData.getData('text/plain')
    const sanitizedText = sanitizeAndValidateInput(text, {
      maxLength: 3000,
      removeHtml: false,
      encodeSpecialChars: false,
    })
    if (!sanitizedText) {
      toastError('Sorry, that content cannot be pasted into Stell', '')
    } else {
      const delta = new Delta()
        .retain(range.index)
        .delete(range.length)
        .insert(sanitizedText)
      const index = text.length + range.index
      const length = 0
      this.quill.updateContents(delta, 'silent')
      this.quill.setSelection(index, length, 'silent')
      this.quill.scrollIntoView()
    }
  }
}

Quill.register('modules/clipboard', PlainClipboard, true)

export const stripWhitespaceAndBom = (str: string | null | undefined) =>
  (str || '')
    .replace(/[\n\r]/g, '')
    .replace(/[\n\r]/g, '')
    .replace(/\uFEFF/g, '')

export enum ReferenceTypes {
  Requirement = 'requirement',
  Specification = 'specification',
  Block = 'block',
}

interface SpecificationReferenceData {
  specificationId: string
}

interface RequirementReferenceData {
  contextId: string
  specificationId: string
  requirementId: string
}

interface BlockReferenceData {
  contextId: string
  specificationId: string
  blockId: string
}

interface ReferenceData {
  referenceType: ReferenceTypes
  data:
    | SpecificationReferenceData
    | RequirementReferenceData
    | BlockReferenceData
  name: string
}

const DATA_VALUE_ATTRIBUTE = 'data-value'
const REFERENCE_CLASSNAME_IDENTIFIER =
  'quill_embedded-reference_unique_classname'

class QuillReference extends Embed {
  static blotName = 'reference'
  static tagName = 'span'
  static anchorStyle = {
    color: '#0639EF',
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  } as CSSProperties
  static create(value: ReferenceData) {
    const node = super.create(value)
    node.setAttribute(DATA_VALUE_ATTRIBUTE, JSON.stringify(value))
    const { referenceType, data, name } = value
    let innerHtml = ''

    switch (referenceType) {
      case ReferenceTypes.Specification: {
        const { specificationId } = data as SpecificationReferenceData
        innerHtml = renderToString(
          <a
            className={REFERENCE_CLASSNAME_IDENTIFIER}
            style={QuillReference.anchorStyle}
            href={`/specifications/${specificationId}/document/`}
          >
            {name || 'Untitled specification reference'}
          </a>,
        )
        break
      }
      case ReferenceTypes.Requirement: {
        const { specificationId, requirementId } =
          data as RequirementReferenceData
        innerHtml = renderToString(
          <a
            className={REFERENCE_CLASSNAME_IDENTIFIER}
            style={QuillReference.anchorStyle}
            href={`/specifications/${specificationId}/document/${requirementId}`}
          >
            {name || 'Untitled requirement reference'}
          </a>,
        )
        break
      }

      case ReferenceTypes.Block: {
        const { specificationId, blockId } = data as BlockReferenceData
        innerHtml = renderToString(
          <a
            className={REFERENCE_CLASSNAME_IDENTIFIER}
            style={QuillReference.anchorStyle}
            href={`/specifications/${specificationId}/document/?blockId=${blockId}`}
          >
            {name || 'Untitled block reference'}
          </a>,
        )
        break
      }
    }

    node.innerHTML = innerHtml
    return node
  }

  static value(domNode) {
    return JSON.parse(domNode.getAttribute(DATA_VALUE_ATTRIBUTE))
  }
}

const CONVERT_TO_REQUIREMENT_ATTRIBUTOR_NAME = 'convertToRequirement'
const ConvertToRequirementAttributor = new Parchment.Attributor.Class(
  CONVERT_TO_REQUIREMENT_ATTRIBUTOR_NAME,
  'ql-convert-to-requirement',
  {
    scope: Parchment.Scope.BLOCK,
  },
)

ToolbarIcons[CONVERT_TO_REQUIREMENT_ATTRIBUTOR_NAME] =
  `<div>Convert To Requirement</div>`

Quill.register({
  'formats/reference': QuillReference,
  'formats/convertToRequirement': ConvertToRequirementAttributor,
})

const QuillContent = (props: {
  className?: string
  style?: any
  focusOnLoad?: boolean
  delta?: string | null
  readOnly?: boolean
  onEnter?: (
    quillRef?: RefObject<QuillNamespace.Quill | null>,
  ) => Promise<void> | void
  onCtrlEnter?: (
    quillRef?: RefObject<QuillNamespace.Quill | null>,
  ) => Promise<void> | void
  onBackspaceAtStart?: (
    quillRef?: RefObject<QuillNamespace.Quill | null>,
  ) => void
  onFocus?: (delta: any) => void
  onBlur?: (delta: string, str: string) => void
  onValueChange?: (delta: string, str: string) => void
  placeholder?: string
  block?: Block<BlockData> | SharedBlock<BlockData>
  modalPortal?: any
  modalStyle?: CSSProperties
  instanceHandler?: (quill?: QuillNamespace.Quill) => void
}) => {
  const {
    className,
    style,
    focusOnLoad,
    delta,
    readOnly,
    onEnter,
    onCtrlEnter,
    onBackspaceAtStart,
    onFocus,
    onValueChange,
    onBlur,
    placeholder = '',
    block,
    modalPortal: ModalWrapper = Fragment,
    modalStyle,
    instanceHandler,
  } = props

  const { setRequirementIds, specification, document } =
    useSpecificationContext()
  const { blockIds, setBlockIds } = useSectionContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const quillRef = useRef<HTMLDivElement | null>(null)
  const quill = useRef<QuillNamespace.Quill | null>(null)

  useEffect(() => {
    const backspaceHandler = (range) => {
      if (range.index === 0 && onBackspaceAtStart) {
        onBackspaceAtStart(quill)
        return false
      }

      /**
       * Quill has problems with cursor position + highlighting position when it comes to embeds
       * If a quill input contains only an embed, its position and selection length is incorrect, quill does not believe anything is there.
       * This line checks if quill thinks its empty, then checks the quill root to see if it actually contains embeds
       * If so try to delete the embed for the user. This problem only happens in Chrome.
       */

      // @ts-expect-error quill.current will not be null when handler is called
      if (range.index === 0 || quill.current.getLength() <= 1) {
        if (
          // @ts-expect-error quill.current will not be null when handler is called
          quill.current.root.getElementsByClassName(
            REFERENCE_CLASSNAME_IDENTIFIER,
          ).length > 0
        ) {
          quill.current?.deleteText?.(0, 1)
        }
      }

      // Return true ensures backspace event continues bubbling up to quills default backspace behavior
      return true
    }
    if (quillRef.current && quill.current === null) {
      const bindings = {
        enter: {
          key: 13,
          handler: () => false, // No quill handling
        },
        backSlash: {
          key: 220,
          handler: () => {
            setIsModalOpen(true)
          },
        },
        customBackspace: {
          key: 'backspace',
          handler: backspaceHandler,
        },
        'list autofill': {
          key: 'period',
          handler: () => true, // Prevent default autolist behavior
        },
      }

      const toolbarOptions =
        block && block.type === BlockType.Text
          ? [[CONVERT_TO_REQUIREMENT_ATTRIBUTOR_NAME]]
          : false

      quill.current = new Quill(quillRef.current, {
        theme: 'bubble',
        readOnly: !!readOnly,
        modules: {
          toolbar: toolbarOptions,
          keyboard: { bindings },
        },
        bounds: '.quillBounds',
        placeholder: placeholder,
      })

      if (quill.current === null) {
        console.error(new Error('Quill reference expected'))
        return
      }

      if (instanceHandler) {
        instanceHandler(quill.current)
      }

      // @ts-expect-error - Null check being performed above, this is a non issue
      // Fall back on default browser behavior for tabbing
      quill.current.keyboard.bindings[9] = null

      try {
        if (delta) {
          quill.current.setContents(JSON.parse(delta), 'api')
        }
      } catch (error) {
        console.warn('Quill delta expected. Rendering plaintext instead.')
        quill.current.insertText(0, delta || '', 'api')
      }
    }
    if (quill.current) {
      // Live update backspace
      // @ts-expect-error This property exists
      quill.current.keyboard.bindings[8][1].handler = backspaceHandler

      // Live update placeholder
      quill.current.root.dataset.placeholder = placeholder
      if (readOnly) {
        quill.current.disable()
      } else {
        quill.current.enable()
      }

      if (block && block.type === BlockType.Text) {
        // Dynamically add a handler to attributor so we can access functions that are only available at render time
        quill.current
          .getModule('toolbar')
          .addHandler(CONVERT_TO_REQUIREMENT_ATTRIBUTOR_NAME, async () => {
            if (!quill.current) {
              return
            }

            const range = quill.current.getSelection(false)
            if (!range) {
              return
            }

            // Use getContents on the range to safely convert embeds to text by reading them in as a delta
            const selectedDelta = quill.current.getContents(
              range.index,
              range.length,
            )
            const selectedText = deltaObjectToPlainText(selectedDelta)

            // If there is more content after the selection range, convert that content to a new text block
            let afterSelectionDelta: any = null
            if (range.index + range.length + 1 !== quill.current.getLength()) {
              afterSelectionDelta = quill.current.getContents(
                range.index + range.length,
              )
            }

            const newReq = await requirementApi.createRequirement(
              specification.id,
              document!.sections[0],
              {
                shallStatement: selectedText,
                data: {
                  delta: {
                    shallStatement: JSON.stringify(selectedDelta),
                    rationale: EMPTY_DELTA,
                  },
                },
              },
            )
            setRequirementIds((rIds) => new Set(rIds.add(newReq.id)))

            let newTextBlock
            if (afterSelectionDelta) {
              newTextBlock = await createBlock(
                specification.id,
                document!.id,
                document!.sections[0],
                {
                  type: BlockType.Text,
                  data: {
                    _data: { quillDelta: JSON.stringify(afterSelectionDelta) },
                  },
                },
              )
            }

            const order = [...blockIds]
            order.splice(order.indexOf(block?.id) + 1, 0, newReq.id)

            if (newTextBlock) {
              order.splice(order.indexOf(newReq.id) + 1, 0, newTextBlock.id)
            }
            await reorderSectionElements(
              specification.id,
              document!.id,
              document!.sections[0],
              [...order],
            )

            quill.current.deleteText(range?.index, quill.current.getLength())
            if (quill.current.getLength() === 1) {
              await deleteBlock(specification.id, document!.id, block?.id)
              setBlockIds([...order.filter((id) => id !== block?.id)])
            } else {
              setBlockIds([...order])
              await updateBlock(
                specification.id,
                document!.id,
                document!.sections[0],
                block?.id,
                {
                  _data: {
                    quillDelta: JSON.stringify(quill.current.getContents()),
                  },
                },
              )
            }

            // @ts-expect-error - The types returned from @types/quill do not align with the types shipped in quill dist
            // Quill does not always close the tooltip, so we do it manually
            quill?.current?.theme?.tooltip?.hide?.()
          })
      }
    }
  }, [
    readOnly,
    delta,
    placeholder,
    block,
    specification.id,
    document,
    blockIds,
    setBlockIds,
    setRequirementIds,
    onBackspaceAtStart,
    instanceHandler,
  ])

  useEffect(() => {
    if (focusOnLoad && quill.current) {
      quill.current.focus()
    }
  }, [focusOnLoad])

  return (
    <div style={{ width: '100%', ...(style || {}) }}>
      {isModalOpen && (
        <ModalWrapper>
          <ReferenceSearchModal
            style={modalStyle}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            onRequirementClick={(result) => {
              if (!quill.current) {
                return
              }

              const selection = quill.current.getSelection(true)
              quill.current.insertEmbed(selection.index, 'reference', {
                referenceType: ReferenceTypes.Requirement,
                data: {
                  contextId: result.requirement.contextId,
                  specificationId: result.specification.id,
                  requirementId: result.requirement.id,
                } as RequirementReferenceData,
                name: result.requirement.title,
              } as ReferenceData)
              quill.current.setSelection(
                selection.index + selection.length + 1,
                0,
              )
              setIsModalOpen(false)
              onValueChange?.(
                JSON.stringify(quill.current.getContents()),
                stripWhitespaceAndBom(quill.current.root.textContent),
              )
            }}
            onSpecificationClick={(result) => {
              if (!quill.current) {
                return
              }
              const selection = quill.current.getSelection(true)
              quill.current.insertEmbed(selection.index, 'reference', {
                referenceType: ReferenceTypes.Specification,
                data: {
                  specificationId: result.id,
                } as SpecificationReferenceData,
                name: result.name,
              } as ReferenceData)
              quill.current.setSelection(
                selection.index + selection.length + 1,
                0,
              )
              setIsModalOpen(false)
              onValueChange?.(
                JSON.stringify(quill.current.getContents()),
                stripWhitespaceAndBom(quill.current.root.textContent),
              )
            }}
            onBlockClick={(result) => {
              if (!quill.current) {
                return
              }

              const selection = quill.current.getSelection(true)
              quill.current.insertEmbed(selection.index, 'reference', {
                referenceType: ReferenceTypes.Block,
                data: {
                  contextId: result.contextId,
                  specificationId: result.specification.id,
                  blockId: result.id,
                } as BlockReferenceData,
                name: ((result as any).data as ImageBlockData | TableBlockData)
                  .name,
              } as ReferenceData)
              quill.current.setSelection(
                selection.index + selection.length + 1,
                0,
              )
              setIsModalOpen(false)
              onValueChange?.(
                JSON.stringify(quill.current.getContents()),
                stripWhitespaceAndBom(quill.current.root.textContent),
              )
            }}
          />
        </ModalWrapper>
      )}
      <div
        className={`override-quill-content ${className || ''}`}
        ref={quillRef}
        onFocus={() => {
          if (typeof onFocus === 'function' && quill.current) {
            onFocus(quill.current.root.textContent)
          }
        }}
        onKeyUp={debounce(() => {
          if (typeof onValueChange === 'function' && quill.current) {
            onValueChange(
              JSON.stringify(quill.current.getContents()),
              stripWhitespaceAndBom(quill.current.root.textContent),
            )
          }
        })}
        onKeyDown={(e) => {
          const hitEnter = e.key === 'Enter' && !e.shiftKey
          if (hitEnter) {
            if (onCtrlEnter && e.ctrlKey) {
              onCtrlEnter(quill)
            } else if (onEnter) {
              onEnter(quill)
            }
          }
        }}
        onBlur={() => {
          if (typeof onBlur === 'function' && quill.current && !isModalOpen) {
            onBlur(
              JSON.stringify(quill.current.getContents()),
              stripWhitespaceAndBom(quill.current.root.textContent),
            )
          }
        }}
        data-testid="quill-content"
      />
    </div>
  )
}

export default QuillContent
