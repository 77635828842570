import { ReactNode, useEffect, useState } from 'react'
import styles from './SnapshotRequirementHistoryEvents.module.css'
import {
  getSnapshotRequirementHistoryWithUsers,
  SnapshotHistoryEventType,
  SpecificationSnapshotRequirementHistoryEvent,
} from '../../api/v2/projects.ts'
import { HistoryEventType } from '../../api/v2/specifications.ts'
import { formatTime, monthLongDayYear } from '../../lib/date.ts'
import { reviewStates } from '../../pages/shared-specification/SharedRequirementColumns.tsx'
import { LoadingState } from '../../types/enums.ts'
import LoadingIndicator from '../loading-indicator/LoadingIndicator.tsx'

interface HistoryEventRowProps {
  createdOn: Date
  showBorder?: boolean
  children: ReactNode
}

const HistoryEventRow = (props: HistoryEventRowProps) => {
  const { createdOn, showBorder, children } = props
  return (
    <div
      className={`${styles.updatedEvent} ${showBorder ? styles.border : ''}`}
    >
      <div className={styles.eventMetadata}>
        <span className={styles.eventDate}>{monthLongDayYear(createdOn)}</span>
        <span className={styles.eventTime}>{formatTime(createdOn)}</span>
      </div>
      <div className={styles.eventChange}>{children}</div>
    </div>
  )
}

const EVENTS = {
  [SnapshotHistoryEventType.UNKNOWN]: () => null,
  [SnapshotHistoryEventType.REVIEWER_REMOVED]: ({ event }) => {
    return (
      <HistoryEventRow createdOn={event.createdOn} showBorder>
        <span className={styles.eventDetails}>
          {`${
            event?.data?.removedUser || 'unknown user'
          } removed as a reviewer and reviews deleted`}
          <span className={styles.eventUserName}>
            {event.createdBy
              ? `${event.createdBy.firstName} ${event.createdBy.lastName}`
              : 'Unknown'}
          </span>
        </span>
      </HistoryEventRow>
    )
  },
  [SnapshotHistoryEventType.STATUS_UPDATED]: ({ event }) => (
    <HistoryEventRow createdOn={event.createdOn} showBorder>
      <span className={styles.tag}>
        {reviewStates[event.data.status].component}
      </span>
      <span className={styles.eventDetails}>
        <span className={styles.eventText}>
          {reviewStates[event.data.status].label}
        </span>
        <span className={styles.eventUserName}>
          {event.createdBy
            ? `${event.createdBy.firstName} ${event.createdBy.lastName}`
            : 'Unknown'}
        </span>
      </span>
    </HistoryEventRow>
  ),
}

const SnapshotRequirementHistoryEvents = (props: {
  projectId: string
  snapshotId: string
  requirementId: string
}) => {
  const { projectId, snapshotId, requirementId } = props
  const [loading, setLoading] = useState(LoadingState.Loading)
  const [history, setHistory] = useState<
    SpecificationSnapshotRequirementHistoryEvent[]
  >([])

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(LoadingState.Loading)
      try {
        const historyEvents = await getSnapshotRequirementHistoryWithUsers(
          projectId,
          snapshotId,
          requirementId,
        )
        setHistory(historyEvents)
        setLoading(LoadingState.Loaded)
      } catch (error) {
        console.error('Unable to load history', error)
        setLoading(LoadingState.Failed)
      }
    }
    fetchHistory()
  }, [projectId, snapshotId, requirementId])

  return (
    <div className={styles.historyContainer}>
      {loading === LoadingState.Loaded && (
        <div className={styles.historyList}>
          {history.length === 0 ? (
            <div className={styles.center}>No history events available</div>
          ) : (
            history.map((historyEvent) => {
              const Component =
                EVENTS[historyEvent.type] || EVENTS[HistoryEventType.UNKNOWN]
              return <Component key={historyEvent.id} event={historyEvent} />
            })
          )}
        </div>
      )}
      {loading === LoadingState.Loading && (
        <div className={styles.center}>
          <LoadingIndicator />
        </div>
      )}
      {loading === LoadingState.Failed && (
        <div className={styles.center}>Unable to load history</div>
      )}
    </div>
  )
}

export default SnapshotRequirementHistoryEvents
