import { Maximize, ArrowUpRight } from '@carbon/icons-react'
import { PostHogFeature } from 'posthog-js/react'
import { useNavigate } from 'react-router-dom'
import styles from './BlockActions.module.css'
import { RequirementBlockActionItemProps } from './types'
import { useSpecificationContext } from '../../../context/SpecificationContext'
import { useModals } from '../../../hooks/useModals'
import { DRAWER_TAB } from '../../drawer/enums'

const RequirementDetailsAction = (props: RequirementBlockActionItemProps) => {
  const { requirement, closeMenu } = props
  const { specification } = useSpecificationContext()
  const { openRequirementDrawer } = useModals()
  const navigate = useNavigate()

  return (
    <div>
      <PostHogFeature flag="requirement-details-page" match>
        <button
          className={`${styles.actionItem} ${styles.borderBottom}`}
          onClick={() => {
            closeMenu()
            navigate(
              `/specifications/${specification.id}/requirements/${requirement?.id}`,
            )
          }}
        >
          <ArrowUpRight />
          View Requirement
        </button>
      </PostHogFeature>
      <button
        className={`${styles.actionItem} ${styles.borderBottom}`}
        onClick={() => {
          closeMenu()
          openRequirementDrawer({
            requirement: requirement!,
            initialTab: DRAWER_TAB.HISTORY,
            specificationId: specification.id,
          })
        }}
      >
        <Maximize />
        Open details
      </button>
    </div>
  )
}

export default RequirementDetailsAction
