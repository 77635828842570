import { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './Tooltip.module.css'

interface TooltipProps {
  className?: string
  children: ReactNode
  content?: string | ReactNode
  placement?: 'left' | 'right' | 'top' | 'bottom'
}

const placementToStyle = {
  top: styles.placeTop,
  bottom: styles.placeBottom,
  left: styles.placeLeft,
  right: styles.placeRight,
}

const Tooltip = (props: TooltipProps) => {
  const { className, children, content, placement = 'bottom' } = props
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
  const timeoutRef = useRef<number | null>(null)
  const placementClass = placementToStyle[placement] || styles.placeBottom

  const showTooltip = () => {
    clearTimeout(timeoutRef.current as number)
    if (!content) {
      return
    }

    setIsTooltipVisible(true)
  }

  const hideTooltip = () => {
    // Timeout used to allow user some time to navigate to tooltip if needed,
    // otherwise tooltip may close too quickly upon mouse out.
    timeoutRef.current = window.setTimeout(
      () => setIsTooltipVisible(false),
      100,
    )
  }

  // Cleanup when component unmounts to avoid memory leak.
  useEffect(() => () => clearTimeout(timeoutRef.current as number), [])

  return (
    <div
      className={`${styles.tooltipContainer} ${className || ''}`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      data-testid="tooltip-container"
    >
      {children}
      {content && isTooltipVisible && (
        <div className={`${styles.tooltip} ${placementClass}`}>
          {typeof content === 'string' ? (
            <div className={styles.textContent}>{content}</div>
          ) : (
            content
          )}
        </div>
      )}
    </div>
  )
}

export default Tooltip
