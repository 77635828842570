import { SetStateAction, useEffect, useRef } from 'react'
import styles from './TextInput.module.css'

type TextInputProps = {
  className?: string
  id?: string
  label?: string
  placeholder?: string
  value: string
  setValue: (val: string) => void | React.Dispatch<SetStateAction<string>>
  focusOnLoad?: boolean

  readOnly?: boolean
  maxLength?: number
}

const WithLabel = (props: {
  id: string
  label: string
  className?: string
  children: any
}) => {
  return (
    <label
      htmlFor={props.id}
      className={`${styles.label} ${props.className || ''}`}
    >
      <span>{props.label}</span>
      {props.children}
    </label>
  )
}

const WithoutLabel = (props: { className?: string; children: any }) => {
  return (
    <div className={`${styles.container}  ${props.className || ''}`}>
      {props.children}
    </div>
  )
}

export const TextInput = (props: TextInputProps) => {
  const {
    className,
    id,
    label,
    placeholder,
    value,
    setValue,
    focusOnLoad,
    ...otherProps
  } = props

  const handleValueChange = (e) => {
    setValue(e.target.value)
  }

  const el = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusOnLoad && el.current) {
      el.current.focus()
    }
  }, [focusOnLoad])

  const Wrapper = label ? WithLabel : WithoutLabel

  return (
    <Wrapper id={id || ''} label={label || ''} className={className}>
      <input
        ref={el}
        type="text"
        id={id}
        name={id}
        placeholder={placeholder}
        value={value}
        className={styles.input}
        onChange={handleValueChange}
        {...otherProps}
      />
    </Wrapper>
  )
}
