import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

interface DragDropCtx {
  activeDnDId: string | null
  setActiveDnDId: Dispatch<SetStateAction<string | null>>
}

const DragDropContext = createContext<DragDropCtx>({
  activeDnDId: null,
  setActiveDnDId: () => {},
})

const DragDropContextProvider = (props) => {
  const [activeDnDId, setActiveDnDId] = useState<string | null>(null)

  return (
    <DragDropContext.Provider value={{ activeDnDId, setActiveDnDId }}>
      {props.children}
    </DragDropContext.Provider>
  )
}

const useDragDropContext = () => {
  const ctx = useContext(DragDropContext)
  if (!ctx) {
    console.error('useDragDropContext has no provider')
  }
  return ctx
}

export { DragDropContextProvider, useDragDropContext }
