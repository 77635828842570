import Tag from '.'
import { ChangeRequestStatus } from '../../types/enums'

const TAG_COLORS = {
  [ChangeRequestStatus.Requested]: {
    fontColor: '#121619',
    backgroundColor: '#C4C9D1',
  },
  [ChangeRequestStatus.Closed]: {
    fontColor: '#121619',
    backgroundColor: '#C4C9D1',
  },
  [ChangeRequestStatus.InReview]: {
    fontColor: '#6929C4',
    backgroundColor: '#DCC7FF',
  },
  [ChangeRequestStatus.Implemented]: {
    fontColor: '#005D5D',
    backgroundColor: '#9EF0F0',
  },
  [ChangeRequestStatus.Approved]: {
    fontColor: '#0E6027',
    backgroundColor: '#A7F0BA',
  },
  [ChangeRequestStatus.Cancelled]: {
    fontColor: '#9F1853',
    backgroundColor: '#FFD6E8',
  },
  [ChangeRequestStatus.Rejected]: {
    fontColor: '#A2191F',
    backgroundColor: '#FFD7D9',
  },
}

const changeRequestStatusToDisplayText = (
  status: ChangeRequestStatus,
): string => {
  switch (status) {
    case ChangeRequestStatus.InReview:
      return 'IN REVIEW'
    default:
      return status
  }
}

const ChangeRequestStatusTag = (props: {
  status: ChangeRequestStatus
  onCancel?: () => void
}) => {
  const { status, onCancel } = props

  return (
    <Tag
      text={changeRequestStatusToDisplayText(status)}
      color={TAG_COLORS[status]}
      onCancel={onCancel}
    />
  )
}

export default ChangeRequestStatusTag
