import {
  AddComment,
  ArrowRight,
  ChangeCatalog,
  CheckmarkFilled,
  Document,
  Link as CarbonLink,
  ListBoxes,
  ListChecked,
  TextScale,
  TreeView,
} from '@carbon/icons-react'
import { captureException } from '@sentry/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './DashboardPage.module.css'
import SpecificationTree from './specification-tree/SpecificationTree.tsx'
import {
  addNodeToTree,
  removeNodeFromTree,
} from './specification-tree/specificationTreeUtils.ts'
import useDashboardDataLoader from './useDashboardDataLoader.ts'
import {
  ChangeRequestAddedData,
  CommentAddedData,
  EvidenceRecordLinkedData,
  Notification,
  NotificationData,
  NotificationType,
  ParentChildLinkAddedData,
  RequirementTextChangedData,
  ReviewRequestedData,
} from '../../api/v2/notifications.ts'
import {
  getSpecificationTreeByProgramId,
  SpecificationTreeNode,
} from '../../api/v2/programs.ts'
import {
  getRequirementsCustomAttributes,
  GetRequirementsWithCustomAttributes,
} from '../../api/v2/requirements.ts'
import Button, { BUTTON_COLORS } from '../../components/button'
import LoadingIndicator from '../../components/loading-indicator/LoadingIndicator.tsx'
import ProgramSelect from '../../components/program-select/ProgramSelect.tsx'
import ComplianceTag from '../../components/tag/ComplianceTag.tsx'
import EvidenceActivityStatusTag from '../../components/tag/EvidenceActivityStatusTag.tsx'
import { useAttributesContext } from '../../context/AttributesContext.tsx'
import { useAuth } from '../../context/AuthContext.tsx'
import { useModals } from '../../hooks/useModals.ts'
import * as ls from '../../lib/localstorage.ts'
import { EvidenceActivityStatus, LoadingState } from '../../types/enums.ts'
import { ApiError } from '../../types/errors.ts'

const NotificationInfo = ({ icon, message }) => {
  return (
    <div className={styles.notificationInfo}>
      <span>{icon}</span>
      <span>{message}</span>
    </div>
  )
}

const NOTIFICATION_REQUIREMENT_TYPE_TO_PRETTY_NAME = {
  [NotificationType.PARENT_CHILD_LINK_ADDED]: (
    <NotificationInfo icon={<CarbonLink />} message="Parent link added" />
  ),
  [NotificationType.REQUIREMENT_TEXT_CHANGED]: (
    <NotificationInfo icon={<TextScale />} message="Text changed" />
  ),
  [NotificationType.EVIDENCE_RECORD_LINKED]: (
    <NotificationInfo icon={<ListChecked />} message="Evidence linked" />
  ),
}

const getNotificationDisplayData = (
  notification: Notification<NotificationData>,
) => {
  switch (notification.type) {
    case NotificationType.REVIEW_REQUESTED: {
      const data = notification.data as ReviewRequestedData
      return {
        link: `/specifications/${data.specificationId}`,
        linkText: 'View specification',
        text: data.specificationName || 'Untitled',
        icon: () => (
          <CheckmarkFilled className={styles.filledCheck} size={16} />
        ),
      }
    }
    case NotificationType.COMMENT_ADDED: {
      const data = notification.data as CommentAddedData
      return {
        link: `/specifications/${data.specificationId}/document/${data.requirementId}`,
        linkText: 'Respond',
        text: data.requirementTitle || 'Untitled',
        icon: () => <AddComment size={16} />,
      }
    }
    case NotificationType.CHANGE_REQUEST_ADDED: {
      const data = notification.data as ChangeRequestAddedData
      return {
        link: `/specifications/${data.specificationId}/document/${data.requirementId}`,
        linkText: 'Review',
        text: data.requirementTitle || 'Untitled',
        icon: () => <ChangeCatalog size={16} />,
      }
    }
    case NotificationType.REQUIREMENT_TEXT_CHANGED:
    case NotificationType.EVIDENCE_RECORD_LINKED:
    case NotificationType.REQUIREMENT_COMPLIANCE_CHANGED:
    case NotificationType.PARENT_CHILD_LINK_ADDED: {
      const data = notification.data as
        | EvidenceRecordLinkedData
        | ParentChildLinkAddedData
        | RequirementTextChangedData
      return {
        link: `/specifications/${data.specificationId}/document/${data.requirementId}`,
      }
    }
  }
  return {}
}

const OverviewModule = ({
  selectedProgram,
  selectedSpecificationsCount,
  selectedRecordsCount,
  selectedRequirementsCount,
  selectedActivitiesCount,
}) => {
  const modules = useMemo(() => {
    let paramsStr = ''

    if (selectedProgram) {
      const searchParams = new URLSearchParams()
      searchParams.append('programId', selectedProgram.id)
      paramsStr = '?' + searchParams.toString()
    }

    return [
      {
        label: 'Specifications',
        Icon: Document,
        data: selectedSpecificationsCount,
        link: () => (
          <Link className={styles.link} to={`/specifications${paramsStr}`}>
            View specifications
          </Link>
        ),
      },
      {
        label: 'Requirements',
        Icon: ListBoxes,
        data: selectedRequirementsCount,
      },
      {
        label: 'Records',
        Icon: ListChecked,
        data: selectedRecordsCount,
        link: () => (
          <Link className={styles.link} to={`/evidence/records${paramsStr}`}>
            View records
          </Link>
        ),
      },
      {
        label: 'Activities',
        Icon: ChangeCatalog,
        data: selectedActivitiesCount,
        link: () => (
          <Link className={styles.link} to={`/evidence/activities${paramsStr}`}>
            View activities
          </Link>
        ),
      },
    ]
  }, [
    selectedActivitiesCount,
    selectedProgram,
    selectedRecordsCount,
    selectedRequirementsCount,
    selectedSpecificationsCount,
  ])

  return (
    <div className={styles.overviewModule}>
      {modules.map(({ label, Icon, data, link: Link }) => (
        <div className={`${styles.iconBox} ${styles.outline}`} key={label}>
          <div className={styles.title}>
            <span>{label}</span>
            <span>
              <Icon className={styles.icon} size={24} />
            </span>
          </div>
          <div className={styles.count}>{data}</div>
          {Link && <Link />}
        </div>
      ))}
    </div>
  )
}

const RequirementComplianceModule = ({ selectedProgram }) => {
  const [requirements, setRequirements] = useState<
    GetRequirementsWithCustomAttributes[]
  >([])
  const { getComplianceStyles } = useAttributesContext()
  useEffect(() => {
    const fetchRequirements = async () => {
      const resp = await getRequirementsCustomAttributes(selectedProgram?.id)
      setRequirements(resp.requirements)
    }

    fetchRequirements()
  }, [selectedProgram])

  const complianceTagsWithCount = requirements
    .filter((requirement) => requirement.complianceName !== null)
    .reduce((acc, requirement) => {
      if (acc[requirement.complianceName] === undefined) {
        acc[requirement.complianceName] = 0
      }
      acc[requirement.complianceName] += 1
      return acc
    }, {})
  return (
    <div className={`${styles.requirementComplianceModule} ${styles.outline}`}>
      <h3>Requirement Compliance</h3>
      {(Object.entries(complianceTagsWithCount) as [string, number][]).map(
        ([complianceName, count]) => {
          const compliance = getComplianceStyles(complianceName)
          if (!compliance) {
            return null
          }
          return (
            <div className={styles.outline} key={complianceName}>
              <ComplianceTag
                complianceName={compliance.name}
                color={compliance.styles}
              />
              <span>{count} requirements</span>
            </div>
          )
        },
      )}
    </div>
  )
}

const EvidenceActivitiesByStatusModule = ({
  evidenceActivityTags,
  selectedProgram,
}) => {
  return (
    <div
      className={`${styles.evidenceActivitiesByStatusModule} ${styles.outline}`}
    >
      <h3>Evidence Activities by Status</h3>
      {(
        Object.entries(evidenceActivityTags) as [
          EvidenceActivityStatus,
          number,
        ][]
      ).map(([statusTag, count]) => {
        const programParam = selectedProgram
          ? `&programId=${selectedProgram.id}`
          : ''
        return (
          <div className={styles.outline} key={statusTag}>
            <Link
              to={`/evidence/activities?status=${statusTag}${programParam}`}
              className={styles.statusLink}
            >
              <EvidenceActivityStatusTag status={statusTag} />
              <span>{count}</span>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

const SpecificationsTreeModule = ({ selectedProgram }) => {
  const { openSpecificationTreeBuilderModal } = useModals()
  const { isAdmin } = useAuth()
  const [treeData, setTreeData] = useState<SpecificationTreeNode | null>(null)
  const [treeDataLoading, setTreeDataLoading] = useState<{
    state: LoadingState
    message: string | null
    error: number | null
  }>({ state: LoadingState.Loading, message: null, error: null })

  const loadTreeData = useCallback(async () => {
    if (!selectedProgram) {
      return
    }

    setTreeData(null)
    setTreeDataLoading({
      state: LoadingState.Loading,
      message: null,
      error: null,
    })

    try {
      const specificationTree = await getSpecificationTreeByProgramId(
        selectedProgram.id,
      )
      setTreeData(specificationTree)
      setTreeDataLoading({
        state: LoadingState.Loaded,
        message: null,
        error: null,
      })
    } catch (error) {
      let message = 'Unable to display specification tree.'
      captureException(error)
      if (error instanceof ApiError && error.fetchResponse.status === 404) {
        message =
          'No specification trees have been created for this program yet.'
        setTreeDataLoading({
          state: LoadingState.Failed,
          message,
          error: 404,
        })
        return
      }

      setTreeDataLoading({
        state: LoadingState.Failed,
        message,
        error: error instanceof ApiError ? error.fetchResponse.status : null,
      })
    }
  }, [selectedProgram])

  useEffect(() => {
    loadTreeData()
  }, [loadTreeData])

  const onAddSpec = useCallback(
    (
      createdNode: SpecificationTreeNode,
      parentNode?: SpecificationTreeNode | null,
    ) => {
      const newNode = {
        id: createdNode.id,
        specificationTreeId: createdNode.specificationTreeId,
        specification: {
          identifier: createdNode.specification.identifier,
          id: createdNode.specification.id,
          name: createdNode.specification.name,
        },
        children: [],
      }

      setTreeData((prevTree) => {
        if (!prevTree) {
          return {
            id: 'placeholder',
            specificationTreeId: '',
            specification: {
              id: '',
              name: '',
              identifier: '',
            },
            children: [newNode],
          }
        }

        return addNodeToTree(prevTree, newNode, parentNode?.id)
      })

      setTreeDataLoading({
        state: LoadingState.Loaded,
        message: null,
        error: null,
      })
    },
    [],
  )

  const onDeleteSpec = useCallback((nodeId: string) => {
    setTreeData((prevTree) => {
      if (!prevTree) {
        return prevTree
      }
      return removeNodeFromTree(prevTree, nodeId)
    })
  }, [])

  return (
    <>
      {selectedProgram && (
        <div className={styles.specTree}>
          <div className={styles.headerWrapper}>
            <h3>Specification Tree</h3>
            {isAdmin &&
              (treeDataLoading.state === LoadingState.Loaded ||
                treeDataLoading.error === 404) && (
                <Button
                  text="Open tree builder"
                  endIcon={<TreeView />}
                  color={BUTTON_COLORS.PRIMARY}
                  onClick={() =>
                    openSpecificationTreeBuilderModal({
                      program: selectedProgram,
                      treeData,
                      onAddSpec,
                      onDeleteSpec,
                    })
                  }
                />
              )}
          </div>
          <div className={styles.specTreeWrapper}>
            <SpecificationTree
              treeData={treeData}
              treeDataLoading={treeDataLoading}
            />
          </div>
        </div>
      )}
    </>
  )
}

const DashboardPage = () => {
  const {
    isLoading,
    selectedProgram,
    setSelectedProgram,
    updatedReqNotifications,
    evidenceActivityTags,
    selectedSpecificationsCount,
    selectedRecordsCount,
    selectedRequirementsCount,
    selectedActivitiesCount,
  } = useDashboardDataLoader()
  const { activePrograms } = useAttributesContext()

  useEffect(() => {
    window.document.title = 'Dashboard'
  }, [])

  return (
    <div className={styles.container}>
      <div className={`${styles.header} ${styles.outline}`}>
        <h1>Dashboard</h1>
        {activePrograms.length > 0 && (
          <div className={styles.filterSection}>
            <span>Filter by program:</span>
            <ProgramSelect
              selectedProgramId={selectedProgram?.id}
              onSelect={(program) => {
                setSelectedProgram(program)
                ls.setRecentlyUsedDashboardFilter(program)
              }}
              onCancel={() => {
                setSelectedProgram(null)
                ls.setRecentlyUsedDashboardFilter(null)
              }}
            />
          </div>
        )}
      </div>
      <WithLoading isLoading={isLoading}>
        <SpecificationsTreeModule selectedProgram={selectedProgram} />
        <div className={styles.modules}>
          <RequirementComplianceModule selectedProgram={selectedProgram} />
          <OverviewModule
            selectedProgram={selectedProgram}
            selectedSpecificationsCount={selectedSpecificationsCount}
            selectedRecordsCount={selectedRecordsCount}
            selectedRequirementsCount={selectedRequirementsCount}
            selectedActivitiesCount={selectedActivitiesCount}
          />
          <EvidenceActivitiesByStatusModule
            evidenceActivityTags={evidenceActivityTags}
            selectedProgram={selectedProgram}
          />
          <div
            className={`${styles.requirementUpdatesModule} ${styles.outline}`}
          >
            <h3>Requirement updates</h3>
            <table className={styles.requirements}>
              <tbody>
                {updatedReqNotifications.map((notification) => {
                  const link =
                    getNotificationDisplayData(notification).link || ''
                  return (
                    <tr className={styles.requirement} key={notification.id}>
                      <td className={`${styles.title} ${styles.cell}`}>
                        {notification.data?.requirementTitle || 'Untitled'}
                      </td>
                      <td className={styles.cell}>
                        <Link className={styles.specName} to={link}>
                          <Document size={16} />
                          <span>
                            {notification.data?.specificationName || 'Untitled'}
                          </span>
                        </Link>
                      </td>
                      <td className={`${styles.type} ${styles.cell}`}>
                        {
                          NOTIFICATION_REQUIREMENT_TYPE_TO_PRETTY_NAME[
                            notification.type
                          ]
                        }
                      </td>
                      <td className={`${styles.fromUser} ${styles.cell}`}>
                        {notification.data?.userFullName}
                      </td>
                      <td className={styles.cell}>
                        <Link className={styles.arrow} to={link}>
                          <ArrowRight />
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {(!updatedReqNotifications ||
              updatedReqNotifications.length === 0) && (
              <span className={styles.noResults}>
                No recent requirement updates found
                {selectedProgram ? ' for selected program.' : '.'}
              </span>
            )}
          </div>
        </div>
      </WithLoading>
    </div>
  )
}

const WithLoading = (props: { isLoading: boolean; children: any }) => {
  const { isLoading, children } = props

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingContent}>
          <LoadingIndicator />
        </div>
      ) : (
        children
      )}
    </>
  )
}
export default DashboardPage
