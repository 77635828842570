import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useAuth } from './AuthContext.tsx'
import { BlockType, createBlock } from '../api/v2/blocks'
import {
  createDocumentSection,
  getDocument,
  SectionType,
} from '../api/v2/documents'
import * as revisionApi from '../api/v2/revisions'
import * as api from '../api/v2/specifications'
import * as userApi from '../api/v2/users.ts'

type SpecificationList = {
  specifications: Array<api.Specification>
  userReviewRequests: {
    specificationId: string
    specificationName: string
  }[]
  createSpecification?: (
    name?: string,
  ) => Promise<api.CreateSpecificationResponse>
  deleteSpecification: (id: string) => void
  updateSpecificationCategory: (
    id: string,
    categoryId: string | null,
  ) => Promise<void>
  updateSpecificationProgram: (
    id: string,
    programId: string | null,
  ) => Promise<void>
  updateSpecificationPhase: (
    id: string,
    phaseId: string | null,
  ) => Promise<void>
  loading: boolean
  publicTenant: boolean
}

export const useSpecificationListContext = () => {
  const ctx = useContext(SpecificationListContext)
  if (!ctx) {
    console.error('SpecificationListContext has no provider')
  }
  return ctx
}

export const SpecificationListContext = createContext<SpecificationList>({
  specifications: [],
  userReviewRequests: [],
  deleteSpecification: () => {},
  updateSpecificationCategory: () => Promise.resolve(),
  updateSpecificationProgram: () => Promise.resolve(),
  updateSpecificationPhase: () => Promise.resolve(),
  loading: false,
  publicTenant: false,
})

export const SpecificationListContextProvider = (props: {
  children: ReactNode
  publicTenant?: boolean
}) => {
  const { userDetails, reload } = useAuth()
  const [specifications, setSpecifications] = useState<
    Array<api.Specification>
  >([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const { getAllSpecifications } = props.publicTenant
        ? api.publicTenantMethods
        : api

      const specs = await getAllSpecifications()

      setSpecifications(
        specs.map((s) => ({
          ...s,
          status: s.status || revisionApi.RevisionStatus.ARCHIVED,
        })),
      )

      setLoading(false)
    }
    fetchData()
  }, [props.publicTenant])

  const [userReviewRequests, setUserReviewRequests] = useState<
    { specificationId: string; specificationName: string }[]
  >([])
  useEffect(() => {
    const loadUserReviewRequests = async () => {
      if (!userDetails?.id?.trim() || !specifications.length) {
        setUserReviewRequests([])
        return
      }

      const pendingSpecs = await userApi.getPendingReviewSpecIds(
        userDetails?.id,
      )
      setUserReviewRequests(
        pendingSpecs.map((specId) => {
          const spec = specifications.find(
            (specification) => specification.id === specId,
          )

          return {
            specificationId: specId,
            specificationName: spec?.name || 'Unknown',
          }
        }),
      )
    }

    loadUserReviewRequests()
  }, [specifications, userDetails?.id])

  const createSpecification = useCallback(
    async (name?: string) => {
      const spec = await api.createSpecification(name || 'Untitled')
      const [firstRevision] = await revisionApi.getRevisions(spec.id)
      const document = await getDocument(spec.id, firstRevision.documentId)
      const bodySection = await createDocumentSection(
        spec.id,
        document.id,
        null,
        SectionType.Body,
      )
      await createBlock(spec.id, firstRevision.documentId, bodySection.id, {
        type: BlockType.Heading,
        data: { _data: { text: '' } },
      })
      reload()
      return spec
    },
    [reload],
  )

  const deleteSpecification = useCallback(async (id) => {
    const { id: deletedId } = await api.deleteSpecification(id)
    setSpecifications((specs) => specs.filter((s) => s.id !== deletedId))
  }, [])

  const updateSpecificationCategory = useCallback(
    async (id: string, categoryId: string | null) => {
      const update = await api.updateSpecification(id, { category: categoryId })
      setSpecifications((specs) =>
        specs.map((s) =>
          s.id === id
            ? {
                ...update,
                status: s.status,
                organization: s.organization,
                requirementCount: s.requirementCount,
              }
            : s,
        ),
      )
    },
    [],
  )

  const updateSpecificationProgram = useCallback(async (id, programId) => {
    const update = await api.updateSpecification(id, { program: programId })
    setSpecifications((specs) =>
      specs.map((s) =>
        s.id === id
          ? {
              ...update,
              status: s.status,
              organization: s.organization,
              requirementCount: s.requirementCount,
            }
          : s,
      ),
    )
  }, [])

  const updateSpecificationPhase = useCallback(async (id, phaseId) => {
    const update = await api.updateSpecification(id, { phase: phaseId })
    setSpecifications((specs) =>
      specs.map((s) =>
        s.id === id
          ? {
              ...update,
              status: s.status,
              organization: s.organization,
              requirementCount: s.requirementCount,
            }
          : s,
      ),
    )
  }, [])

  return (
    <SpecificationListContext.Provider
      value={{
        specifications,
        userReviewRequests,
        createSpecification,
        deleteSpecification,
        updateSpecificationCategory,
        updateSpecificationProgram,
        updateSpecificationPhase,
        loading,
        publicTenant: !!props.publicTenant,
      }}
    >
      {props.children}
    </SpecificationListContext.Provider>
  )
}
