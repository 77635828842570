import {
  CircleDash,
  CircleSolid,
  OverflowMenuVertical,
  TrashCan,
} from '@carbon/icons-react'
import { useState } from 'react'
import styles from './CommentCard.module.css'
import { UUID } from '../../../../api/utilityTypes.ts'
import { RequirementComment } from '../../../../api/v2/requirements.ts'
import Avatar from '../../../../components/avatar'
import { AvatarSize } from '../../../../components/avatar/constants.ts'
import { useAuth } from '../../../../context/AuthContext.tsx'
import useClickOutside from '../../../../hooks/useClickOutside.ts'
import { monthDayYearTime } from '../../../../lib/date.ts'

const CommentCard = (props: {
  comment: RequirementComment
  requirementVersion?: string
  onDelete?: () => void
  onToggleResolve?: (commentId: UUID, resolveComment: boolean) => Promise<void>
}) => {
  const { comment, requirementVersion, onDelete, onToggleResolve } = props
  const [actionsOpen, setActionsOpen] = useState(false)
  const [togglingResolved, setTogglingResolved] = useState(false)
  const ref = useClickOutside(() => setActionsOpen(false), actionsOpen)

  const { userDetails } = useAuth()
  const userId = userDetails?.id

  return (
    <div className={`${styles.commentCard} ${styles.basicElevation}`}>
      <div className={styles.commentHeader}>
        <span className={styles.leftHeader}>
          <Avatar
            size={AvatarSize.MediumSmall}
            firstName={comment.createdBy?.firstName}
            lastName={comment.createdBy?.lastName}
            showName
          />
          <span className={styles.date}>
            {monthDayYearTime(comment.createdOn)}
          </span>
        </span>
        <span className={styles.rightHeader}>
          {requirementVersion && <span>Version {requirementVersion}</span>}
          {onToggleResolve && (
            <button
              className={styles.resolutionBtn}
              onClick={async () => {
                setTogglingResolved(true)
                await onToggleResolve(comment.id, !comment.resolved)
                setTogglingResolved(false)
              }}
              disabled={togglingResolved}
            >
              {comment.resolved ? <CircleSolid /> : <CircleDash />}
              <span className={styles.resolutionState}>
                {comment.resolved ? 'Resolved' : 'Resolve'}
              </span>
            </button>
          )}
        </span>
      </div>
      <div className={styles.content}>
        <div
          className={`${styles.commentBody} ${
            comment.resolved ? styles.resolved : ''
          }`}
        >
          {comment.body}
        </div>
        {userId === comment.createdBy.id && onDelete && (
          <div ref={ref} className={styles.actionMenu}>
            <button onClick={() => setActionsOpen(!actionsOpen)}>
              <OverflowMenuVertical size={20} className={styles.icon} />
            </button>
            <div
              className={`${styles.dropdown} ${styles.basicElevation} ${
                actionsOpen ? styles.open : ''
              }`}
            >
              <button onClick={onDelete}>
                <TrashCan size={16} />
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CommentCard
