import api, { usingPublicTenant } from './api'
import {
  EntityReviewEventOperation,
  EntityReviewReviewerEventOperation,
  EntityReviewReviewerStatus,
  EntityReviewStatus,
} from '../../types/api/v2/entity.ts'
import {
  RevisionReview,
  RevisionReviewReviewer,
} from '../../types/api/v2/revisions.ts'
import { UUID } from '../utilityTypes.ts'

const createUrl = (specificationId: string, revisionId?: string) =>
  `/api/v2/specifications/${specificationId}/revisions${
    revisionId ? `/${revisionId}` : ''
  }`

export enum RevisionStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  ARCHIVED = 'ARCHIVED',
  CLONING = 'CLONING',
  SUPERSEDED = 'SUPERSEDED',
}

const withDates: (revision: RevisionResponse) => Revision = (revision) => ({
  ...revision,
  createdOn: new Date(revision.createdOn),
  lastModifiedOn: revision.lastModifiedOn
    ? new Date(revision.lastModifiedOn)
    : new Date(revision.createdOn),
})

interface RevisionResponse {
  id: UUID
  documentId: UUID
  version: number
  status: RevisionStatus
  exportControlled: boolean
  createdBy: UUID
  createdOn: string
  lastModifiedBy: UUID
  lastModifiedOn: string
}

export interface Revision
  extends Omit<RevisionResponse, 'createdOn' | 'lastModifiedOn'> {
  createdOn: Date
  lastModifiedOn: Date
}

export const getRevisions: (
  specificationId: string,
) => Promise<Revision[]> = async (specificationId) => {
  const { revisions } = await api.get(createUrl(specificationId))
  return revisions.map(withDates)
}

export const createRevision: (
  specificationId: string,
) => Promise<Revision> = async (specificationId) => {
  const revision = await api.post(createUrl(specificationId), { body: {} })
  return withDates(revision)
}

export const getRevision: (
  specificationId: string,
  revisionId: string,
) => Promise<Revision> = async (specificaitonId, revisionId) => {
  const revision = await api.get(createUrl(specificaitonId, revisionId))
  return withDates(revision)
}

type UpdateRevisionRequest = {
  exportControlled: boolean
}

export const updateRevision: (
  specificationId: string,
  revisionId: string,
  request: UpdateRevisionRequest,
) => Promise<Revision> = async (specificationId, revisionId, request) => {
  const revision = await api.patch(createUrl(specificationId, revisionId), {
    body: request,
  })
  return withDates(revision)
}

/**
 * Revision reviews
 */

interface RevisionReviewResponse {
  id: string
  status: EntityReviewStatus
  data: {
    specificationId: string
  }
}

const mapResponseToRevisionReview = (
  review: RevisionReviewResponse,
): RevisionReview => {
  return {
    id: review.id,
    status: review.status,
    specificationId: review.data.specificationId,
  }
}

export const getRevisionReviews = async (
  specificationId: string,
  revisionId: string,
): Promise<RevisionReview[]> => {
  const reviewsResponse: {
    reviews: RevisionReviewResponse[]
  } = await api.get(`${createUrl(specificationId, revisionId)}/reviews`)

  return reviewsResponse.reviews.map(mapResponseToRevisionReview)
}

export const createRevisionReview = async (
  specificationId: string,
  revisionId: string,
) => {
  const createResponse: {
    id: string
    createdBy: string
    createdOn: string
  } = await api.post(`${createUrl(specificationId, revisionId)}/reviews`, {
    body: {},
  })

  return createResponse.id
}

export const getRevisionReview = async (
  specificationId: string,
  revisionId: string,
  reviewId: string,
) => {
  const reviewResponse: RevisionReviewResponse = await api.get(
    `${createUrl(specificationId, revisionId)}/reviews/${reviewId}`,
  )

  return mapResponseToRevisionReview(reviewResponse)
}

export const createRevisionReviewEvent = async (
  specificationId: string,
  revisionId: string,
  reviewId: string,
  action: EntityReviewEventOperation,
): Promise<RevisionReview> => {
  const reviewResponse: RevisionReviewResponse = await api.post(
    `${createUrl(specificationId, revisionId)}/reviews/${reviewId}/events`,
    { body: { operation: action } },
  )

  return mapResponseToRevisionReview(reviewResponse)
}

/**
 * Revision review reviewers
 */

export const createRevisionReviewReviewer = async (
  specificationId: string,
  revisionId: string,
  reviewId: string,
  userId: string,
) => {
  const createResponse: {
    specificationId: string
    revisionId: string
    reviewId: string
    reviewerId: string
  } = await api.post(
    `${createUrl(
      specificationId,
      revisionId,
    )}/reviews/${reviewId}/reviewers/${userId}`,
    { body: {} },
  )

  return createResponse.reviewerId
}

export const getRevisionReviewReviewers = async (
  specificationId: string,
  revisionId: string,
  reviewId: string,
): Promise<RevisionReviewReviewer[]> => {
  const reviewersResponse: {
    reviewers: Record<
      string,
      {
        status: EntityReviewReviewerStatus
        requestedBy: string
        requestedOn: string
        updatedOn: string
      }
    >
  } = await api.get(
    `${createUrl(specificationId, revisionId)}/reviews/${reviewId}/reviewers`,
  )

  return Object.entries(reviewersResponse.reviewers).map(
    ([userId, reviewer]) => ({
      ...reviewer,
      userId,
      requestedOn: new Date(reviewer.requestedOn),
      updatedOn: new Date(reviewer.updatedOn),
    }),
  )
}

export const createRevisionReviewReviewerEvent = async (
  specificationId: string,
  revisionId: string,
  reviewId: string,
  userId: string,
  review: EntityReviewReviewerEventOperation,
): Promise<object> => {
  return await api.post(
    `${createUrl(
      specificationId,
      revisionId,
    )}/reviews/${reviewId}/reviewers/${userId}/events`,
    { body: { operation: review } },
  )
}

export const publicTenantMethods = {
  getRevisions: async (specificationId: string): Promise<Revision[]> => {
    const { revisions } = await usingPublicTenant(api.get)(
      createUrl(specificationId),
    )
    return revisions.map(withDates)
  },
}
