import { Add } from '@carbon/icons-react'
import { Dispatch, SetStateAction } from 'react'
import styles from './ExceptionForm.module.css'
import ExceptionLinkedRequirementBox from './ExceptionLinkedRequirementBox'
import ExceptionProgramSelect from './ExceptionProgramSelect'
import {
  CreateExceptionRequest,
  ExtendedRequirementExceptionLink,
} from '../../api/v2/exceptions'
import Button, { BUTTON_COLORS } from '../../components/button'
import ExceptionStatusTag from '../../components/tag/ExceptionStatusTag'
import ExceptionTypeTag from '../../components/tag/ExceptionTypeTag'
import ProgramTag from '../../components/tag/ProgramTag'
import globalStyles from '../../global.module.css'
import { useModals } from '../../hooks/useModals'
import { ExceptionType } from '../../types/enums'

interface ExceptionFormProps {
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  exceptionRecord: CreateExceptionRequest
  setExceptionRecord: Dispatch<SetStateAction<CreateExceptionRequest>>
  linkedRequirements: ExtendedRequirementExceptionLink[]
  setLinkedRequirements: Dispatch<
    SetStateAction<ExtendedRequirementExceptionLink[]>
  >
}

const ExceptionForm = (props: ExceptionFormProps) => {
  const {
    editMode,
    exceptionRecord,
    setExceptionRecord,
    linkedRequirements,
    setLinkedRequirements,
  } = props
  const { openAddLinkedRequirementExceptionModal } = useModals()

  const handleInputChange = (field: string, value: string) => {
    setExceptionRecord((prevRecord) => ({
      ...prevRecord,
      [field]: value,
    }))
  }

  const handleDeleteLinkedRequirement = (
    e: React.MouseEvent,
    index: number,
  ) => {
    e.preventDefault()
    setLinkedRequirements((prevRequirements) =>
      prevRequirements.filter((_, i) => i !== index),
    )
  }

  if (editMode) {
    return (
      <>
        <div className={styles.exceptionRecord}>
          <form>
            <div className={styles.formItem}>
              <div className={styles.formItemLabel}>Mission/Program Name</div>
              <ExceptionProgramSelect
                value={exceptionRecord.program || ''}
                onSelect={(program) => handleInputChange('program', program)}
              />
            </div>
            <div className={styles.formItem}>
              <div className={styles.formItemLabel}>Type</div>
              <div className={styles.radioButtons}>
                {Object.values(ExceptionType).map((type) => {
                  return (
                    <label key={type}>
                      <input
                        className={styles.editable}
                        type="radio"
                        value={type}
                        checked={exceptionRecord.type === type}
                        onChange={() => handleInputChange('type', type)}
                      />
                      <span
                        className={`${styles.radioLabel} ${styles.editable}`}
                      >
                        {type.toLowerCase()}
                      </span>
                    </label>
                  )
                })}
              </div>
            </div>
            <div className={styles.formItem}>
              <label className={styles.formItemLabel} htmlFor="title">
                Exception Title
              </label>
              <input
                id="title"
                className={globalStyles.basicElevation}
                placeholder="Add Title"
                value={exceptionRecord.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
                maxLength={256}
              />
            </div>
            <div className={styles.formItem}>
              <label className={styles.formItemLabel} htmlFor="requirements">
                Requirement(s)
              </label>
              <div style={{ marginBottom: '10px' }}>
                <Button
                  text={'Select Requirements'}
                  onClick={(e) => {
                    e.preventDefault()
                    openAddLinkedRequirementExceptionModal({
                      existingLinks: linkedRequirements,
                      addLinkedRequirement: (
                        linkToAdd: ExtendedRequirementExceptionLink,
                      ) => {
                        setLinkedRequirements((prev) => [...prev, linkToAdd])
                      },
                    })
                  }}
                  endIcon={<Add size={16} />}
                  color={BUTTON_COLORS.SECONDARY}
                />
              </div>
              <div className={styles.requirementsList}>
                {linkedRequirements.map(
                  (requirement, index) =>
                    !requirement.isRequirementDeleted &&
                    !requirement.isSpecificationDeleted && (
                      <ExceptionLinkedRequirementBox
                        key={index}
                        requirement={requirement}
                        onDelete={(e) =>
                          handleDeleteLinkedRequirement(e, index)
                        }
                        editable={editMode}
                      />
                    ),
                )}
              </div>
            </div>
          </form>
        </div>
        <div className={styles.exceptionRecord}>
          <form>
            <div className={styles.formItem}>
              <label className={styles.formItemLabel} htmlFor="variance">
                Variance
              </label>
              <textarea
                id="variance"
                className={globalStyles.basicElevation}
                placeholder="Brief description of the variance"
                value={exceptionRecord.variance}
                onChange={(e) => handleInputChange('variance', e.target.value)}
                rows={4}
                maxLength={524288}
              />
            </div>
            <div className={styles.formItem}>
              <label className={styles.formItemLabel} htmlFor="rationale">
                Rationale/Disposition
              </label>
              <textarea
                id="rationale"
                className={globalStyles.basicElevation}
                placeholder="Add Rationale or Disposition"
                value={exceptionRecord.rationale}
                onChange={(e) => handleInputChange('rationale', e.target.value)}
                rows={4}
                maxLength={524288}
              />
            </div>
          </form>
        </div>
      </>
    )
  }

  return (
    <div className={styles.viewException}>
      <div className={`${styles.header} ${globalStyles.basicElevation}`}>
        <div className={styles.title}>{exceptionRecord.title}</div>
        {exceptionRecord.status && (
          <ExceptionStatusTag status={exceptionRecord.status} />
        )}
      </div>
      <div className={styles.exceptionRecord}>
        <div className={styles.lockedForm}>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Mission/Program Name</div>
            {exceptionRecord.program ? (
              <ProgramTag programId={exceptionRecord.program} />
            ) : (
              <div>No Program Associated</div>
            )}
          </div>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Type</div>
            <ExceptionTypeTag type={exceptionRecord.type} />
          </div>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Requirement(s)</div>
            <div className={styles.requirementsList}>
              {linkedRequirements.map(
                (requirement, index) =>
                  !requirement.isRequirementDeleted &&
                  !requirement.isSpecificationDeleted && (
                    <ExceptionLinkedRequirementBox
                      key={index}
                      requirement={requirement}
                      onDelete={(e: React.MouseEvent) =>
                        handleDeleteLinkedRequirement(e, index)
                      }
                      editable={editMode}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.exceptionRecord}>
        <div className={styles.lockedForm}>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Variance</div>
            <div>{exceptionRecord.variance}</div>
          </div>
          <div className={styles.formItem}>
            <div className={styles.formItemLabel}>Rationale/Disposition</div>
            <div>{exceptionRecord.rationale}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ExceptionForm
