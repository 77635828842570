import { Rocket } from '@carbon/icons-react'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './NotificationDropdown.module.css'
import { NOTIFICATIONS } from './NotificationRow.tsx'
import { useNotifications } from '../../context/NotificationsContext.tsx'
import useClickOutside from '../../hooks/useClickOutside.ts'
import Dropdown from '../dropdown/index.tsx'

const NotificationDropdown = ({ isOpen, setIsOpen }) => {
  const { filteredNotifications, updateNotificationReadStatus } =
    useNotifications()
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isOpen && filteredNotifications) {
      timerRef.current = setTimeout(async () => {
        for (const notification of filteredNotifications) {
          if (!notification.read) {
            await updateNotificationReadStatus(notification.id, true)
          }
        }
      }, 1000)
    } else if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [isOpen, filteredNotifications, updateNotificationReadStatus])

  return (
    <Dropdown className={styles.dropdown} isOpen={isOpen}>
      {filteredNotifications && filteredNotifications.length > 0 ? (
        filteredNotifications.slice(0, 3).map((notification) => {
          const Component = NOTIFICATIONS[notification.type]
          return (
            <Component
              key={notification.id}
              notification={notification}
              setIsOpen={setIsOpen}
            />
          )
        })
      ) : (
        <div className={styles.endNotifications}>No new notifications</div>
      )}
      <Link
        className={styles.viewAllNotifications}
        to="/notifications"
        onClick={() => setIsOpen(false)}
      >
        View all notifications
      </Link>
    </Dropdown>
  )
}

const NotificationIcon = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)
  const { filteredNotifications } = useNotifications()

  useEffect(() => {
    if (filteredNotifications) {
      const unread = filteredNotifications
        .slice(0, 3)
        .some((notification) => !notification.read)
      setHasUnreadNotifications(unread)
    }
  }, [filteredNotifications])

  const containerRef = useClickOutside(() => {
    if (isDropdownOpen) {
      setIsDropdownOpen(!isDropdownOpen)
    }
  }, isDropdownOpen)

  return (
    <div ref={containerRef}>
      <button
        className={styles.rocket}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen)
        }}
      >
        <Rocket size={23} />
        {hasUnreadNotifications && <div className={styles.unread} />}
      </button>
      <NotificationDropdown
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
      />
    </div>
  )
}

export default NotificationIcon
