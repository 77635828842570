import { Group } from '@visx/group'
import { Tree } from '@visx/hierarchy'
import AnimatedLink from './AnimatedLink'
import AnimatedNode from './AnimatedNode'
import { ExpandedNode, LinkData, TreeNode } from './types'

const calculateVerticalOffset = (
  nodeHeight: number,
  nodeCount: number,
  rootHeight: number,
) => {
  const distanceBetweenLevelNodes = 10
  const distanceBetweenRootandNearest = 50
  const levelHeight =
    nodeHeight * nodeCount + distanceBetweenLevelNodes * (nodeCount - 1)
  return levelHeight / 2 + rootHeight / 2 + distanceBetweenRootandNearest
}

interface GraphSectionProps {
  position: 'left' | 'right'
  root: any
  rootHeight: number
  setRootHeight: (rootHeight: number) => void
  expandedNodeHeight: number
  setExpandedNodeHeight: (expandedNodeHeight: number) => void
  expandedNode: ExpandedNode
  setExpandedNode: (expandedNode: ExpandedNode) => void
  closeModal: () => void
}

const GraphSection = (props: GraphSectionProps) => {
  const {
    position,
    root,
    rootHeight,
    setRootHeight,
    expandedNodeHeight,
    setExpandedNodeHeight,
    expandedNode,
    setExpandedNode,
    closeModal,
  } = props
  const origin = { x: 0, y: 0 }
  const targetNodeCount = root.children ? root.children.length : 0
  const nodeWidth = 350
  const nodeHeight = 32
  const verticalOffset = calculateVerticalOffset(
    nodeHeight,
    targetNodeCount,
    rootHeight,
  )
  const expansionVerticalOffset = expandedNodeHeight - nodeHeight

  return (
    <Tree
      root={root}
      separation={() => 1}
      nodeSize={[32 + 10, 100 + 50]} // Sets a fixed size for each node; use to add distance between nodes [height + extra, width + extra]
    >
      {(tree) => (
        <Group
          top={
            expandedNode.position === 'left'
              ? origin.y + expansionVerticalOffset
              : origin.y
          }
          left={origin.x}
        >
          {tree.links().map((link: LinkData, i) => {
            return (
              <AnimatedLink
                key={`${link.source.data.requirementId}-${link.target.data.requirementId}`}
                index={i}
                link={link}
                nodeWidth={nodeWidth}
                verticalOffset={verticalOffset}
                position={position}
                expansionVerticalOffset={expansionVerticalOffset}
                expandedNode={expandedNode}
              />
            )
          })}

          {(tree.descendants() as TreeNode[]).map((node, i) => {
            if (
              position === 'left' &&
              node.depth === 0 // Skips rendering root node of parent tree
            ) {
              return
            }

            return (
              <AnimatedNode
                key={
                  node.parent
                    ? `${node.data.requirementId}-${node.parent.data.requirementId}`
                    : node.data.requirementId
                }
                index={i}
                node={node as TreeNode}
                position={position}
                verticalOffset={verticalOffset}
                expandedNode={expandedNode}
                expansionVerticalOffset={expansionVerticalOffset}
                rootHeight={rootHeight}
                nodeHeight={nodeHeight}
                expandedNodeHeight={expandedNodeHeight}
                nodeWidth={nodeWidth}
                setExpandedNode={setExpandedNode}
                setExpandedNodeHeight={setExpandedNodeHeight}
                setRootHeight={setRootHeight}
                closeModal={closeModal}
              />
            )
          })}
        </Group>
      )}
    </Tree>
  )
}

export default GraphSection
